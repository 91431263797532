import SDK from 'api.digitalpages.module.sdk.api';
import chroma from 'chroma-js';
import RDP_CONFIG from './config';

const RDP_UTILS = {};

RDP_UTILS.redirectTo = (url = '') => {
  window.location = `${
    window.location.origin + window.location.pathname
  }#/${url}`;
};

RDP_UTILS.urlRDPContentToSave = (uid) =>
  `${SDK.Api.domain}/storage/v1.0/content/path/index.html?uid=${uid}`;

RDP_UTILS.urlRDPContent = (uid) => {
  const token = SDK.Api.authorization.credential.accessToken;
  return `${SDK.Api.domain}/storage/v1.0/content/path/index.html?uid=${uid}&access_token=${token}&project_key=${SDK.Api.authorization.projectKey}&api_env=${SDK.Api.enviroment}`;
};

RDP_UTILS.urlRDPImageToSave = (uid) =>
  `${SDK.Api.domain}/storage/v1.0/content?uid=${uid}`;

RDP_UTILS.urlRDPImage = (uid) => {
  const token = SDK.Api.authorization.credential.accessToken;
  return `${SDK.Api.domain}/storage/v1.0/content?uid=${uid}&project_key=${SDK.Api.authorization.projectKey}&access_token=${token}&api_env=${SDK.Api.enviroment}`;
};

RDP_UTILS.formatProductsToSelect = (products) =>
  products.map((product) => ({
    label: `${product.name} (${product.connector_type})`,
    value: product.uid,
  }));

RDP_UTILS.formatProductsDefault = (products, seleteds) =>
  products.filter((product) => {
    if (seleteds.includes(product.value)) {
      return product;
    }
  });

RDP_UTILS.formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

RDP_UTILS.typeFile = (type) => {
  let textType = '';
  switch (type) {
    case 'application/x-zip-compressed':
      textType = 'ZIP';
      break;
    case 'image/jpeg':
      textType = 'JPG';
      break;
    case 'image/png':
      textType = 'PNG';
      break;
    case 'image/gif':
      textType = 'GIF';
      break;
    default:
      textType = 'FILE';
  }
  return textType;
};

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 20,
    width: 20,
  },
});

RDP_UTILS.ColorSelectStyle = {
  control: (provided, state) => ({
    ...provided,
    height: 40,
    width: '100%',
    padding: 0,
    backgroundColor: '#FFF',
    overflow: 'hidden',
  }),
  container: (provided, state) => ({
    ...provided,
    height: 42,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: '0px 10px',
    maxHeight: '40px',
    alignItems: 'flex-start',
    overflowY: 'auto',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      cursor: 'pointer',
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : 'black',
      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
    };
  },
  input: (styles) => ({ ...styles, ...dot() }),
  placeholder: (styles) => ({ ...styles, ...dot() }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};

RDP_UTILS.MultipleSelect = {
  styleForm: {
    option: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
    }),
    control: (provided, state) => ({
      ...provided,
      // height: 40,
      width: '100%',
      padding: 0,
      backgroundColor: '#FFF',
      overflow: 'hidden',
      boxShadow: state.isFocused ? '0 0 0 1px #333945' : null,
      borderColor: state.isFocused ? '#333945' : 'hsl(0,0%,80%)',
      '&:hover': {
        borderColor: '#333945',
      },
    }),
    input: (provided, state) => ({
      ...provided,
      // height: 42,
    }),
    multiValue: (provided, state) => ({
      ...provided,
      height: '40px',
      margin: '6px 6px 6px 0',
      alignItems: 'center',
      padding: '10px',
      backgroundColor: '#333945',
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: '#fff',
    }),
    container: (provided, state) => ({
      ...provided,
      // height: 42
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: '0px 10px',
      // maxHeight: "40px",
      alignItems: 'flex-start',
      overflowY: 'hidden',
    }),
  },
  styleFilter: {
    option: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
    }),
    control: (provided, state) => ({
      ...provided,
      height: 42,
      width: 250,
      padding: 0,
      backgroundColor: 'transparent',
      cursor: 'pointer',
    }),
    input: (provided, state) => ({
      ...provided,
      height: 0,
      marginBottom: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      height: 42,
      marginLeft: 10,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: '0px 10px',
      height: 42,
    }),
  },
  fullWidthStyleFilter: {
    option: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
      backgroundColor: state.isSelected ? '#764ab0' : 'transparent',
      color: state.isSelected ? '#fff' : '#333945',
      ":hover": {
        backgroundColor: state.isSelected ? 'rgba(118,74,176,0.85)' : '#764ab0',
        color: '#fff'
      }
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'transparent',
      cursor: 'pointer',
      border: state.isFocused ? '1px solid #764ab0' : '1px solid #dbdfe6',
      boxShadow: state.isFocused ? '0 0 0 1px #764ab0' : "none",
      ":hover": {
        borderColor: '#764ab0',
      }
    }),
    input: (provided, state) => ({
      ...provided,
      width: 100,
      height: 36,
      display: 'flex',
      alignItems: 'center',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      marginTop: 2,
    }),
  },
  theme: (theme) => ({
    ...theme,
    borderRadius: 4,
    borderColor: '#fff',
    colors: {
      ...theme.colors,
      primary25: '#f1f3f4',
      primary: '#4cbf69',
    },
  }),
};

export default RDP_UTILS;
