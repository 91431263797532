import React from 'react';
import './table-dynamic.scss';

import TableTrDynamic from './table-tr-dynamic';
import TableOrderArrows from '../table-order-arrows/table-order-arrows';

const IGNORED_TYPES = ['webpack', 'image', 'table'];

export default function TableDynamic(
  {
    hasCheckbox = false,
    hasDelete = false,
    hasEdit = false,
    hasClone = false,
    hasViewList = false,
    headers = [],
    onFilterChange = () => {},
    defaultFields = [],
    data = [],
    onUpdate = () => {},
    onDelete = () => {},
    onClone = () => {},
    onCheckboxClick = () => {},
    title = "",
    configuration,
    onCheckBoxCheckAll = () => {
    },
    filters = {
      order_field: '',
      order_type: 'asc',
    },
  },
) {
  const getActiveArrow = (column) => {
    if (filters.order_field === column.identifier) {
      if (filters.order_type === 'asc') {
        return 'up';
      }

      if (filters.order_type === 'desc') {
        return 'down';
      }
    }

    return undefined;
  };

  const definedHead = () => {
    const fields = [...headers, ...defaultFields];

    if (!data || !data.length) {
      return null;
    }

    return (
      <thead>
      <tr>
        {hasCheckbox && (
          <th className='text-center'>
            <input
              className='td-checkbox-all'
              type='checkbox'
              onClick={checkAll}
            />
          </th>
        )}
        {fields.map((th, i) => {
          if (!IGNORED_TYPES.includes(th.type)) {
            return (
              <th
                key={i}
                onClick={() => onFilterChange && onFilterChange(th)}
              >
                <div className='rdp-dynamic-table-th'>
                  {onFilterChange && (
                    <TableOrderArrows activeArrow={getActiveArrow(th)} />
                  )}
                  <span>{th.field ? th.field : ' - '}</span>
                </div>
              </th>
            );
          }
        })}
        {hasViewList && <td className='size-small' />}
        {hasClone && <td className='size-small' />}
        {hasEdit && <td className='size-small' />}
        {hasDelete && <td className='size-small' />}
      </tr>
      </thead>
    );
  };

  const definedBody = () => {
    return (
      <tbody>
      {data &&
        data.map((tr, i) => (
          <TableTrDynamic
            configuration={configuration}
            key={i}
            hasCheckbox={hasCheckbox}
            hasEdit={hasEdit}
            hasClone={hasClone}
            hasDelete={hasDelete}
            hasViewList={hasViewList}
            headers={headers.filter(
              ({ type }) => !IGNORED_TYPES.includes(type),
            )}
            defaultFields={defaultFields}
            tr={tr}
            title={title}
            onCheckboxClick={(elementId) => onCheckboxClick(elementId)}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onClone={onClone}
          />
        ))}
      </tbody>
    );
  };

  const checkAll = (event) => {
    const elements = [];
    const op = event.target.checked;
    const inputs = document.querySelectorAll(
      'input[type=checkbox].td-checkbox',
    );

    for (let i = 0; i < inputs.length; i++) {
      inputs[i].checked = op;
    }

    if (op) {
      for (let i = 0; i < data.length; i++) {
        elements.push(data[i].uid);
      }
      onCheckBoxCheckAll('add-check', elements);
    } else {
      onCheckBoxCheckAll('uncheck-all');
    }
  };

  return (
    <div className='rdp-admin-component-table-dynamic'>
      <table className='table-dynamic'>
        {definedHead()}
        {definedBody()}
      </table>
    </div>
  );
}
