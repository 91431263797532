import {atom} from "recoil";
import {persistAtomEffect} from "../effects/persistAtom";

export const selectedDomainState = atom<string>({
  key: 'SelectedDomainState',
  default: "",
  effects: [
    persistAtomEffect
  ]
});
