import React from 'react';
import 'react-responsive-modal/styles.css';
import './modal-delete.scss';
import { IconTrash, IconCloseModal } from '../icon';

export default function ModalDelete({
  title = 'Excluir',
  open,
  onClose,
  color,
  action,
  message = 'Tem certeza de que deseja excluir os registros selecionados?',
  icon = <IconTrash />,
  textAction = 'Enviar',
}) {
  return (
    <div
      aria-hidden={open}
      role="dialog"
      className={`rdp-modal ${open ? 'open' : ''}`}
      onClose={() => onClose()}
    >
      <div className="rdp-modal-overlay" onClick={onClose}></div>
      <div className="rdp-modal-container">
        <div className="rdp-modal-container-header">
          <div className="header-left">
            <div className="icon">{icon}</div>
            <span>{title}</span>
          </div>
          <button
            type="button"
            className="rdp-modal-close"
            onClick={() => onClose()}
          >
            <IconCloseModal />
          </button>
        </div>
        <div className="rdp-modal-container-content">
          <div className="text">
            <span>{message}</span>
          </div>
        </div>
        <div className="rdp-modal-container-footer">
          <button
            className="rdp-modal-button-cancel margin-right"
            onClick={() => onClose()}
          >
            Cancelar
          </button>

          <button
            className={`rdp-modal-button-delete ${color}`}
            onClick={() => (action ? action() : console.log('Modal exclusao'))}
          >
            {textAction}
          </button>
        </div>
      </div>
    </div>
  );
}
