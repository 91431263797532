import React, { useState, useEffect } from 'react';
import { GenericList } from '../../builders/list';
import { Footer } from '../../components';
import Loading from '../../components/loading';
import Sdk from 'api.digitalpages.module.sdk.api';
import JSZip from 'jszip';
import $ from 'jquery';
import './list-certificates.scss';
// import JSZipUtils from 'jszip-utils';
import DynamicForm from '../../components/form-dynamic-v2';
import { DAMService } from '../../services/dam';
import { getConnectorFromArray } from '../../utils/getConnectorFromArray';
import PreloaderImage from './icon-preloader.gif'

export default function ViewCertificatesList({ consumer, entity, history }) {
  const [elements, setElements] = useState([]);
  const [messages, setMessages] = useState({});
  const [deleting, setDeleting] = useState(false);
  const [config, setConfig] = useState({});
  const [preloader, setPreloader] = useState(true);
  const [preferences, setPreferences] = useState(null);
  const [fullPreloader, setFullPreloader] = useState(false);

  async function configTable() {
    const res = await fetch('./preferences.json');
    const prefs = await res.json();
    setPreferences(prefs);

    // const { tables } = await response.json();

    const Configuration = {
      options: {
        hasCheckbox: false,
        hasEdit: true,
        hasDelete: true,
      },
      fields: [
        {
          identifier: 'title',
          field: 'Nome do certificado',
          type: 'text',
        },
        {
          identifier: 'document.last_modified',
          field: 'Data de atualização',
          type: 'date-time',
          path: 'document.last_modified',
        }
      ],
    };

    setConfig(Configuration);
  }

  async function getCertificates() {
    if (Sdk.authorization.authenticated) {
      var templates = await Sdk.dynamic.bridge(`storage/v1.0/search?filter=(content_type eq 'digitalpages/template') and (project_uid eq '${Sdk.Api.authorization.activeProject.uid}')&top=1000&orderBy=last_modified desc`)
      console.log("results", templates)

      if (templates.results.length === 0) {
        setMessages({ noContent: 'Nenhum certificado cadastrado.' });
      }
      var results = templates.results;
      for (var i = 0; i < results.length; i++) {
        results[i].uid = results[i].document.content_uid;
        let title = results[i].document.content_info[1] || "";
        title = title.split("=")[1] || "";
        results[i].title = title;
      }

      setElements({
        "result": results,
        "current_page": 1,
        "page_count": 1,
        "page_size": results.length,
        "row_count": results.length,
      });

      setPreloader(false);
    }
  }

  useEffect(() => {
    configTable();
    getCertificates();

    consumer.defineRoute({ name: 'Certificados', uri: '/certificates' });
  }, []);

  async function handleDelete(data) {
    setDeleting(true);
    try {
      if (Array.isArray(data)) {
        await handleMultiDelete(data);
        setMessages({ success: config.multiDeleteText });
      } else {
        await deleteItem(data);
        setMessages({ success: config.deleteText });
      }
    } catch (error) {
      setMessages({ error: config.deleteError });
    }

    setDeleting(false);
    getCertificates();
  }

  async function handleMultiDelete(uids) {
    for (const uid of uids) {
      await deleteItem(uid);
    }
  }

  async function deleteItem(uid) {
    return await Sdk.dynamic.bridge(
      `storage/v1.0/content?uid=${uid}`,
      null,
      'DELETE'
    );
  }

  function onUpdate(uid) {
    history.push(`certificates/${uid}`);
  }

  function createCertificate() {
    setFullPreloader(true);
    var zip = new JSZip();
    zip.file("manifest.json", JSON.stringify({
      "name": "Template de certificado",
      "version": "1.3",
      "metadata": {
        "title": "Template de certificado",
        "content_type": "json"
      },
      "assets": [],
      "type": "certificate",
      "default_target": "index.html",
      "app_version": "1.0"
    }));
    zip.file("structure.json", JSON.stringify({
      "name": "Template de certificado",
      "size": "a4",
      "orientation": "landscape",
      "background_image": null,
      "items": []
    }));
    zip.file("index.html", '<!DOCTYPE html> <html lang="pt-br"> <head> <meta charset="UTF-8"> <meta http-equiv="X-UA-Compatible" content="IE=edge"> <meta name="viewport" content="width=device-width, initial-scale=1.0"> <title></title> <style> body { margin: 0; } #iframe { border: 0; position: absolute; top: 0px; bottom: 0px; left: 0px; right: 0px; width: 100%; height: 100%;} </style> </head> <body> <iframe id="iframe"></iframe> <script> var renderAppUrl = window.location.origin + window.location.pathname; var structureUrl = window.location.href.replace("template.html", "structure.json").replace("index.html", "structure.json").split("?")[0]; var manifestUrl = window.location.href.replace("template.html", "manifest.json").replace("index.html", "manifest.json").split("?")[0]; var iframe = document.getElementById("iframe"); iframe.setAttribute("src", "https://pocs.digitalpages.com.br/certificate-player/" + window.location.search); iframe.addEventListener("load", function() { fetch(structureUrl).then((resp) => resp.json()).then(function(structure) { iframe.contentWindow.postMessage({"structure": structure}, "*"); }); fetch(manifestUrl).then((resp) => resp.json()).then(function(manifest) { iframe.contentWindow.postMessage({"manifest": manifest}, "*"); }); }); </script> </body> </html>')
    zip.generateAsync({type: "blob"}).then(zipBlob => {
      onSaveClick(zipBlob);
    })
  }

  function onSaveClick(zipBlob) {
    // var connectorUid = Sdk.Api.authorization.activeProject.connectors.filter(connector => connector.type === "Learning_1")[0].uid;
    var connectorUid = Sdk.Api.authorization.activeProject.connectors[0].uid;
    
    var url = `${Sdk.Api.domain}/storage/v1.0/upload/connector/uid/${connectorUid}?manual_project_uid=${Sdk.Api.authorization.activeProject.uid}`;
    var method = "POST";
    var fd = new FormData();

    fd.append('file', zipBlob, "Certificado.rdptemplate")

    $.ajax({
      url: url,
      type: method,
      data: fd,
      contentType: false,
      processData: false,
      headers: {
        "Authorization": "Bearer " + Sdk.Api.authorization.credential.accessToken,
        "Project-Key": Sdk.Api.authorization.projectKey,
        "Api-Env": Sdk.Api.enviroment
      },
      success: function (response) {
        var newId = response[0].uid;
        history.push(`certificates/${newId}`);
      },
      error: function (error) {
        console.log(error)
      }
    });
  }

  if (preferences) {
    return <>
      {fullPreloader &&
        <div className="rdp-admin-certificate-list-preloader"></div>
      }
      <GenericList
        enableLocalSearch={false}
        localSearchPlaceholder="Buscar certificados"
        consumer={consumer}
        elements={elements}
        messages={messages}
        fields={config.fields}
        options={config.options}
        hideDelete={true}
        breadcrumb={{
          selectOptions: null,
          routeNew: () => createCertificate(),
          label: entity,
          customNewOptionTitle: preferences.tables.certificates.newButtonLabel,
        }}
        loading={{
          preloader,
        }}
        actions={{
          closeMessage: () => {
            setMessages({ success: null, error: null, noContent: null });
          },
          onUpdate: (uid) => onUpdate(uid),
          onDelete: (uid) => handleDelete(uid),
        }}
      />
      {deleting ? <Loading msg={config.loadingDeleteText} /> : null}
      <Footer />
    </>
  } else {
    return "";
  }
}
