import React, { Component } from 'react';
import './edit-content.scss';

import {
  Header,
  Sidebar,
  Breadcrumb,
  FormDynamic,
  NotifyBox,
} from '../../components';

import { EntityService } from '../../services/entity-content';
import { NoticeService } from '../../services/notice';
import Loading from '../../components/loading';
import ModalDelete from '../../components/modal-delete';
import { ProductsService } from '../../services/products';
import RDP_UTILS from '../../config/util';

const FORM_ERROR = 'Módelo do formulário não encontrado.';
const NOTICE_ERROR = 'Não foi possivel carregar o conteúdo.';
const NOTICE_UPDATE_ERROR =
  'Não foi possivel salvar as alterações. Tente novamente.';

export default class ViewEditContent extends Component {
  constructor(props) {
    super(props);
    const { consumer, match } = this.props;
    let entityId = null;
    if (consumer && consumer.currentRoute) {
      entityId = consumer.currentRoute.entity_uid;
    }
    const { noticeId } = match.params;
    let erroParams = null;
    if (!entityId) {
      erroParams = FORM_ERROR;
    } else if (!noticeId) {
      erroParams = NOTICE_ERROR;
    }
    this.state = {
      noticeId: noticeId,
      entityId: entityId,
      ready: false,
      loading: false,
      loadingSubmit: null,
      msgSuccess: null,
      msgError: erroParams,
      deleteModal: false,
      deleteCompleted: false,
    };
  }

  componentDidMount() {
    this.setState({ ready: false, loading: true }, this.getData);
  }

  componentDidUpdate(prevProps, prevStates) {
    // console.log("UPDT", prevStates, this.state)
  }

  handleChange = ({ id, value }) => {
    const dataEntity = this.state.entity;

    if (this.state.listenValues.includes(id)) {
      try {
        dataEntity.configuration.map(
          async ({
            identifier,
            reference_entity_properties,
            type,
            configuration,
          }) => {
            if (type == 'table') {
              await Promise.all(
                configuration.columns.map(async (column) => {
                  if (column.reference_entity_properties) {
                    if (column.reference_entity_properties.filter_by) {
                      EntityService.getOptionsReference(
                        this.state.entityId,
                        [identifier, column.identifier].join('->'),
                        {
                          filter: `(${column.reference_entity_properties.filter_by}:${value})`,
                        }
                      ).then((data) => {
                        this.setState({
                          selectOptions: [
                            ...this.state.selectOptions.filter(
                              (option) =>
                                option.identifier !=
                                [identifier, column.identifier].join('->')
                            ),
                            {
                              identifier: [identifier, column.identifier].join(
                                '->'
                              ),
                              data,
                            },
                          ],
                        });
                      });
                    }
                  }
                })
              );
            }

            if (reference_entity_properties) {
              if (reference_entity_properties.filter_by) {
                EntityService.getOptionsReference(
                  this.state.entityId,
                  identifier,
                  {
                    filter: `(${reference_entity_properties.filter_by}:${value})`,
                  }
                ).then((data) => {
                  this.setState({
                    selectOptions: [
                      ...this.state.selectOptions.filter(
                        (option) => option.identifier != identifier
                      ),
                      {
                        identifier,
                        data,
                      },
                    ],
                  });
                });
              }
            }
          }
        );
      } catch (e) {
        console.log('erorrrr:', e);
      }
    }
  };

  async getData() {
    let dataEntity = await EntityService.getEntity(this.state.entityId);
    let dataNotice = await NoticeService.getNotice(this.state.noticeId);

    if (dataNotice) {
      dataEntity.configuration.filter(
        ({ reference_entity_properties, identifier, type, configuration }) => {
          if (type == 'table') {
            configuration.columns.forEach((column) => {
              if (column.type == 'reference-entity') {
                dataNotice.data[identifier] = dataNotice.data[identifier].map(
                  (row) => ({
                    ...row,
                    [column.identifier]: row[column.identifier].uid,
                  })
                );
              }
            });
          }

          if (type == 'reference-entity') {
            dataNotice.data[identifier] = dataNotice.data[identifier]
              ? dataNotice.data[identifier].uid
              : null;
          }
        }
      );
    }

    let products = [];
    let selectOptions = [];
    let listenValues = [];

    try {
      products = await ProductsService.getProductsAvailables();
    } catch (_) {}

    try {
      await Promise.all(
        dataEntity.configuration.map(
          async ({
            identifier,
            reference_entity_properties,
            type,
            configuration,
          }) => {
            if (type == 'table') {
              await Promise.all(
                configuration.columns.map(async (column) => {
                  if (column.reference_entity_properties) {
                    if (column.reference_entity_properties.filter_by) {
                      listenValues.push(
                        column.reference_entity_properties.filter_by
                      );
                    }

                    if (
                      dataNotice.data[identifier] &&
                      dataNotice.data[identifier][
                        column.reference_entity_properties.filter_by
                      ]
                    ) {
                      selectOptions.push({
                        identifier: [identifier, column.identifier].join('->'),
                        data: await EntityService.getOptionsReference(
                          this.state.entityId,
                          [identifier, column.identifier].join('->'),
                          {
                            filter: `(${
                              column.reference_entity_properties.filter_by
                            }:${
                              dataNotice.data[identifier][
                                column.reference_entity_properties.filter_by
                              ]
                            })`,
                          }
                        ),
                      });
                    } else {
                      selectOptions.push({
                        identifier: [identifier, column.identifier].join('->'),
                        data: await EntityService.getOptionsReference(
                          this.state.entityId,
                          [identifier, column.identifier].join('->')
                        ),
                      });
                    }
                  }
                })
              );
            }

            if (reference_entity_properties) {
              if (reference_entity_properties.filter_by) {
                listenValues.push(reference_entity_properties.filter_by);
              }

              if (dataNotice.data[reference_entity_properties.filter_by]) {
                selectOptions.push({
                  identifier,
                  data: await EntityService.getOptionsReference(
                    this.state.entityId,
                    identifier,
                    {
                      filter: `(${reference_entity_properties.filter_by}:${
                        dataNotice.data[reference_entity_properties.filter_by]
                      })`,
                    }
                  ),
                });
              } else {
                selectOptions.push({
                  identifier,
                  data: await EntityService.getOptionsReference(
                    this.state.entityId,
                    identifier
                  ),
                });
              }
            }
          }
        )
      );
    } catch (e) {
      console.log('erorrr:', e);
    }

    this.setState({
      ready: true,
      loading: false,
      entity: dataEntity.configuration ? dataEntity : null,
      notice: dataNotice,
      msgError: !dataNotice.data
        ? NOTICE_ERROR
        : !dataEntity
        ? FORM_ERROR
        : null,
      productsAvailables: products
        ? RDP_UTILS.formatProductsToSelect(products)
        : [],
      listenValues,
      selectOptions,
    });
  }

  async editEntity(dataForm) {
    let dataNotice = await NoticeService.updateNotice(
      this.state.noticeId,
      dataForm
    );
    let typeAction =
      dataNotice.status === 'Published' ? 'Publicação ' : 'Rascunho ';
    if (dataNotice.status === 'Draft' || dataNotice.status === 'Published') {
      this.setState(
        {
          ready: false,
          loading: true,
          loadingSubmit: null,
          msgSuccess:
            typeAction +
            this.props.consumer.currentRoute.name +
            ' foi alterado com sucesso!',
          notice: dataNotice,
        },
        this.getData
      );
    } else {
      this.setState({
        ready: true,
        loading: false,
        loadingSubmit: null,
        msgSuccess: null,
        msgError: NOTICE_UPDATE_ERROR,
      });
    }
  }

  async deleteEntity() {
    let removeResult = await NoticeService.deleteNotice(this.state.noticeId);
    this.setState({
      ready: false,
      loading: false,
      loadingSubmit: null,
      deleteCompleted: true,
      msgSuccess:
        this.props.consumer.currentRoute.name + ' foi excluído com sucesso!',
    });
  }

  handleSubmit = (dataForm) => {
    this.setState(
      {
        ready: true,
        loading: false,
        loadingSubmit: 'Enviando dados...',
      },
      () => this.editEntity(dataForm)
    );
  };

  returnPath() {
    const { consumer } = this.props;
    if (consumer.currentGroup && consumer.currentRoute) {
      return (
        '/listagem/' +
        consumer.currentGroup.identifier +
        '/' +
        consumer.currentRoute.identifier +
        '/' +
        consumer.currentRoute.entity_uid
      );
    }
    return null;
  }

  resetMsg = () => {
    this.setState({ msgSuccess: null, msgError: null });
  };

  openModal = () => {
    this.setState({
      deleteModal: true,
    });
  };

  onCloseModal = () => {
    this.setState({
      deleteModal: false,
    });
  };

  handleDelete = () => {
    this.setState(
      {
        deleteModal: false,
        loadingSubmit: 'Apagando dados...',
      },
      this.deleteEntity
    );
  };

  render() {
    const { consumer } = this.props;
    const {
      entity,
      notice,
      ready,
      loading,
      loadingSubmit,
      msgSuccess,
      msgError,
      deleteModal,
      deleteCompleted,
    } = this.state;

    return (
      <div className="rdp-admin-new-entity">
        <Header />
        <Sidebar
          defineGroup={consumer ? consumer.defineGroup : null}
          defineRoute={consumer ? consumer.defineRoute : null}
          groups={consumer ? consumer.groups : null}
        />
        <Breadcrumb
          deleteOption={!msgError && !deleteCompleted ? this.openModal : null}
          updateMode={true}
          backRoute={this.returnPath()}
          currentRoute={consumer ? consumer.currentRoute : null}
        />
        <div id="rdp-admin-content-area" className="rdp-admin-content">
          {loadingSubmit && <Loading msg={loadingSubmit} />}
          {!loadingSubmit && msgSuccess && (
            <NotifyBox
              type="success"
              onClose={this.resetMsg}
              message={msgSuccess}
            />
          )}
          {!loadingSubmit && msgError && (
            <NotifyBox
              type="error"
              onClose={this.resetMsg}
              message={msgError}
            />
          )}
          {loading && <Loading msg="Carregando formulário..." />}
          {ready && !loading && entity && notice && (
            <FormDynamic
              notice={notice}
              isNew={false}
              entity={entity}
              selectOptions={this.state.selectOptions}
              blocked={msgSuccess || msgError ? true : false}
              cleanValues={false}
              handleSubmit={this.handleSubmit}
              handleChange={this.handleChange}
              productsAvailables={this.state.productsAvailables}
            />
          )}
        </div>
        {ready && !loading && entity && (
          <ModalDelete
            title={entity.title}
            action={this.handleDelete}
            open={deleteModal}
            onClose={() => this.onCloseModal()}
          />
        )}
      </div>
    );
  }
}
