import React, { Suspense, useEffect, useRef } from 'react';
import * as S from './new-terms.styled';
import { Breadcrumb, Header, Sidebar } from '../../components';
import Loading from '../../components/loading';
import { useHistory } from 'react-router-dom';
// @ts-ignore
import Components from 'rdp-builder-components';
import DynamicForm from '../../components/form-dynamic-v2';
import SDK from 'api.digitalpages.module.sdk.api';
import { useTerms } from './useTerms';

interface IEditTermsProps extends IConsumerContext {}

function NewTerms(props: IEditTermsProps) {
  const history = useHistory();
  const [consumer] = React.useState(props.consumer);
  const [entity, setEntity] = React.useState<IAppFormPreference<ITermsModel>>();
  const [language, setLanguage] = React.useState<string>('pt_br');
  const [termsConnector, setTermsConnector] = React.useState<IConnectorModel>();
  const [ids, setIds] = React.useState<string[]>([]);
  const [entityUpd, setEntityUpd] =
    React.useState<IAppFormPreference<ITermsModel>>();
  const { loadEntities, createAuthorization } = useTerms();

  useEffect(() => {
    if (entity) {
      loadEntities(entity).then((res) => {
        if (res) {
          setIds(res.uids);
          setEntityUpd(res.form);
        }
      });
    }
  }, [entity]);

  useEffect(() => {
    if (consumer && consumer.defineRoute) {
      consumer.defineRoute({
        name: 'Novo Termo',
        uri: '/terms/new',
      });
      getFormFields();

      getConnector();
    }
  }, []);

  const getConnector = async () => {
    try {
      const directory: IDirectoryModel = await SDK.dynamic.bridge(
        `auth/v1.0/directory/uid/${SDK.Api.authorization.activeProject.directory.uid}`,
        null,
        'GET'
      );

      if (directory) {
        const termsPolicies = directory.connectors.find(
          (connector: { type: string }) => connector.type === 'Policies_1'
        );
        if (termsPolicies) {
          setTermsConnector(termsPolicies);
          return termsPolicies;
        }
      }

      throw new Error(
        'Não foi possível encontrar o connector de políticas de privacidade'
      );
    } catch (e) {
      console.error(e);
    }
  };

  async function getFormFields() {
    const response = await fetch('./preferences.json');

    const { forms, general } = await response.json();

    setLanguage(general.language);
    setEntity(forms['new-terms']);
  }

  const handleSave = async (data: ITermsModel & Record<string, any>) => {
    try {
      let uids = ids
        .map((item) => {
          if (data[item]) {
            const backup = { ...data[item] };
            delete data[item];
            return Object.keys(backup).filter((key) => backup[key]);
          }
        })
        .reduce((a, b) => [...(a || []), ...(b || [])], []);

      const entityReferences = (uids ?? []).map((uid) => ({
        reference_uid: uid,
        type: 'Entity',
      }));

      const references = [...entityReferences, ...(data.users || [])];

      if (data.users) delete data.users;
      
      const term: ITermsModel = await SDK.dynamic.bridge(
        `auth/v1.0/policies/connector/uid/${termsConnector?.uid}/management/term`,
        data,
        'POST'
      );

      if (entityReferences.length) {
        createAuthorization(
          termsConnector?.uid ?? '',
          term.uid,
          references
        );
      }
      // TODO: Remover quando concertar o bug do endpoint auth/v1.0/policies/connector/uid/${termsPolicies?.uid}/term/uid/${resp[0].uid}/content
      const public_content: { share_uid: string } = await SDK.dynamic.bridge(
        `/storage/v1.0/content/share/scope/public?manual_project_uid=${SDK.Api.authorization.activeProject.uid}&uid=${data.content_uid}`,
        null,
        'POST'
      );

      await SDK.dynamic.bridge(
        `auth/v1.0/policies/connector/uid/${termsConnector?.uid}/term/uid/${term.uid}/management/version`,
        {
          current_version: true,
          // content_uid: data.content_uid,
          content_uid: public_content.share_uid,
        },
        'POST'
      );

      history.push('/terms');
    } catch (e) {
      console.error(e);
    }
  };

  const containerRef = useRef(null);

  return (
    <S.Container>
      <Header />

      <Sidebar
        currentRoute={consumer.currentRoute}
        defineGroup={consumer.defineGroup}
        defineRoute={consumer.defineRoute}
        groups={consumer.groups}
      />

      <Breadcrumb
        currentRoute={consumer.currentRoute}
        currentGroup={consumer.currentGroup}
        currentBreadcrumbTitle="Novo Termo de aceite"
        label="Novo Termo de aceite"
      />

      <S.Content ref={containerRef}>
        <Suspense fallback={<Loading />}>
          {entityUpd && (
            <DynamicForm
              defaultUserAuthorizations={undefined}
              parentRef={containerRef}
              handleSubmit={handleSave}
              form={entityUpd}
              submitText={entityUpd.submitText}
              handleSave={handleSave}
              defaultValues={{
                active: true,
              }}
              language={language}
            />
          )}
        </Suspense>
      </S.Content>
    </S.Container>
  );
}

export default NewTerms;
