import React, { useState, useEffect } from 'react';
import { GenericList } from '../../builders/list';
import { Footer } from '../../components';
import Loading from '../../components/loading';
import Sdk from 'api.digitalpages.module.sdk.api';
import './list-schemas.scss';

export default function ListSchemas({ consumer, entity, history }) {
  const [elements, setElements] = useState([]);
  const [messages, setMessages] = useState({});
  const [deleting, setDeleting] = useState(false);
  const [config, setConfig] = useState({});
  const [preloader, setPreloader] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchConfig();
  }, []);
  useEffect(() => {
    if (config) {
      loadSchemas();
    }
  }, [config]);
  const fetchConfig = async () => {
    const response = await fetch('./preferences.json');

    const { tables } = await response.json();

    setConfig(tables.schemas);
  };
  const loadSchemas = async () => {
    // LOAD SCHEMAS
    const project = await Sdk.dynamic.bridge(
      `auth/v1.0/project/uid/${Sdk.authorization.activeProject.uid}`,
      null,
      'GET'
    );
    const { directory } = project;
    const schemas = await Sdk.dynamic.bridge(
      `auth/v1.0/entity/schema/available/directory/uid/${directory.uid}`,
      null,
      'GET'
    );
    setElements({
      result: schemas,
      page_size: schemas.length,
      row_count: schemas.length,
    });
    setPreloader(false);
  };
  const onUpdate = (uid) => {
    history.push(`schema/${uid}`);
  };
  const handleDelete = (uid) => {};
  return (
    <>
      <GenericList
        // enableLocalSearch={true}
        // localSearchPlaceholder="Buscar entidades"
        consumer={consumer}
        elements={elements}
        messages={messages}
        fields={config.fields}
        options={config.options}
        breadcrumb={{
          selectOptions: null,
          routeNew: '/schema/new',
          label: entity,
          customNewOptionTitle: config.sendButtonText,
        }}
        loading={{
          preloader,
        }}
        actions={{
          closeMessage: () => {
            setMessages({ success: null, error: null, noContent: null });
          },
          onUpdate: (uid) => onUpdate(uid),
          onDelete: async (uid) => await handleDelete(uid),
        }}
      />
      {deleting ? <Loading msg={config.loadingDeleteText} /> : null}
      <Footer />
    </>
  );
}
