import React from "react";
import * as S from "./attendance-table.styled";
import {
  Loading,
  Pagination,
  TableDynamic
} from "../../components";
import Components from "rdp-builder-components";
import Sdk from "api.digitalpages.module.sdk.api";
import { getConnectorFromArray } from "../../utils/getConnectorFromArray";
import { withRouter } from "react-router-dom";
import RDP_UTILS from "../../config/util";
import Select from "react-select";

class AttendanceTable extends React.Component {
  constructor (props) {
    super(props);

    this.loadCourses = this.loadCourses.bind(this);
    this.loadCourseRegisters = this.loadCourseRegisters.bind(this);
    this.handleSelectCourse = this.handleSelectCourse.bind(this);
    this.mapCoursesToOptions = this.mapCoursesToOptions.bind(this);
    this.mapRegistersToOptions = this.mapRegistersToOptions.bind(this);
    this.handleSelectRegister = this.handleSelectRegister.bind(this);
    this.doFirstLoad = this.doFirstLoad.bind(this);
    this.loadFrequencies = this.loadFrequencies.bind(this);
    this.loadData = this.loadData.bind(this);
    this.getData = this.getData.bind(this);
    this.getHeaders = this.getHeaders.bind(this);
    this.getRegisterStatus = this.getRegisterStatus.bind(this);
    this.getAccessStatus = this.getAccessStatus.bind(this);

    this.state = {
      preloader: true,
      courses: [],
      registers: [], // Turmas do curso selecionado
      selectedCourseUid: null,
      selectedRegisterUid: null,
      userEntities: [],
      headers: [],
      data: [],
      frequencies: [],
      is_loading_data: true,
      data_current_page: 1,
      data_total_pages: 1,
      data_total_results: 1,
    };
  }

  componentDidMount () {
    this.doFirstLoad();
    this.getUserEntities();
  }

  async doFirstLoad () {
    try {
      await Promise.all([
        this.loadCourses(),
        this.loadProjectUsers(),
      ]);

      const { courses } = this.state;
      let registers = [];
      let selectedCourseUid = null;
      let selectedRegisterUid = null;
      let frequencies = [];

      if (courses.length > 0) {
        const { courseUid, registerUid } = this.props.match.params;

        registers = [];

        if (courseUid && registerUid) {
          registers = await this.loadCourseRegisters(courseUid);

          selectedCourseUid = courseUid;
          selectedRegisterUid = registerUid;
        } else {
          registers = await this.loadCourseRegisters(courses[0].uid);
          selectedCourseUid = courses[0].uid;

          if (registers.length > 0) {
            selectedRegisterUid = registers[0].uid;
          }
        }
      }

      if (selectedRegisterUid)
        frequencies = await this.loadFrequencies(selectedRegisterUid);

      this.setState({
        frequencies,
        registers,
        selectedCourseUid,
        selectedRegisterUid,
      }, () => {
        this.loadData();
      });
    } catch (error) {
      console.error(error);
    }
  }

  async loadFrequencies (registerUid, page = 1) {
    const { user } = Sdk.authorization;
    var filter = "";
    try {

    if (user.roles) {
        for (const role of user.roles) {
          if (
            role.type ==="TeamAdmin"
          ) {
            filter = "&filter_entities=true"
          }
        }
      }
      const resp = await fetch(
        `${Sdk.Api.domain}/cms/v1.0/learning/register/uid/${registerUid}/report/frequency/management?page=${page}${filter}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${Sdk.authorization.credential.accessToken}`,
            "Project-Key": Sdk.Api.projectKey,
            "Api-Env": Sdk.Api.enviroment,
          },
        },
      );
      let data = await resp.json();
      this.setState({
        data_current_page: data.current_page,
        data_total_pages: data.total_pages,
        data_total_results: data.total_results,
      });
      return data.result;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  loadData () {
    let headers = this.getHeaders();
    let data = this.getData();

    this.setState({
      headers,
      data,
      is_loading_data: false,
    });
  }

  async loadCourses () {
    const { connectors } = Sdk.authorization.activeProject;
    const conectorUid = getConnectorFromArray(
      connectors,
      "Learning_1",
      "ContentManager",
    ).uid;
    return new Promise(async (resolve, reject) => {
      try {
        const { result } = await Sdk.dynamic.bridge(
          `cms/v1.0/learning/connector/uid/${conectorUid}/courses/management?page_size=10000`,
          null,
          "GET",
        );

        this.setState({
          courses: result,
        }, () => {
          resolve();
        });
      } catch (error) {
        console.error(error);

        this.setState({
          courses: [],
        }, () => {
          reject();
        });
      }
    });
  }

  async loadProjectUsers () {
    return new Promise(async (resolve, reject) => {
      try {
        const users = await Sdk.dynamic.bridge(
          `auth/v1.0/project/uid/${Sdk.Api.authorization.activeProject.uid}/users`);
        if (this.props.onUpdateUsers) {
          this.props.onUpdateUsers(users);
        }
        this.setState({
          users,
        }, () => {
          resolve();
        });
      } catch (e) {
        console.error(e);
        this.setState({
          users: [],
        }, () => {
          reject();
        });
      }
    });
  }

  async loadCourseRegisters (courseUid) {
    let registers = [];

    try {
      registers = await Sdk.dynamic.bridge(
        `cms/v1.0/learning/course/uid/${courseUid}/management/registers`,
        null,
        "GET",
      );

      return registers;
    } catch (error) {
      console.error(error);
    }
  }

  async getUserEntities () {
    let uid = Sdk.authorization.user.uid;
    const resp = await Sdk.dynamic.bridge(
      `cms/v1.0/learning/user/uid/${uid}/management/registers/available?page_size=10000`,
      null,
      "GET",
    );

    const userEntities = await resp.result.map((item) => item.uid);

    this.setState({
      userEntities,
    });
  }

  getHeaders () {
    return [
      {
        type: "text",
        field: "Nome",
        identifier: "name",
      },
      {
        type: "text",
        field: "E-mail",
        identifier: "email",
      },
      {
        type: "text",
        field: "Curso",
        identifier: "course",
      },
      {
        type: "text",
        field: "Turma",
        identifier: "register",
      },
      {
        type: "text",
        field: "Status",
        identifier: "status",
      },
      {
        type: "text",
        field: "Matrícula",
        identifier: "user_register",
      },
      {
        type: "text",
        field: "Atividades Realizadas",
        identifier: "user_done_activities",
      },
      {
        type: "text",
        field: "Progresso",
        identifier: "user_progress",
      },
      {
        type: "text",
        field: "Data de início",
        identifier: "start_date",
      },
      {
        type: "text",
        field: "Data de conclusão",
        identifier: "end_date",
      },
    ];
  }

  getData () {
    const {
      users,
      frequencies = [],
    } = this.state;

    let rows = [];

    frequencies.forEach((frequency) => {
      let user = users.find((user) => user.uid === frequency.user_uid);

      if (user) {
        if (user.roles) {
          let isStudent = true;
          for (const role of user.roles) {
            if (role.type === "ReviewContributor" || role.type ===
              "ReviewAdmin" || role.type === "AdministratorGlobal") {
              isStudent = false;
              break;
            }
          }

          if (!isStudent) return;
        }

        let user_progress = 0;

        if (frequency.completed_activities_count !== null && frequency.activities_count !== null) {
          user_progress = ((frequency.completed_activities_count /
            frequency.activities_count) * 100).toFixed(0);
        }

        let columns = {
          name: user.detail.name,
          email: user.login,
          course: frequency.course_title,
          register: frequency.register_name,
          status: this.getRegisterStatus(frequency.register_status),
          user_register: this.getAccessStatus(frequency.access_status),
          user_done_activities: `${frequency.completed_activities_count || 0} de ${frequency.activities_count || 0}`,
          user_progress: user_progress ? `${user_progress}%` : "0%",
          start_date: this.formatDate(frequency.start_course),
          end_date: this.formatDate(frequency.end_course),
        };

        rows.push(columns);
      }
    });

    return rows;
  }

  formatDate(str) {
    if (str === null) return "-";
    var items = str.split("T")[0].split("-");
    return `${items[2]}/${items[1]}/${items[0]}`;
  }

  getAccessStatus(status) {
    if (status === "Active") {
      return "Ativo";
    } else if (status === "Inactive") {
      return "Inativo";
    }
    return "-";
  }

  getRegisterStatus(status) {
    if (status === "Started") {
      return "Iniciado";
    } else if (status === "NotAttempted") {
      return "Não iniciado";
    } else if (status === "Completed") {
      return "Finalizado";
    } else if (status === "Passed") {
      return "Aprovado";
    }
  }

  handleSelectCourse (courseUid) {
    if (courseUid !== null) {
      this.setState({
        is_loading_data: true,
        selectedRegisterUid: null,
      }, async () => {
        const { registerUid } = this.props.match.params;
        const { userEntities } = this.state;
        const { user } = Sdk.authorization;

        let _registers = await this.loadCourseRegisters(courseUid);
        let registers = _registers;

        // if (user.roles) {
        //   for (const role of user.roles) {
        //     if (
        //       role.type ==="TeamAdmin"
        //     ) {
        //       registers = _registers.filter(register => register.uid && userEntities.includes(register.uid));
        //     }
        //   }
        // }

        let selectedRegisterUid = null;
        let frequencies = [];

        if (registers.length > 0) {
          let registerInCourse = registers.find(r => r.uid === registerUid);
          selectedRegisterUid = registerInCourse ? registerUid : registers[0].uid;
          this.props.history.push(`/report/attendance/course/${courseUid}/register/${selectedRegisterUid}`)
          if (this.props.onUpdateRegister) {
            this.props.onUpdateRegister(selectedRegisterUid);
          }
          frequencies = await this.loadFrequencies(selectedRegisterUid);
        }

        this.setState({
          selectedCourseUid: courseUid,
          selectedRegisterUid,
          registers,
          frequencies,
          is_loading_data: false,
        }, async () => {
          this.loadData();
        });
      });
    }
  }

  handleSelectRegister (registerUid) {
    if (registerUid !== null) {
      this.setState({
        is_loading_data: true
      }, async () => {
        const { courseUid } = this.props.match.params;
        let selectedRegisterUid = registerUid;

        let frequencies = await this.loadFrequencies(registerUid);
        this.props.history.push(`/report/attendance/course/${courseUid}/register/${selectedRegisterUid}`)

        if (this.props.onUpdateRegister) {
          this.props.onUpdateRegister(registerUid);
        }

        this.setState({
          selectedRegisterUid,
          frequencies,
          is_loading_data: false,
        }, async () => {
          this.loadData();
        });
      });
    }
  }

  mapCoursesToOptions () {
    const { courses } = this.state;
    return courses.map(course => {
      return {
        value: course.uid,
        label: course.title,
      };
    });
  }

  mapRegistersToOptions () {
    const { registers } = this.state;
    return registers.map(register => {
      return {
        value: register.uid,
        label: register.name,
      };
    });
  }

  render () {
    const {
      headers,
      data,
      data_total_results,
      data_total_pages,
      data_current_page,
      selectedCourseUid,
      selectedRegisterUid,
      is_loading_data,
    } = this.state;

    return (
      <S.MainContainer>
        <S.Filters>
          <S.Filter>
            <p style={{ marginBottom: "0.7rem" }}>
              Curso
            </p>

            <Components.Buttons.Select
              borderColor='#d3d5dd'
              title='Curso'
              defaultValue={selectedCourseUid}
              items={this.mapCoursesToOptions()}
              onChange={this.handleSelectCourse}
              required
            />
          </S.Filter>


          {
            selectedRegisterUid !== null &&
            <S.Filter>
              <p style={{ marginBottom: "0.7rem" }}>
                Turma
              </p>

              <Components.Buttons.Select
                borderColor='#d3d5dd'
                title='Turma'
                defaultValue={selectedRegisterUid}
                items={this.mapRegistersToOptions()}
                onChange={this.handleSelectRegister}
                required
              />
            </S.Filter>
          }
        </S.Filters>

        {
          is_loading_data ? (
            <Loading msg='Carregando informações do relatório...' />
          ) : (
            <>
              <S.TableContainer>
                <div className='info-counter'>
                  Mostrando <strong>{data.length}</strong> resultados
                </div>
                <S.Table className='rdp-admin-table'>
                  <thead>
                  <tr>
                    {headers.map(({ field, highlighted }, index) =>
                      <S.Th key={index} highlighted={highlighted}>{field}</S.Th>,
                    )}
                  </tr>
                  </thead>
                  <tbody>
                  {
                    (data.length > 0) ? (
                      data.map((row, index) => (
                        <tr key={index}>
                          {headers.map(({ identifier, highlighted }, i) =>
                            <S.Td key={i} highlighted={highlighted}>{row[identifier]}</S.Td>,
                          )}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <S.NoContent
                          className='no-content'
                          colSpan={headers.length}
                        >Nenhum resultado encontrado.</S.NoContent>
                      </tr>
                    )
                  }
                  </tbody>
                </S.Table>

                <S.PaginationContainer>
                  <Pagination
                    currentPage={data_current_page}
                    registerCount={data_total_results}
                    limit={50}
                    totalPages={data_total_pages}
                    onPaginationButtonClick={async (page) => {
                      const frequencies = await this.loadFrequencies(
                        selectedRegisterUid, page);
                      this.setState({
                        frequencies,
                      }, () => {
                        this.loadData();
                      });
                    }}
                  />
                </S.PaginationContainer>
              </S.TableContainer>
            </>
          )
        }
      </S.MainContainer>
    );
  }
}

export default withRouter(AttendanceTable);
