import React, { useEffect } from "react";
import * as S from "./terms-table.styled";
import { Loading, Pagination } from "../../components";
// @ts-ignore
import Components from "rdp-builder-components";
import { IconConstruct, IconEdit, IconNone, IconTrash } from "../../components/icon";
import { Link } from "react-router-dom";
import ModalDelete from "../../components/modal-delete";
import SDK from "api.digitalpages.module.sdk.api";

interface TableHeader {
  field: string; 
  highlighted: boolean;
  identifier: "title" | "active" | "actions";
}

interface ITermsTableProps {
  termsConnector?: IConnectorModel;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

function TermsTable(props: ITermsTableProps) {
  const {
    termsConnector,
    isLoading,
    setIsLoading
  } = props;
  const [data, setData] = React.useState<Array<ITermsModel>>([]);
  const [headers, setHeaders] = React.useState<Array<TableHeader>>([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);
  const [pageSize, _] = React.useState(3);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState<ITermsModel>();
  const [isPaginated, setIsPaginated] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);
  
  useEffect(() => {
    let alive = true;
    
    setHeaders([
      {
        field: "Título",
        highlighted: false,
        identifier: "title"
      },
      {
        field: "Ativo",
        highlighted: false,
        identifier: "active"
      },
      {
        field: "Ações",
        highlighted: false,
        identifier: "actions"
      },
    ])

    if (termsConnector) {
      fetchData(currentPage, termsConnector)
        .then(resp => alive ? updateTableState(resp) : null)
    }
    
    return () => {
      alive = false;
    };
  }, [termsConnector, currentPage]);
  
  
  const fetchData = async (page: number, termsConnector?: IConnectorModel): Promise<IPagedResult<ITermsModel>|undefined> => {
    try {
      if (isFetching) return;
      if (termsConnector) {
        let pagination = isPaginated ? `?page=${page}&limit=${pageSize}` : "";
        setIsFetching(true);
        const resp: IPagedResult<ITermsModel> =  await SDK.dynamic.bridge(
          `auth/v1.0/policies/connector/uid/${termsConnector.uid}/terms${pagination}`,
          null,
          "GET"
        );
        setIsFetching(false);
        return resp;
      }
    } catch (error) {
      console.error("error", error);
      setIsLoading(false);
      setData([]);
      setIsFetching(false);
    }
  }
  
  const updateTableState = async (resp: IPagedResult<ITermsModel> | ITermsModel[] | undefined) => {
    if (!resp) return;
    if ("result" in resp && "total_results" in resp && "total_pages" in resp && "current_page" in resp) {
      setData(resp.result);
      setTotalRecords(resp.total_results);
      setTotalPages(resp.total_pages);
      setIsPaginated(resp.total_pages > 1);
      setCurrentPage(resp.current_page);
      setIsLoading(false);
      setIsPaginated(true);
    } else {
      setIsPaginated(false);
      setIsLoading(false);
      setData(resp);
    }
  }
  
  const handlePagination = async (page: number) => {
    setIsLoading(true);
    fetchData(page, termsConnector).then(updateTableState);
  }
  
  const handleDelete = (id: string) => {
    SDK.dynamic.bridge(
      `auth/v1.0/policies/connector/uid/${termsConnector?.uid}/management/term/uid/${id}`,
      null,
      'DELETE'
    ).finally(() => {
      setShowDeleteModal(false);
      setIsLoading(true);
      fetchData(currentPage, termsConnector).then(updateTableState)
    });
  }

  const handleSave = async (data: ITermsModel) => {
    try {
      await SDK.dynamic.bridge(
        `auth/v1.0/policies/connector/uid/${termsConnector?.uid}/management/term/uid/${data.uid}`,
        data,
        'PUT'
      );
    } catch (error) {
      console.error(error);
    }
  }
  
  const renderRow = () => {
    return data.map((row, index) => (
      <tr key={index}>
        {headers.map((header, i) => {
          switch (header.identifier) {
            case "active":
              return (
                <S.Td 
                  key={i} 
                  highlighted={header.highlighted}
                >
                  <Components.Buttons.Checkbox
                    label=""
                    checkedColor="#764AB0"
                    defaultChecked={row[header.identifier]}
                    onChange={(value: boolean, label: string, checked: boolean) => {
                      handleSave({
                        ...row,
                        active: checked
                      });
                    }}
                  />
                </S.Td>
              );
            case "actions":
              return (
                <S.Td
                  key={i}
                  highlighted={header.highlighted}
                >
                  <S.FlexRow>
                    <Link to={`/terms/${row.uid}`}>
                      <IconEdit />
                    </Link>

                    <Link
                      to="#"
                      onClick={() => {
                        setSelectedItem(row);
                        setShowDeleteModal(true);
                      }}
                    >
                      <IconTrash />
                    </Link>
                  </S.FlexRow>
                </S.Td>
              )
            default:
              return (
                <S.Td 
                  key={i} 
                  highlighted={header.highlighted}
                >
                  {row[header.identifier]}
                </S.Td>
              )
          }
        })}
      </tr>
    ));
  }
  
  if (!isLoading && !termsConnector) {
    return (
      <S.Container>
        <S.NoContentContainer>
          <S.BoxConstruction>
            <div>
              <IconConstruct />
            </div>
            <p>Nenhum resultado encontrado.</p>
          </S.BoxConstruction>
        </S.NoContentContainer>
      </S.Container>
    )
  }
  
  return (
    <S.Container>
      {
        isLoading ? (
          <Loading msg="Carregando..." />
        ) : (
          <>
            <S.TableContainer>
              <div className="info-counter">
                Mostrando <strong>{data.length}</strong> resultados
              </div>
              
              <S.Table className="rdp-admin-table">
                <thead>
                <tr>
                  {headers.map(({ field, highlighted }, index) =>
                    <S.Th key={index} highlighted={highlighted}>{field}</S.Th>,
                  )}
                </tr>
                </thead>
                <tbody>
                {(data.length > 0) ? renderRow() : (
                  <tr>
                    <S.NoContent
                      className="no-content"
                      colSpan={headers.length}
                    >
                      Nenhum resultado encontrado.
                    </S.NoContent>
                  </tr>
                )}
                </tbody>
              </S.Table>

              <ModalDelete
                open={showDeleteModal}
                action={() => {
                  handleDelete(selectedItem?.uid || '');
                }}
                title={"Excluir termo"}
                color=""
                onClose={() => setShowDeleteModal(false)}
              />

              {isPaginated && (
                <S.PaginationContainer>
                  <Pagination
                    currentPage={currentPage}
                    registerCount={totalRecords}
                    limit={pageSize}
                    totalPages={totalPages}
                    onPaginationButtonClick={handlePagination}
                  />
                </S.PaginationContainer>
              )}
            </S.TableContainer>
          </>
        )
      }
    </S.Container>
  );
}

export default TermsTable;