import styled, {css} from "styled-components";

export const EntityItemsTree = styled.div<{
  hasBorder?: boolean;
}>`
  display: block;
  width: auto;
  min-height: 600px;
  height: 100%;
  margin: 20px;
  padding: 20px;
  background: rgba(245, 247, 248, 0.83);
  
  ${props => props.hasBorder && css`
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    box-shadow: inset 0 0 10px #ececec;
  `}
`;

export const EntityItemAddChildBtn = styled.button`
  height: 30px;
  border-radius: 5px;
  background: transparent;
  color: #764ab0;
  border: 2px solid #764ab0;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 15px;
  
  &:hover, &:focus {
    background: #764ab0;
    color: white;
  }

  &:focus {
    outline: 2px solid #764ab0 !important;
    outline-offset: 2px;
  }
`;

export const TitleInput = styled.input`
  height: auto;
  width: 100%;
  border: none;
  margin: 0;
  padding: 0;
  caret-color: #764ab0;
`;

export const Relative = styled.div`
  position: relative;
`;

export const CheckboxContainer = styled.div`
  position: absolute;
  left: -55px;
`;