import styled, {css} from "styled-components";
import { opacity } from "html2canvas/dist/types/css/property-descriptors/opacity";

export const MainContainer = styled.div`
  display: block;
  min-height: 800px;
`;

export const Filters = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
`;

export const Filter = styled.div<{
  disabled?: boolean;
}>`
  width: 300px;
  cursor: ${({disabled}) => disabled ? "not-allowed" : "auto"};
`;

export const TableContainer = styled.div`
  overflow: auto;
`;

export const Th = styled.th<{
  highlighted?: boolean;
}>`
`

export const Td = styled.td<{
  highlighted?: boolean;
}>`
`

export const NoContent = styled.td`
  height: 300px;
`;

export const Table = styled.table`
  &.rdp-admin-table {
    tr {
      td.no-content {
        text-align: center;
        vertical-align: middle;
      }
    }
  }
`;

export const PaginationContainer = styled.div`
  margin: 20px;
`