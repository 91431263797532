import styled, { css } from "styled-components";

export const EntityItem = styled.div<{
  readonly column?: number;
  readonly showChildrenLine?: boolean;
  readonly border?: string;
  readonly selected?: boolean;
}>`
  display: block;
  border: 1px solid #dbdfe6;
  border-radius: 5px;
  height: 40px;
  margin-bottom: 10px;
  text-align: left;
  vertical-align: center;
  background: white;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  transition: all 0.3s ease;
  
  ${props => props.border && css`
    border: ${props.border};
  `}

  .rdp-entity-tree-add-child-btn {
    button {
      display: none;
    }
  }

  &.selected {
    border-color: #764ab0;
  }
  
  &:hover, &:focus  {
    ${props => !props.border && css`
      border-color: #764ab0;
    `}
    margin-bottom: 50px;
    
    .entity-child-icon-children-icon {
      height: 70px;
      bottom: -70px;
      transition: none;
    }
    
    .entity-child-icon-right-brother-icon {
      height: 90px;
      bottom: -70px;
      transition: none;
    }
    
    .rdp-entity-tree-add-child-btn {
      button {
        display: block;
      }
    }
  }

  &:focus {
    outline: 2px solid #764ab0 !important;
    outline-offset: 2px;
  }
  
  ${({column}) => css`margin-left: calc(${column || 0} * 40px)`}
`;

export const EntityChildIcon = styled.div`
  height: 30px;
  width: 30px;
  border-left: 1px solid #764ab0;
  border-bottom: 1px solid #764ab0;
  position: absolute;
  left: 20px;
  bottom: -30px;
  pointer-events: none;
  
  &:hover {
    border-left: 2px solid #764ab0;
    border-bottom: 2px solid #764ab0;
  }
`;

export const EntityBrotherIcon = styled.div`
  height: 50px;
  width: 20px;
  border-left: 1px solid #764ab0;
  border-bottom: 1px solid #764ab0;
  position: absolute;
  left: -20px;
  bottom: -30px;
  pointer-events: none;
`;

export const EntityItemContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const EntityItemName = styled.div`
  flex: 4;
  max-height: 38px;
`;

export const EntityItemActions = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;

export const EntityItemIcon = styled.span`
  display: flex;
  width: auto;
  margin-right: 20px;
  color: #764ab0;
  border-radius: 4px;
  background: #f2f2f2;
  border: 2px solid transparent;
`;

export const EntityItemAddChildConteiner = styled.div`
  position: absolute;
  left: 0;
  margin-left: 40px;
  bottom: -45px;
  width: calc(100% - 40px);
  height: 50px;
`;

export const EntityItemAddChildBtn = styled.button`
  height: 30px;
  border-radius: 5px;
  background: transparent;
  border: 2px solid ${({color}) => color || "#764ab0"};
  width: 100%;
  margin-top: 15px;
  color: ${({color}) => color || "#764ab0"};

  svg path {
    fill: ${({color}) => color || "#764ab0"};
  }
  
  &:hover, &:focus {
    background: ${({color}) => color || "#764ab0"};
    color: white;
    svg path {
      fill: white;
    }
  }

  &:disabled {
    opacity: 0.5;
  }
`;

export const EntityItemBtn = styled.button`
  background: transparent;
  border: 2px solid transparent;
  border-radius: 5px;
  height: 100%;
  width: auto;
  
  &:hover {
    background: #f2f2f2;
    border: 2px solid #764ab0;
  }
`;

export const EntityItemEditBtn = styled(EntityItemBtn)`
  color: ${({color}) => color || "#764ab0"};
  
  svg path {
    fill: ${({color}) => color || "#764ab0"};
  }

  &:hover, &:focus {
    background: #f2f2f2;
    border: 2px solid ${({color}) => color || "#764ab0"};
  }
  
  &:disabled {
    opacity: 0.5;
    
    &:hover {
      border-color: transparent;
    }
  }
`;

export const EntityItemRemoveBtn = styled.button`
  background: transparent;
  border: 2px solid transparent;
  border-radius: 5px;
  height: 100%;
  width: auto;

  color: ${({color}) => color || "#764ab0"};

  svg path {
    fill: ${({color}) => color || "#764ab0"};
  }
  
  &:hover, &:focus {
    background: #f2f2f2;
    border: 2px solid ${({color}) => color || "#764ab0"};
  }

  &:disabled {
    opacity: 0.5;

    &:hover {
      border-color: transparent;
    }
  }
`;