import React, { Component } from 'react';
import './sidebar.scss';
import { Redirect, useHistory } from 'react-router-dom';
import Sdk from 'api.digitalpages.module.sdk.api';
import { useRecoilValue } from 'recoil';
import { currentRouteState } from '../../recoil/atoms/currentRouteState';
import { groupsState } from '../../recoil/atoms/groupsState';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      loading: false,
      redirectTo: null,
      groups: this.props.groups,
    };
  }
  async componentDidMount() {
    const { groups } = this.props;
    if (!groups || !groups.length) {
      this.setState(
        { ready: false, loading: true, redirectTo: null },
        await this.getGroups
      );
    } else {
      this.setState({ ready: true, loading: false, redirectTo: null, groups });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.redirectTo != null) {
      this.setState({ redirectTo: null });
    }

    if (prevProps.groups !== this.props.groups) {
      const { groups } = this.props;
      if (groups && groups.length) {
        this.setState({ groups, loading: false });
      } else {
        this.setState({ loading: true });
      }
    }
  }

  openLink(group, route) {
    const { defineGroup, defineRoute } = this.props;
    if (
      route.uri.split('https://').length === 2 ||
      route.uri.split('http://').length === 2
    ) {
      if (route.force_project_and_access_token) {
        var projectUid = Sdk.authorization.activeProject.uid;
        var accessToken = Sdk.authorization.credential.accessToken;
        window.open(
          `${route.uri}/#/auth-with-token/pt_br/home/${projectUid}/${accessToken}`,
          '_blank'
        );
      } else {
        window.open(route.uri, '_blank');
      }
    } else {
      // this.setState({ redirectTo: route.uri }, () => {
      if (
        typeof defineRoute === 'function' &&
        typeof defineGroup === 'function'
      ) {
        defineGroup(group);
        defineRoute(route);
        this.props.history.push(route.uri);
      }
      // });
    }
  }

  renderRedirect = () => {
    return <Redirect to={{ pathname: this.state.redirectTo }} />;
  };

  verifyPathInGroup(group) {
    let result = false;
    if (group.subItems && group.subItems.length > 0) {
      group.subItems.forEach((item, i) => {
        if (window.location.hash.indexOf(item.uri) > -1) {
          result = true;
        }
      });
    }
    return result;
  }

  renderGroups() {
    const { groups } = this.state;
    const { currentRoute } = this.props;
    return groups.map((group, j) => {
      return (
        <CollapseOption
          key={j}
          icon={group.icon ? group.icon : 'label'}
          title={group.name ? group.name : 'Grupo'}
          hasSubItems={!!group.subItems}
          disabled={group.disabled}
          isSelected={(currentRoute && currentRoute.name) === group.name}
          startOpen={this.verifyPathInGroup(group)}
          handleClick={(e) => {
            this.openLink(group, group);
          }}
        >
          {group.subItems &&
            group.subItems.map((item, i) => (
              <li
                key={i + '_' + item.uri}
                className={`collapse-inside-option ${
                  (currentRoute && currentRoute.name) === item.name
                    ? 'option-active'
                    : null
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  this.openLink(group, item);
                }}
                style={{
                  opacity:
                    currentRoute && currentRoute.disabled ? '0.5' : '1.0',
                  pointerEvents:
                    currentRoute && currentRoute.disabled ? 'none' : 'initial',
                }}
              >
                <span className="option-text sub-item-text">{item.name}</span>
              </li>
            ))}
        </CollapseOption>
      );
    });
  }
  renderMenu() {
    return (
      <ul className="rdp-admin-component-sidebar">
        {this.state.loading ? 'Carregando...' : this.renderGroups()}
      </ul>
    );
  }

  render() {
    if (this.state.redirectTo) {
      return this.renderRedirect();
    }
    return this.renderMenu();
  }
}

class CollapseOption extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.setState({ opened: this.props.startOpen ? true : false });
  }

  toggleCollapse = (evt) => {
    const { hasSubItems, handleClick, disabled } = this.props;
    if (hasSubItems) {
      this.setState({ opened: !this.state.opened });
    } else {
      if (!disabled) {
        handleClick();
      }
    }
  };

  render() {
    const { icon, title, hasSubItems, children, isSelected, disabled } =
      this.props;
    return (
      <li
        className={`${hasSubItems ? 'sidebar-collapse-option' : ''} ${
          disabled ? 'sidebar-disabled' : ''
        } sidebar-option icon-sidebar-${icon} ${
          this.state.opened || isSelected ? 'collapse-open active' : ''
        }`}
        onClick={this.toggleCollapse}
      >
        <span className="option-text">{title}</span>
        <ul>{children}</ul>
      </li>
    );
  }
}

export default function ({ currentRoute, ...rest }) {
  const crs = useRecoilValue(currentRouteState);
  const grps = useRecoilValue(groupsState);
  const history = useHistory();

  return (
    <Sidebar
      {...rest}
      currentRoute={currentRoute || crs}
      groups={grps}
      history={history}
    />
  );
}
