import $ from 'jquery';
import jwtDecode from 'jwt-decode';
import Sdk from 'api.digitalpages.module.sdk.api';
import RDP_CONFIG from '../config/config';

const userService = function () {
  this.getUserDecoded = function () {
    if (window.localStorage.getItem('rdp-manager-user-data') !== null) {
      return jwtDecode(
        JSON.parse(window.localStorage.getItem('rdp-manager-user-data'))
          .access_token,
      );
    }
    return null;
  };

  this.getUser = function () {
    return JSON.parse(window.localStorage.getItem('rdp-manager-user-data'));
  };

  this.setUser = function (data) {
    window.localStorage.setItem('rdp-manager-user-data', JSON.stringify(data));
  };

  this.getProjects = function () {
    return JSON.parse(window.localStorage.getItem('rdp-manager-projects'));
  };

  this.setProjects = function (data) {
    window.localStorage.setItem('rdp-manager-projects', JSON.stringify(data));
  };

  this.getActiveProject = function () {
    return Sdk.authorization.activeProject;

    // return JSON.parse(window.localStorage.getItem("rdp-manager-active-project"));
  };

  this.getConnector = function () {
    const { activeProject } = Sdk.Api.authorization;

    let connector = null;
    if (activeProject && activeProject.connectors.length > 0) {
      connector = activeProject.connectors.filter(
        (con) => con.type === RDP_CONFIG.connectorType,
      );
    }
    return connector && connector[0] ? connector[0].uid : null;
  };

  // this.setActiveProject = function(data) {
  //   window.localStorage.setItem("rdp-analytics-active-project", JSON.stringify(data));
  // }

  this.setActiveProject = function (uid, type, accessToken) {
    return Sdk.initializeFromProject(uid);

    // return new Promise(function (resolve, reject) {
    //   $.ajax({
    //     url: RDP_CONFIG.prodLogin + `auth/${RDP_CONFIG.version}/project/uid/` + uid,
    //     type: "GET",
    //     dataType: "json",
    //     headers: {
    //       "Authorization": type + " " + accessToken,
    //       'Project-Key': RDP_CONFIG.ProjectKey
    //     }
    //   }).then(function (data) {
    //     window.localStorage.setItem("rdp-manager-active-project", JSON.stringify(data));
    //     resolve(data)
    //   }, function (error) {
    //     reject(error)
    //   });
    // })
  };

  return this;
};

const UserService = new userService();

export { UserService };
