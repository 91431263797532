import React, { Component } from 'react';
import './dashboard.scss';

import { Header, Sidebar, Breadcrumb, Footer } from '../../components';
import Sdk from 'api.digitalpages.module.sdk.api';
import PowerbiEmbedded from 'react-powerbi';
import Components from 'rdp-builder-components';
import RDP_UTILS from '../../config/util';
import { getConnectorFromArray } from '../../utils/getConnectorFromArray';

export default class ViewDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      dashboards: null,
      selectItems: null,
      dashboardId: 0,
      loading: false,
      loadingSubmit: false,
      msgSuccess: null,
      msgError: null,
      currentProject: null,
      users: null,
      courses: null,
      expanded: false
    };
  }

  componentDidMount() {
    var _this = this;
    const project = window.localStorage.getItem('rdp-manager-active-project');
    this.setState({ ready: true, loading: false, currentProject: project });
    const user = Sdk.Api.authorization.user;

    if (user && user.roles) {
      const roles = user.roles.map((i) => i.type);

      if (roles.includes('TeamAdmin')) {
        window.location.hash = '/report/scores';
        window.location.reload(true);
      }
    }




    this.reportAvailable();

    window.document.addEventListener('keydown', function(e) {
      if(e.keyCode == 27) {
        _this.onExpandClose();
      }
    });
    // this.getUsers();
    // this.getCourses();
  }

  async getUsers() {
    const users = await Sdk.Api.authorization.usersByProject();
    this.setState({
      "users": users
    });
  }

  async getCourses() {
    const { connectors } = Sdk.authorization.activeProject;
    const conectorUid = getConnectorFromArray(connectors, 'Learning_1', 'ContentManager').uid;
    const { result } = await Sdk.dynamic.bridge(
      `cms/v1.0/learning/connector/uid/${conectorUid}/courses/management?page_size=10000`,
      null,
      'GET'
    );
    this.setState({
      "courses": result
    })
  }

  async reportAvailable() {
    const response = await Sdk.dynamic.bridge(
      `insights/v1.1/report/authorized`,
      null,
      'GET'
    );

    this.mountSelect(response);

    this.setState({
      dashboards: response,
      dashboardId: '0',
    });
  }

  async mountSelect(items) {
    const data = await items.map((item, idx) => {
      return { label: item.name, value: idx };
    });

    this.setState({
      selectItems: data,
    });
  }

  handleSelect(data) {
    if (!data && data !== 0) return;
    this.setState({ dashboardId: data });
  }

  onExpandToggle() {
    this.setState({
      "expanded": !this.state.expanded
    })
  }

  onExpandClose() {
    this.setState({
      "expanded": false
    })
  }

  render() {
    const { dashboardId, dashboards, selectItems } =
      this.state;
    const { consumer } = this.props;

    const showSelect = selectItems && selectItems.length > 1;
    const componentHeight = `calc( 100% - ${showSelect? 97 :0}px )`;

    return (
      <>
        <div className="rdp-admin-tex">
          <Header />
          <Sidebar
            currentRoute={consumer ? consumer.currentRoute : null}
            defineGroup={
              this.props.consumer ? this.props.consumer.defineGroup : null
            }
            defineRoute={
              this.props.consumer ? this.props.consumer.defineRoute : null
            }
            groups={this.props.consumer ? this.props.consumer.groups : null}
          />
          <Breadcrumb
            currentRoute={consumer ? consumer.currentRoute : null}
            defineRoute={consumer ? consumer.defineRoute : null}
            expandOption={() => this.onExpandToggle()}
            currentBreadcrumbTitle={"Dashboard"}
          />
          <div id="rdp-admin-content-area" className={`rdp-admin-content rdp-admin-dashboard-view ${this.state.expanded ? "expanded": ""}`}>
            {showSelect &&(
              <div className="dashboard-select">
                <Components.Buttons.Select
                  borderColor="#d2d5dd"
                  defaultValue={0}
                  placeholder={'Select a dashboard'}
                  onChange={(value) => this.handleSelect(value)}
                  items={selectItems}
                />
              </div>
            )}

            {dashboards && dashboards[dashboardId] && (
              <PowerbiEmbedded
                  id={dashboards[dashboardId].id}
                  embedUrl={dashboards[dashboardId].url}
                  accessToken={dashboards[dashboardId].token}
                  filterPaneEnabled={false}
                  navContentPaneEnabled={true}
                  width="100%"
                  height={componentHeight}
                />
            )}

            {this.state.expanded &&
              <span className="dashboard-expand-btn" onClick={() => this.onExpandToggle()}></span>
            }
          </div>
          <Footer version="01" />
        </div>
      </>
    );
  }
}
