import React, { useEffect, useRef, useState } from "react";
import "./edit-offer.scss";
import { Breadcrumb, Footer, Header, Loading, NotifyBox, Registrations, Sidebar } from "../../components";
import Sdk from "api.digitalpages.module.sdk.api";
import DynamicForm from "../../components/form-dynamic-v2";
import moment from "moment-timezone";
import RegisterTable from "../../components/register-table/register-table";
import Card from "../../components/card/card";
import downloadUserFrequencyCSV from "../../utils/downloadUserFrequencyCSV";

const INFORMATION_TAB = Symbol('INFORMATION_TAB');
const REGISTERED_USERS_TAB = Symbol('REGISTERED_USERS_TAB');

export default function NewOffer({ consumer, history, match }) {
  const [isLoadingCertificate, setIsLoadingCertificate] = useState(false);
  const [isFirstLoading, setIsFirstLoading] = useState(false);
  const [entity, setEntity] = useState(null);
  const [entityUpd, setEntityUpd] = useState(null);
  const [refList, setRefList] = useState(null);
  const [ids, setIds] = useState([]);
  const [defaultValues, setDefaultValues] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState('');
  const [msgError, setMsgError] = useState('');
  const [registrationsRender, setRegistrationsRender] = useState(false);
  const [registrationsLoading, setRegistrationsLoading] = useState(true);
  const [registrationsUsers, setRegistrationsUsers] = useState(null);
  const [registrationsRegistering, setRegistrationsRegistering] = useState(false);
  const [registrationsFeedback, setRegistrationsFeedback] = useState(null);
  const [formIndex, setFormIndex] = useState(INFORMATION_TAB);
  const csvInputRef = useRef();
  const [currentGroups, setCurrentGroups] = useState()

  const getForm = async () => {
    const res = await fetch('./preferences.json');
    const { forms } = await res.json();
    setEntity(forms['new-offer']);
  };

  const loadEntities = async () => {
    if (isLoadingCertificate) return;
    setIsLoadingCertificate(true);
    const entitiesCp = { ...entity };
    let idList = [];

    const res = await Sdk.dynamic.bridge(
      `auth/v1.0/entity/management/schema/available?type=security`,
      null,
      'GET'
    );

    for (const [idx, entity] of res.entries()) {
      const tree = await Sdk.dynamic.bridge(
        `auth/v1.0/entity/management/schema/uid/${entity.uid}/items`,
        null,
        'GET'
      );

      const schemaInfo = await Sdk.dynamic.bridge(
        `auth/v1.0/entity/schema/uid/${entity.uid}/items`,
        null,
        'GET'
      );

      function orderByRows(obj) {
        const orderedObj = [...obj].sort((a, b) => a.row - b.row);
        return orderedObj;
      }

      const newTree = orderByRows(tree)
      const newSchemaInfo = orderByRows(schemaInfo)

      console.log(newTree)
      console.log(newSchemaInfo)

      entitiesCp.fields.push({
        id: `container-category-${idx}`,
        group: entity.name,
        schema_properties: newSchemaInfo.properties,
        required: true,
        type: 'input-tree-checkbox',
        defaultValues: refList,
        config: {},
        field: `entities-${idx}`,
        options: newTree,
      });

      idList.push(`container-category-${idx}`);
    }

    const certificateTemplates = await Sdk.dynamic.bridge(
      `storage/v1.0/search?filter=(content_type eq 'digitalpages/template') and (project_uid eq '${Sdk.Api.authorization.activeProject.uid}')&top=1000&orderBy=last_modified desc`
    );

      if (certificateTemplates.results.length > 0) {
        entitiesCp.fields.push({
          id: 'certificate_internal_content_uid',
          config: {},
          field: 'certificate_internal_content_uid',
          group: 'Template de certificado',
          placeholder: 'Select a type',
          required: false,
          type: 'input-select',
          options: [
            {
              label: 'Nenhum',
              value: "",
            },
            ...certificateTemplates.results.map((item) => {
              return {
                label: item.document.content_info[1].split("=")[1],
                value: item.document.content_uid,
              };
            })
          ],
        });
      }

    setEntityUpd(entitiesCp);
    setIds(idList);
    setIsLoadingCertificate(false);
  };

  async function getDefaultValues() {
    const register = await Sdk.dynamic.bridge(
      `cms/v1.0/learning/register/uid/${match.params.registerUid}/management`,
      null,
      'GET'
    );
    
    const list = await register.authorizations
    .flatMap((a) =>
    a.references.map((ref) => {
      return ref.reference_uid;
    })
    )
    .filter((value, index, self) => self.indexOf(value) === index);
    
    setRefList(list);

    const {
      visibility_end,
      visibility_start,
      register_start,
      register_end,
      interaction_start,
      interaction_end,
    } = register;

    const format = 'YYYY-MM-DDTHH:mm:ss';

    const newRegister = {
      ...register, ...{
        visibility_end: moment(visibility_end).local().format(format),
        visibility_start: moment(visibility_start).local().format(format),
        register_start: moment(register_start).local().format(format),
        register_end: moment(register_end).local().format(format),
        interaction_start: moment(interaction_start).local().format(format),
        interaction_end: moment(interaction_end).local().format(format),
      },
    };

    setDefaultValues(newRegister);

  }

  const valuesDefault = defaultValues?.authorizations

  
  function formatDefaultValues(defaultValues) {
    return defaultValues?.map((defaultValue, i) => ({
      references: defaultValue.references.map((reference) => ({
        type: reference.type,
        reference_uid: reference.reference_uid,
      })),
      role: defaultValue.role,
    }));
  }
  
 

  useEffect(() => {
    doFirstLoad();
  }, []);

  useEffect(() => {
    if (entity !== null && refList !== null) {
      loadEntities();
    }
  }, [refList, entity]);

  const doFirstLoad = async () => {
    if (isFirstLoading) return;
    setIsFirstLoading(true);
    try {
      await Promise.all([getForm(), getDefaultValues()]);
    } catch (e) {
      console.error(e);
    } finally {
      setIsFirstLoading(false);
    }
  }

  const handleSubmit = async (formValues, setInputs) => {

    const trueIds = [];
    const falseIds = [];
    
    const entityUids = ids.map((item) => {
      if (formValues[item]) {
        return Object.keys(formValues[item]).filter((key) => {
          const checkboxValue = formValues[item][key];
          if (checkboxValue === true) {
            trueIds.push(key);
            return true;
          } else if (checkboxValue === false) {
            falseIds.push(key);
            return false;
          }
        });
      }
    });
    function filterUniqueValues(array1, array2) {
      const uniqueValues = array1
        .filter(value => !array2.includes(value))
        .concat(array2.filter(value => !array1.includes(value)));
    
      return uniqueValues;
    }

    const getDefaultValuesArray = valuesDefault.map((id) => id.references.map((ref) => ref.reference_uid))

    const flattenedArray = getDefaultValuesArray.flat();

    const filteredValues = filterUniqueValues(flattenedArray, falseIds)

    const trueAndFalse = trueIds.concat(falseIds)

    const result = [trueAndFalse, undefined];
    
    const auths = adjustAuthorizations(result);

    const filteredAuths = adjustAuthorizations([filteredValues, undefined])
    
    const formattedDefault = formatDefaultValues(valuesDefault)
    
    const concatValues = formattedDefault.concat(auths)

    try {
      setLoadingSubmit(true);

      let {
        name,
        description,
        register_start,
        register_end,
        visibility_start,
        visibility_end,
        interaction_start,
        interaction_end,
        certificate_internal_content_uid,
      } = { ...formValues };

      const format = "YYYY-MM-DDTHH:mm:ss.SSS";

      const putRegisterObj = {
        name,
        description,
        visibility_end: moment(visibility_end).utc().format(format) + "Z",
        visibility_start: moment(visibility_start).utc().format(format) + "Z",
        register_start: moment(register_start).utc().format(format) + "Z",
        register_end: moment(register_end).utc().format(format) + "Z",
        interaction_start: moment(interaction_start).utc().format(format) + "Z",
        interaction_end: moment(interaction_end).utc().format(format) + "Z",
        certificate_internal_content_uid,
        authorizations: falseIds.length ? filteredAuths : concatValues,
      };

      putRegisterObj.certificate_internal_content_custom_service =
        '/default/target';

      const res = await Sdk.dynamic.bridge(
        `cms/v1.0/learning/register/uid/${match.params.registerUid}/management`,
        putRegisterObj,
        'PUT'
      );

      for (let prop in putRegisterObj) {
        defaultValues[prop] = putRegisterObj[prop];
      }

      setMsgSuccess('Turma criada com sucesso');
      setInputs({});
      history.push(`/tree/${match.params.treeUid}`);
      setLoadingSubmit(false);
    } catch (error) {
      setMsgError('Algo deu errado ... Tente novamente mais tarde');
    }
  };



  function adjustAuthorizations(entityUids = []) {
    let authorizations = [];
  
    entityUids.map((entityUid) => {
      (entityUid || []).map((item) => {
          authorizations.push({
            references: [{
              type: 'Entity',
              reference_uid: item,
            }],
            role: "Viewer"
          });
        }
      );
    })
  
    return authorizations;
  }


  async function onCsvFileChange(evt) {
    var f = evt.currentTarget.files[0];

    setRegistrationsLoading(true);
    setRegistrationsRender(true);

    const apiUsers = await Sdk.Api.authorization.usersByProject();

    if (f) {
      var r = new FileReader();
      r.onload = function(e) { 
        var contents = e.target.result;

        const csvJSON = csv => {
          var lines=csv.split("\n");
          var result = [];
          var headers=lines[0].split(",");
        
          for(var i=1;i<lines.length;i++){
            var obj = {};
            var currentline=lines[i].split(",");
        
            for(var j=0;j<headers.length;j++){
              obj[headers[j]] = currentline[j];
            }
            result.push(obj);
          }

          return result;
        }

        var csvUsers = csvJSON(contents);
        var tempUsers = [];
    
        for (var i = 0; i < apiUsers.length; i++) {
          for (var j = 0; j < csvUsers.length; j++) {
            if (apiUsers[i].detail.email_principal === csvUsers[j].email 
              || apiUsers[i].detail.cpf === csvUsers[j].cpf 
              || apiUsers[i].login === csvUsers[j].cpf) {
              tempUsers.push({
                "uid": apiUsers[i].uid,
                "name": apiUsers[i].detail.name,
                "email": csvUsers[j].email || csvUsers[j].cpf || "",
                "status": csvUsers[j].status
              })
            }
          } 
        }

        setRegistrationsUsers(tempUsers);

        setRegistrationsLoading(false);
      }
      r.readAsText(f);
    }
  }

  function onImportCsvOfferRegistration() {
    csvInputRef.current.click();
  }

  const registrationsOnClose = () => {
    csvInputRef.current.value = "";
    setRegistrationsLoading(true);
    setRegistrationsUsers(null);
    setRegistrationsFeedback(null);
    setRegistrationsRender(false);
  }

  const getStatus = (status) => {
    if (status === "inscrito") {
      return "Active";
    } else if (status === "inativo") {
      return "Blocked";
    }
  }

  const onRegisterSubmit = async () => {
    setRegistrationsRegistering(true);

    var feedback = "success";
    for (var i = 0; i < registrationsUsers.length; i++) {
      try {
        await Sdk.dynamic.bridge(`cms/v1.0/learning/register/uid/${match.params.registerUid}/user/uid/${registrationsUsers[i].uid}/management/register`,
          {"access_status": getStatus(registrationsUsers[i].status)},
          "POST"
        );
      } catch (e) {
        try {
          await Sdk.dynamic.bridge(`cms/v1.0/learning/register/uid/${match.params.registerUid}/user/uid/${registrationsUsers[i].uid}/management/register`,
            {"access_status": getStatus(registrationsUsers[i].status)},
            "PUT"
          )
        } catch (error) {
          var feedback = "error";
          console.log(error);
        }
      }
    }

    setRegistrationsFeedback(feedback);
    setRegistrationsRegistering(false);
  }

  const getAccessStatus = (status) => {
    if (status === "Active") {
      return "Ativo";
    } else if (status === "Inactive") {
      return "Inativo";
    };
  }

  const getRegisterStatus = (status) => {
    if (status === "Started") {
      return "Iniciado";
    } else if (status === "NotAttempted") {
      return "Não iniciado";
    } else if (status === "Completed") {
      return "Finalizado";
    } else if (status === "Passed") {
      return "Aprovado";
    }
  }

  return (
    <div>
      {registrationsRender && registrationsLoading &&
        <Registrations
          loading={true}
          onClose={() => registrationsOnClose()}
        />
      }

      {registrationsRender && !registrationsLoading &&
        <Registrations
          users={registrationsUsers}
          registering={registrationsRegistering}
          onClose={() => registrationsOnClose()}
          onSuccessBtnClick={() => registrationsOnClose()}
          onErrorBtnClick={() => registrationsOnClose()}
          onRegister={() => onRegisterSubmit()}
          feedback={registrationsFeedback}
        />
      }
      
      <div className="rdp-manager-admin-csv-input-group" >
        <input ref={csvInputRef} type="file" style={{display: `${window.top !== window.self ? 'none' : 'block'}`}} className="rdp-manager-admin-csv-input" onChange={(e) => onCsvFileChange(e)}/>
      </div>
      <Header />
      <Sidebar
        currentRoute="/users/new"
        defineGroup={consumer ? consumer.defineGroup : null}
        defineRoute={consumer ? consumer.defineRoute : null}
        groups={consumer ? consumer.groups : null}
      />
      <Breadcrumb
        currentRoute={consumer ? consumer.currentRoute : null}
        importCsvOfferRegistrationEnabled={true}
        importCsvOfferRegistrationOnClick={() => onImportCsvOfferRegistration()}
        importDownloadFrequenciesDataEnabled={formIndex === REGISTERED_USERS_TAB}
        importDownloadFrequenciesDataOnClick={(exportAllUserData) => downloadUserFrequencyCSV(match.params.registerUid, exportAllUserData)}
      />
      <div className="main-content">
        {loadingSubmit && <Loading msg="Criando turma..." />}
        {!loadingSubmit && msgSuccess && (
          <NotifyBox
            type="success"
            onClose={() => setMsgSuccess('')}
            message={msgSuccess}
          />
        )}
        {!loadingSubmit && msgError && (
          <NotifyBox
            type="error"
            onClose={() => setMsgError('')}
            message={msgError}
          />
        )}
        <div className="edit-offer-form-buttons">
          <button
            className={`tab-button ${formIndex === INFORMATION_TAB ? 'active' : null}`}
            onClick={() => setFormIndex(INFORMATION_TAB)}>
            Informações
          </button>
          <button
            className={`tab-button ${formIndex === REGISTERED_USERS_TAB ? 'active' : null}`}
            onClick={() => setFormIndex(REGISTERED_USERS_TAB)}>
            Usuários matriculados
          </button>
        </div>
        {formIndex === INFORMATION_TAB &&
          <>
            {(entity && defaultValues && !isFirstLoading && !isLoadingCertificate) ? (
              <DynamicForm
                handleSubmit={handleSubmit}
                form={entityUpd ? entityUpd : entity}
                submitText="SALVAR OFERTA"
                defaultValues={defaultValues}
              />
            ) : (
              <Loading msg="Configurando formulário ..." />
            )}
          </>
        }
        {formIndex === REGISTERED_USERS_TAB && (
          <Card>
            <RegisterTable registerUid={match.params.registerUid} />
          </Card>
        )}
      </div>
      <Footer version="01" />
    </div>
  );
}

