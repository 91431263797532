import React, { useState, useEffect } from 'react';
import { GenericList } from '../../builders/list';
import { Footer } from '../../components';
import Loading from '../../components/loading';
import Sdk from 'api.digitalpages.module.sdk.api';
import { getConnectorFromArray } from '../../utils/getConnectorFromArray';
import toast from "react-hot-toast";

export default function ListTree({ consumer, entity, history }) {
  const [elements, setElements] = useState([]);
  const [messages, setMessages] = useState({});
  const [deleting, setDeleting] = useState(false);
  const [config, setConfig] = useState({});
  const [preloader, setPreloader] = useState(true);

  async function configTable() {
    const response = await fetch('./preferences.json');

    const { tables } = await response.json();

    setConfig(tables.tree);
  }

  async function getTree() {
    const { connectors } = Sdk.authorization.activeProject;
    const conectorUid = getConnectorFromArray(connectors, 'Learning_1', 'ContentManager').uid;

    const { result } = await Sdk.dynamic.bridge(
      `cms/v1.0/learning/connector/uid/${conectorUid}/courses/management?page_size=10000`,
      null,
      'GET'
    );

    const tempStructure = {
      result,
      current_page: 1,
      page_count: 1,
      page_size: result.length,
      row_count: result.length,
    };

    if (result.length == 0) {
      setMessages({ noContent: 'Nenhum curso registrado' });
    }

    setElements(tempStructure);
    setPreloader(false);
  }

  useEffect(() => {
    configTable();
    getTree();

    consumer.defineRoute({ name: 'Gestão de cursos', uri: '/tree' });
  }, []);

  async function handleDelete(data) {
    setDeleting(true);
    try {
      if (Array.isArray(data)) {
        await toast.promise(
          handleMultiDelete(data),
          {
            loading: "Apagando cursos...",
            success: config.multiDeleteText,
            error: config.deleteError,
          }
        )
      } else {
        await toast.promise(
          deleteItem(data),
          {
            loading: "Apagando curso...",
            success: config.deleteText,
            error: config.deleteError,
          }
        )
      }

      getTree();
    } catch (error) {
      console.error(error);
    }

    setDeleting(false);
  }

  async function handleMultiDelete(uids) {
    for (const uid of uids) {
      await deleteItem(uid);
    }
  }
  async function handleCopy(uid) {
    try {
      const res = await Sdk.dynamic.bridge(`cms/v1.0/learning/course/uid/${uid}/management/clone`, null, 'POST')
      console.log(res)
      if (res.uid) {
        history.push(`tree/${res.uid}`);
      }
    } catch (error) {
      console.log(error)
    }
    console.log(uid)
  }

  async function deleteItem(uid) {
    return await Sdk.dynamic.bridge(
      `cms/v1.0/learning/course/uid/${uid}/management`,
      null,
      'DELETE'
    );
  }
//cms/v1.0/learning/course/uid/4ab2375e-105b-4ff1-286d-08d9488cea42/management/clone
  function onUpdate(uid) {
    history.push(`tree/${uid}`);
  }

  return (
    <>
      <GenericList
        consumer={consumer}
        elements={elements}
        messages={messages}
        fields={config.fields}
        options={config.options}
        breadcrumb={{
          selectOptions: null,
          routeNew: '/tree/new',
          label: entity,
          customNewOptionTitle: "Novo Curso",
        }}
        loading={{
          preloader,
        }}
        actions={{
          closeMessage: () => {
            setMessages({ success: null, error: null, noContent: null });
          },
          onUpdate: (uid) => onUpdate(uid),
          onDelete: (uid) => handleDelete(uid),
          onClone: (uid) => handleCopy(uid)
        }}
      />
      {deleting ? <Loading msg={config.loadingDeleteText} /> : null}
      <Footer />
    </>
  );
}
