import React, { useState, useEffect, useRef } from 'react';
import { GenericList } from '../../builders/list';
import {
  Header,
  Sidebar,
  Breadcrumb,
  Registrations
} from '../../components';
import Loading from '../../components/loading';
import Sdk from 'api.digitalpages.module.sdk.api';
import $ from 'jquery';
import './report-progress.scss';
import DynamicForm from '../../components/form-dynamic-v2';
import { DAMService } from '../../services/dam';
import { getConnectorFromArray } from '../../utils/getConnectorFromArray';
import PreloaderImage from './icon-preloader.gif';
import TemplateCsv from './template-csv.csv';

export default function ViewReportProgress({ consumer, entity, history, match }) {
  const [config, setConfig] = useState({});
  const [preferences, setPreferences] = useState(null);

  async function configTable() {
    const res = await fetch('./preferences.json');
    const prefs = await res.json();
    setPreferences(prefs);

    // const { tables } = await response.json();

    const Configuration = {
      options: {
        hasCheckbox: false,
        hasEdit: true,
        hasDelete: true,
      }
    };

    setConfig(Configuration);
  }

  useEffect(() => {
    configTable();
    getCourses();

    consumer.defineRoute({ name: 'Relatório de Progresso', uri: '/report/progress' });
  }, []);

  const getCourses = async () => {
    const { connectors } = Sdk.authorization.activeProject;
    const conectorUid = getConnectorFromArray(connectors, 'Learning_1', 'ContentManager').uid;
    const users = await Sdk.dynamic.bridge(`auth/v1.0/project/uid/${Sdk.Api.authorization.activeProject.uid}/users`);
    const coursesData = await Sdk.dynamic.bridge(`cms/v1.0/learning/connector/uid/${conectorUid}/courses/management?page_size=10000`, null, 'GET');
    const courses = coursesData.result;

    // for (var i = 0; i < courses.length; i++) {
    //   var courseRegisters = await Sdk.dynamic.bridge(`cms/v1.0/learning/course/uid/${courses[i].uid}/management/registers`, null, 'GET');
      
    //   for (var j = 0; j < courseRegisters.length; j++) {
    //     var usersRegistersData = await Sdk.dynamic.bridge(`cms/v1.0/learning/register/uid/${courseRegisters[j].uid}/management/users?count=50000`);
    //     var usersRegisters = usersRegistersData.result;
    //     for (var i = 0; i < usersRegisters.length; i++) {
    //       // await sleep(500)
    //       try {
    //         usersRegisters[i].detail = await Sdk.dynamic.bridge(`cms/v1.0/learning/register/uid/${courseRegisters[j].uid}/user/uid/${usersRegisters[i].user_uid}/management/detail`);
    //       } catch (e) {
    //         console.log(e)
    //       }
    //       for (var j = 0; j < users.length; j++) {
    //         if (usersRegisters[i].user_uid === users[j].uid) {
    //           usersRegisters[i].user_details = users[j];
    //         }
    //       }
    //     }
    //   };

    //   courses[i].registers = courseRegisters;
    // };

    console.log(courses);

    // this.setState({
    //   "courses": result
    // })
  }

  // const getUserReports = async () => {
  //   var registers = await Sdk.dynamic.bridge(`cms/v1.0/learning/register/uid/${match.params.registerUid}/management/users?count=50000`);
  //   var users = await Sdk.dynamic.bridge(`auth/v1.0/project/uid/${Sdk.Api.authorization.activeProject.uid}/users`);
  //   var usersRegisters = registers.result;
  //   for (var i = 0; i < usersRegisters.length; i++) {
  //     // await sleep(500)
  //     try {
  //       usersRegisters[i].detail = await Sdk.dynamic.bridge(`cms/v1.0/learning/register/uid/${match.params.registerUid}/user/uid/${usersRegisters[i].user_uid}/management/detail`);
  //     } catch (e) {
  //       console.log(e)
  //     }
  //     for (var j = 0; j < users.length; j++) {
  //       if (usersRegisters[i].user_uid === users[j].uid) {
  //         usersRegisters[i].user_details = users[j];
  //       }
  //     }
  //   }

  //   setUserReport(usersRegisters);
  //   console.log(usersRegisters)
  // };

  if (preferences) {
    return <>
      <Header />
      <Sidebar
        currentRoute={consumer ? consumer.currentRoute : null}
        defineGroup={consumer ? consumer.defineGroup : null}
        defineRoute={consumer ? consumer.defineRoute : null}
        groups={consumer ? consumer.groups : null}
      />
      <Breadcrumb
        currentRoute={consumer ? consumer.currentRoute : null}
        currentGroup={consumer ? consumer.currentGroup : null}
        options={null}
        newOption={null}
        label="Envio de e-mail"
      />

      <div>

      </div>
    </>
  } else {
    return "";
  }
}