import $ from 'jquery';
import SDK from 'api.digitalpages.module.sdk.api';
import RDP_CONFIG from '../config/config';

const loginService = function () {
  this.login = function (login, password) {
    return SDK.Api.authorization.signinByLogin(login, password);
  };

  this.logout = async function () {
    await SDK.Api.authorization.setActiveProject(null);
    window.localStorage.removeItem("rdp-manager-active-project");
    return SDK.Api.authorization.logout();
  };

  return this;
};

const LoginService = new loginService();

export { LoginService };
