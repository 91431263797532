import useSWR from "swr";
import cmsService from "../services/cms-service";

export default function useOptimisticFetchCoursesManagement(learningConnectorUid?: string, searchQuery?: IPaginationQuery) {
  const resp = useSWR([learningConnectorUid, searchQuery], cmsService.learningManagementEndpoint.coursesManagement);
  let data = resp.data;

  return {
    ...resp,
    data,
  };
}