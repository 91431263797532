import {atom} from "recoil";
import {persistAtomEffect} from "../effects/persistAtom";

export const groupsState = atom<IGroup[]>({
  key: 'GroupsState',
  default: [],
  effects: [
    persistAtomEffect
  ],
  
});
