import React, { Component } from 'react';
import './new-product.scss';
import Sdk from 'api.digitalpages.module.sdk.api';

import {
  Header,
  Sidebar,
  Breadcrumb,
  FormDynamic,
  NotifyBox,
} from '../../components';

import Loading from '../../components/loading';

export default class ViewProductNew extends Component {
  constructor(props) {
    super(props);

    let connectors = Sdk.authorization.activeProject.connectors;
    const connectorMap = connectors
      .filter((connector) => {
        return connector.type.toLowerCase().includes('payment')
      })
      .map((connector) => {

        const configuration = JSON.parse(connector.configuration);

        const data = {
          value: connector.uid,
          label: configuration.name,
          connector: connector,
        };

        return data;
      });

    this.state = {
      entity: {
        layout_configuration: {
          hide_event_period: true,
          hide_products_restriction: true,
        },
        configuration: [
          {
            configuration: null,
            field: 'Conector',
            hidden: false,
            identifier: 'connector_uid',
            reference_entity_properties: null,
            required: true,
            search_column: true,
            type: 'select',
            data: connectorMap,
          },
          {
            configuration: null,
            field: 'Nome',
            hidden: false,
            identifier: 'name',
            reference_entity_properties: null,
            required: true,
            search_column: true,
            type: 'input-text',
          },
          {
            configuration: null,
            field: 'Preço',
            hidden: false,
            identifier: 'amount',
            reference_entity_properties: null,
            required: true,
            search_column: true,
            type: 'input-text',
          },
          {
            configuration: null,
            field: 'Número maximo de parcelas',
            hidden: false,
            identifier: 'installments_max',
            reference_entity_properties: null,
            required: true,
            search_column: true,
            type: 'input-text',
          },
          {
            configuration: null,
            field: 'Juros por parcela',
            hidden: false,
            identifier: 'installments_tax',
            reference_entity_properties: null,
            required: true,
            search_column: true,
            type: 'input-text',
          },
          {
            configuration: null,
            field: 'Moeda',
            hidden: false,
            identifier: 'currency',
            reference_entity_properties: null,
            required: true,
            search_column: true,
            type: 'input-text',
          },
          {
            configuration: null,
            field: 'Identificação única do produto',
            hidden: false,
            identifier: 'unique_name',
            reference_entity_properties: null,
            required: true,
            search_column: true,
            type: 'input-text',
          },
          {
            configuration: null,
            field: 'Tipo de produto',
            hidden: false,
            identifier: 'type',
            reference_entity_properties: null,
            required: true,
            search_column: true,
            type: 'select',
            data: [
              { value: 'Subscription', label: 'Assinatura' },
              { value: 'NotConsumable', label: 'Não consumível' },
              { value: 'Consumable', label: 'Consumível' },
            ],
          },
        ],
      },
      ready: false,
      loading: false,
      loadingSubmit: false,
      msgSuccess: null,
      msgError: null,
    };
  }

  componentDidMount() {
    this.props.consumer.defineRoute({ name: 'Gestão de Produtos', uri: '/store/product/new' });
  }
  async saveEntity(dataForm) {
    const connectors = Sdk.authorization.activeProject.connectors;
    const connector = connectors.find(
      (p) => p.uid == dataForm.data.connector_uid
    );

    var installments_tax_string = dataForm["data"]["installments_tax"]
    var installments_tax_list = installments_tax_string.split(',')

    dataForm["data"]["installments_tax"] = installments_tax_list

    try {
      const res = await Sdk.dynamic.bridge(
        `store/v1.0/product/connector/uid/${connector.uid}/management`,
        dataForm.data,
        'POST'
      );

      if (res){
        this.setState({
          ready: true,
          loading: false,
          loadingSubmit: false,
          msgSuccess: 'Produto salvo com sucesso!',
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({
        ready: true,
        loading: false,
        loadingSubmit: false,
        msgError: 'Erro ao salvar produto',
      });
    }
  }

  handleSubmit = (dataForm) => {
    this.setState(
      {
        ready: true,
        loading: false,
        loadingSubmit: true,
      },
      () => this.saveEntity(dataForm)
    );
  };

  resetMsg = () => {
    this.setState({ msgSuccess: null, msgError: null });
  };

  render() {
    const { consumer } = this.props;
    const { entity, loadingSubmit, msgSuccess, msgError } = this.state;

    return (
      <div className="rdp-admin-new-entity">
        <Header />
        <Sidebar
          defineGroup={consumer ? consumer.defineGroup : null}
          defineRoute={consumer ? consumer.defineRoute : null}
          groups={consumer ? consumer.groups : null}
        />
        <Breadcrumb currentRoute={consumer ? consumer.currentRoute : null} />
        <div id="rdp-admin-content-area" className="rdp-admin-content">
          {loadingSubmit && <Loading msg="Enviando..." />}
          {!loadingSubmit && msgSuccess && (
            <NotifyBox
              type="success"
              onClose={this.resetMsg}
              message={msgSuccess}
            />
          )}
          {!loadingSubmit && msgError && (
            <NotifyBox
              type="error"
              onClose={this.resetMsg}
              message={msgError}
            />
          )}
          {entity && (
            <FormDynamic
              blocked={msgSuccess || msgError ? true : false}
              isNew={true}
              cleanValues={msgSuccess ? true : false}
              handleSubmit={this.handleSubmit}
              entity={entity}
            />
          )}
        </div>
      </div>
    );
  }
}
