import React, { useEffect, useMemo } from "react";
import * as S from "./scores-table.styled";
import { Loading, Pagination } from "../../components";
import { withRouter } from "react-router-dom";
import IUserModel from "../../@types/IUserModel";
import ICourseUserRegisterReportScore from "../../@types/ICourseUserRegisterReportScore";
import ReactTooltip from "react-tooltip";
import useOptimisticFetchUsers from "../../hooks/useOptimisticFetchUsers";
import useOptimisticFetchReportScoresManagement from "../../hooks/useOptimisticFetchReportScoresManagement";
import Sdk from "api.digitalpages.module.sdk.api";

interface IScoresTableProps {
  courseUid?: Guid;
  registerUid?: Guid;
}

function ScoresTable(props: IScoresTableProps) {
  const {
    courseUid,
    registerUid,
  } = props;

  const { user } = Sdk.authorization;
  var filter = false;

  if (user.roles) {
    for (const role of user.roles) {
      if (
        role.type ==="TeamAdmin"
      ) {
        filter = true
      } else {
        filter = false
      }
    }
  }
  const { data: users } = useOptimisticFetchUsers();
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const { data: scoresPaged } = useOptimisticFetchReportScoresManagement(registerUid, {page_size: 50, page: currentPage, filter_entities: filter});
  const scores = scoresPaged?.result || [];
  const [headers, setHeaders] = React.useState<Array<{
    type: string,
    field: string,
    identifier: string,
    highlighted: boolean,
  }>>([]);
  const [data, setData] = React.useState<Array<{[key: string]: string}>>([]);
  const [is_loading_data, setIsLoadingData] = React.useState<boolean>(false);

  const getHeaders = (scores: ICourseUserRegisterReportScore[]) => {
    let headers = [
      {
        type: 'text',
        field: 'Usuário',
        identifier: 'user',
        highlighted: false,
      },
      {
        type: 'text',
        field: 'Curso',
        identifier: 'course',
        highlighted: false,
      },
      {
        type: 'text',
        field: 'Turma',
        identifier: 'register',
        highlighted: false,
      },
    ];

    headers = [
      ...headers,
      ...getNodeHeaders(scores),
    ];

    headers.push({
      type: 'text',
      field: 'Média final Curso/Turma',
      identifier: 'final_course_score',
      highlighted: true,
    });

    return headers;
  }

  const getNodeHeaders = (scores: ICourseUserRegisterReportScore[]) => {
    let headers: Array<{
      type: string,
      field: string,
      identifier: string,
      highlighted: boolean,
    }> = [];

    if (scores.length > 0) {
      const score = scores[0];
      const { nodes = [] } = score;

      if (nodes.length > 0) {
        nodes.forEach((node) => {
          const { activity_scores = [] } = node;

          if (activity_scores.length > 0) {
            activity_scores.forEach((activity) => {
              headers.push({
                type: 'text',
                field: activity.name + " (Atividade)",
                identifier: activity.activity_uid,
                highlighted: false,
              });
            });
          }

          headers.push({
            type: 'text',
            field: node.node_title + " (Nó)",
            identifier: node.node_uid,
            highlighted: true,
          });
        });
      }
    }

    return headers;
  }


  const getData = (scores: ICourseUserRegisterReportScore[], users: IUserModel[]) => {
    let rows: Array<{[index: string]: string}> = [];

    scores.forEach((score) => {
      let user = users.find((user) => user.uid === score.user_uid);

      if (user) {
        if (user.roles) {
          let isStudent = true;
          for (const role of user.roles) {
            if (role.type === 'ReviewContributor' || role.type === 'ReviewAdmin' || role.type === 'AdministratorGlobal') {
              isStudent = false;
              break;
            }
          }

          if (!isStudent) return;
        }

        let columns: {[index: string]: any} = {
          user: user.login,
          course: score.course_title,
          register: score.register_name,
        };

        let node_score_sum = 0;

        score.nodes.forEach((node) => {
          const { activity_scores = [] } = node;

          let activities_score_sum = 0;

          if (activity_scores.length > 0) {
            activity_scores.map((activity) => {
              let score = activity.score || 0;

              if (activity.score_weight !== null) {
                score = (score * (activity.score_weight || 10)) / 10;
              }

              activities_score_sum += score || 0;

              const scoreParts = String(activity.score).split('.');
              if (scoreParts.length > 1) {
                activity.score = parseInt((activity.score || 0).toFixed(2));
              }

              columns[activity.activity_uid] = activity.score || "-";
            });
          }

          if (activities_score_sum > 0) {
            switch (String(node.type_formula_score).toLowerCase()) {
              case 'sum':
                columns[node.node_uid] = parseFloat(activities_score_sum.toFixed(2));
                break;
              case 'average':
                columns[node.node_uid] = parseFloat((activities_score_sum / activity_scores.length).toFixed(2));
                break;
              default:
                columns[node.node_uid] = 0;
            }
          } else {
            columns[node.node_uid] = 0;
          }

          node_score_sum += columns[node.node_uid];
        });

        if (node_score_sum > 0) {
          switch (String(score.type_formula_score).toLowerCase()) {
            case 'sum':
              columns['final_course_score'] = parseFloat(node_score_sum.toFixed(2));
              break;
            case 'average':
              columns['final_course_score'] = parseFloat((node_score_sum / score.nodes.length).toFixed(2));
              break;
            default:
              columns['final_course_score'] = 0;
          }
        } else {
          columns['final_course_score'] = 0;
        }

        rows.push(columns);
      }
    });

    return rows;
  }

  const loadData = (scores: ICourseUserRegisterReportScore[], users?: IUserModel[]) => {
    let headers = getHeaders(scores);
    if (users && users.length > 0) {
      let data = getData(scores, users);
      setData(data);
    }

    setHeaders(headers);
    setIsLoadingData(false);
  }

  const doFirstload = async (users?: IUserModel[], scores?: ICourseUserRegisterReportScore[]) => {
    try {
      setIsLoadingData(false);

      if (scores && scores.length > 0)
        loadData(scores, users);
      return;
    } catch (error) {
      console.error(error);
      return;
    }
  }

  useMemo( () => {
    if (users && users.length > 0
      && scores && scores.length > 0
    )
      return doFirstload(users, scores);
  }, [users, scores, courseUid, registerUid]);

  useEffect(() => {
    doFirstload(users, scores)
  }, [scores, users])


  return (
    <S.MainContainer>
      {/*@ts-ignore*/}
      <ReactTooltip
        effect="solid"
        type="dark"
        place="bottom"
      />

      {
        is_loading_data ? (
          <Loading msg='Carregando informações do relatório...' />
        ) : (
          <>
            <S.TableContainer>
              <div className='info-counter'>
                Mostrando <strong>{data.length}</strong> resultados
              </div>
              <S.Table className='rdp-admin-table'>
                <thead>
                <tr>
                  {headers.map(({ field, highlighted }, index) =>
                    <S.Th key={index} highlighted={highlighted}>{field}</S.Th>,
                  )}
                </tr>
                </thead>
                <tbody>
                {
                  (scores.length > 0) ? (
                    data.map((row, index) => (
                      <tr key={index}>
                        {headers.map(({ identifier, highlighted }, i) =>
                          <S.Td key={i} highlighted={highlighted}>{row[identifier]}</S.Td>,
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <S.NoContent
                        className='no-content'
                        colSpan={headers.length}
                      >Nenhum resultado encontrado.</S.NoContent>
                    </tr>
                  )
                }
                </tbody>
              </S.Table>

              <S.PaginationContainer>
                <Pagination
                  currentPage={scoresPaged?.current_page || 1}
                  registerCount={scoresPaged?.total_results || 0}
                  limit={50}
                  onPaginationButtonClick={async (page: number) => {
                    if (registerUid) {
                      setCurrentPage(page);
                      loadData(scores, users);
                    }
                  }}
                />
              </S.PaginationContainer>
            </S.TableContainer>
          </>
        )
      }
    </S.MainContainer>
  );
}

//@ts-ignore
export default withRouter(ScoresTable) as typeof ScoresTable;
