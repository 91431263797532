import React, { Component } from 'react';

export class IconEdit extends Component {
  render() {
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="18px"
        height="18px"
        viewBox="0 0 18 18"
        enableBackground="new 0 0 18 18"
        space="preserve"
      >
        <g extraneous="self">
          <path
            fill="#9E9FA7"
            d="M17.36,16.72H0.64c-0.35,0-0.62,0.3-0.62,0.64S0.31,18,0.64,18
              h16.73c0.36,0,0.63-0.3,0.63-0.64S17.71,16.72,17.36,16.72z M1.28,11.35l7.55-7.69l2.06,2.1l-7.54,7.68l-2.08,0.01L1.28,11.35z
              M10.89,1.56l2.07,2.1L11.8,4.84l-2.07-2.1L10.89,1.56z M0.02,11.07L0,14.11c0,0.17,0.06,0.34,0.18,0.46
              c0.13,0.13,0.28,0.19,0.45,0.19l2.96-0.01c0.17,0,0.32-0.07,0.44-0.19L14.27,4.13c0.25-0.25,0.25-0.66,0-0.92l-2.93-3.02
              c-0.25-0.25-0.64-0.25-0.9,0l-2.05,2.1L0.2,10.62C0.09,10.74,0.02,10.9,0.02,11.07z"
          />
        </g>
      </svg>
    );
  }
}

export class ViewListIcon extends Component {
  render() {
    return (
      

<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 511.999 511.999" style={{enableBackground:"new 0 0 511.999 511.999"}} xmlSpace="preserve">
<g>
	<g>
		<path d="M255.998,214.787c-22.724,0-41.212,18.488-41.212,41.212s18.488,41.212,41.212,41.212s41.212-18.488,41.212-41.212
			S278.723,214.787,255.998,214.787z"/>
	</g>
</g>
<g>
	<g>
		<path d="M507.836,244.912c-1.147-1.312-28.596-32.502-72.998-63.93c-59.487-42.105-121.329-64.362-178.84-64.362
			c-57.51,0-119.352,22.256-178.839,64.362C32.758,212.41,5.308,243.6,4.162,244.912c-5.549,6.35-5.549,15.824,0,22.174
			c1.147,1.312,28.596,32.502,72.998,63.931c59.487,42.105,121.329,64.362,178.839,64.362c57.511,0,119.353-22.256,178.84-64.362
			c44.402-31.428,71.852-62.618,72.998-63.931C513.386,260.737,513.386,251.262,507.836,244.912z M255.997,350.365
			c-52.032,0-94.365-42.332-94.365-94.366s42.332-94.366,94.365-94.366c52.036,0,94.367,42.332,94.367,94.366
			S308.033,350.365,255.997,350.365z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>

    )
  }
}
export class IconTrash extends Component {
  render() {
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="18px"
        height="18px"
        viewBox="0 0 18 18"
        enableBackground="new 0 0 18 18"
        space="preserve"
      >
        <g extraneous="self">
          <path
            fill="#A9B5BA"
            d="M6.49,15.78c0.25,0,0.46-0.22,0.46-0.49V9.8
              c0-0.27-0.21-0.49-0.46-0.49c-0.26,0-0.47,0.22-0.47,0.49v5.49C6.02,15.56,6.23,15.78,6.49,15.78z M9,15.78
              c0.26,0,0.47-0.22,0.47-0.49V9.8c0-0.27-0.21-0.49-0.47-0.49S8.53,9.53,8.53,9.8v5.49C8.53,15.56,8.74,15.78,9,15.78z
              M11.51,15.78c0.26,0,0.47-0.22,0.47-0.49V9.8c0-0.27-0.21-0.49-0.47-0.49c-0.25,0-0.46,0.22-0.46,0.49v5.49
              C11.05,15.56,11.26,15.78,11.51,15.78z M15.06,4.32c0,0.6-0.46,1.08-1.04,1.08H3.98c-0.58,0-1.04-0.48-1.04-1.08V4.21
              c0-0.59,0.46-1.08,1.04-1.08h10.04c0.58,0,1.04,0.49,1.04,1.08V4.32z M13.81,15.94c0,0.6-0.46,1.08-1.04,1.08H5.23
              c-0.58,0-1.04-0.48-1.04-1.08V6.37h9.62V15.94z M9,0.98c0.68,0,1.25,0.5,1.38,1.18H7.62C7.75,1.48,8.32,0.98,9,0.98z M14.02,2.16
              h-2.69C11.19,0.94,10.2,0,9,0S6.81,0.94,6.67,2.16H3.98C2.89,2.16,2,3.08,2,4.21v0.11c0,0.87,0.52,1.61,1.25,1.91v9.71
              c0,1.14,0.89,2.06,1.98,2.06h7.54c1.09,0,1.98-0.92,1.98-2.06V6.23C15.48,5.93,16,5.19,16,4.32V4.21
              C16,3.08,15.11,2.16,14.02,2.16z"
          />
        </g>
      </svg>
    );
  }
}

export class IconSearch extends Component {
  render() {
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="16px"
        height="16px"
        viewBox="0 0 16 16"
        enableBackground="new 0 0 16 16"
        space="preserve"
      >
        <g extraneous="self">
          <path
            fill="#A6B1B7"
            d="M0.95,6.82c0-3.23,2.64-5.86,5.87-5.86
            c3.24,0,5.87,2.63,5.87,5.86s-2.63,5.87-5.87,5.87C3.59,12.69,0.95,10.06,0.95,6.82z M15.86,15.18l-3.89-3.88
            c1.05-1.2,1.68-2.77,1.68-4.48C13.65,3.06,10.59,0,6.82,0C3.06,0,0,3.06,0,6.82s3.06,6.82,6.82,6.82c1.71,0,3.28-0.63,4.48-1.67
            l3.89,3.89C15.28,15.95,15.4,16,15.52,16c0.13,0,0.25-0.05,0.34-0.14C16.05,15.67,16.05,15.37,15.86,15.18z"
          />
        </g>
      </svg>
    );
  }
}

export class IconCloseModal extends Component {
  render() {
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="14px"
        height="14px"
        viewBox="0 0 14 14"
        enableBackground="new 0 0 14 14"
      >
        <g extraneous="self">
          <g>
            <path
              fill="#C0C1C2"
              d="M13.79,0.21c-0.27-0.28-0.71-0.28-0.98,0L7,6.01l-5.81-5.8c-0.27-0.28-0.71-0.28-0.98,0
          c-0.28,0.27-0.28,0.71,0,0.98L6.01,7l-5.8,5.81c-0.28,0.27-0.28,0.71,0,0.98C0.34,13.93,0.52,14,0.7,14s0.36-0.07,0.49-0.21
          L7,7.99l5.81,5.8C12.94,13.93,13.12,14,13.3,14c0.18,0,0.36-0.07,0.49-0.21c0.28-0.27,0.28-0.71,0-0.98L7.99,7l5.8-5.81
          C14.07,0.92,14.07,0.48,13.79,0.21z"
            />
          </g>
        </g>
      </svg>
    );
  }
}

export class IconPreview extends Component {
  render() {
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="18px"
        height="18px"
        viewBox="0 0 18 18"
        enableBackground="new 0 0 18 18"
        space="preserve"
      >
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#777986"
            d="M9,6.72c1.31,0,2.37,1.03,2.37,2.28S10.31,11.28,9,11.28
	S6.63,10.25,6.63,9S7.69,6.72,9,6.72z M9,12.41c1.96,0,3.56-1.53,3.56-3.41S10.96,5.59,9,5.59S5.44,7.12,5.44,9S7.04,12.41,9,12.41z
	 M1.35,9C2.27,7.92,5.39,4.64,9,4.64c3.6,0,6.73,3.29,7.65,4.36c-0.92,1.08-4.04,4.36-7.65,4.36C5.4,13.36,2.27,10.07,1.35,9z
	 M9,14.5c4.89,0,8.72-4.95,8.89-5.16c0.15-0.2,0.15-0.48,0-0.68C17.72,8.45,13.89,3.5,9,3.5S0.27,8.45,0.11,8.66
	c-0.15,0.2-0.15,0.48,0,0.68C0.27,9.55,4.11,14.5,9,14.5z"
          />
        </g>
      </svg>
    );
  }
}

export class IconSubscriber extends Component {
  render() {
    return (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="22px"
        height="22px"
        viewBox="0 0 22 22"
        enableBackground="new 0 0 22 22"
        xmlSpace="preserve"
      >
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#2D2F3E"
            d="M0,11C0,4.92,4.92,0,11,0s11,4.92,11,11s-4.92,11-11,11
            S0,17.08,0,11z"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FFD24D"
          d="M12.78,9.12H9.22V8.21c0-1.01,0.8-1.84,1.78-1.84
          s1.78,0.83,1.78,1.84V9.12z M11.53,12.61l0.14,1.3c0,0.07-0.02,0.13-0.06,0.18s-0.1,0.08-0.17,0.08h-0.88
          c-0.07,0-0.13-0.03-0.17-0.08s-0.06-0.11-0.06-0.18l0.14-1.3c-0.22-0.17-0.36-0.44-0.36-0.74c0-0.5,0.4-0.91,0.89-0.91
          s0.89,0.41,0.89,0.91C11.89,12.17,11.75,12.44,11.53,12.61z M14.78,9.12h-0.67V8.21C14.11,6.44,12.72,5,11,5S7.89,6.44,7.89,8.21
          v0.91H7.22C7.1,9.12,7,9.23,7,9.35v5.73C7,15.59,7.4,16,7.89,16h6.22C14.6,16,15,15.59,15,15.08V9.35C15,9.23,14.9,9.12,14.78,9.12z
          "
        />
      </svg>
    );
  }
}

export class IconNonSubscriber extends Component {
  render() {
    return (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="22px"
        height="22px"
        viewBox="0 0 22 22"
        enableBackground="new 0 0 22 22"
        xmlSpace="preserve"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#5AC475"
          d="M0,11c0,6.08,4.92,11,11,11s11-4.92,11-11S17.08,0,11,0
        S0,4.92,0,11z M1,11C1,5.48,5.48,1,11,1s10,4.48,10,10s-4.48,10-10,10S1,16.52,1,11z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#5AC475"
          d="M13.88,7C13.42,5.83,12.3,5,11,5C9.28,5,7.89,6.44,7.89,8.21V9
        v0.12H7.22C7.1,9.12,7,9.23,7,9.35v5.73C7,15.59,7.4,16,7.89,16h6.22C14.6,16,15,15.59,15,15.08V9.35c0-0.12-0.1-0.23-0.22-0.23
        h-0.67V9h-1.33v0.12H9.22V9V8.21c0-1.01,0.8-1.84,1.78-1.84c0.53,0,1.01,0.25,1.33,0.63H13.88z M11.53,12.61l0.14,1.3
        c0,0.07-0.02,0.13-0.06,0.18s-0.1,0.08-0.17,0.08h-0.88c-0.07,0-0.13-0.03-0.17-0.08s-0.06-0.11-0.06-0.18l0.14-1.3
        c-0.22-0.17-0.36-0.44-0.36-0.74c0-0.5,0.4-0.91,0.89-0.91s0.89,0.41,0.89,0.91C11.89,12.17,11.75,12.44,11.53,12.61z"
        />
      </svg>
    );
  }
}

export class IconCalendar extends Component {
  render() {
    return (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="20px"
        height="20px"
        viewBox="0 0 20 20"
        enableBackground="new 0 0 20 20"
        xmlSpace="preserve"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#9C9C9C"
          d="M0.731,19.337V5.674h18.554v13.663H0.731z M0.731,2.014h2.135
          v0.999c0,0.179,0.168,0.337,0.358,0.337h2.503c0.2,0,0.358-0.158,0.358-0.337V2.014h7.846v0.999c0,0.179,0.158,0.337,0.358,0.337
          h2.503c0.189,0,0.347-0.158,0.347-0.337V2.014h2.146v2.998H0.731V2.014z M3.581,0.678h1.788v1.998H3.581V0.678z M14.646,0.678h1.788
          v1.998h-1.788V0.678z M19.642,1.351h-2.503V0.352c0-0.189-0.158-0.337-0.347-0.337h-2.503c-0.2,0-0.358,0.147-0.358,0.337v0.999
          H6.085V0.352c0-0.189-0.158-0.337-0.358-0.337H3.224c-0.189,0-0.358,0.147-0.358,0.337v0.999H0.373c-0.2,0-0.358,0.147-0.358,0.326
          v17.986C0.016,19.853,0.173,20,0.373,20h19.269c0.2,0,0.358-0.147,0.358-0.337V1.677C20,1.499,19.842,1.351,19.642,1.351z"
        />
      </svg>
    );
  }
}

export class IconFilterArrowDown extends Component {
  render() {
    return (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="8px"
        height="4px"
        viewBox="0 0 8 4"
        enableBackground="new 0 0 8 4"
        xmlSpace="preserve"
      >
        <polygon
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#B7B9B9"
          points="8,0 0,0 4,4 "
        />
      </svg>
    );
  }
}

export class IconFilterArrowUp extends Component {
  render() {
    return (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="8px"
        height="4px"
        viewBox="0 0 8 4"
        enableBackground="new 0 0 8 4"
        xmlSpace="preserve"
      >
        <polygon
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#B7B9B9"
          points="8,4 0,4 4,0 "
        />
      </svg>
    );
  }
}

export class IconScheduled extends Component {
  render() {
    return (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="18px"
        height="18px"
        viewBox="0 0 18 18"
        enableBackground="new 0 0 18 18"
        xmlSpace="preserve"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#4D7AFF"
          d="M13.08,8.72H9.53v-5.3c0-0.42-0.34-0.76-0.76-0.76
          C8.35,2.66,8.01,3,8.01,3.42v6.06c0,0.42,0.34,0.76,0.76,0.76H8.8h0.02h4.26c0.42,0,0.76-0.34,0.76-0.76
          C13.84,9.06,13.5,8.72,13.08,8.72z M9,16.48c-4.12,0-7.48-3.36-7.48-7.48S4.88,1.52,9,1.52S16.48,4.88,16.48,9S13.12,16.48,9,16.48z
          M9,0C4.04,0,0,4.04,0,9s4.04,9,9,9s9-4.04,9-9S13.96,0,9,0z"
        />
      </svg>
    );
  }
}

export class IconPublished extends Component {
  render() {
    return (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="18px"
        height="18px"
        viewBox="0 0 18 18"
        enableBackground="new 0 0 18 18"
        xmlSpace="preserve"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#5AC475"
          d="M9,0C4.04,0,0,4.04,0,9s4.04,9,9,9s9-4.04,9-9S13.96,0,9,0z
          M13.56,7.09l-4.87,4.88c-0.15,0.14-0.34,0.22-0.53,0.22c-0.2,0-0.39-0.08-0.53-0.22L5.19,9.53c-0.29-0.29-0.29-0.77,0-1.06
          s0.77-0.29,1.06,0l1.91,1.91l4.34-4.35c0.29-0.29,0.77-0.29,1.06,0C13.85,6.33,13.85,6.8,13.56,7.09z"
        />
      </svg>
    );
  }
}

export class IconDraft extends Component {
  render() {
    return (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="18px"
        height="18px"
        viewBox="0 0 18 18"
        enableBackground="new 0 0 18 18"
        xmlSpace="preserve"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#B9BBC2"
          d="M9,0C4.04,0,0,4.04,0,9s4.04,9,9,9s9-4.04,9-9S13.96,0,9,0z M6,9
          c0-1.66,1.34-3,3-3s3,1.34,3,3s-1.34,3-3,3S6,10.66,6,9z"
        />
      </svg>
    );
  }
}

export class IconArrowLeft extends Component {
  render() {
    return (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="5px"
        height="9px"
        viewBox="0 0 5 9"
        enableBackground="new 0 0 5 9"
        xmlSpace="preserve"
      >
        <polygon
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#5B5B5B"
          points="5,0 5,9 0,4.5 "
        />
      </svg>
    );
  }
}

export class IconArrowRight extends Component {
  render() {
    return (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="5px"
        height="9px"
        viewBox="0 0 5 9"
        enableBackground="new 0 0 5 9"
        xmlSpace="preserve"
      >
        <polygon
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#5B5B5B"
          points="0,0 0,9 5,4.5 "
        />
      </svg>
    );
  }
}

export class IconCancelUpload extends Component {
  render() {
    return (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="8px"
        height="8px"
        viewBox="0 0 8 8"
        enableBackground="new 0 0 8 8"
        space="preserve"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FFFFFF"
          d="M7.75,6.55c0.33,0.33,0.33,0.87,0,1.2C7.59,7.92,7.37,8,7.15,8
	c-0.22,0-0.43-0.08-0.6-0.25L4,5.2L1.45,7.75C1.28,7.92,1.07,8,0.85,8s-0.44-0.08-0.6-0.25c-0.33-0.33-0.33-0.87,0-1.2L2.8,4
	L0.25,1.45c-0.33-0.33-0.33-0.87,0-1.2s0.87-0.33,1.2,0L4,2.8l2.55-2.55c0.33-0.33,0.87-0.33,1.2,0s0.33,0.87,0,1.2L5.2,4L7.75,6.55
	z"
        />
      </svg>
    );
  }
}

export class IconUpload extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        width="24px"
        height="22px"
        viewBox="0 0 24 22"
        enableBackground="new 0 0 24 22"
        space="preserve"
      >
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#4D7AFF"
            d="M19.52,5.75c-0.26-1.52-1.01-2.9-2.17-3.94   C16.07,0.64,14.4,0,12.66,0c-1.34,0-2.65,0.38-3.77,1.1c-0.93,0.6-1.7,1.41-2.25,2.36C6.4,3.41,6.15,3.39,5.91,3.39   c-2.1,0-3.81,1.69-3.81,3.77c0,0.27,0.03,0.53,0.08,0.78C0.82,8.92,0,10.5,0,12.18c0,1.35,0.51,2.67,1.44,3.71   c0.95,1.07,2.21,1.7,3.55,1.77h4.28c0.37,0,0.67-0.29,0.67-0.66c0-0.36-0.3-0.66-0.67-0.66H5.05c-2.02-0.12-3.72-2.02-3.72-4.17   c0-1.38,0.75-2.68,1.96-3.37c0.28-0.17,0.4-0.5,0.29-0.81c-0.1-0.26-0.15-0.54-0.15-0.84c0-1.35,1.12-2.45,2.48-2.45   c0.29,0,0.57,0.05,0.84,0.15c0.33,0.11,0.69-0.03,0.84-0.34c0.92-1.95,2.91-3.2,5.08-3.2c2.91,0,5.31,2.17,5.59,5.03   c0.03,0.3,0.26,0.54,0.55,0.59c2.2,0.37,3.86,2.38,3.86,4.68c0,2.43-1.93,4.55-4.31,4.73h-3.64c-0.37,0-0.66,0.29-0.66,0.66   s0.29,0.66,0.66,0.66h3.71c1.51-0.11,2.91-0.79,3.96-1.94c1.04-1.14,1.61-2.59,1.61-4.11C24,8.86,22.1,6.42,19.52,5.75z M16,12.8   c0.26-0.26,0.26-0.67,0-0.93l-3.53-3.5c-0.12-0.12-0.29-0.2-0.47-0.2c-0.17,0-0.34,0.07-0.47,0.2l-3.52,3.5   c-0.27,0.26-0.27,0.67,0,0.93c0.12,0.13,0.3,0.2,0.46,0.2c0.17,0,0.34-0.06,0.47-0.2l2.4-2.37v10.91c0,0.37,0.29,0.66,0.66,0.66   s0.67-0.29,0.67-0.66V10.43l2.39,2.37C15.32,13.06,15.74,13.06,16,12.8z"
          />
        </g>
      </svg>
    );
  }
}

export class IconDisket extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        width="20px"
        height="20px"
        viewBox="0 0 20 20"
        enableBackground="new 0 0 20 20"
        space="preserve"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#4D7AFF"
          d="M11.82,2.73h0.91v2.72h-0.91V2.73z M11.36,6.36h1.82  c0.25,0,0.46-0.2,0.46-0.45V2.27c0-0.25-0.21-0.45-0.46-0.45h-1.82c-0.25,0-0.45,0.2-0.45,0.45v3.64  C10.91,6.16,11.11,6.36,11.36,6.36z M19.09,18.18c0,0.5-0.41,0.91-0.91,0.91h-0.91v-8.64c0-0.25-0.2-0.45-0.45-0.45H3.18  c-0.25,0-0.45,0.2-0.45,0.45v8.64H1.82c-0.5,0-0.91-0.41-0.91-0.91V1.82c0-0.5,0.41-0.91,0.91-0.91h1.82v5.45  c0,1.01,0.81,1.82,1.81,1.82h8.19c1,0,1.81-0.81,1.81-1.82V0.91h0.27l3.37,3.37V18.18z M16.36,19.09H3.64v-8.18h12.72V19.09z   M4.55,0.91h10v5.45c0,0.5-0.41,0.91-0.91,0.91H5.45c-0.5,0-0.9-0.41-0.9-0.91V0.91z M19.87,3.77l-3.64-3.64  C16.15,0.05,16.03,0,15.91,0H1.82C0.82,0,0,0.82,0,1.82v16.36c0,1,0.82,1.82,1.82,1.82h16.36c1,0,1.82-0.82,1.82-1.82V4.09  C20,3.97,19.95,3.85,19.87,3.77z"
        />
      </svg>
    );
  }
}

export class IconAdd extends Component {
  render() {
    return (
      <svg
        viewBox="0 0 426.66667 426.66667"
        xmlns="http://www.w3.org/2000/svg"
        width="14px"
        height="14px"
      >
        <path
          fill="#C0C1C2"
          d="m405.332031 192h-170.664062v-170.667969c0-11.773437-9.558594-21.332031-21.335938-21.332031-11.773437 0-21.332031 9.558594-21.332031 21.332031v170.667969h-170.667969c-11.773437 0-21.332031 9.558594-21.332031 21.332031 0 11.777344 9.558594 21.335938 21.332031 21.335938h170.667969v170.664062c0 11.777344 9.558594 21.335938 21.332031 21.335938 11.777344 0 21.335938-9.558594 21.335938-21.335938v-170.664062h170.664062c11.777344 0 21.335938-9.558594 21.335938-21.335938 0-11.773437-9.558594-21.332031-21.335938-21.332031zm0 0"
        />
      </svg>
    );
  }
}

export class IconClone extends Component {
  render() {
    return (
      <svg
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="18px"
        height="18px"
      >
        <title />
        <g data-name="1" id="_1">
          <path
            fill="#A9B5BA"
            d="M308.51,450H80.59a15,15,0,0,1-15-15V143.93a15,15,0,0,1,15-15H308.51a15,15,0,0,1,15,15V435A15,15,0,0,1,308.51,450ZM95.59,420H293.51V158.93H95.59Z"
          />
          <path
            fill="#A9B5BA"
            d="M389.44,369.07H308.51a15,15,0,0,1,0-30h65.93V78H176.52v65.92a15,15,0,0,1-30,0V63a15,15,0,0,1,15-15H389.44a15,15,0,0,1,15,15V354.07A15,15,0,0,1,389.44,369.07Z"
          />
        </g>
      </svg>
    );
  }
}

export class IconConstruct extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        width="80px"
        height="80px"
        viewBox="0 0 80 80"
        enableBackground="new 0 0 80 80"
        space="preserve"
      >
        <path
          fill="#FFFFFF"
          d="M74.667,0H5.333C2.388,0,0,2.388,0,5.333v69.333C0,77.612,2.388,80,5.333,80h69.333  C77.612,80,80,77.612,80,74.667V5.333C80,2.388,77.612,0,74.667,0z M5.333,2.667h69.333c1.473,0,2.667,1.194,2.667,2.667v5.333  H2.667V5.333C2.667,3.861,3.861,2.667,5.333,2.667z M74.667,77.333H5.333c-1.473,0-2.667-1.194-2.667-2.667V13.333h74.667v61.333  C77.333,76.139,76.139,77.333,74.667,77.333z"
        />
        <rect x="5.333" y="5.333" fill="#FFFFFF" width="2.667" height="2.667" />
        <rect
          x="10.667"
          y="5.333"
          fill="#FFFFFF"
          width="2.667"
          height="2.667"
        />
        <rect x="16" y="5.333" fill="#FFFFFF" width="2.667" height="2.667" />
        <path
          fill="#FFFFFF"
          d="M58.992,54.875l-1.52-3.733l1.376-3.317l3.705-1.56c0.494-0.209,0.815-0.693,0.815-1.229v-6.143  c0-0.537-0.321-1.021-0.816-1.229l-3.704-1.56l-1.381-3.316l1.52-3.733c0.202-0.497,0.087-1.066-0.292-1.445l-4.344-4.345  c-0.379-0.379-0.949-0.494-1.445-0.292l-3.733,1.52l-3.305-1.373l-1.56-3.705c-0.208-0.495-0.693-0.816-1.229-0.816h-6.144  c-0.537,0-1.021,0.321-1.229,0.816l-1.571,3.705l-3.317,1.375l-3.733-1.52c-0.497-0.202-1.066-0.087-1.445,0.292l-4.344,4.345  c-0.379,0.379-0.494,0.949-0.292,1.445l1.52,3.733l-1.376,3.316l-3.704,1.56c-0.495,0.208-0.816,0.693-0.816,1.229v6.144  c0,0.536,0.321,1.021,0.815,1.229l3.705,1.56l1.388,3.316l-1.52,3.733c-0.202,0.497-0.087,1.066,0.292,1.445l4.339,4.345  c0.379,0.379,0.949,0.494,1.445,0.292l3.733-1.519l3.317,1.373l1.56,3.707c0.21,0.495,0.696,0.816,1.233,0.813h6.133  c0.536,0,1.021-0.321,1.229-0.815l1.56-3.707l3.317-1.375l3.733,1.52c0.497,0.202,1.066,0.087,1.445-0.292l4.344-4.345  C59.076,55.942,59.193,55.372,58.992,54.875z M56.592,46.3L54.8,50.629c-0.134,0.324-0.134,0.688,0,1.012l1.389,3.425l-3.093,3.092  l-3.419-1.392c-0.325-0.132-0.688-0.132-1.013,0l-4.329,1.795c-0.324,0.134-0.582,0.39-0.719,0.713l-1.429,3.392h-4.373  l-1.429-3.395c-0.136-0.324-0.394-0.58-0.719-0.715l-4.332-1.793c-0.324-0.133-0.688-0.133-1.012,0l-3.419,1.391l-3.092-3.088  l1.389-3.425c0.134-0.324,0.134-0.688,0-1.012L23.407,46.3c-0.134-0.324-0.39-0.582-0.713-0.719l-3.395-1.431v-4.373l3.393-1.428  c0.324-0.136,0.58-0.394,0.715-0.719l1.793-4.329c0.133-0.325,0.133-0.689,0-1.013l-1.392-3.42l3.093-3.092l3.419,1.392  c0.324,0.135,0.689,0.135,1.013,0l4.329-1.795c0.324-0.134,0.582-0.39,0.719-0.713l1.429-3.395h4.373l1.429,3.395  c0.136,0.323,0.395,0.579,0.719,0.713l4.335,1.792c0.324,0.135,0.689,0.135,1.013,0l3.419-1.392l3.093,3.092L54.8,32.288  c-0.133,0.325-0.133,0.689,0,1.013l1.793,4.329c0.134,0.324,0.391,0.583,0.715,0.719l3.393,1.428v4.373l-3.395,1.431  c-0.323,0.136-0.579,0.395-0.713,0.719H56.592z"
        />
        <path
          fill="#FFFFFF"
          d="M40,28.632c-7.364,0-13.333,5.97-13.333,13.333c0,7.364,5.97,13.333,13.333,13.333  s13.333-5.97,13.333-13.333l0,0C53.325,34.605,47.36,28.64,40,28.632z M40,52.632c-5.891,0-10.667-4.776-10.667-10.667  c0-5.891,4.776-10.667,10.667-10.667s10.667,4.776,10.667,10.667C50.66,47.854,45.888,52.625,40,52.632z"
        />
        <rect
          x="38.667"
          y="40.632"
          fill="#FFFFFF"
          width="2.667"
          height="2.667"
        />
        <rect
          x="33.333"
          y="40.632"
          fill="#FFFFFF"
          width="2.667"
          height="2.667"
        />
        <rect x="44" y="40.632" fill="#FFFFFF" width="2.667" height="2.667" />
        <rect
          x="6.667"
          y="65.333"
          fill="#FFFFFF"
          width="2.667"
          height="2.667"
        />
        <rect x="12" y="65.333" fill="#FFFFFF" width="12" height="2.667" />
        <rect x="6.667" y="70.667" fill="#FFFFFF" width="12" height="2.667" />
        <rect x="56" y="65.333" fill="#FFFFFF" width="2.667" height="2.667" />
        <rect x="61.333" y="65.333" fill="#FFFFFF" width="12" height="2.667" />
        <rect x="56" y="70.667" fill="#FFFFFF" width="12" height="2.667" />
      </svg>
    );
  }
}

export class IconNone extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        width="80px"
        height="80px"
        viewBox="0 0 24 24"
        enableBackground="new 0 0 80 80"
        space="preserve"
      >
        <path d="M0 0h80v80H0z" fill="none" />
        <path
          d="M3 5H1v16c0 1.1.9 2 2 2h16v-2H3V5zm18-4H7c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2zm0 16H7V3h14v14z"
          fill="white"
        />
      </svg>
    );
  }
}

export class IconOk extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8 10.6667C9.47276 10.6667 10.6667 9.47276 10.6667 8C10.6667 6.52724 9.47276 5.33333 8 5.33333C6.52724 5.33333 5.33333 6.52724 5.33333 8C5.33333 9.47276 6.52724 10.6667 8 10.6667Z"
          fill="#00AA4B"
        />
      </svg>
    );
  }
}

export class IconCircleNone extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8 10.6667C9.47276 10.6667 10.6667 9.47276 10.6667 8C10.6667 6.52724 9.47276 5.33333 8 5.33333C6.52724 5.33333 5.33333 6.52724 5.33333 8C5.33333 9.47276 6.52724 10.6667 8 10.6667Z"
          fill="#BFBFBF"
        />
      </svg>
    );
  }
}

export class IconLinkVariant extends Component {
  render() {
    return (
      <svg 
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <title>link-variant</title>
        <path
          d="M10.59,13.41C11,13.8 11,14.44 10.59,14.83C10.2,15.22 9.56,15.22 9.17,14.83C7.22,12.88 7.22,9.71 9.17,7.76V7.76L12.71,4.22C14.66,2.27 17.83,2.27 19.78,4.22C21.73,6.17 21.73,9.34 19.78,11.29L18.29,12.78C18.3,11.96 18.17,11.14 17.89,10.36L18.36,9.88C19.54,8.71 19.54,6.81 18.36,5.64C17.19,4.46 15.29,4.46 14.12,5.64L10.59,9.17C9.41,10.34 9.41,12.24 10.59,13.41M13.41,9.17C13.8,8.78 14.44,8.78 14.83,9.17C16.78,11.12 16.78,14.29 14.83,16.24V16.24L11.29,19.78C9.34,21.73 6.17,21.73 4.22,19.78C2.27,17.83 2.27,14.66 4.22,12.71L5.71,11.22C5.7,12.04 5.83,12.86 6.11,13.65L5.64,14.12C4.46,15.29 4.46,17.19 5.64,18.36C6.81,19.54 8.71,19.54 9.88,18.36L13.41,14.83C14.59,13.66 14.59,11.76 13.41,10.59C13,10.2 13,9.56 13.41,9.17Z" 
        />
      </svg>
    );
  }
}
