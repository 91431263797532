import React, { Component } from 'react';
import { IconArrowLeft, IconArrowRight } from '../icon';
import './pagination.scss';

export default class Pagination extends Component {
  getPages(pages) {
    const buttons = [];
    for (let i = 0; i < pages; i++) {
      buttons.push(i + 1);
    }

    return buttons;
  }

  getPreviousPage(page) {
    if (page > 1) {
      return page - 1;
    }

    return 1;
  }

  getNextPage(page, totalPages) {
    const nextPage = page + 1;

    if (nextPage >= totalPages) {
      return totalPages;
    }

    return nextPage;
  }

  render() {
    const {
      currentPage,
      registerCount,
      totalPages,
      limit,
      onPaginationButtonClick,
      indicatorsLimit = 4
    } = this.props;

    const tp = totalPages !== undefined ? totalPages : Math.ceil(registerCount / limit);
    let pages = this.getPages(tp);

    let leftIndicators = pages.slice(0, currentPage - 1);
    let rightIndicators = pages.slice(currentPage, indicatorsLimit);
    let restIndicators = pages.slice(indicatorsLimit, pages.length);
    let hasCutLeft = false;
   
    
    if (leftIndicators.length >= indicatorsLimit - 1) {
      let showRestQuantity = leftIndicators.length - 1;
      restIndicators = restIndicators.filter((item, index) => item > currentPage)
      leftIndicators = pages.slice(currentPage - (indicatorsLimit - 1), currentPage - 1);
      hasCutLeft = pages.slice(0, leftIndicators[0]).length > 0;
      Array.from({length: showRestQuantity}).forEach((_, i) => {
        let restPage = restIndicators.shift();
        if (restPage !== undefined) {
          rightIndicators.push(restPage);
        }
      });
    }

    return (
      <div className="rdp-pagination">
        {tp > 1 && (
          <>
            <button
              className={currentPage === 1 ? 'disabled' : null}
              onClick={() => onPaginationButtonClick(this.getPreviousPage(currentPage))}
            >
              <IconArrowLeft />
            </button>
            {
              hasCutLeft && (
                <button
                  onClick={() => onPaginationButtonClick(leftIndicators[0] - 1)}
                >
                  ...
                </button>
              )
            }
            {leftIndicators.map((page) => (
              <button
                key={page}
                className={page === currentPage ? 'active' : null}
                onClick={() => onPaginationButtonClick(page)}
              >
                {page}
              </button>
            ))}
            <button
              className="active"
              onClick={() => onPaginationButtonClick(currentPage)}
            >
              {currentPage}
            </button>
            {rightIndicators.map((page, i) => (
              <span key={i}>
                {
                  page !== currentPage && (
                    <button
                      key={page}
                      className={page === currentPage ? 'active' : null}
                      onClick={() => onPaginationButtonClick(page)}
                    >
                      {page}
                    </button>
                  )
                }
              </span>
            ))}
            {
              restIndicators.length > 0 && (
                <button
                  onClick={() => onPaginationButtonClick(restIndicators[0])}
                >
                  ...
                </button>
              )
            }
            <button
              className={currentPage === tp ? 'disabled' : null}
              onClick={() => onPaginationButtonClick(
                this.getNextPage(currentPage, tp),
              )}
            >
              <IconArrowRight />
            </button>
          </>
        )}
      </div>
    );
  }
}
