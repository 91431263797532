import React, { useEffect } from "react";
import Sdk from "api.digitalpages.module.sdk.api";
import { Route, Switch, useHistory } from "react-router-dom";
import * as View from "../views";
import ContextRoute from "./context-route";
import { SWRConfig } from "swr";
import useCacheProvider from "../hooks/useCacheProvider";
import Personalize from "../views/personalize/personalize";
import ViewAuthWithToken from "../components/auth-with-token/auth-with-token";

export default function Routes() {
  const history = useHistory();

  useEffect(() => {
    let alive = true;

    window.addEventListener("@rdpmanager-admin.event(logout)", handleLogout);

    Sdk.authorization.addStatusListen((authorization) => {
      if (alive) {
        if (authorization.authenticated) return;
        history.push("/login");
      }
    });

    return () => {
      window.removeEventListener("@rdpmanager-admin.event(logout)", handleLogout);
    }
  }, []);


  const handleLogout = async () => {
    window.localStorage.removeItem("rdp-manager-active-project");
    await Sdk.Api.authorization.logout();
    await Sdk.Api.authorization.setActiveProject(null);
    history.push("/login");
  }

  return (
    <SWRConfig>
      <Switch>
        <Route
          exact
          path="/login"
          component={View.ViewLogin}
        />
        <ContextRoute
          exact
          path="/"
          component={View.ViewDashboard}
        />
        <ContextRoute
          exact
          path="/reference/name/:name"
          component={View.ViewIframe}
        />
        <ContextRoute
          exact
          path="/reports"
          component={View.ViewReports}
        />
        <ContextRoute
          exact
          path="/report/scores"
          component={View.ViewReportScores}
        />
        <ContextRoute
          exact
          path="/report/attendance"
          component={View.ViewReportAttendance}
        />
        <ContextRoute
          exact
          path="/report/attendance/course/:courseUid/register/:registerUid"
          component={View.ViewReportAttendance}
        />
        <ContextRoute
          exact
          path="/cms/news/group/uid/:groupUid"
          component={View.ViewList}
        />
        <ContextRoute
          exact
          path="/cms/news/route/uid/:routeUid"
          component={View.ViewList}
        />
        <ContextRoute
          exact
          path="/cms/news/route/uid/:routeUid/entity/uid/:entityUid"
          component={View.ViewNewContent}
        />
        <ContextRoute
          exact
          path="/store/products"
          component={View.ListProducts}
          name="Produtos"
        />
        <ContextRoute
          exact
          path="/store/product/new"
          component={View.NewProductV2}
        />
          <ContextRoute
          exact
          path="/store/product/:productUid"
          component={View.EditProduct}
        />

        <ContextRoute
          exact
          path="/management/groups"
          component={View.ViewGroupsList}
        />
        <ContextRoute
          exact
          path="/management/groups/:groupUid"
          component={View.ViewGroupsManagement}
        />
        <ContextRoute
          exact
          path="/learning/courses"
          component={View.ViewCoursesList}
        />
        <ContextRoute
          exact
          path="/learning/courses/:courseUid"
          component={View.ViewCourseManagement}
        />
        <ContextRoute
          exact
          path="/users"
          component={View.ViewUsersList}
        />
        <ContextRoute
          exact
          path="/users/new"
          component={View.ViewNewUser}
        />
        <ContextRoute
          exact
          path="/users/:userUid"
          component={View.ViewEditUser}
        />
        <ContextRoute
          exact
          path="/profiles"
          component={View.ViewProfilesList}
        />
        <ContextRoute
          exact
          path="/profiles/new"
          component={View.ViewNewProfile}
        />
        <ContextRoute
          exact
          path="/administrators"
          component={View.ViewAdministratorsList}
        />
        <ContextRoute
          exact
          path="/administrators/new"
          component={View.ViewAdministratorsNew}
        />
        <ContextRoute
          exact
          path="/administrators/:uid"
          component={View.ViewAdministratorsEdit}
        />
        <ContextRoute
          exact
          path="/devices/:uid"
          component={View.ViewDeviceManagement}
        />
        <ContextRoute
          exact
          path="/devices"
          component={View.ViewDevicesList}
        />
        <ContextRoute
          exact
          path="/iot/groups/schema/:schemaUid/entity/:uid"
          component={View.ViewGroupIotManagement}
        />
        <ContextRoute
          exact
          path="/iot/groups"
          component={View.ViewIotList}
        />
        <ContextRoute
          exact
          path="/container/new"
          component={View.ViewNewContainer}
        />
        <ContextRoute
          exact
          path="/tree/new"
          component={View.ViewNewCourse}
        />
        <ContextRoute
          exact
          path="/tree/:treeUid"
          component={View.ViewEditTree}
        />
        <ContextRoute
          exact
          path="/tree"
          component={View.ViewListTree}
        />
        <ContextRoute
          exact
          path="/offer/new/:treeUid"
          component={View.ViewNewOffer}
        />
        <ContextRoute
          exact
          path="/offer/:treeUid/:registerUid"
          component={View.ViewEditOffer}
        />
        <ContextRoute
          exact
          path="/schema/new"
          component={View.ViewNewSchema}
        />
        <ContextRoute
          exact
          path="/entities"
          component={View.ViewEntitiesList}
        />
        <ContextRoute
          exact
          path="/schema"
          component={View.ViewSchemasList}
        />
        <ContextRoute
          exact
          path="/schema/:schemaId"
          component={View.ViewEntitiesList}
        />
        <ContextRoute
          exact
          path="/schema/:schemaId/entities/new"
          component={View.ViewNewEntity}
        />
        <ContextRoute
          exact
          path="/schema/:schemaId/entities/:entityId/edit"
          component={View.ViewEditEntity}
        />
        <ContextRoute
          exact
          path="/settings/layout/edit"
          component={View.ViewEditLayoutConfiguration}
        />
        <ContextRoute
          exact
          path="/certificates"
          component={View.ViewCertificatesList}
        />
        <ContextRoute
          exact
          path="/certificates/:contentUid"
          component={View.ViewEditCertificate}
        />
        <ContextRoute
          exact
          path="/forum"
          component={View.ViewForum}
        />
        <ContextRoute
          exact
          path="/emails"
          component={View.ViewEmails}
        />
        <ContextRoute
          exact
          path="/report/progress"
          component={View.ViewReportProgress}
        />
        <ContextRoute
          exact
          path="/compositions"
          component={View.ViewCompositionsList}
        />
        <ContextRoute
          exact
          path="/files"
          component={View.ViewFilesList}
        />
        <ContextRoute
          exact
          path="/catalog"
          component={View.ViewCatalogList}
        />
         <ContextRoute
          exact
          path="/personalize"
          component={Personalize}
        />
        <ContextRoute
          exact
          path="/terms"
          component={View.ViewTermsList}
        />
        <ContextRoute
          exact
          path="/terms/new"
          component={View.ViewNewTerm}
        />
        <ContextRoute
          exact
          path="/terms/:termUid"
          component={View.ViewTermsEdit}
        />
        <ContextRoute
          exact
          path="/auth-with-credentials/:token"
          component={ViewAuthWithToken}/>
        <ContextRoute
          exact
          path="/users-group"
          component={View.ViewUsersGroupList}
        />
      </Switch>
    </SWRConfig>
  );
}
