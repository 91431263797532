import React, { useEffect, useState } from 'react';
import Checkbox from '../inputs/checkbox/checkbox';

export const FormUserList = ({ users, onSave, defaultValue }) => {
  const [selecteds, setSelecteds] = useState([]);

  useEffect(() => {
    if (defaultValue) setSelecteds(defaultValue);
  }, []);

  const checked = (uid) => selecteds.includes(uid);

  const handleChange = (userId) => {
    if (checked(userId)) {
      setSelecteds((prev) => prev.filter((item) => item !== userId));
      return;
    }

    setSelecteds((prev) => [...prev, userId]);
  };

  return (
    <div className="users-content-selector">
      <div className="scrolled-container">
        <table className="rdp-admin-table" style={{ padding: '8px' }}>
          <tr>
            <th>
              <Checkbox
                rootStyles={{
                  height: 'auto',
                  width: 'auto',
                }}
                style={{
                  borderRadius: '5px',
                  ...(selecteds.length === users.length && {
                    background: '#764AB0',
                  }),
                }}
                checkedColor={
                  selecteds.length === users.length ? '#fff' : '#764AB0'
                }
                checked={selecteds.length === users.length}
                onCheck={(value, label, checked) => {
                  if (selecteds.length === users.length) {
                    setSelecteds([]);
                    return;
                  }
                  setSelecteds(users.map((user) => user.uid));
                }}
              />
            </th>
            <th>NOME</th>
            <th>USUÁRIO</th>
            <th>E-MAIL</th>
          </tr>
          {users.map((item) => (
            <tr key={item.uid}>
              <td>
                <Checkbox
                  rootStyles={{
                    height: 'auto',
                    width: 'auto',
                  }}
                  style={{
                    borderRadius: '5px',
                    ...(checked(item.uid) && {
                      background: '#764AB0',
                    }),
                  }}
                  checkedColor={checked(item.uid) ? '#fff' : '#764AB0'}
                  checked={checked(item.uid)}
                  onCheck={(value, label, checked) => handleChange(item.uid)}
                />
              </td>
              <td>{item.detail.name || item.detail.first_name}</td>
              <td>{item.login || ''}</td>
              <td>{item.detail.email_principal}</td>
            </tr>
          ))}
        </table>
      </div>

      <div className="users-content-selector-footer">
        <button
          className="button-apply-users-selector"
          type="button"
          onClick={() => {
            onSave(selecteds);
          }}
        >
          <span>Aplicar</span>
        </button>
      </div>
    </div>
  );
};
