import React, { useEffect, useState } from 'react';
import './new-course.scss';
import {
  Header,
  Sidebar,
  Breadcrumb,
  Footer,
  Loading,
  NotifyBox,
} from '../../components';
import Sdk from 'api.digitalpages.module.sdk.api';
import DynamicForm from '../../components/form-dynamic-v2';
import { DAMService } from '../../services/dam';
import { getConnectorFromArray } from '../../utils/getConnectorFromArray';

export default function NewCourse({ consumer, history }) {
  const [entity, setEntity] = useState();
  const [categories, setCategories] = useState([]);
  const [defaultValues, setDefaultValues] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState('');
  const [msgError, setMsgError] = useState('');

  const getForm = async () => {
    const res = await fetch('./preferences.json');
    const { forms } = await res.json();
    setEntity(forms['new-course']);
  };

  const loadEntities = async () => {
    if (entity) {
      const entitiesCp = [...entity.fields];
      console.log(entitiesCp);
      const field = entitiesCp.find((item) => item.field === 'entities');
      if (field) {
        if (Array.isArray(field.options) && field.options.length > 0) {
          return;
        }
        const res = await Sdk.dynamic.bridge(
          `auth/v1.0/entity/items/available?type=category`,
          null,
          'GET'
        );
        if (!res) {
          return;
        }
        const options = res.map((item) => ({
          uid: item.uid,
          label: item.data.name,
        }));
        field.options = options;

        setEntity({ ...entity, fields: entitiesCp });
      }
    }
  };

  useEffect(() => {
    getForm();
    const rawDraft = localStorage.getItem('new-course-draft');
    if (rawDraft) {
      const parsedDraft = JSON.parse(rawDraft);
      setDefaultValues(parsedDraft);
    }
  }, []);

  useEffect(() => {
    loadEntities();
  }, [entity]);

  const handleSave = async (inputs, image) => {
    let inputsCp = { ...inputs };
    if (image) {
      const base64Image = await toBase64(inputs[image.id]);
      inputsCp[image.id] = base64Image;
    }
    localStorage.setItem('new-course-draft', JSON.stringify(inputsCp));
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleSubmit = async (inputs, setInputs) => {
    setLoadingSubmit(true);

    try {
      const { connectors } = Sdk.authorization.activeProject;
      const learningConnector = getConnectorFromArray(
        connectors,
        'Learning_1',
        'ContentManager'
      );
      if (!learningConnector) return;
      const course = { ...inputs };
      if (entity.image) {
        const imageId = entity.image.id;
        const image = inputs[imageId];

        if (!image) {
          setLoadingSubmit(false);
          setMsgError(entity?.noThumbMessage);
          return;
        }
        course.thumb_url = await getThumbUrl(image);
        delete course[entity.image.id];
      }
      course.node_properties = [
        {
          name: 'title',
          type: 'Text',
          regex_validation: '',
        },
        {
          name: 'description',
          type: 'Text',
          regex_validation: '',
        },
        {
          name: 'thumb',
          type: 'Text',
          regex_validation: '',
        },
      ];
      const field = entity.fields.find((item) => item.field === 'entities');
      if (field) {
        course.entities = Object.keys(inputs[field.id]).filter(
          (key) => inputs[field.id][key]
        );
      } else {
        course.entities = [];
      }

      const res = await Sdk.dynamic.bridge(
        `cms/v1.0/learning/connector/uid/${learningConnector.uid}/management/course`,
        course,
        'POST'
      );

      window.location =
        window.location.origin + window.location.pathname + '#/tree/' + res.uid;

      setMsgSuccess(entity?.createText);
      // localStorage.setItem('new-course-draft', null);
      setInputs({});
    } catch (error) {
      setMsgError(entity?.createError);
    }
    setLoadingSubmit(false);
  };

  async function getThumbUrl(file) {
    const response = await DAMService.uploadFileAxios(
      file,
      null,
      'ContentManager'
    );

    const publicUrl = await DAMService.getPublicUrl(response.data[0].uid);

    return publicUrl;
  }

  return (
    <div>
      <Header />
      <Sidebar
        currentRoute="/users/new"
        defineGroup={consumer ? consumer.defineGroup : null}
        defineRoute={consumer ? consumer.defineRoute : null}
        groups={consumer ? consumer.groups : null}
      />
      <Breadcrumb currentRoute={consumer ? consumer.currentRoute : null} />
      <div className="main-content">
        {loadingSubmit && <Loading msg="Criando curso..." />}
        {!loadingSubmit && msgSuccess && (
          <NotifyBox
            type="success"
            onClose={() => setMsgSuccess('')}
            message={msgSuccess}
          />
        )}
        {!loadingSubmit && msgError && (
          <NotifyBox
            type="error"
            onClose={() => setMsgError('')}
            message={msgError}
          />
        )}
        {entity ? (
          <DynamicForm
            handleSubmit={handleSubmit}
            handleSave={handleSave}
            form={entity}
            submitText="Cadastrar curso"
            defaultValues={defaultValues}
          />
        ) : (
          <Loading msg="Configurando formulário..." />
        )}
      </div>
      <Footer version="01" />
    </div>
  );
}
/*
  {
    "id":"container-tags",
    "group": "Tags do curso",
    "required": true,
    "type": "input-tags",
    "field": "tags"
  }
*/
