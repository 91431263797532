import React from "react";
import * as S from "./entity-item-tree-list.styled";
import useOptimisticFetchEntityItems from "../../hooks/useOptimisticFetchEntityItems";
import IEntityItem from "../../@types/IEntityItem";
import ModalDelete from "../modal-delete";
import { l } from "../../hooks/useLocalization";
import EntityItemDetailModal from "../entity-item-detail-modal/entity-item-detail-modal";
import Loading from "../loading";
import Checkbox from "../inputs/checkbox/checkbox";
import {useRecoilState} from "recoil";
import {selectedEntityItemsState} from "../../recoil/atoms/selectedEntityItemsState";
import {selectedSchemasState} from "../../recoil/atoms/selectedSchemasState";

const EntityItemTreeItem = React.lazy(() => import("../entity-item-tree-item/entity-item-tree-item"));

interface IEntityItemTreeListProps {
  entitySchemaUid: Guid;
  
  columnOffset?: number;
  selectAll?: boolean;
}

function EntityItemTreeList(props: IEntityItemTreeListProps) {
  const {
    entitySchemaUid,
    columnOffset = 0,
    selectAll = false,
  } = props;
  const { data: entityItems, deleteEntity, createEntity, updateEntity } = useOptimisticFetchEntityItems(entitySchemaUid);
  const [itemToDelete, setItemToDelete] = React.useState<IEntityItem<IEntityItemData> | null>(null);
  const [showModal, setShowModal] = React.useState(false);
  const [isChild, setIsChild] = React.useState(false);
  const [parentItem, setParentItem] = React.useState<IEntityItem<IEntityItemData> | null>(null);
  const [checkedItems, setCheckedItems] = useRecoilState<Guid[]>(selectedEntityItemsState);
  const [selectedSchemas, setSelectedSchemas] = useRecoilState<Guid[]>(selectedSchemasState);
  
  React.useEffect(() => {
    if (selectAll) {
      const filteredItems = entityItems?.filter((item) => !checkedItems.includes(item.uid || ""));
      setCheckedItems(checkedItems.concat(filteredItems?.map((item) => item.uid || "") || []));
    } else {
      setCheckedItems(checkedItems.filter((item) => !entityItems?.map((item) => item.uid || "").includes(item)));
    }
  }, [selectAll]);
  
  if (!entityItems) {
    return null;
  }


  const getModalItem = () => {
    if (showModal && parentItem) {
      if (isChild) {
        return {
          data: {
            title: "",
            initials: ""
          },
          column: (parentItem.column || 0) + 1,
          row: (parentItem.row || 0) + 1
        };
      } else {
        return parentItem;
      }
    }
  };

  const handleSave = (item: IEntityItem<IEntityItemData>) => {
    try {
      if (entityItems) {
        if (isChild) {
          createEntity(entitySchemaUid, item, entityItems);
        } else if (parentItem && parentItem.uid) {
          updateEntity(parentItem.uid, item, entityItems);
        }
        setShowModal(false);
        setIsChild(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const hasRightBrother = (item: IEntityItem<IEntityItemData>, index: number) => {
    const nextItem = entityItems[index + 1];
    
    if (nextItem) {
      return (nextItem.column || 0) === (item?.column || 0);
    }

    return false;
  };

  const hasChildren = (item: IEntityItem<IEntityItemData>, index: number) => {
    const nextItem = entityItems[index + 1];
    if (nextItem) {
      return (nextItem.column || 0) > (item?.column || 0);
    }

    return false;
  };

  const handleDelete = async () => {
    if (itemToDelete && itemToDelete.uid) {
      try {
        await deleteEntity(itemToDelete.uid, entityItems);
        setItemToDelete(null);
      } catch (e) {
        console.error(e);
      }
    }
  };
  
  const handleCheck = (entityItem: IEntityItem<IEntityItemData>) => (value: string, label: string, checked: boolean) => {
    if (checked) {
      setCheckedItems([...checkedItems, entityItem.uid || ""]);
    } else {
      setCheckedItems(checkedItems.filter(item => item !== entityItem.uid));
      setSelectedSchemas(selectedSchemas.filter(item => item !== entitySchemaUid));
    }
  }
  
  return (
    <S.EntityList>
      <React.Suspense fallback={<Loading/>}>
        {entityItems.map((entityItem, index) => (
          <span
            key={entityItem.uid || index}  
          >
            <S.CheckboxContainer>
              <Checkbox
                defaultChecked={checkedItems.includes(entityItem.uid || "")}
                onCheck={handleCheck(entityItem)}
              />
            </S.CheckboxContainer>
            
            <EntityItemTreeItem
              title={entityItem.data && entityItem.data.title ? entityItem.data.title : ""}
              column={columnOffset + (entityItem.column || 0)}
              hasChildren={hasChildren(entityItem, index)}
              hasRightBrother={hasRightBrother(entityItem, index)}
              onClickDelete={() => setItemToDelete(entityItem)}
              selected={checkedItems.includes(entityItem.uid || "")}
              onClickEdit={() => {
                setParentItem(entityItem);
                setShowModal(true);
                setIsChild(false);
              }}
              onClickAdd={() => {
                setIsChild(true);
                setParentItem(entityItem);
                setShowModal(true);
              }}
            />
          </span>
        ))}

        {showModal && (
          <EntityItemDetailModal
            item={getModalItem()}
            show
            onSave={handleSave}
            onClose={() => setShowModal(false)}
          />
        )}

        {itemToDelete && (
          <ModalDelete
            open
            action={handleDelete}
            title={l("delete confirmation")}
            color=""
            onClose={() => setItemToDelete(null)}
          />
        )}
      </React.Suspense>
    </S.EntityList>
  );
}

export default EntityItemTreeList;