import React, { useState, useEffect } from 'react'
import './properties.scss'
import Components from 'rdp-builder-components';
export default function Properties({ onChange }) {
  const [properties, setProperties] = useState([{name: '', regex: '', type: ''}])
  const handleAddProperty = (e) => {
    e.preventDefault()
    setProperties(oldSt => {
      const stateCp = [...oldSt]
      stateCp.push({name: '', regex: '', type: ''})
      return stateCp
    })
  }
  useEffect(() => {
    onChange(properties)
  }, [properties])
  return (
    <div>
      {properties.length >= 0 && properties.map((property, index) => (
        <div key={index} style={{display: 'flex', columnGap: 12, alignItems: 'center'}}>
          <input
            type="text"
            className="text-input properties-input"
            placeholder="Nome"
            value={property.name || ''}
            onChange={e => {
              const propertiesCp = [...properties]
              propertiesCp[index].name = e.target.value
              setProperties(propertiesCp)
            }}
          />
          <input
            type="text"
            className="text-input properties-input"
            placeholder="Regex"
            value={property.regex || ''}
            onChange={e => {
              const propertiesCp = [...properties]
              propertiesCp[index].regex = e.target.value
              setProperties(propertiesCp)
            }}
          />
          {/* <input
            type="text"
            className="text-input"
            placeholder="Tipo"
            value={property.type || ''}
            onChange={e => {
              const propertiesCp = [...properties]
              propertiesCp[index].type = e.target.value
              setProperties(propertiesCp)
            }}
          /> */}
          <div className="properties-input" style={{width: '100%', height: '44px'}}>
            <Components.Buttons.Select
              borderColor="#d3d5dd"
              title="Tipo"
              defaultValue="Text"
              items={[
                {
                  label: 'Texto',
                  value: 'Text'
                },
                {
                  label: 'Inteiro',
                  value: 'NumberInteger'
                },
                {
                  label: 'Decimal',
                  value: 'NumberDecimal'
                },
                {
                  label: 'Data',
                  value: 'DateTime'
                }
              ]}
              onChange={(value) => {
                const propertiesCp = [...properties]
                propertiesCp[index].type = value
                setProperties(propertiesCp)
              }}
            />
          </div>
          <button
            onClick={(e) => {
              e.preventDefault()
              if (index > -1) {
                const propertiesCp = [...properties]
                propertiesCp.splice(index, 1)
                setProperties(propertiesCp)
              }
            }}
            style={{
              width: 22,
              height: 20,
              marginTop: '0.7rem',
              borderRadius: 4,
              justifySelf: 'center'
            }}
          >
            -
          </button>
        </div>
      ))}
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <button style={{marginTop: 12, borderRadius: '4px', backgroundColor: '#764ab0', color: 'white'}} onClick={handleAddProperty}>+</button>
      </div>
    </div>
  )
}
