import React, { Component } from 'react';
import './registrations.scss';

export default class Registrations extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      loading = false,
      users = null,
      registering = false,
      feedback = null,
      onClose = function(){},
      onRegister = function(){},
      onSuccessBtnClick = function(){},
      onErrorBtnClick = function(){},
      headerTitle = "Usuários a serem matriculados",
      processBtnLabel = "Processar matrículas",
      processingBtnLabel = "Processando matrículas...",
      successMessage = "Matrículas atualizadas com sucesso!",
      errorMessage = "Erro ao atualizar matrícula. Verifique se o arquivo CSV está correto."
    } = this.props;
    return <div className="rdp-admin-component-registrations">
      <div className="rdp-admin-component-registrations-overlay"></div>
      <div className="rdp-admin-component-registrations-modal">
        <div className="rdp-admin-component-registrations-header">
          <span className="rdp-admin-component-registrations-header-title">{headerTitle}</span>
          <span className="rdp-admin-component-registrations-header-close" onClick={() => onClose()}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="768" height="768" viewBox="0 0 768 768"><path fill="#646871" d="M607.5 205.5l-178.5 178.5 178.5 178.5-45 45-178.5-178.5-178.5 178.5-45-45 178.5-178.5-178.5-178.5 45-45 178.5 178.5 178.5-178.5z"></path></svg>
          </span>
        </div>
        {loading &&
          <div className="rdp-admin-component-registrations-preloader"></div>
        }

        {users !== null && feedback === null &&
          <>
            <ul className="rdp-admin-component-registrations-items">
              {users.map(function (user, index) {
                return <li className="rdp-admin-component-registrations-item" key={index}>
                  {user.name} - ({user.email})
                </li>
              })}
            </ul>
            <div className="rdp-admin-component-registrations-footer">
              {!registering &&
                <button className="rdp-admin-component-registration-submit-btn" onClick={()=> onRegister()}>{processBtnLabel}</button>
              }
              {registering &&
                <button className="rdp-admin-component-registration-submit-btn processing">{processingBtnLabel}</button>
              }
              </div>
          </>
        }

        {feedback === "success" &&
          <div className="rdp-admin-component-registration-feedback">
            <span className="rdp-admin-component-registration-icon success"></span>
            <span className="rdp-admin-component-registration-message" dangerouslySetInnerHTML={{ __html: successMessage}}></span>
            <button className="rdp-admin-component-registration-success-btn" onClick={() => onSuccessBtnClick()}>
              Fechar janela
            </button>
          </div>
        }

        {feedback === "error" &&
          <div className="rdp-admin-component-registration-feedback">
            <span className="rdp-admin-component-registration-icon error"></span>
            <span className="rdp-admin-component-registration-message" dangerouslySetInnerHTML={{ __html: errorMessage}}></span>
            <button className="rdp-admin-component-registration-error-btn" onClick={() => onErrorBtnClick()}>
              Fechar e tentar novamente.
            </button>
          </div>
        }

        {/* {renderType === "uploading" &&
          <>
            <span className="rdp-admin-component-registrations-icon uploading"></span>
            <span className="rdp-admin-component-registrations-message" dangerouslySetInnerHTML={{ __html: uploadingMessage}}></span>
            <div className="rdp-admin-component-registrations-preloader"></div>
          </>
        } */}
      </div>
    </div>
  }
}