import React from "react";
import * as S from "./entity-item-tree-item.styled";
import { IconEdit, IconTrash } from "../icon";

interface IEntityItemTreeItemProps<I> {
  key?: any;
  border?: string;
  icon?: JSX.Element | null;
  title: string | JSX.Element;
  column: number;
  hasChildren?: boolean;
  hasRightBrother?: boolean;
  onClickEdit?: () => void;
  editIcon?: JSX.Element;
  editIconColor?: string;
  onClickDelete?: () => void;
  deleteIcon?: JSX.Element;
  deleteIconColor?: string;
  onClickAdd?: () => void;
  addIconColor?: string;
  addIcon?: JSX.Element;
  selected?: boolean;
}

function EntityItemTreeItem<I extends unknown>(props: IEntityItemTreeItemProps<I>) {
  const {
    key,
    border,
    icon = (
      <svg width="24" height="24" viewBox="0 0 24 24">
        <path fill="currentColor"
              d="M12,5A3.5,3.5 0 0,0 8.5,8.5A3.5,3.5 0 0,0 12,12A3.5,3.5 0 0,0 15.5,8.5A3.5,3.5 0 0,0 12,5M12,7A1.5,1.5 0 0,1 13.5,8.5A1.5,1.5 0 0,1 12,10A1.5,1.5 0 0,1 10.5,8.5A1.5,1.5 0 0,1 12,7M5.5,8A2.5,2.5 0 0,0 3,10.5C3,11.44 3.53,12.25 4.29,12.68C4.65,12.88 5.06,13 5.5,13C5.94,13 6.35,12.88 6.71,12.68C7.08,12.47 7.39,12.17 7.62,11.81C6.89,10.86 6.5,9.7 6.5,8.5C6.5,8.41 6.5,8.31 6.5,8.22C6.2,8.08 5.86,8 5.5,8M18.5,8C18.14,8 17.8,8.08 17.5,8.22C17.5,8.31 17.5,8.41 17.5,8.5C17.5,9.7 17.11,10.86 16.38,11.81C16.5,12 16.63,12.15 16.78,12.3C16.94,12.45 17.1,12.58 17.29,12.68C17.65,12.88 18.06,13 18.5,13C18.94,13 19.35,12.88 19.71,12.68C20.47,12.25 21,11.44 21,10.5A2.5,2.5 0 0,0 18.5,8M12,14C9.66,14 5,15.17 5,17.5V19H19V17.5C19,15.17 14.34,14 12,14M4.71,14.55C2.78,14.78 0,15.76 0,17.5V19H3V17.07C3,16.06 3.69,15.22 4.71,14.55M19.29,14.55C20.31,15.22 21,16.06 21,17.07V19H24V17.5C24,15.76 21.22,14.78 19.29,14.55M12,16C13.53,16 15.24,16.5 16.23,17H7.77C8.76,16.5 10.47,16 12,16Z" />
      </svg>
    ),
    title,
    column,
    hasChildren = false,
    hasRightBrother = false,
    selected = false,
    editIcon = <IconEdit />,
    editIconColor = "#A9B5BA",
    deleteIcon = <IconTrash />,
    deleteIconColor = "#A9B5BA",
    addIcon = (
      <svg width="24" height="24" viewBox="0 0 24 24">
        <path fill="currentColor"
              d="M12,5A3.5,3.5 0 0,0 8.5,8.5A3.5,3.5 0 0,0 12,12A3.5,3.5 0 0,0 15.5,8.5A3.5,3.5 0 0,0 12,5M12,7A1.5,1.5 0 0,1 13.5,8.5A1.5,1.5 0 0,1 12,10A1.5,1.5 0 0,1 10.5,8.5A1.5,1.5 0 0,1 12,7M5.5,8A2.5,2.5 0 0,0 3,10.5C3,11.44 3.53,12.25 4.29,12.68C4.65,12.88 5.06,13 5.5,13C5.94,13 6.35,12.88 6.71,12.68C7.08,12.47 7.39,12.17 7.62,11.81C6.89,10.86 6.5,9.7 6.5,8.5C6.5,8.41 6.5,8.31 6.5,8.22C6.2,8.08 5.86,8 5.5,8M18.5,8C18.14,8 17.8,8.08 17.5,8.22C17.5,8.31 17.5,8.41 17.5,8.5C17.5,9.7 17.11,10.86 16.38,11.81C16.5,12 16.63,12.15 16.78,12.3C16.94,12.45 17.1,12.58 17.29,12.68C17.65,12.88 18.06,13 18.5,13C18.94,13 19.35,12.88 19.71,12.68C20.47,12.25 21,11.44 21,10.5A2.5,2.5 0 0,0 18.5,8M12,14C9.66,14 5,15.17 5,17.5V19H19V17.5C19,15.17 14.34,14 12,14M4.71,14.55C2.78,14.78 0,15.76 0,17.5V19H3V17.07C3,16.06 3.69,15.22 4.71,14.55M19.29,14.55C20.31,15.22 21,16.06 21,17.07V19H24V17.5C24,15.76 21.22,14.78 19.29,14.55M12,16C13.53,16 15.24,16.5 16.23,17H7.77C8.76,16.5 10.47,16 12,16Z" />
      </svg>
    ),
    addIconColor = "#764ab0",
  } = props;
  return (
    <S.EntityItem
      className={`entity-item ${selected ? 'selected' : ''}`}
      key={key}
      column={column}
      border={border}
    >
      {hasChildren && (
        <S.EntityChildIcon
          className="entity-child-icon-children-icon"
        />
      )}

      {hasRightBrother && (
        <S.EntityBrotherIcon
          className="entity-child-icon-right-brother-icon"
        />
      )}

      <S.EntityItemContent>
        {icon &&
          <S.EntityItemIcon>
            {icon}
          </S.EntityItemIcon>
        }

        <S.EntityItemName>
          {title}
        </S.EntityItemName>

        <S.EntityItemActions>
          <S.EntityItemEditBtn
            type="button"
            tabIndex={0}
            disabled={!props.onClickEdit}
            onClick={props.onClickEdit}
            color={editIconColor}
          >
            {editIcon}
          </S.EntityItemEditBtn>

          <S.EntityItemRemoveBtn
            type="button"
            tabIndex={0}
            disabled={!props.onClickDelete}
            onClick={props.onClickDelete}
            color={deleteIconColor}
          >
            {deleteIcon}
          </S.EntityItemRemoveBtn>
        </S.EntityItemActions>
      </S.EntityItemContent>

      {props.onClickAdd && (
        <S.EntityItemAddChildConteiner className="rdp-entity-tree-add-child-btn">
          <S.EntityItemAddChildBtn
            tabIndex={0}
            type="button"
            onClick={props.onClickAdd}
            color={addIconColor}
          >
            {addIcon}
          </S.EntityItemAddChildBtn>
        </S.EntityItemAddChildConteiner>
      )}
    </S.EntityItem>
  );
}

export default EntityItemTreeItem;