import React, { useState, useEffect } from 'react';
import 'react-responsive-modal/styles.css';
import './modal-galerie.scss';
import Modal from 'react-responsive-modal';
import SDK from 'api.digitalpages.module.sdk.api';
import { IconCloseModal, IconSearch, IconUpload } from '../icon';
import { DAMService } from '../../services/dam';
import ModalUpload from '../modal-upload';
import Pagination from '../pagination/pagination';
import Loading from '../loading';
import RDP_UTILS from '../../config/util';
import RDP_CONFIG from '../../config/config';

export default function Galerie({ title, open, onClose, handleSelect }) {
  const [images, setImages] = useState([]);
  const [total, setTotal] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpen] = useState(false);
  const [getError, setError] = useState(false);
  const [tags, setTags] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  async function fetchData() {
    const data = await getImages();
    if (data) {
      setImages(data.images);
      setTotal(data.total);
    } else {
      setError(true);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    setIsLoading(true);
    open === true && fetchData();
  }, [open, page]);

  const getImages = async () => {
    const skip = page > 1 ? (page - 1) * limit : 0;
    const data = await DAMService.searchContent('', skip, limit);
    if (data && data.results) {
      let assets = data.results;

      assets = assets.map((asset) => {
        const id = asset.document.content_uid;
        asset.url = `${SDK.Api.domain}/storage/v1.0/content?uid=${id}&project_key=${SDK.Api.authorization.projectKey}&api_env=${SDK.Api.enviroment}`;
        return asset;
      });

      return {
        images: assets,
        total: data.total,
      };
    }
  };

  const defineURL = (url) => {
    const token = SDK.Api.authorization.credential.accessToken;
    return `${url}&access_token=${token}`;
  };

  const renderBoxImage = () =>
    images.map((image, index) => {
      const typeImage = image ? image.document.content_type : null;
      return (
        <div
          onClick={() => handleSelect(image)}
          className="image-box"
          key={index}
          style={{ backgroundImage: `url(${defineURL(image.url)})` }}
        >
          {typeImage && (
            <span className="file-badge-type">
              {RDP_UTILS.typeFile(typeImage)}
            </span>
          )}
        </div>
      );
    });

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      styles={{
        overlay: { backgroundColor: 'rgba(125, 125, 125, 0.2)' },
        modal: {
          borderRadius: '4px',
          padding: '20px',
          maxWidth: '100%',
          position: 'absolute',
          padding: '0px',
          top: '84px',
          bottom: '5px',
          left: '5px',
          backgroundColor: '#f5f7f8',
        },
      }}
      showCloseIcon={false}
      focusTrapped={false}
      center
    >
      <div className="rdp-modal-galerie-container">
        <div className="rdp-modal-container-header">
          <div className="header-left">
            <div className="icon">
              <IconSearch />
            </div>
            <span>Galeria de Imagens</span>
          </div>
          <button
            type="button"
            className="rdp-modal-close"
            onClick={() => onClose()}
          >
            <IconCloseModal />
          </button>
        </div>
        <div className="rdp-modal-container-content">
          <div className="galerie-content">
            <div className="box-filters">
              <span>
                EXIBINDO <strong>{images.length}</strong> de{' '}
                <strong>{total}</strong> IMAGENS
              </span>
              <div className="box-filter-options">
                <button
                  onClick={() => setOpen(true)}
                  className="btn-galerie-upload"
                >
                  <IconUpload />
                </button>
              </div>
            </div>
            <div className="list-images">
              {getError ? (
                <span className="msg-error">
                  Erro ao carregar imagens. Tente novamente.
                </span>
              ) : isLoading ? (
                <Loading msg="Carregando Imagens..." />
              ) : (
                renderBoxImage()
              )}
            </div>
            <Pagination
              currentPage={page}
              registerCount={total}
              limit={limit}
              onPaginationButtonClick={(e) => setPage(e)}
            />
          </div>
        </div>
        <div className="rdp-modal-container-footer">
          {/* <button className="rdp-dialog-button-cancel margin-right" onClick={() => onClose()}>Cancelar</button>
          <button className="rdp-dialog-button-delete">Excluir</button> */}
        </div>
      </div>

      <ModalUpload
        open={openModal}
        onClose={() => {
          fetchData();
          setOpen(false);
        }}
        onUpload={() => {}}
      />
    </Modal>
  );
}
