import useSWR from "swr";
import cmsService from "../services/cms-service";

export default function useOptimisticFetchCourseRegistersManagement(courseUid?: string) {
  const resp = useSWR(courseUid, cmsService.learningManagementEndpoint.courseRegister);
  let data = resp.data;

  return {
    ...resp,
    data,
  };
}