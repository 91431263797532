import React, { Suspense, useEffect, useMemo, useState } from "react";
import { Breadcrumb, Header, Sidebar } from "../../components";
import * as S from "./list-report-scores.styled";
import Loading from "../../components/loading";
import ScoresTable from "./scores-table";
import downloadUserScoresCSV from "../../utils/downloadUserScoresCSV";
import ReactTooltip from "react-tooltip";
import Select from "react-select";
import RDP_UTILS from "../../config/util";
import ICourse from "../../@types/ICourse";
import ICourseRegister from "../../@types/ICourseRegister";
import useOptimisticFetchCoursesManagement from "../../hooks/useOptimisticFetchCoursesManagement";
import Sdk from "api.digitalpages.module.sdk.api";
import { getConnectorFromArray } from "../../utils/getConnectorFromArray";
import useOptimisticFetchCourseRegistersManagement from "../../hooks/useOptimisticFetchCourseRegistersManagement";
import { useRecoilState } from "recoil";
import { selectedCourseUidState } from "../../recoil/atoms/selectedCourseUidState";
import { selectedCourseRegisterUidState } from "../../recoil/atoms/selectedCourseRegisterUidState";

interface IListClassesProps extends IBasicView<{ courseUid: string, registerUid: string }> {}

export default function ViewReportScores(props: IListClassesProps) {
  const {
    consumer
  } = props;
  const learningConnectorUid = useMemo(() => {
    const { connectors } = Sdk.authorization.activeProject;
    return getConnectorFromArray(
      connectors,
      'Learning_1',
      'ContentManager',
    ).uid;
  }, [])
  const [selectedCourseUid, setSelectedCourseUid] = useRecoilState(selectedCourseUidState);
  const [selectedRegisterUid, setSelectedRegisterUid] = useRecoilState(selectedCourseRegisterUidState);
  const [selectedRegisterUids, setSelectedRegisterUids] = useState<string[]>([]);
  const { data: coursesPaged } = useOptimisticFetchCoursesManagement(learningConnectorUid, {page_size: 10000});
  const courses = coursesPaged?.result || [];
  const { data: registers } = useOptimisticFetchCourseRegistersManagement(selectedCourseUid);
  const [userEntityList, setUserEntityList] = useState<string[]>([]);

  const handleDownload = async () => {
    if (selectedRegisterUid) await downloadUserScoresCSV(selectedRegisterUid);
  }

  const mapCoursesToOptions = (courses: ICourse[]) => {
    const options = [
    ];

    options.push(...courses.map((course, i) => ({
      value: course.uid || i.toString(),
      label: course.title || i.toString(),
    })));

    return options;
  }

  const mapRegistersToOptions = (registers: ICourseRegister[]): { value: string; label: string; }[] => {
    const { user } = Sdk.authorization;

    if (user.roles) {
      const isAnalyticAdmin = false; //user.roles.some((role: { type: string; }) => role.type === "TeamAdmin");

      const options = registers
        .filter(register => !isAnalyticAdmin || (register.uid && selectedRegisterUids.includes(register.uid)))
        .map((register, i) => ({
          value: register.uid || i.toString(),
          label: register.name || i.toString(),
        }));

      return options;
    }

    return [];
  }



  async function getUserEntities (uid: any) {
    const resp: any = await Sdk.dynamic.bridge(
      `cms/v1.0/learning/user/uid/${uid}/management/registers/available?page_size=10000`,
      null,
      "GET",
    );

    const userEntities = await resp.result.map((item: { uid: any; }) => item.uid);
    setUserEntityList(userEntities);
  }

  useEffect(() => {
    if (Sdk.authorization.user.uid) {
      getUserEntities(Sdk.authorization.user.uid);
    }
  }, []);

  useEffect(() => {
    if (userEntityList.length > 0) {
      setSelectedRegisterUids(userEntityList);
    }

    setSelectedRegisterUid(undefined);

  }, [selectedCourseUid, userEntityList]);

  const defaultValueOptions = mapRegistersToOptions(registers || [])

  return (
    <S.MainContainer className="rdp-admin-view-report-scores">
      <Header />

      <Sidebar {...consumer} />

      <Breadcrumb
        {...consumer}
        importDownloadScoresDataEnabled
        importDownloadScoresDataOnClick={handleDownload}
      />

      <S.Content className="rdp-admin-view-report-scores-content">
        <Suspense fallback={<Loading />}>
          {/*@ts-ignore*/}
          <ReactTooltip
            effect="solid"
            type="dark"
            place="bottom"
          />

          <S.Filters>
            <S.Filter>
              <>
                <p style={{ marginBottom: '0.7rem' }}>
                  Curso
                </p>

                <Select
                  placeholder="Curso"
                  isSearchable
                  value={mapCoursesToOptions(courses || []).find((option) => option.value === selectedCourseUid)}
                  options={mapCoursesToOptions(courses)}
                  styles={RDP_UTILS.MultipleSelect.fullWidthStyleFilter}
                  onChange={(option) => option && setSelectedCourseUid(option.value)}
                />
              </>
            </S.Filter>

            <S.Filter
              disabled={selectedCourseUid === null}
              data-tip={selectedCourseUid === null ? 'Selecione um curso primeiro' : ''}
            >
              <p style={{ marginBottom: '0.7rem' }}>
                Turma
              </p>
              <Select
                placeholder="Turma"
                isSearchable
                value={mapRegistersToOptions(registers || []).find(option => option.value === selectedRegisterUid)}
                isDisabled={selectedRegisterUid === null}
                options={mapRegistersToOptions(registers || [])}
                defaultValue={[]}
                styles={RDP_UTILS.MultipleSelect.fullWidthStyleFilter}
                onChange={(option) => option && setSelectedRegisterUid(option?.value)}
              />
            </S.Filter>
          </S.Filters>
          <ScoresTable
            courseUid={selectedCourseUid}
            registerUid={selectedRegisterUid}
          />
        </Suspense>
      </S.Content>
    </S.MainContainer>
  );
}