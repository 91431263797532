import React, { Component } from 'react';
import './loading.scss';
import { DominoSpinner, RotateSpinner } from 'react-spinners-kit';

export default class Loading extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { msg, globalLoading } = this.props;
    const classLoader = globalLoading
      ? 'rdp-admin-loader rdp-admin-global-loader'
      : 'rdp-admin-loader';

    return (
      <div className={classLoader}>
        <div className="group-loader">
          <DominoSpinner
            size={globalLoading ? 310 : 110}
            color={globalLoading ? '#FFF' : '#764ab0'}
            loading
          />
          <span>{msg || 'Carregando...'}</span>
        </div>
      </div>
    );
  }
}
