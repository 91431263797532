import React, { Component } from 'react';
import './button-grey.scss';

export default class ButtonGrey extends Component {
  render() {
    const { onClick, label = "Importar", icon = "", disabled, radioOptions, onRadioOptionClick} = this.props;
    return <div className="rdp-admin-component-button-grey-group">
      {radioOptions &&
        <ul className="rdp-admin-component-button-grey-radio-options">
          {radioOptions.map(function (opt, index) {
            return <li key={index} onClick={() => onRadioOptionClick(opt.value)} className={`rdp-admin-component-button-grey-radio-option ${opt.active ? "active" : ""}`}>{opt.label}</li>
          })}
        </ul>
      }
      <button onClick={() => onClick()} className={`rdp-admin-component-button-grey ${radioOptions ? "unbordered" : ""}`} disabled={disabled}>
        <span className={`rdp-admin-component-button-grey-icon ${icon}`}></span>
        <span className="rdp-admin-component-button-grey-label">{label}</span>
      </button>
    </div>
  }
}