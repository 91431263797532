import React from 'react';
import * as S from "./card.styled";

type Colors = "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark";

interface ICardProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  boxShadow?: string;
  border?: string | Colors;
  backgroundColor?: string | Colors;
  backgroundOpacity?: number;
  fontColor?: string | Colors;
}

const Card: React.FC<ICardProps> = (props) => {
  const {children, ...rest} = props;
  
  const getBorder = () => {
    const borders = {
      "primary": "2px solid #764ab0",
      "secondary": "2px solid #6c757d",
      "success": "2px solid #28a745",
      "danger": "2px solid #dc3545",
      "warning": "2px solid #ffc107",
      "info": "2px solid #17a2b8",
      "light": "2px solid #f8f9fa",
      "dark": "2px solid #343a40",
    }
    
    const border = props.border as keyof typeof borders;
    
    if (border !== undefined) {
      return borders[border];
    }
    
    return props.border;
  }
  
  const getColor = (color: any) => {
    const colors = {
      "primary": "#764ab0",
      "secondary": "#6c757d",
      "success": "#28a745",
      "danger": "#dc3545",
      "warning": "#ffc107",
      "info": "#17a2b8",
      "light": "#f8f9fa",
      "dark": "#343a40",
    }
    
    const colorInternal = color as keyof typeof colors;
    
    if (colorInternal !== undefined) {
      return colors[colorInternal];
    }
    
    return color;
  }
  
  const hexColorOpacity = (hex?: string, opacity: number = 1) => {
    if (hex) {
      const r = parseInt(hex.slice(1, 3), 16);
      const g = parseInt(hex.slice(3, 5), 16);
      const b = parseInt(hex.slice(5, 7), 16);

      return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    }
    
    return hex;
  }
  
  return (
    <S.Container
      {...rest}
      border={getBorder()}
      backgroundColor={hexColorOpacity(getColor(rest.backgroundColor), rest.backgroundOpacity)}
      fontColor={getColor(rest.fontColor)}
    >
      {children}
    </S.Container>
  )
}

export default Card;