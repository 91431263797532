import useSWR from "swr";
import authService from "../services/auth-service";
import toast from "react-hot-toast";
import useLocalization from "./useLocalization";
import IEntityItem from "../@types/IEntityItem";

export default function useOptimisticFetchEntityItems(schemaUid?: Guid) {
  const l = useLocalization();
  const resp = useSWR(schemaUid, authService.entityEndpoint.schemaItems);
  let data: Array<IEntityItem<IEntityItemData>> | undefined = resp.data;

  return {
    ...resp,
    data,
    createEntity: async (schemaUid: Guid, data: IEntityItem<IEntityItemData>, datas: IEntityItem<IEntityItemData>[]) => {
      return toast.promise(
        resp.mutate(authService.entityEndpoint.createEntity(schemaUid, data, datas), {
          optimisticData: [data, ...datas],
          rollbackOnError: true,
          populateCache: true,
          revalidate: false,
        }),
        {
          loading: l('Saving...'),
          success: l('Saved!'),
          error: l('Could not save.'),
        }
      );
    },
    updateEntity: async (entityUid: Guid, data: IEntityItem<IEntityItemData>, datas: IEntityItem<IEntityItemData>[]) => {
      return toast.promise(
        resp.mutate(authService.entityEndpoint.updateEntity(entityUid, data, datas), {
          optimisticData: [data, ...datas.filter(r => r.uid !== entityUid)],
          rollbackOnError: true,
          populateCache: true,
          revalidate: false
        }),
        {
          loading: l('Saving...'),
          success: l('Saved!'),
          error: l('Could not save.'),
        }
      );
    },
    deleteEntity: async (entityUid: Guid, datas: IEntityItem<IEntityItemData>[]) => {
      return toast.promise(
        resp.mutate(authService.entityEndpoint.deleteEntity(entityUid, datas), {
          optimisticData: datas.filter((item) => item.uid !== entityUid),
          rollbackOnError: true,
          populateCache: true,
          revalidate: false
        }),
        {
          loading: l('Deleting...'),
          success: l('Deleted!'),
          error: l('Could not delete.'),
        }
      );
    }
  };
}