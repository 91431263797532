import {atom} from "recoil";
import {persistAtomEffect} from "../effects/persistAtom";

export const selectedCourseRegisterUidState = atom<string | undefined>({
  key: 'SelectedCourseRegisterUidState',
  default: undefined,
  effects: [
    persistAtomEffect
  ]
});
