import React from "react";
import * as S from "./text-field.styled";

interface ITextFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {}

export default function TextField(props: ITextFieldProps) {
  return (
    <React.Fragment>
      <S.Input 
        {...props}
      />
    </React.Fragment>
  );
}