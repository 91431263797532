import React from 'react';
// @ts-ignore
import * as S from './checkbox.styled';

interface ICheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  checkedColor?: string;
  defaultChecked: boolean;
  onCheck?: (value: string, label: string, checked: boolean) => void;
  rootStyles?: React.CSSProperties;
}

export default function Checkbox(props: ICheckboxProps) {
  const {
    checkedColor = '#764ab0',
    defaultChecked = false,
    onCheck,
    rootStyles,
    ...rest
  } = props;
  const [checked, setChecked] = React.useState(defaultChecked);

  React.useEffect(() => {
    if (defaultChecked !== checked) {
      setChecked(defaultChecked);
    }
  }, [defaultChecked]);

  return (
    <S.Checkbox style={rootStyles} primaryColor={checkedColor}>
      <S.Input
        type="checkbox"
        checked={checked}
        onChange={(e) => {
          setChecked(e.target.checked);
          onCheck && onCheck(e.target.value, e.target.name, e.target.checked);
        }}
        {...rest}
      />
    </S.Checkbox>
  );
}
