import React, { Component } from 'react';
import ButtonDynamic from '../button-dynamic/button-dynamic';
import './breadcrumb-simple.scss';

export default class BreadcrumbSimple extends Component {
  redirectToDashboard = () => {
    window.location = window.location.origin + window.location.pathname;
  };

  render() {
    const { showHomeButton = false } = this.props;
    
    return (
      <div className={`rdp-admin-component-breadcrumb-simple ${showHomeButton ? "rdp-admin-component-breadcrumb-simple-with-home" : ""}`}>
        {showHomeButton && (
          <div className="breadcrumb-simple-dashboard-button">
            <ButtonDynamic icon="home" actionClick={this.redirectToDashboard} >Dashboard</ButtonDynamic>
          </div>
        )}
      </div>
    );
  }
}
