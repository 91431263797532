import React from "react";
import * as S from "./list-users-group.styled";
import useLocalization from "../../hooks/useLocalization";
import {Breadcrumb, Header, Sidebar} from "../../components";
import EntityItemTree from "../../components/entity-item-tree/entity-item-tree";
import Card from "../../components/card/card";
import {useRecoilState} from "recoil";
import {userGroupsInfoCardVisibleState} from "../../recoil/atoms/userGroupsInfoCardVisibleState";
import {selectedEntityItemsState} from "../../recoil/atoms/selectedEntityItemsState";
import {IconLinkVariant} from "../../components/icon";
import Sdk from "api.digitalpages.module.sdk.api";
import IProjectModel from "../../@types/IProjectModel";
import toast from "react-hot-toast";
import copy from 'copy-to-clipboard';
import Dialog from "../../components/dialog";
import RDP_UTILS from "../../config/util";
import Select from "react-select";
import {selectedDomainState} from "../../recoil/atoms/selectedDomainState";

interface IListClassesProps extends IBasicView<{ }> {}

function ListUsersGroup(props: IListClassesProps) {
  const {
    consumer
  } = props;
  const l = useLocalization();
  const [usersGroupsInfoCardVisible, setUsersGroupsInfoCardVisible] = useRecoilState(userGroupsInfoCardVisibleState);
  const [checkedItems, setCheckedItems] = useRecoilState<Guid[]>(selectedEntityItemsState);
  const [linkDomainSelectionDialogVisible, setLinkDomainSelectionDialogVisible] = React.useState<boolean>(false);
  const [domains, setDomains] = React.useState<string[]>([]);
  const [selectedDomain, setSelectedDomain] = useRecoilState(selectedDomainState);
  
  const handleGenerateEntityAppSignupLinkClick = async () => {
    if (checkedItems.length === 0) {
      toast.error(l("Select at least one item."));
      return;
    }
    
    
    const activeProject: IProjectModel = Sdk.authorization.activeProject || {};
    
    if (activeProject) {
      const {
        domain_specs = []
      } = activeProject;
      
      if (domain_specs.length === 1) {
        const spec = domain_specs[0];
        createSignupLink(spec.domain);
      } else if (domain_specs.length > 1) {
        setDomains(domain_specs.map((spec) => spec.domain));
        if (selectedDomain === "" || domain_specs.find(d => d.domain === selectedDomain) === undefined) {
          setSelectedDomain(domain_specs[0].domain);
        }
        setLinkDomainSelectionDialogVisible(true);
      }
    }
  }
  
  const createSignupLink = (domain: string) => {
    let link = `https://${domain}/#/cadastro?groups=${checkedItems.join(",")}`;

    try {
      copy(link);
      toast.success(l("Generated!! Link copied to clipboard."));
    } catch (e) {
      console.error(e);
    }
  }

  const mapDomainsToOptions = (domains: string[]) => {
    const options = [
    ];

    options.push(...domains.map((domain, i) => ({
      value: domain || i.toString(),
      label: domain || i.toString(),
    })));

    return options;
  }
  
  const renderDomainSelectionDialogContent = () => {
    return (
      <S.ModalContentContainer>
        <S.Label htmlFor="domain-select">{l("This project has multiples domains, choose one:")}</S.Label>
        <Select
          id="domain-select"
          placeholder={l("Select a domain")}
          isSearchable
          value={mapDomainsToOptions(domains).find((option) => option.value === selectedDomain)}
          options={mapDomainsToOptions(domains)}
          styles={RDP_UTILS.MultipleSelect.fullWidthStyleFilter}
          onChange={(option) => option && setSelectedDomain(option.value)}
        />
      </S.ModalContentContainer>
    );
  }
  
  return (
    <S.Container>
      <Header />

      <Sidebar {...consumer} />
      
      <Breadcrumb 
        {...consumer}
        generateEntityAppSignupLinkEnabled
        generateEntityAppSignupLinkOnClick={handleGenerateEntityAppSignupLinkClick}
      />
      
      <Dialog
        open={linkDomainSelectionDialogVisible}
        title={l("Select a domain to generate link")}
        onClose={() => setLinkDomainSelectionDialogVisible(false)} 
        onCancel={() => setLinkDomainSelectionDialogVisible(false)}
        confirmColor="primary"
        icon={<IconLinkVariant />}
        onConfirm={() => {
          if (selectedDomain) {
            createSignupLink(selectedDomain);
            setLinkDomainSelectionDialogVisible(false);
          }
        }}
        confirmText={l("Generate link")}
        children={renderDomainSelectionDialogContent()}        
      />

      <S.Content>
        {usersGroupsInfoCardVisible && (
          <Card
            border="primary"
            backgroundColor="primary"
            backgroundOpacity={0.1}
            fontColor="primary"
          >
            <S.CloseButton
              title={l("close")}
              tabIndex={0}
              autoFocus
              onClick={() => setUsersGroupsInfoCardVisible(false)}
            >
              <svg width="24" height="24" viewBox="0 0 24 24">
                <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
              </svg>
            </S.CloseButton>

            {l("The list below contains all registered groups of the \"Security\" type. Adding the user to a group enables centralized control of the user's permissions on the platform (controlled via the group) and especially in access control. If the user is not related to any group, he will not have access to the resources of the groups below.")}
          </Card>
        )}
        
        <EntityItemTree
          border={false}  
        />
      </S.Content>
    </S.Container>
  );
}

export default ListUsersGroup;