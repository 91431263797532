import styled from "styled-components";

export const MainList = styled.ul`
  background: #f5f7f8;
  position: absolute;
  top: 136px;
  left: 230px;
  bottom: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 20px;
`

export const Item = styled.li`
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 25px;
  cursor: pointer;
  margin-bottom: 15px;
  font-weight: bold;
  color: #000;
  background-color: #fff;
  background-image: url("./icon-right.svg");
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: calc(100% - 14px) 50%;
`