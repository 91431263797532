import React from "react";
import * as S from "./styles.styled";
import TextField from "../inputs/text-field/text-field";
import Checkbox from "../inputs/checkbox/checkbox";
import Label from "../label/label";
import Time from "../inputs/time/time";
import Date from "../inputs/date/date";

interface IPropertiesDynamicProps {
  values: Array<ICourseDynamicProp>;
  gap?: string;
  onChange?: (value: string, prop: ICourseDynamicProp) => void;
  translations?: { [key: string]: string };
}

function PropertiesDynamic(props: IPropertiesDynamicProps) {
  const {
    values = [],
    gap = "0.5rem",
    onChange,
    translations = {},
  } = props;
  
  const renderProp = (prop: ICourseDynamicProp, index: number) => {
    return (
      <div key={index}>
        <Label htmlFor={prop.uid}>
          {getTranlatedPropName(prop)}
        </Label>

        {renderField(prop)}
      </div>
    );
  }
  
  const renderField = (prop: ICourseDynamicProp) => {
    const typesComponents = {
      "Text": (
        <TextField
          id={prop.uid}
          type="text"
          defaultValue={prop.value}
          onChange={(e) => onChange && onChange(e.target.value, prop)}
        />
      ),
      "Bool": (
        <Checkbox
          defaultChecked={prop.value === "true"}
          onCheck={(value, label, checked) => onChange && onChange(checked ? "true" : "false", prop)}
        />
      ),
      "TimeSpan": (
        <Time
          id={prop.uid}
          defaultValue={prop.value}
          onChange={(e) => onChange && onChange(e.target.value, prop)}
        />
      ),
      "NumberInteger": (
        <TextField
          id={prop.uid}
          type="number"
          step="1"
          defaultValue={prop.value}
          onChange={(e) => onChange && onChange(e.target.value, prop)}
        />
      ),
      "NumberDecimal": (
        <TextField
          id={prop.uid}
          type="number"
          step="0.1"
          defaultValue={prop.value}
          onChange={(e) => onChange && onChange(e.target.value, prop)}
        />
      ),
      "DateTime": (
        <Date
          id={prop.uid}
          defaultValue={prop.value}
          onChange={(e) => onChange && onChange(e.target.value, prop)}
        />
      ),
      "ImageUrl": (<></>),
      "FileUrl": (<></>),
      "Option": (<></>),
    }

    return typesComponents[prop.type] || null;
  }
  
  const getTranlatedPropName = (prop: ICourseDynamicProp) => {
    return translations[prop.name] || prop.name;
  }
  
  return (
    <S.Fieldset
      gap={gap}
    >
      {values.map(renderProp)}
    </S.Fieldset>
  );
}

export default PropertiesDynamic;