import styled from "styled-components";

export const Container = styled.div<{
  boxShadow?: string;
  border?: string;
  backgroundColor?: string;
  fontColor?: string;
}>`
  background-color: ${(props) => props.backgroundColor || "white"};
  box-shadow: ${(props) => props.boxShadow || "0 8px 16px 0 #00376d1a"};
  width: calc(100% - 40px);
  padding: 24px;
  border-radius: 4px;
  margin: 30px auto;
  overflow: hidden;
  border: ${(props) => props.border || "none"};
  color: ${(props) => props.fontColor || "auto"};
  position: relative;
`;