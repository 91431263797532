import React, { Component } from 'react';
import './iframe.scss';

import { Header, Sidebar, Breadcrumb } from '../../components';

export default class ViewIframe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      loading: false,
      loadingSubmit: false,
      msgSuccess: null,
      msgError: null,
    };
  }

  render() {
    const { consumer } = this.props;
    const uri = consumer && consumer.currentRoute
      ? consumer.currentRoute.externalUri
      : '';

    return (
      <div className="rdp-admin-tex">
        <Header />
        <Sidebar
          defineGroup={
            this.props.consumer ? this.props.consumer.defineGroup : null
          }
          defineRoute={
            this.props.consumer ? this.props.consumer.defineRoute : null
          }
          groups={this.props.consumer ? this.props.consumer.groups : null}
        />
        <Breadcrumb
          currentRoute={consumer ? consumer.currentRoute : null}
          defineRoute={consumer ? consumer.defineRoute : null}
        />
        <div id="rdp-admin-content-area" className="rdp-admin-content">
          <iframe
            src={uri}
            style={{ width: '100%', height: 'calc(100% - 2px)' }}
          />
        </div>
      </div>
    );
  }
}
