import styled from "styled-components";

export const Label = styled.label`
  font-size: 11px;
  letter-spacing: 0.08em;
  display: block;
  padding: 6px 0;
  font-family: Roboto, sans-serif !important;
  font-weight: 500 !important;
  
  &:first-letter {
    text-transform: uppercase;
  }
`;