import color from "color";

const opacity = (code, value) => {
	let hex = code.replace('#','');

	if (hex.length === 3) {
		hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
	}

	const 
		r = parseInt(hex.substring(0,2), 16),
		g = parseInt(hex.substring(2,4), 16),
		b = parseInt(hex.substring(4,6), 16);

	return `rgba(${r},${g},${b},${value/100})`;
}

const theme = {
	"fontSizes": {
		"large": "32px",
		"medium": "24px",
		"medium1": "23px",
		"medium2": "22px",
		"small": "20px",
		"small5": "16px",
		"small4": "17px",
		"small1": "15px",
		"small2": "13px",
		"small3": "11px",
	},
	"colors": {
		"light": "#fff",
		"light1": "#A7AFB9",
		"light2": "#9AA2AE",
		"light3": "#F8F9FE",
		"light4": "#ebedf3",
		"light5": {
			"default": "#D3DAE8",
			"opacity": {
				"3": opacity("#D3DAE8", 30),
				"5": opacity("#D3DAE8", 50),
				"6": opacity("#D3DAE8", 60),
				"7": opacity("#D3DAE8", 70),
			}
		},
		"light6": {
			"default": "#E9EEF9",
			"opacity": {
				"4": opacity("#E9EEF9", 40),
				"2": opacity("#E9EEF9", 20),
			}
		},
		"light7": {
			"default": "#8490A6"
		},
		"light8": {
			"default": "#B1BBCF",
			"opacity": {
				"3": opacity("#B1BBCF", 30),
			}
		},
		"primary": {
			"default": "#0F1726",
			"whiten": {
				"3": color("#0F1726").whiten(0.3).hex(),
				"5": color("#0F1726").whiten(0.5).hex(),
			}
		},
		"primary1": {
			"default": "#1D2C48",
			"opacity": {
				"5": opacity("#1D2C48", 50),
			}
		},
		"primary2": {
			"default": "#4B618B"
		},
		"secondary": {
			"default": "#33D8B7",
			"opacity": {
				"2": opacity("#33D8B7", 20),
			}
		},
		"yellow": {
			"opacity": {
				"2": opacity("#F2C94C", 20)
			}
		}
	},
	"borders": {
		"none": "none",
		"thin": "1px solid",
	}
};

export default theme;