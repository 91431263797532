import React, { Component } from 'react';
import Switch from 'react-switch';
import './form-dynamic.scss';
import {
  TextEditor,
  DropzoneUpload,
  Galerie,
  PreviewModal,
  ButtonIcon,
  TableField,
  HierarchyDynamic,
} from '../../components';
import ButtonDynamic from '../button-dynamic/button-dynamic';
import { IconDisket, IconPreview, IconAdd, IconCloseModal } from '../icon';
import Select from 'react-select';
import RDP_UTILS from '../../config/util';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { format } from 'date-fns';
import { BlockPicker } from 'react-color';
import Components from 'rdp-builder-components';

const fieldsType = {
  COMPUTED: 'computed',
  INPUT_TEXT: 'input-text',
  INPUT_NUMBER: 'input-number',
  INPUT_PASSWORD: 'input-password',
  INPUT_EMAIL: 'input-email',
  INPUT_COLOR: 'input-color',
  INPUT_ADD: 'input-add',
  REFERENCE_ENTITY: 'reference-entity',
  TEXT: 'text',
  TEXT_AREA: 'text_area',
  NUMBER: 'number',
  DATE: 'date',
  COLOR: 'color',
  DATE_TIME: 'date-time',
  STATUS: 'status',
  SELECT: 'select',
  MULTIPLE_SELECT: 'multiple-select',
  CHECKBOX: 'checkbox',
  IMAGE: 'image',
  DROPZONE_IMAGE: 'dropzone-image',
  HIERARCHY: 'hierarchy',
  TABLE: 'table',
  SWITCH: 'switch',
  WEBPACK: 'webpack',
};

export default class FormDynamic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalGalerie: false,
      modalPreview: false,
      uidPreview: null,
      urlPreview: null,
      fieldActiveGalerie: null,
      blockActions: true,
      options: {
        hasCheckbox: true,
        editOption: false,
        deleteOption: false,
      },
      formIsValid: false,
      fieldsValues: {},
      fieldsError: {},
      eventStart: null,
      eventEnd: null,
      fields: [],
    };
  }

  componentDidMount() {
    const { entity, notice, values = {} } = this.props;
    this.setState({
      fields: entity ? entity.configuration : [],
      fieldsValues: notice ? notice.data : values,
      eventStart:
        notice && notice.event_start_at
          ? format(new Date(notice.event_start_at), 'yyyy-MM-dd')
          : null,
      eventEnd:
        notice && notice.event_end_at
          ? format(new Date(notice.event_end_at), 'yyyy-MM-dd')
          : null,
      noticeProducts:
        notice && notice.products
          ? RDP_UTILS.formatProductsDefault(
              this.props.productsAvailables,
              notice.products
            )
          : [],
    });
    // Modelo Form
    // this.setState({ fields: dataFields.configuration })
  }

  componentDidUpdate(prevProps, prevStates) {
    if (prevProps.cleanValues !== this.props.cleanValues) {
      this.setState({ blockActions: true, fieldsValues: {}, fieldsError: {} });
    }
    if (prevProps.blocked !== this.props.blocked && !this.state.blockActions) {
      this.setState({ blockActions: true });
    }
  }

  computedLabel() {
    return (
      <span
        data-tip="O dado apresentado abaixo não é editável pois é calculado automaticamente."
        className="bullet-computed"
      ></span>
    );
  }

  requiredLabel() {
    return <span className="bullet-required">*</span>;
  }

  msgInvalid = (id) => {
    return this.state.fieldsError[id] ? (
      <span id={'error-msg-' + id} className="field-invalid-msg">
        {this.state.fieldsError[id]}
      </span>
    ) : null;
  };

  activeGalerie = (id, children = null) => {
    this.setState({
      modalGalerie: true,
      fieldActiveGalerie: id,
      fieldChildrenGalerie: children,
    });
  };

  defaultComputed(id, type, required, step = '0.01') {
    let value =
      this.props.values || this.state.fieldsValues
        ? (this.props.values || this.state.fieldsValues)[id]
        : '';
    return (
      <input
        className="field-computed"
        key={'field-' + id}
        disabled={true}
        step={step}
        id={'field-' + id}
        value={value}
        name={id}
        required={required}
        type={'text'}
        onChange={(e) => this.handleChange(id, e.target.value)}
      />
    );
  }

  addInputChild(parent, id) {
    const parentValue = (this.props.values || this.state.fieldsValues)[parent]
      ? (this.props.values || this.state.fieldsValues)[parent]
      : '';

    this.handleChange(parent, {
      child: [...(parentValue.child || []), { [id]: null }],
    });
  }

  removeInputChild(parent, key) {
    const parentValue = (this.props.values || this.state.fieldsValues)[parent]
      ? (this.props.values || this.state.fieldsValues)[parent]
      : '';

    const { child } = parentValue;

    if (key > -1) {
      child.splice(key, 1);
    }

    this.handleChange(parent, { ...parentValue, child });
  }

  inputChildChangeValue(parent, field, key, value) {
    const parentValue = (this.props.values || this.state.fieldsValues)[parent]
      ? (this.props.values || this.state.fieldsValues)[parent]
      : '';

    const { child } = parentValue;
    child[key][field] = value;

    this.handleChange(parent, { ...parentValue, child });
  }

  defaultInputAdd(id, type, child, required, className, step = '0.01') {
    let value =
      this.props.values || this.state.fieldsValues
        ? (this.props.values || this.state.fieldsValues)[id]
        : '';

    return (
      <>
        <div className="input-add">
          <input
            className={className}
            key={'field-' + id}
            step={step}
            id={'field-' + id}
            value={value.text}
            name={id}
            required={required}
            type={type}
            onChange={(e) =>
              this.handleChange(id, { ...value, text: e.target.value })
            }
          />
          <button
            className="button-add"
            type="button"
            onClick={() => this.addInputChild(id, child.field)}
          >
            <IconAdd />
          </button>
        </div>
        {(value.child || []).map((childValue, key) => (
          <div className="input-add" key={key}>
            <input
              type="text"
              // key={key}
              value={childValue[child.field]}
              onChange={(e) =>
                this.inputChildChangeValue(id, child.field, key, e.target.value)
              }
            />
            <button
              className="button-add"
              type="button"
              onClick={() => this.removeInputChild(id, key)}
            >
              <IconCloseModal />
            </button>
          </div>
        ))}
      </>
    );
  }

  defaultButton(field) {
    return (
      <ButtonDynamic
        tooltip={field.action_button.title}
        actionClick={() => {
          if (
            this.props.actions &&
            this.props.actions.click &&
            this.props.actions.click[field.identifier]
          ) {
            this.props.actions.click[field.identifier]();
          }
        }}
        size="fit"
        color="green"
        icon="publish"
        iconDiv={true}
      >
        {field.action_button.title}
      </ButtonDynamic>
    );
  }

  defaultInput(id, type, required, className, placeholder = '', step = '0.01') {
    let value =
      this.props.values || this.state.fieldsValues
        ? (this.props.values || this.state.fieldsValues)[id]
        : '';
    return (
      <input
        className={className}
        key={'field-' + id}
        placeholder={placeholder}
        step={step}
        id={'field-' + id}
        value={value}
        name={id}
        required={required}
        type={type}
        onChange={(e) => this.handleChange(id, e.target.value)}
      />
    );
  }

  defaultTextArea(
    id,
    type,
    required,
    className,
    rows,
    placeholder = 'test',
    step = '0.01'
  ) {
    let value =
      this.props.values || this.state.fieldsValues
        ? (this.props.values || this.state.fieldsValues)[id]
        : '';
    return (
      <textarea
        className={className + ' text-area'}
        key={'field-' + id}
        step={step}
        placeholder={placeholder}
        id={'field-' + id}
        rows={rows ? rows : '10'}
        value={value}
        name={id}
        required={required}
        type={type}
        onChange={(e) => this.handleChange(id, e.target.value)}
      />
    );
  }

  entitySelect = (id, required, options) => {
    const { entity, selectOptions } = this.props;
    if (!entity || !entity.configuration) return null;

    const value =
      this.props.values || this.state.fieldsValues
        ? (this.props.values || this.state.fieldsValues)[id]
        : '';
    const entityConfiguration = entity.configuration.find(
      ({ identifier }) => identifier === id
    );

    if (!selectOptions) {
      return null;
    }

    const result = selectOptions.find(({ identifier }) => identifier == id);
    let data = [];

    if (result) {
      data = result.data;
    }

    const hasColor = () => {
      return entityConfiguration.reference_entity_properties.options_fields.some(
        ({ type }) => [fieldsType.INPUT_COLOR].includes(type)
      );
    };

    const getColor = ({ data }) => {
      const field =
        entityConfiguration.reference_entity_properties.options_fields.find(
          ({ type }) => [fieldsType.INPUT_COLOR].includes(type)
        );

      if (!field) return;

      return data[field.identifier];
    };

    const getLabel = ({ data }) => {
      return entityConfiguration.reference_entity_properties.options_fields
        .filter(({ type }) => [fieldsType.INPUT_TEXT].includes(type))
        .map(({ identifier }) => data[identifier])
        .join(',');
    };

    const requiredValue = (this.props.values || this.state.fieldsValues)[
      entityConfiguration.reference_entity_properties.filter_by
    ];
    const getPlaceholder = () => {
      if (
        entityConfiguration.reference_entity_properties &&
        entityConfiguration.reference_entity_properties.filter_by
      ) {
        if (requiredValue) {
          return 'Selecione';
        } else {
          const requiredField = entity.configuration.find(
            ({ identifier }) =>
              identifier ===
              entityConfiguration.reference_entity_properties.filter_by
          );
          return `Selecione ${requiredField.field.toLowerCase()}`;
        }
      }

      return 'Selecione';
    };

    const isDisabled = () => {
      if (
        entityConfiguration.reference_entity_properties &&
        entityConfiguration.reference_entity_properties.filter_by
      ) {
        return !requiredValue;
      }
    };

    const handleSelectChange = (identifier, target) => {
      if (Array.isArray(target)) {
        target = target.map((option) => option.value);
      } else if (target && target.value) {
        target = target.value;
      }

      this.handleChange(identifier, target);
    };

    const getValue = () => {
      if (!value || !data || !data.length) return null;

      if (options && options.multiple) {
        return data
          .filter(({ uid }) => value.includes(uid))
          .map((option) => ({
            value: option.uid,
            label: getLabel(option),
            color: getColor(option),
          }));
      }

      return data.find(({ uid }) => uid === value)
        ? {
            value,
            label: getLabel(data.find(({ uid }) => uid === value)),
            color: getColor(data.find(({ uid }) => uid === value)),
          }
        : null;
    };

    const dataOptions = data.map(({ uid, ...option }) => ({
      value: uid,
      label: getLabel(option),
      color: getColor(option),
    }));

    return (
      <Select
        filterOption={(option, inputValue) =>
          option.label.toLowerCase().includes(inputValue.toLowerCase())
        }
        isSearchable={true}
        placeholder={getPlaceholder()}
        onChange={(target) => handleSelectChange(id, target)}
        isMulti={options && options.multiple}
        isClearable={true}
        isDisabled={isDisabled()}
        options={dataOptions}
        value={getValue()}
        defaultValue={getValue()}
        styles={
          hasColor()
            ? RDP_UTILS.ColorSelectStyle
            : RDP_UTILS.MultipleSelect.styleForm
        }
        theme={RDP_UTILS.MultipleSelect.theme}
      />
    );
  };

  inputColor(id, data, required) {
    const color =
      this.props.values || this.state.fieldsValues
        ? (this.props.values || this.state.fieldsValues)[id]
        : '';
    return (
      <BlockPicker
        color={color}
        onChangeComplete={(value) => this.handleChange(id, value.hex)}
      />
    );
  }

  defaultSelect(id, data, required, placeholder = 'Select an Option') {
    let value =
      this.props.values || this.state.fieldsValues
        ? (this.props.values || this.state.fieldsValues)[id]
        : '';
    return (
      <Components.Buttons.Select
        key={'field-' + id}
        value={value}
        id={'field-' + id}
        borderColor="#d2d5dd"
        placeholder={placeholder}
        name={id}
        required={required}
        onChange={(value) => this.handleChange(id, value)}
        items={data}
      />
    );
  }

  defaultImage(id, data) {
    const imageData = (this.props.values || this.state.fieldsValues)[id];
    let imageUrl = '';
    let typeImage = null;
    if (imageData) {
      imageUrl = RDP_UTILS.urlRDPImage(imageData.uid);
      typeImage = imageData.content_type ? imageData.content_type : null;
    }

    return (
      <div key={id}>
        <div
          className="box-image-preview"
          style={{ backgroundImage: `url(${imageUrl})` }}
        >
          {typeImage && (
            <span className="file-badge-type">
              {RDP_UTILS.typeFile(typeImage)}
            </span>
          )}
        </div>
        <button
          type="button"
          onClick={() => this.activeGalerie(id)}
          className="btn-select-image"
        >
          Selecione a imagem...
        </button>
      </div>
    );
  }

  defaultDropdownImage(id, data) {
    const imageData = (this.props.values || this.state.fieldsValues)[id];

    return (
      <Components.Forms.Dropzone
        title="Image Upload"
        titleSize="smal1"
        placeholderBorderType="dashed"
        placeholderIconWidth="25px"
        iconColor="#5F6368"
        buttonText="UPLOAD"
        buttonFontSize="small1"
        buttonBorderRadius="5px"
        buttonFontColor="#FFFFFF"
        buttonColor="#764AB0"
        buttonBordered={false}
        defaultImage={imageData}
        onChange={(e) => this.handleChange(id, e.target.files[0])}
      />
    );
  }

  defaultMultiSelect(id, data, required) {
    let value =
      this.props.values || this.state.fieldsValues
        ? (this.props.values || this.state.fieldsValues)[id]
        : null;
    return (
      <Select
        placeholder="Selecione"
        onChange={(e) => this.handleChange(id, e)}
        isMulti={true}
        isClearable={true}
        isDisabled={false}
        options={data}
        defaultValue={value}
        styles={RDP_UTILS.MultipleSelect.styleForm}
        isSearchable={false}
        theme={RDP_UTILS.MultipleSelect.theme}
      />
    );
  }

  defineField = (field, index) => {
    let dataField = [];
    let classAttr = 'field-text';

    switch (field.type) {
      case fieldsType.INPUT_ADD:
        dataField.push(
          this.defaultInputAdd(
            field.identifier,
            'text',
            field.child,
            field.required,
            field.className
          )
        );
        break;

      case fieldsType.COMPUTED:
        dataField.push(
          this.defaultComputed(field.identifier, 'text', field.required)
        );
        break;
      case fieldsType.INPUT_TEXT:
        dataField.push(
          this.defaultInput(
            field.identifier,
            'text',
            field.required,
            field.className,
            field?.placeholder
          )
        );
        break;
      case fieldsType.INPUT_PASSWORD:
        dataField.push(
          this.defaultInput(
            field.identifier,
            'password',
            field.required,
            field.className,
            field?.placeholder
          )
        );
        break;
      case fieldsType.INPUT_EMAIL:
        dataField.push(
          this.defaultInput(
            field.identifier,
            'email',
            field.required,
            field.className,
            field?.placeholder
          )
        );
        break;
      case fieldsType.TEXT_AREA:
        dataField.push(
          this.defaultTextArea(
            field.identifier,
            null,
            field.required,
            field.className,
            field?.rows,
            field?.placeholder
          )
        );
        break;
      case fieldsType.INPUT_NUMBER:
        dataField.push(
          this.defaultInput(field.identifier, 'number', field.required)
        );
        break;
      case fieldsType.COLOR:
        dataField.push(
          this.defaultInput(field.identifier, 'color', field.required)
        );
        break;
      case fieldsType.NUMBER:
        dataField.push(
          this.defaultInput(field.identifier, 'number', field.required)
        );
        break;
      case fieldsType.DATE:
        dataField.push(
          this.defaultInput(field.identifier, 'date', field.required)
        );
        break;
      case fieldsType.CHECKBOX:
        dataField.push(
          this.defaultInput(field.identifier, 'checkbox', field.required)
        );
        break;
      case fieldsType.REFERENCE_ENTITY:
        classAttr = 'field-select';

        const component = this.entitySelect(
          field.identifier,
          field.required,
          field.options
        );

        if (field.action_button) {
          dataField.push(
            <div className="reference-entity-wrapper">
              {component}
              {this.defaultButton(field)}
            </div>
          );
        } else {
          dataField.push(component);
        }
        break;
      case fieldsType.SELECT:
        classAttr = 'field-select';
        dataField.push(
          this.defaultSelect(
            field.identifier,
            field.data,
            field.required,
            field?.placeholder
          )
        );
        break;
      case fieldsType.INPUT_COLOR:
        classAttr = 'field-select';
        dataField.push(
          this.inputColor(field.identifier, field.data, field.required)
        );
        break;
      case fieldsType.MULTIPLE_SELECT:
        classAttr = 'field-multiple-select';
        dataField.push(
          this.defaultMultiSelect(field.identifier, field.data, field.required)
        );
        break;
      case fieldsType.IMAGE:
        classAttr = 'field-image';
        dataField.push(this.defaultImage(field.identifier, field.data));
        break;
      case fieldsType.DROPZONE_IMAGE:
        classAttr = 'field-image';
        dataField.push(this.defaultDropdownImage(field.identifier, field.data));
        break;
      case fieldsType.TABLE:
        classAttr = 'field-table';
        let defaultData = (this.props.values || this.state.fieldsValues)[
          field.identifier
        ]
          ? (this.props.values || this.state.fieldsValues)[field.identifier]
          : null;
        dataField.push(
          <TableField
            entity={this.props.entity}
            selectOptions={this.props.selectOptions}
            key={field.identifier}
            id={field.identifier}
            onChange={this.handleTableField}
            configuration={field.configuration}
            defaultData={defaultData}
            triggerSelectImage={(setExternalState) =>
              this.setState({
                setExternalState,
                modalGalerie: true,
              })
            }
          />
        );
        break;
      case fieldsType.SWITCH:
        classAttr = 'field-switch';
        dataField.push(
          <div className="box-component-switch">
            <Switch
              checked={
                (this.props.values || this.state.fieldsValues)[field.identifier]
                  ? (this.props.values || this.state.fieldsValues)[
                      field.identifier
                    ]
                  : false
              }
              onChange={(e) => this.handleChange(field.identifier, e)}
              onColor="#4cbf69"
              onHandleColor="#FFF"
              handleDiameter={20}
              uncheckedIcon={false}
              checkedIcon={false}
              height={22}
              width={48}
              className="react-switch"
              key={'field-' + field.identifier}
              id={'field-' + field.identifier}
            />
            <span className="label-switch">
              {(this.props.values || this.state.fieldsValues)[field.identifier]
                ? 'Sim'
                : 'Não'}
            </span>
          </div>
        );
        break;
      case fieldsType.TEXT:
        let value = (this.props.values || this.state.fieldsValues)[
          field.identifier
        ]
          ? (this.props.values || this.state.fieldsValues)[field.identifier]
          : null;
        classAttr = '';
        dataField.push(
          <TextEditor
            key={field.identifier}
            id={field.identifier}
            defaultValue={value}
            onChange={this.handleChange}
          />
        );
        break;
      case fieldsType.WEBPACK:
        let valueContent = (this.props.values || this.state.fieldsValues)[
          field.identifier
        ]
          ? (this.props.values || this.state.fieldsValues)[field.identifier]
          : null;
        classAttr = 'field-webpack';
        dataField.push(
          <div>
            {!this.props.isNew &&
              valueContent &&
              this.renderAttached(valueContent)}
            <DropzoneUpload
              cleanValues={this.props.cleanValues}
              subtitle="Selecione o arquivo de conteúdo no formato .zip..."
              accept=".zip"
              key={field.identifier}
              id={field.identifier}
              multiple={false}
              onUpload={this.handleUpload}
            />
          </div>
        );
        break;
      case fieldsType.HIERARCHY:
        classAttr = 'field-hierarchy';
        dataField.push(
          <HierarchyDynamic
            {...(this.props.actions || {})[field.identifier]}
            values={
              (this.props.values || this.state.fieldsValues)[field.identifier]
            }
            id={field.identifier}
            onChange={this.handleChange}
            selectOptions={this.props.selectOptions}
            configuration={field.fields_configuration}
          />
        );
        break;
      default:
        dataField.push(
          this.defaultInput(field.identifier, 'text', field.required)
        );
        break;
    }

    return (
      <div key={field.identifier} className={'group-field ' + classAttr}>
        {field.field && (
          <label className="field-label" htmlFor={'field-' + field.identifier}>
            {' '}
            {field.field} {field.type == 'computed' && this.computedLabel()}{' '}
            {field.required && this.requiredLabel()}
          </label>
        )}
        {dataField}
        {this.msgInvalid(field.identifier)}
      </div>
    );
  };

  renderPeriod() {
    const { eventStart, eventEnd } = this.state;
    return (
      <div
        key="fixed-fields-period"
        className="group-field field-text fixed-fields"
      >
        <span className="label-badge">Período do Evento</span>
        <label htmlFor="field-event-start">Data de Ínicio</label>
        <input
          key="field-event-start"
          id="field-event-start"
          value={eventStart}
          type="date"
          onChange={(e) =>
            this.setState({ blockActions: false, eventStart: e.target.value })
          }
        />
        <label htmlFor="field-event-end">Data de Fim</label>
        <input
          key="field-event-end"
          id="field-event-end"
          value={eventEnd}
          type="date"
          onChange={(e) =>
            this.setState({ blockActions: false, eventEnd: e.target.value })
          }
        />
      </div>
    );
  }

  renderSelectProduct(dataProducts) {
    const { noticeProducts } = this.state;
    return (
      <div
        key="fixed-fields-product"
        className="group-field field-select fixed-fields"
      >
        <span className="label-badge">Restrições do Conteúdo</span>
        <label htmlFor="field-products">Produtos Relacionados</label>
        <Select
          placeholder="Selecione os produtos"
          onChange={(e) =>
            this.setState({ blockActions: false, noticeProducts: e || [] })
          }
          isMulti={true}
          isClearable={true}
          isDisabled={false}
          options={dataProducts}
          defaultValue={noticeProducts}
          styles={RDP_UTILS.MultipleSelect.styleForm}
          isSearchable={false}
          theme={RDP_UTILS.MultipleSelect.theme}
        />
      </div>
    );
  }

  renderFixedFields = () => {
    const { entity } = this.props;

    return (
      <div>
        {(!entity.layout_configuration ||
          !entity.layout_configuration.hide_event_period) &&
          this.renderPeriod()}
        {(!entity.layout_configuration ||
          !entity.layout_configuration.hide_products_restriction) &&
          this.renderSelectProduct(this.props.productsAvailables)}
      </div>
    );
  };

  closePreview = () => {
    this.setState({ modalPreview: false, uidPreview: null, urlPreview: null });
  };

  renderAttached(fieldValue) {
    return (
      <li className="ready-file">
        <span className="file-name">Anexo atual </span>
        <div className="file-options">
          <span>Preview</span>
          <Link
            to="#"
            onClick={() => {
              this.setState({
                modalPreview: true,
                uidPreview: fieldValue.uid ? fieldValue.uid : null,
                urlPreview: fieldValue.url ? fieldValue.url : null,
              });
            }}
            className="file-option option-preview"
          >
            <IconPreview />
          </Link>
        </div>
      </li>
    );
  }

  handleTableField = (id, data) => {
    let fieldsV = this.props.values || this.state.fieldsValues;
    fieldsV[id] = data;
    this.setState({ blockActions: false, fieldsValues: fieldsV });
    if (this.props.handleChange) this.props.handleChange({ id, data });
  };

  handleUpload = (id, file) => {
    let fieldsV = this.props.values || this.state.fieldsValues;
    fieldsV[id] = file;
    this.setState({ blockActions: false, fieldsValues: fieldsV });
  };

  handleChange = (id, value) => {
    let fieldsV = this.props.values || this.state.fieldsValues;
    fieldsV[id] = value;
    this.setState({
      blockActions: false,
      fieldsValues: fieldsV,
    });
    if (this.props.handleChange) this.props.handleChange({ id, value });
  };

  validRequiredFields() {
    let invalids = {};
    const { fields, fieldsValues } = this.state;
    fields.map((f) => {
      if (f.required && typeof fieldsValues[f.identifier] === 'undefined') {
        invalids[f.identifier] = 'Preencha este campo.';
      }
    });
    return invalids;
  }

  clickSubmit = (action) => {
    const formLength = this.formEl.length;
    let defineMessages = {};
    let externalFields = this.validRequiredFields();

    if (
      this.formEl.checkValidity() === false ||
      Object.keys(externalFields).length > 0
    ) {
      for (let i = 0; i < formLength; i++) {
        const elem = this.formEl[i];
        if (elem.nodeName.toLowerCase() !== 'button') {
          if (!elem.validity.valid) {
            let id = elem.id.split('field-');
            id = id[1];
            defineMessages[id] = elem.validationMessage;
          }
        }
      }
      this.setState({
        formIsValid: false,
        fieldsError: { ...defineMessages, ...externalFields },
      });
      return false;
    }

    this.setState({ formIsValid: true, fieldsError: {} }, () => {
      let dataForm = {
        data: this.props.values || this.state.fieldsValues,
        status: action,
        start_at: null,
        finish_at: null,
        event_start_at: this.state.eventStart,
        event_end_at: this.state.eventEnd,
        products:
          this.state.noticeProducts.length > 0
            ? this.state.noticeProducts.map((p) => p.value)
            : [],
      };
      const contentArea = document.getElementById('rdp-admin-content-area');

      if (contentArea) {
        contentArea.scrollTo({ top: 0, behavior: 'smooth' });
      }
      this.props.handleSubmit(dataForm);
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
  };

  renderPreviewModal() {
    const { modalPreview, uidPreview, urlPreview } = this.state;
    return (
      <PreviewModal
        open={modalPreview}
        onClose={this.closePreview}
        uidContent={uidPreview}
        urlContent={urlPreview}
      />
    );
  }

  transformGroups(fields) {
    // const test = fields.reduce((defaultValue, current) => {
    //   current.group = current.group || 'Informações gerais'
    //   if (current.group) {
    //       defaultValue[current.group] = [...defaultValue[current.group]||[], current];
    //   }
    //   return defaultValue;
    // }, {})

    const test = fields.reduce((defaultValue, current) => {
      current.group = current.group || 'Informações gerais';

      if (current.group) {
        defaultValue[current.group] = [
          ...(defaultValue[current.group] || []),
          current,
        ];
      }
      return defaultValue;
    }, {});

    return Object.entries(test);
  }

  renderFields() {
    const { fields, blockActions } = this.state;
    const { entity } = this.props;

    return (
      <>
        <form
          ref={(form) => (this.formEl = form)}
          onSubmit={this.handleSubmit}
          noValidate
        >
          <div
            className={`form-main-content ${
              !fields.find((field) => field.column == 'right') ? 'all-page' : ''
            }`}
          >
            {this.transformGroups(
              fields.filter((field) => !field.column || field.column === 'main')
            ).map(([title, fields]) => (
              <>
                <div className="content-fields">
                  {entity.hideBadges ? null : (
                    <span className="label-badge">{title}</span>
                  )}
                  {fields.map((field) =>
                    field?.row?.length > 1 ? (
                      <div className="same-row">
                        {field.row.map((input) => this.defineField(input))}
                      </div>
                    ) : (
                      this.defineField(field)
                    )
                  )}
                </div>
              </>
            ))}
            {this.renderFixedFields()}
          </div>
          {fields.find((field) => field.column == 'right') && (
            <div className="form-right-content">
              {fields.map(
                (field, i) =>
                  field.column === 'right' && this.defineField(field, i)
              )}
            </div>
          )}

          {this.renderPreviewModal()}
          <ReactTooltip effect="solid" type="dark" place="top" />
        </form>
        <div className="action-btn">
          {!this.props.hideDraftButton && (
            <ButtonIcon
              tooltip="Salvar Rascunho"
              disabled={this.props.submitEnabled ? false : blockActions}
              actionClick={() => {
                this.clickSubmit('Draft');
              }}
              color="blue"
            >
              <IconDisket />
            </ButtonIcon>
          )}
          {!this.props.hideSubmitButton && (
            <ButtonDynamic
              tooltip={this.props.submitText || 'Publicar'}
              disabled={this.props.submitEnabled ? false : blockActions}
              actionClick={() => {
                this.clickSubmit('Done');
              }}
              size="fit"
              color="green"
              icon="publish"
              iconDiv={true}
            >
              {this.props.submitText || 'Publicar'}
            </ButtonDynamic>
          )}
        </div>
      </>
    );
  }

  handleSelectImage = (image) => {
    let imageUID = image.document.content_uid;
    let dataToSave = {
      uid: imageUID,
      url: RDP_UTILS.urlRDPImageToSave(imageUID),
      content_type: image.document.content_type,
    };

    this.state.setExternalState
      ? this.state.setExternalState(dataToSave)
      : this.setState({
          blockActions: false,
          fieldsValues: {
            ...(this.props.values || this.state.fieldsValues),
            [this.state.fieldActiveGalerie]: dataToSave,
          },
        });

    this.setState({
      modalGalerie: false,
    });
  };

  renderGalerie() {
    const { modalGalerie } = this.state;

    return (
      <Galerie
        title="Galeria de Imagens"
        handleSelect={this.handleSelectImage}
        open={modalGalerie}
        onClose={() =>
          this.setState({
            setExternalState: null,
            modalGalerie: !this.state.modalGalerie,
            fieldActiveGalerie: null,
          })
        }
      />
    );
  }

  render() {
    return (
      <div className="rdp-admin-component-form-dynamic">
        {this.renderFields()}
        {this.renderGalerie(this.state.setExternalState)}
      </div>
    );
  }
}
