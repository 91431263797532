import React, { Component } from 'react';
import './button-icon.scss';

//Colors: default(primary), blue, green, orange, black;
//Icons: home, plus, transactions, users;
//Size: full, fit;
export default class ButtonIcon extends Component {

  renderSimple = (definedClass, children) => {
    const { tooltip, type } = this.props
    return (
      <button data-tip={tooltip ? tooltip : null} className={definedClass}  type={type}>
        <span>{children ? children : "Botão"}</span>
      </button>
    );
  }


  defineStyle() {
    const { disabled, color } = this.props;
    let definedClass = "";
    if (!disabled) {
      definedClass = color ? `bd-colored-button bd-color-${color}` : `bd-simple-button`;
    } else {
      definedClass = color ? `bd-colored-button bd-color-disabled` : `bd-simple-button  bd-color-disabled`;
    }
    return definedClass;
  }


  render() {
    const { disabled, children, actionClick } = this.props;

    let definedClass = this.defineStyle();
    return (
      <div onClick={!disabled ? actionClick : () => { }} className={disabled ? `rdp-admin-button-icon bd-disabled` : `rdp-admin-button-icon bd-enabled`}>
        {this.renderSimple(definedClass, children)}
      </div>
    );
  }
}
