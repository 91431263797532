import React from "react";
import * as S from "./entity-item-members-table.styled";
import { Loading } from "../../components";
// @ts-ignore
import Components from "rdp-builder-components";
import { IconConstruct } from "../icon";
import useLocalization from "../../hooks/useLocalization";
import useOptimisticFetchUsers from "../../hooks/useOptimisticFetchUsers";
import useOptimisticFetchEntityItemMembers from "../../hooks/useOptimisticFetchEntityItemMembers";
import IUserModel from "../../@types/IUserModel";
import Checkbox from "../inputs/checkbox/checkbox";
import { useRecoilState } from "recoil";
import { checkedUsersMembersState } from "../../recoil/atoms/checkedUsersMembersState";

interface TableHeader {
  field: string;
  highlighted: boolean;
  identifier: "name" | "active" | "login";
}

interface IUsersTableProps {
  entityItemUid?: Guid;
  onCheck: (usersUids: Array<Guid>) => void;
  showOnlyActive?: boolean | null;
}

function EntityItemMembersTable(props: IUsersTableProps) {
  const {
    entityItemUid,
    onCheck,
    showOnlyActive = null,
  } = props;
  const l = useLocalization();
  const { data } = useOptimisticFetchUsers();
  const { data: members } = useOptimisticFetchEntityItemMembers(entityItemUid);
  const [checkedUsers, setCheckedUsers] = useRecoilState<Array<Guid>>(checkedUsersMembersState);
  const headers: Array<TableHeader> = [
    {
      field: "active",
      highlighted: false,
      identifier: "active"
    },
    {
      field: l("name"),
      highlighted: false,
      identifier: "name"
    },
    {
      field: l("login"),
      highlighted: false,
      identifier: "login"
    }
  ];
  
  React.useEffect(() => {
    if (members) {
      setCheckedUsers(members.map(member => member.user_uid || ""));
    }
  }, [members]);
  
  const users = data?.filter(user => {
    if (showOnlyActive !== null) {
      return showOnlyActive ? checkedUsers.includes(user.uid) : !checkedUsers.includes(user.uid);
    } else {
      return true;
    }
  });

  const handleCheckUser = (checked: boolean, row: IUserModel) => {
    let newCheckedUsers = [...checkedUsers];
    if (checked) {
      newCheckedUsers.push(row.uid);
    } else {
      newCheckedUsers = newCheckedUsers.filter(uid => uid !== row.uid);
    }
    setCheckedUsers(newCheckedUsers);
    onCheck(newCheckedUsers);
  }

  return (
    <S.Container>
      {
        users === undefined ? (
          <Loading msg={l("loading...")} />
        ) : (
          <>
            <S.TableContainer>
              <div className="info-counter">
                {l("showing")} <strong>{users.length}</strong> {l("results")}
              </div>

              <S.Table className="rdp-admin-table">
                <thead>
                <tr>
                  {headers.map(({ field, highlighted, identifier }, index) =>
                    <S.Th key={index} highlighted={highlighted}>{
                      !showOnlyActive && identifier === "active" ? (
                        <Checkbox
                          defaultChecked={users.length !== 0 && checkedUsers.length === users.length}
                          onCheck={(a, b, checked: boolean) => {
                            if (checked) {
                              setCheckedUsers(users.map(user => user.uid));
                              onCheck(users.map(user => user.uid));
                            } else {
                              setCheckedUsers([]);
                              onCheck([]);
                            }
                          }}
                        />
                      ) : field
                    }</S.Th>
                  )}
                </tr>
                </thead>
                <S.TBody>
                {(users && users.length > 0) ? (
                  <>
                    {
                      users.map((row, index) => (
                        <tr key={row.uid}>
                          {headers.map((header, i) => {
                            switch (header.identifier) {
                              case "active":
                                return (
                                  <S.Td
                                    key={header.identifier}
                                    highlighted={header.highlighted}
                                  >
                                    <Checkbox
                                      defaultChecked={checkedUsers.includes(row.uid)}
                                      onCheck={(a, b, checked: boolean) => handleCheckUser(checked, row)}
                                    />
                                  </S.Td>
                                );
                              case "name":
                                return (
                                  <S.Td
                                    key={i}
                                    highlighted={header.highlighted}
                                  >
                                    {row.detail?.name}
                                  </S.Td>
                                );
                              case "login":
                                return (
                                  <S.Td
                                    key={header.identifier}
                                    highlighted={header.highlighted}
                                  >
                                    {row.login}
                                  </S.Td>
                                );
                              default:
                                return (
                                  <S.Td
                                    key={header.identifier}
                                    highlighted={header.highlighted}
                                  >
                                    {row[header.identifier]}
                                  </S.Td>
                                );
                            }
                          })}
                        </tr>
                      ))
                    }
                  </>
                ) : (
                  <tr>
                    <S.NoContent
                      className="no-content"
                      colSpan={headers.length}
                    >
                      <S.BoxConstruction>
                        <div>
                          <IconConstruct />
                        </div>
                        <p>{l("no content.")}</p>
                      </S.BoxConstruction>
                    </S.NoContent>
                  </tr>
                )}
                </S.TBody>
              </S.Table>
            </S.TableContainer>
          </>
        )
      }
    </S.Container>
  );
}

// @ts-ignore
export default EntityItemMembersTable;