import { atom } from "recoil";
import { persistAtomEffect } from "../effects/persistAtom";

export const userGroupsInfoCardVisibleState = atom<boolean>({
  key: 'UserGroupsInfoCardVisibleState',
  default: true,
  effects: [
    persistAtomEffect
  ]
});
