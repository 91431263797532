import React from 'react';
import { Loading, Pagination } from '../index';
import Sdk from 'api.digitalpages.module.sdk.api';
import * as S from './register-table.styled';

export interface IUserReportFrequencyModelWithUserModel extends IUserReportFrequencyModel {
  user: IUserModel;
}

interface IRegisterTableProps {
  registerUid: TGuid;
}

function RegisterTable(props: IRegisterTableProps) {
  const { registerUid } = props;
  const [rows, setRows] = React.useState<IUserReportFrequencyModelWithUserModel[]|null>(null);
  const [users, setUsers] = React.useState<IUserModel[]|null>(null);
  const [data, setData] = React.useState<IPagedResult<IUserReportFrequencyModel>>({
    result: [],
    current_page: 1,
    total_pages: 1,
    total_results: 0,
  });
  const [currentPage, setCurrentPage] = React.useState(1);
  const limit = 50;

  React.useEffect(() => {
    getUsers().then(setUsers);
  }, []);

  React.useEffect(() => {
    setRows(null);
    getData(registerUid, currentPage).then(setData);
  }, [registerUid, currentPage]);

  React.useEffect(() => {
    if (users !== null && data !== null) {
      mapAndFilterUsers(data.result, users).then(setRows);
    }
  }, [users, data]);

  const getData = async (registerUid: TGuid, page: number = 1) => {
    try {
      return await Sdk.dynamic.bridge<IPagedResult<IUserReportFrequencyModel>>(`cms/v1.0/learning/register/uid/${registerUid}/report/frequency/management?page=${page}&page_size=${limit}`);
    } catch (e) {
      console.error(e);
      return {} as IPagedResult<IUserReportFrequencyModel>;
    }
  }

  const isOnlyViewer = (user: IUserModel) => {
    if (user.roles && user.roles.length > 0) {
      const filteredRoles = user.roles?.find(role => {
        return role.type === 'AdministratorGlobal' ||
          role.type === 'ReviewAdmin' ||
          role.type === 'ReviewContributor'
      });
      return filteredRoles === undefined;
    }
    return true;
  }

  const mapAndFilterUsers = async (userReportFrequencies: IUserReportFrequencyModel[], users: IUserModel[]) => {
    const mappedRows: IUserReportFrequencyModelWithUserModel[] = [];

    for (const usersRegister of userReportFrequencies) {
      const user = users.find(user => user.uid === usersRegister.user_uid);
      if (user && isOnlyViewer(user)) {
        mappedRows.push({
          ...usersRegister,
          user,
        })
      }
    }

    return mappedRows;
  };

  const getUsers = async () => {
    try {
      return await Sdk.dynamic.bridge<IUserModel[]>(`auth/v1.0/project/uid/${Sdk.Api.authorization.activeProject.uid}/users`);
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  const completedMoreActivities = (a: IUserReportFrequencyModelWithUserModel, b: IUserReportFrequencyModelWithUserModel): -1|1 => {
    return a.completed_activities_count > b.completed_activities_count ? -1 : 1;
  }

  const getAccessStatus = (status: TUserAccessStatus) => {
    const statusMap: { [status in TUserAccessStatus]: string; } = {
      "Active": "Ativo",
      "Inactive": "Inativo",
    };
    return statusMap[status];
  }

  const getRegisterStatus = (status: TUserRegisterStatus) => {
    const statusMap: { [status in TUserRegisterStatus]: string; } = {
      "Started": "Iniciado",
      "NotAttempted": "Não iniciado",
      "Completed": "Finalizado",
      "Passed": "Aprovado",
      "Abandoned": "-"
    };
    return statusMap[status];
  }
  
  const getProgress = (row: IUserReportFrequencyModelWithUserModel) => {
    let user_progress: string|number = 0;

    if (row.completed_activities_count !== null && row.activities_count !== null) {
      user_progress = ((row.completed_activities_count /
        row.activities_count) * 100).toFixed(0);
    }
    
    return user_progress ? `${user_progress}%` : "0%";
  }
  
  const getDoneActivities = (row: IUserReportFrequencyModelWithUserModel) => {
    return `${row.completed_activities_count || 0}/${row.activities_count || 0}`
  }

  return (
    <>
      {(rows !== null) ? (
        <>
          <div className="info-counter">Mostrando <strong>{rows.length}</strong> resultados</div>

          <S.TableContainerOverflow>
            <table className="rdp-admin-table">
              <thead>
              <tr>
                <th>Usuário</th>
                <th>E-mail</th>
                <th>Status</th>
                <th>Matrícula</th>
                <th>Atividades realizadas</th>
                <th>Progresso(%)</th>
              </tr>
              </thead>
              <tbody>
              {rows.sort(completedMoreActivities)
                .map((row) => (
                  <tr key={row.user_uid}>
                    <td>{row.user.login}</td>
                    <td>{row.user.detail?.email_principal || ""}</td>
                    <td>{getRegisterStatus(row.register_status)}</td>
                    <td>{getAccessStatus(row.access_status)}</td>
                    <td>{getDoneActivities(row)}</td>
                    <td>{getProgress(row)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </S.TableContainerOverflow>

          <Pagination
            currentPage={currentPage}
            registerCount={data.total_results}
            limit={limit}
            onPaginationButtonClick={(page: number) => setCurrentPage(page)}
          />
        </>
      ) : (
        <Loading msg="Carregando informações do relatório..." />
      )}
    </>
  )
}

export default RegisterTable;