import styled from "styled-components";

export const EntityList = styled.div`
 
`;

export const CheckboxContainer = styled.div`
  display: flex;
  position: absolute;
  height: 38px;
  justify-content: center;
  align-items: center;
  left: 0;
`;