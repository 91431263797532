import React from "react";
import * as S from "./date.styled";

interface ITimeProps extends React.InputHTMLAttributes<HTMLInputElement> {
  fullWidth?: boolean;
}

function Date(props: ITimeProps) {
  const {
    type = "datetime-local",
  } = props;
  
  return (
    <React.Fragment>
      <S.Input
        type={type}
        width={props.fullWidth ? "100%" : "auto"}
        {...props}
      />
    </React.Fragment>
  );
}

export default Date;