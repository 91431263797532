import React, { Component } from 'react';
import { LoginService } from '../../services/login';
import './login.scss';
import SDK from 'api.digitalpages.module.sdk.api';
import defaultBackground from '../../assets/images/background.jpg';
import {} from '../../components';
import RDP_UTILS from '../../config/util';
import Loading from '../../components/loading';
import RDP_CONFIG from '../../config/config';
import jwt_decode from "jwt-decode";
import jwt_encode from "jwt-encode";

export default class ViewLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      active_device: 'mobile',
      invalidLogin: '',
      config: null,
      invalidPassword: '',
      msgError: null,
      version: process.env.REACT_APP_VERSION
        ? `v${process.env.REACT_APP_VERSION}`
        : undefined,
    };

    this.getPreloader = this.getPreloader.bind(this);
    this.login = this.login.bind(this);
    this.handleKeyDown = this.login.bind(this);
  }

  async getConfig() {
    this.setState({ preloader: true });

    const response = await fetch('./preferences.json');

    const { forms } = await response.json();

    this.setState({
      preloader: false,
      config: forms.login,
    });
  }

  componentDidMount() {
    this.redirectToHome();
    this.getConfig();
  }

  

  redirectToHome() {
    console.log(SDK.Api.authorization.authenticated);
    if (SDK.Api && SDK.Api.authorization.authenticated) {
      window.location = `${
        window.location.origin + window.location.pathname
      }#/`;
    }
  }

  getPreloader() {
    return this.state.loading ? (
      <div className="rdp-analytics-login-sidebar-preloader">
        <Loading msg="verificando credenciais..." />
      </div>
    ) : (
      ''
    );
  }


  async login(e) {
    if (e !== undefined) e.preventDefault();
    const self = this;
    const login = document.getElementById('rdp-analytics-login-username').value;
    const password = document.getElementById(
      'rdp-analytics-login-password'
    ).value;
    
    
    if (login && password) {
      this.setState({
        loading: true,
      });


      LoginService.login(login, password).then(
        (data) => {
          const secret = 'secret';
          var usrObj = jwt_decode(data.accessToken);
          var oauthObj = {
            "id": usrObj.oid,
            "username": login,
            "email": usrObj.email
          }

          const jwt = jwt_encode(oauthObj, secret);
          
          const d = new Date();
          d.setTime(d.getTime() + (2000*24*60*60*1000));
          let expires = "expires="+ d.toUTCString();
          var domain = window.location.host;
          var subdomain = window.location.hostname.split('.').slice(0, -2).join('.');
          if (subdomain.split(".").length > 1) {
            domain = window.location.host.replace(subdomain, subdomain.split(".")[1]);
          };
          
          document.cookie = "token" + "=" + jwt + ";" + expires + ";domain=" + domain + ";path=/";

          if(data.accessToken){
            const roles = SDK.Api.authorization.user.roles.map((i) => i.type)
            if (roles.includes('AdministratorGlobal')){
              self.setState(
                {
                  loading: false,
                  invalidLogin: '',
                  invalidPassword: '',
                  msgError: null,
                },
                RDP_UTILS.redirectTo,
              );
            } else {
              self.setState(
                {
                  invalidLogin: '',
                  invalidPassword: '',
                  msgError: 'Você não possui as credenciais necessárias para acessar esse sistema.',
                  loading: false,
                })
              SDK.Api.authorization.setActiveProject(null);
              window.localStorage.removeItem("rdp-manager-active-project");
              SDK.Api.authorization.logout();
            }
          } else {
            self.setState({
              invalidLogin: 'field-invalid',
              invalidPassword: 'field-invalid',
              msgError: 'Preencha os campos com suas credenciais.',
              loading: false,
            });
           }
          }
        )
        .catch((error) => {
          self.setState({
            loading: false,
            invalidLogin: 'field-invalid',
            invalidPassword: 'field-invalid',
            msgError: 'Credenciais inválidas, verifique e tente novamente.',
          });
        });
      }
  
  }

  
  render() {
    const { invalidLogin, invalidPassword, msgError, config } = this.state;
    let background = `url(${defaultBackground})`;
    
    if (RDP_CONFIG.customLoginBackground != null) {
      background = `url(${RDP_CONFIG.customLoginBackground})`;
    }
    
    
    const styleInf = { backgroundImage: background };
    
    return (
      <div className="rdp-analytics-login" style={styleInf}>
        <div className="rdp-analytics-login-sidebar">
          <div className="rdp-analytics-login-sidebar-logo" />
          <div className="rdp-analytics-login-sidebar-form">
            <span className="rdp-analytics-login-title">
              {config && config.pageDescription}
            </span>
            <div className="rdp-analytics-login-sidebar-input">
              <label>{config && config.usernameLabel}</label>
              <input
                placeholder={config && config.usernameLabel}
                id="rdp-analytics-login-username"
                className={invalidLogin}
                type="text"
              />
            </div>
            <div className="rdp-analytics-login-sidebar-input">
              <label>{config && config.passwordLabel}</label>
              <form onSubmit={(e) => this.login(e)}>
                <input
                  placeholder="********"
                  id="rdp-analytics-login-password"
                  className={invalidPassword}
                  type="password"
                />
              </form>
            </div>
            {msgError && <span className="msg-invalid"> {msgError}</span>}
            <span
              onClick={() => this.login()}
              className="rdp-analytics-login-sidebar-submit"
            >
              {config && config.sendButtonText}
            </span>
            <div className="rdp-analytics-footer">{this.state.version}</div>
          </div>
          {this.getPreloader()}
        </div>
      </div>
    );
  }
}
