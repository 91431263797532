import React, { Suspense, useEffect } from "react";
import * as S from "./list-terms.styled";
import { Breadcrumb, Header, Sidebar } from "../../components";
import Loading from "../../components/loading";
import TermsTable from "./terms-table";
import {useHistory} from "react-router-dom";
import SDK from "api.digitalpages.module.sdk.api";

interface IListTermsProps extends IConsumerContext {}

function ListTerms(props: IListTermsProps) {
  const history = useHistory();
  const [consumer] = React.useState(props.consumer);
  const [termsConnector, setTermsConnector] = React.useState<IConnectorModel>();
  const [isLoading, setIsLoading] = React.useState(true);
  
  useEffect(() => {
    if (consumer && consumer.defineRoute) {
      consumer.defineRoute({
        name: "Termos de aceite",
        uri: "/terms"
      });
      
      getConnector();
    }
  }, []);

  const getConnector = async () => {
    const directory: IDirectoryModel = await SDK.dynamic.bridge(`auth/v1.0/directory/uid/${SDK.Api.authorization.activeProject.directory.uid}`, null, "GET")

    if (directory) {
      const termsPolicies = directory.connectors.find((connector: { type: string; }) => connector.type === "Policies_1");
      if (termsPolicies) {
        setTermsConnector(termsPolicies);
        return termsPolicies;
      }
    }

    setIsLoading(false);
    return undefined;
  }
  
  const handleNewTermClick = () => {
    history.push("/terms/new");
  }
  
  return (
    <S.Container>
      <Header />

      <Sidebar {...consumer} />

      <Breadcrumb
        {...consumer}
        label="Termos de aceite"
        currentBreadcrumbTitle="Termos de aceite"
        newOption={termsConnector? handleNewTermClick : undefined}
      />

      <S.Content className="rdp-admin-view-terms">
        <S.Card>
          <Suspense fallback={<Loading />}>
           <TermsTable 
             termsConnector={termsConnector}
             isLoading={isLoading}
             setIsLoading={setIsLoading}
           />
          </Suspense>
        </S.Card>
      </S.Content>
    </S.Container>
  );
}

export default ListTerms;