import styled, {css} from "styled-components";

export const Container = styled.div`
  display: block;
  overflow: hidden;
  min-height: 800px;
`;

export const TableContainer = styled.div`
  overflow: auto;
`;

export const PaginationContainer = styled.div`
  margin: 20px;
`

export const Th = styled.th<{
  highlighted?: boolean;
}>`
  ${props => props.highlighted && css`
    background-color: #EFEFEFFF;
    color: #764ab0;
  `}
`

export const Td = styled.td<{
  highlighted?: boolean;
}>`
  ${props => props.highlighted && css`
    background-color: #EFEFEFFF;
    color: #764ab0;
  `}
`

export const NoContent = styled.td`
  height: 300px;
`;

export const Table = styled.table`
  &.rdp-admin-table {
    tr {
      td.no-content {
        text-align: center;
        vertical-align: middle;
      }
    }
  }
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 5px;
`;

export const NoContentContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  bottom: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const BoxConstruction = styled.div`
  display: block;
  width: 250px;
  height: 230px;
  margin: 40px auto;
  border: 2px dashed #d2d5dd;
  border-radius: 4px;

  div {
    display: block;
    background-color: #764ab0;
    border-radius: 50%;
    width: 148px;
    height: 148px;
    margin: 20px auto;
    padding-top: 32px;
    text-align: center;
  }

  p {
    width: 100%;
    text-align: center;
    font-family: "Inter-Bold",sans-serif;
  }
`;