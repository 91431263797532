import React, { useState, useEffect } from 'react';
import { Header, Sidebar, Breadcrumb } from '../../components';
import { GenericList } from '../../builders/list';
import { Footer } from '../../components';
import Loading from '../../components/loading';
import Sdk from 'api.digitalpages.module.sdk.api';
import JSZip from 'jszip';
import $ from 'jquery';
import './edit-certificate.scss';
// import JSZipUtils from 'jszip-utils';
import Components from "rdp-builder-components"

export default function ViewEditCertificate({ consumer, entity, history, match }) {
  const [elements, setElements] = useState([]);
  const [messages, setMessages] = useState({});
  const [deleting, setDeleting] = useState(false);
  const [config, setConfig] = useState({});
  const [preloader, setPreloader] = useState(true);
  const [preferences, setPreferences] = useState(null);
  const [fullPreloader, setFullPreloader] = useState(false);
  const [structure, setStructure] = useState(null);
  const [manifest, setManifest] = useState(null);

  useEffect(() => {
    getStructure();
    getManifest();
  }, []);

  function onBack() {
    consumer.defineRoute({ name: 'Certificados', uri: '/certificates' });
  }

  function updateFile(structureModel) {
    var zip = new JSZip();
    var manifestModel = manifest;
    manifestModel.name = structureModel.name;
    manifestModel.metadata.title = structureModel.name;

    zip.file("manifest.json", JSON.stringify(manifestModel));
    zip.file("structure.json", JSON.stringify(structureModel));
    zip.file("index.html", '<!DOCTYPE html> <html lang="pt-br"> <head> <meta charset="UTF-8"> <meta http-equiv="X-UA-Compatible" content="IE=edge"> <meta name="viewport" content="width=device-width, initial-scale=1.0"> <title></title> <style> body { margin: 0; } #iframe { border: 0; position: absolute; top: 0px; bottom: 0px; left: 0px; right: 0px; width: 100%; height: 100%;} </style> </head> <body> <iframe id="iframe"></iframe> <script> var renderAppUrl = window.location.origin + window.location.pathname; var structureUrl = window.location.href.replace("template.html", "structure.json").replace("index.html", "structure.json").split("?")[0]; var manifestUrl = window.location.href.replace("template.html", "manifest.json").replace("index.html", "manifest.json").split("?")[0]; var iframe = document.getElementById("iframe"); iframe.setAttribute("src", "https://pocs.digitalpages.com.br/certificate-player/" + window.location.search); iframe.addEventListener("load", function() { fetch(structureUrl).then((resp) => resp.json()).then(function(structure) { iframe.contentWindow.postMessage({"structure": structure}, "*"); }); fetch(manifestUrl).then((resp) => resp.json()).then(function(manifest) { iframe.contentWindow.postMessage({"manifest": manifest}, "*"); }); }); </script> </body> </html>')
    zip.generateAsync({type: "blob"}).then(zipBlob => {
      var url = `${Sdk.Api.domain}/storage/v1.0/content?uid=${match.params.contentUid}`;
      var method = "PUT";
      var fd = new FormData();

      fd.append('file', zipBlob, "Certificado.rdptemplate")

      $.ajax({
        url: url,
        type: method,
        data: fd,
        contentType: false,
        processData: false,
        headers: {
          "Authorization": "Bearer " + Sdk.Api.authorization.credential.accessToken,
          "Project-Key": Sdk.Api.authorization.projectKey,
          "Api-Env": Sdk.Api.enviroment
        },
        success: function (response) {
          // var newId = response[0].uid;
          // history.push(`certificates/${newId}`);
          setFullPreloader(false);
          consumer.defineRoute({ name: 'Certificados', uri: '/certificates' });
        },
        error: function (error) {
          console.log(error)
        }
      });
    })
  }

  function onSaveCertificate(structureModel) {
    setFullPreloader(true);
    updateFile(structureModel)
    // console.log(match.params.contentUid);
    // console.log(structureModel)
    // updateStructure(structureModel);
  }

  function blobToFile(theBlob, fileName) {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }

  function createStructureFile(structureModel) {
    var myBlob = new Blob([JSON.stringify(structureModel)], { type: "application/json" });
    return blobToFile(myBlob, "structure.json");
  }

  function updateStructure(structureModel) {
    var uid = match.params.contentUid;
    var fd = new FormData();
    var files = createStructureFile(structureModel);

    fd.append('file', files, "structure.json")

    $.ajax({
      url: `${Sdk.Api.domain}/storage/v1.0/content/path/structure.json?manual_project_uid=${Sdk.Api.authorization.activeProject.uid}&uid=${uid}&access_token=${Sdk.Api.authorization.credential.accessToken}`,
      type: "PUT",
      data: fd,
      contentType: false,
      processData: false,
      headers: {
        "Authorization": "Bearer " + Sdk.Api.authorization.credential.accessToken,
        "Project-Key": Sdk.Api.authorization.projectKey,
        "Api-Env": Sdk.Api.enviroment
      },
      success: function() {
        updateManifest(structureModel);
      }
    });
  }

  function createManifestFile(manifestModel) {
    var myBlob = new Blob([JSON.stringify(manifestModel)], { type: "application/json" });
    return blobToFile(myBlob, "manifest.json");
  }

  function updateManifest(structureModel) {
    var manifestModel = manifest;
    manifestModel.name = structureModel.name;
    manifestModel.metadata.title = structureModel.name;

    var uid = match.params.contentUid;
    var fd = new FormData();
    var files = createManifestFile(manifestModel);

    fd.append('file', files, "manifest.json")

    $.ajax({
      url: `${Sdk.Api.domain}/storage/v1.0/content/path/manifest.json?manual_project_uid=${Sdk.Api.authorization.activeProject.uid}&uid=${uid}&access_token=${Sdk.Api.authorization.credential.accessToken}`,
      type: "PUT",
      data: fd,
      contentType: false,
      processData: false,
      headers: {
        "Authorization": "Bearer " + Sdk.Api.authorization.credential.accessToken,
        "Project-Key": Sdk.Api.authorization.projectKey,
        "Api-Env": Sdk.Api.enviroment
      },
      success: function() {
        setFullPreloader(false);
        consumer.defineRoute({ name: 'Certificados', uri: '/certificates' });
      }
    });
  }

  async function getStructure() {
    if (Sdk.Api.authorization.authenticated) {
      var uid = match.params.contentUid;
      var model = await Sdk.dynamic.bridge(`storage/v1.0/content/path/structure.json?uid=${uid}&access_token=${Sdk.Api.authorization.credential.accessToken}&nocache=true`)
      console.log("STRUCTURE", structure)
      setPreloader(false);
      setStructure(model);
    }
  }

  async function getManifest() {
    if (Sdk.Api.authorization.authenticated) {
      var uid = match.params.contentUid;
      var model = await Sdk.dynamic.bridge(`storage/v1.0/content/path/manifest.json?uid=${uid}&access_token=${Sdk.Api.authorization.credential.accessToken}&nocache=true`)
      console.log("MANIFEST", structure)
      setManifest(model);
    }
  }

  return <>
    
    {fullPreloader &&
      <div className="rdp-admin-certificate-edit-preloader"></div>
    }
    <Header />
    <Sidebar
      defineGroup={
        consumer ? consumer.defineGroup : null
      }
      defineRoute={
        consumer ? consumer.defineRoute : null
      }
      groups={consumer ? consumer.groups : null}
    />
    <Breadcrumb
      currentRoute={consumer ? consumer.currentRoute : null}
      defineRoute={consumer ? consumer.defineRoute : null}
    />
    <div class="rdp-admin-certificate-edit-container">
      {preloader &&
        <Loading msg="Carregando certificado..." />
      }
      {structure &&
        <Components.Builders.CertificatesBuilder
          defaultItems={structure.items}
          defaultBackgroundImage={structure.background_image}
          defaultName={structure.name}
          defaultOrientation={structure.orientation}
          defaultSize={structure.size}
          onBack={() => onBack()}
          onSave={(structureModel) => onSaveCertificate(structureModel)}
        />
      }
    </div>
    <Footer />
  </>
}
