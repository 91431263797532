import styled from "styled-components";

export const Input = styled.input`
  border: 1px solid #d2d5dd;
  border-radius: 4px;
  height: 44px;
  padding: 10px;
  width: 100%;
  background-color: #fff;
  box-shadow: 1px 1px 6px 0 #88888814;
`;