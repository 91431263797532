import styled from "styled-components";

export const MainContainer = styled.div``;

export const Content = styled.div`
  background: #f5f7f8;
  position: absolute;
  top: 135px;
  left: 230px;
  bottom: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 15px;
  width: auto;
`;

export const Card = styled.div`
  padding-top: 20px!important;
  border-radius: 4px;
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 0 8px 16px 0 #00376d1a;
`;

export const CertificateModalContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.6);
`;

export const CertificateModalOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
`;

export const CertificateModal = styled.div`
  // position: absolute;
  // width: 1080px;
  // top: 80px;
  // bottom: 50px;
  // left: 50%;
  // margin-left: -540px;
  // background-color: #FFF;
  // z-index: 1;
  // border-radius: 3px;
  position: absolute;
  width: 1270px;
  top: 80px;
  bottom: 50px;
  left: 50%;
  margin-left: -635px;
  background-color: #FFF;
  z-index: 1;
  border-radius: 3px;
`;

export const CertificateModalHeader = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 50px;
  z-index: 1;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
`;

export const CertificateModalHeaderSaveBtn = styled.button`
  background: #764ab0;
  color: #FFF;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  padding: 12px 30px;
  border-radius: 5px;
  margin-top: 7px;
  margin-left: 15px;
`

export const CertificateModalClose = styled.span`
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
  text-align: center;

  svg {
    width: 26px;
    height: 100%;
  }
`;

export const CertificateModalBody = styled.div`
  position: absolute;
  top: 50px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const CertificateModalItems = styled.ul`
  margin: 15px;
  margin-bottom: 28px;
  background-size: 195px;
`;

export const CertificateModalItem = styled.li`
  border: 0.5px solid #000;
  border-bottom: 0px;

  &:last-child {
    border-bottom: 0.5px solid #000;
  }
`;

export const CertificateModalItemField = styled.div`
  width: 25%;
  display: inline-block;
  border: 0.5px solid #000;
  border-bottom: 0px;
  padding: 20px;
  font-size: 10px;
  font-weight: bold;
`;

export const CertificateModalItemFieldHeader = styled.div`
  width: 25%;
  display: inline-block;
  border: 0.5px solid #000;
  border-bottom: 0px;
  padding: 20px;
  background: #e2e2e2;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 10px;
  padding-right: 0px;
  color: #000;
`;

export const CertificateContainer = styled.div`
  border: 1px solid #000;
  margin: 15px;
  position: relative;
`;

export const CertificateHeader = styled.div`
  border: 1px solid #000;
  padding: 25px;
  margin: 15px;
  position: relative;
`;

export const CertificateLabel = styled.span`
  display: block;
  color: #000;
  margin-bottom: 12px;
  font-size: 18px;

  b {
    font-weight: bold;
  }
`;

export const CertificateHeaderLogo = styled.img`
  width: 280px;
`;

export const CertificateHeaderTitle = styled.span`
  font-weight: bold;
  color: #000;
  font-family: Arial;
  font-size: 22px;
  position: absolute;
  right: 20px;
  top: 32px;
`;

export const CertificatePage = styled.span`
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-weight: bold;
`;