import styled, {css} from "styled-components";

export const Container = styled.div`
  display: block;
  overflow: hidden;
  min-height: 600px;
  max-height: 800px;
  overflow-y: auto;
`;

export const TableContainer = styled.div`
  overflow: auto;
`;

export const PaginationContainer = styled.div`
  margin: 20px;
`

export const Th = styled.th<{
  highlighted?: boolean;
}>`
  ${props => props.highlighted && css`
    background-color: #EFEFEFFF;
    color: #764ab0;
  `}
`

export const Td = styled.td<{
  highlighted?: boolean;
}>`
  ${props => props.highlighted && css`
    background-color: #EFEFEFFF;
    color: #764ab0;
  `}
`

export const NoContent = styled.td`
  height: 300px;
`;

export const Table = styled.table`
  &.rdp-admin-table {
    tr {
      td.no-content {
        text-align: center;
        vertical-align: middle;
      }
    }
  }
`;

export const TBody = styled.tbody`
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 5px;
`;

export const NoContentContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  bottom: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const BoxConstruction = styled.div`
  display: block;
  width: 250px;
  height: 230px;
  margin: 40px auto;
  border: 2px dashed #d2d5dd;
  border-radius: 4px;

  div {
    display: block;
    background-color: #764ab0;
    border-radius: 50%;
    width: 148px;
    height: 148px;
    margin: 20px auto;
    padding-top: 32px;
    text-align: center;
  }

  p {
    width: 100%;
    text-align: center;
    font-family: "Inter-Bold",sans-serif;
  }
`;

export const Checkbox = styled.span<{
  primaryColor?: string
}>`
  --primary-color: ${props => props.primaryColor || "#00643e"};
  
  width: 60px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const Input = styled.input`
  appearance: none;
  background-color: transparent;
  margin: 0;
  cursor: pointer;

  font: inherit;
  color: transparent;
  width: 20px;
  height: 20px;
  border: 1px solid #ddd;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;

  &:before {
    position: absolute;
    top: 11px;
    left: 7px;
    content: "";
    width: 3px;
    height: 0;
    transform: rotate(140deg);
    transition: all 0.2s ease-out;
    background: var(--primary-color);
    opacity: 0;
  }

  &:checked:before,
  &[checked=true]:before,
  &[checked=checked]:before {
    top: 7px;
    left: 4px;
    height: 7px;
    opacity: 1;
  }
  
  &:after {
    content: "";
    position: absolute;
    top: 11px;
    left: 6px;
    width: 3px;
    height: 0;
    transform: rotate(50deg);
    transition: all 0.2s ease-in;
    transition-delay: 50ms;
    background: var(--primary-color);
    opacity: 0;
  }
  
  &:checked:after,
  &[checked=true]:after,
  &[checked=checked]:after {
    top: 3px;
    left: 10px;
    height: 10px;
    opacity: 1;
  }

  @keyframes grow-after {
    0% {
      height: 0;
    }
    100% {
      height: 10px;
    }
  }
`;