import React, { Suspense, useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import Sdk from "api.digitalpages.module.sdk.api";
import { GroupService } from "../services/group";
import Loading from "../components/loading";
import RDP_UTILS from "../config/util";
import { useRecoilState } from "recoil";
import { currentGroupState } from "../recoil/atoms/currentGroupState";
import { groupsState } from "../recoil/atoms/groupsState";
import useCurrentRoute from "../hooks/useCurrentRoute";

const ContextRoute = ({ component, ...rest }) => {
  const history = useHistory();
  const Component = component;
  const [currentRoute, setCurrentRoute] = useCurrentRoute(rest.path);
  const [currentGroup, setCurrentGroup] = useRecoilState(currentGroupState);
  const [groups, setGroups] = useRecoilState(groupsState);

  useEffect(() => {
    Sdk.authorization.addActiveProjectListen(async () => {
      const groupsUpdated = await GroupService.getGroups();
      updateGroups(groupsUpdated);
    });

    GroupService.getGroups().then((data) => updateGroups(data));
  }, []);

  /** @param {{name: string, uri: string, icon: string, subItems?: {name: string, uri: string, icon: string}[]}[]} groupsUpdated */
  const updateGroups = (groupsUpdated) => {
    const url = rest.path;
    
    if (typeof groupsUpdated === 'object' && groupsUpdated.length > 0) {
      setGroups(groupsUpdated);
      const group = groupsUpdated.find((group) => {
        const inSubItem = (group.subItems || []).find((item) => url === item.uri) !== undefined
        if (group.uri) {
          const groupPath = group.uri.split("/")[1];
          const urlPath = url.split("/")[1];
          return url === group.uri || urlPath === groupPath
        }
        return inSubItem;
      });
      
      setCurrentGroup(group);
      
      if (group && group.subItems) {
        const route = (group.subItems || []).find((route) => url === route.uri);
        if (route) {
          setCurrentRoute(route);
        }
      } else if (group) {
        setCurrentRoute(group);
      }
    }
  };

  const defineRoute = (route) => {
    if (!Sdk.authorization.authenticated) {
      RDP_UTILS.redirectTo("login");
    } else {
      setCurrentRoute(route);
      if (route) history.push(route.uri);
    }
  };

  return (
    <Route
      {...rest}
      render={(props) => (
        <Suspense fallback={
          <Loading
            globalLoading
            msg="RDP Manager - Preparando a Plataforma ..."
          />
        }>
          <Component
            {...props}
            consumer={{
              currentRoute,
              currentGroup,
              groups,
              defineGroup: setCurrentGroup,
              defineRoute
            }}
          />
        </Suspense>
      )}
    />
  );
};

export default ContextRoute;
