import SDK from "api.digitalpages.module.sdk.api";
import ICourse from "../@types/ICourse";
import ICourseRegister from "../@types/ICourseRegister";
import ICourseUserRegisterReportScore from "../@types/ICourseUserRegisterReportScore";

const getBasicHeader = () => ({
  "Project-Key": SDK.Api.projectKey,
  "Api-Env": SDK.Api.enviroment,
  "Authorization": `Bearer ${SDK.authorization.credential.accessToken}`,
  "Content-Type": "application/json"
});

const learningManagementEndpoint = {
  coursesManagement: (learningConnectorUid: Guid, searchParams?: IPaginationQuery, options?: {init?: RequestInit}) => {
    const init: RequestInit = {
      method: "GET",
      headers: getBasicHeader()
    }
    
    const url = new URL(`${SDK.Api.domain}/cms/v1.0/learning/connector/uid/${learningConnectorUid}/courses/management`);
    
    if (searchParams) {
      // @ts-ignore
      Object.keys(searchParams).forEach((key: keyof IPaginationQuery) => url.searchParams.append(key, String(searchParams[key])));
    }

    return fetch(url.toString(), options?.init || init).then(res => res.json() as Promise<IPagedResult<ICourse>>);
  },
  reportCourseActivityScoreListManagement: (registerUid: Guid, searchParams?: IPaginationQuery, options?: {init?: RequestInit}) => {
    if (!registerUid) {
      return;
    }
    
    const init: RequestInit = {
      method: "GET",
      headers: getBasicHeader()
    }

    const url = new URL(`${SDK.Api.domain}/cms/v1.0/learning/register/uid/${registerUid}/report/scores/management`);

    if (searchParams) {
      // @ts-ignore
      Object.keys(searchParams).forEach((key: keyof IPaginationQuery) => url.searchParams.append(key, String(searchParams[key])));
    }

    return fetch(url.toString(), options?.init || init).then(res => res.json() as Promise<IPagedResult<ICourseUserRegisterReportScore>>);
  },
  courseRegister: (courseUid: Guid, options?: {init?: RequestInit}) => {
    if (!courseUid) {
      return;
    }
    
    const init: RequestInit = {
      method: "GET",
      headers: getBasicHeader()
    }

    return fetch(`${SDK.Api.domain}/cms/v1.0/learning/course/uid/${courseUid}/management/registers`, options?.init || init).then(res => res.json() as Promise<ICourseRegister[]>);
  },
}
export default {
  learningManagementEndpoint,
}