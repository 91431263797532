import Sdk from 'api.digitalpages.module.sdk.api';
import { objectToCsvFile } from './objectToCsvFile';

const getAccessStatus = (status: TUserAccessStatus) => {
  const statusMap: { [status in TUserAccessStatus]: string; } = {
    "Active": "Ativo",
    "Inactive": "Inativo",
  };
  return statusMap[status] || "-";
}

const getRegisterStatus = (status: TUserRegisterStatus) => {
  const statusMap: { [status in TUserRegisterStatus]: string; } = {
    "Started": "Iniciado",
    "NotAttempted": "Não iniciado",
    "Completed": "Finalizado",
    "Passed": "Aprovado",
    "Abandoned": "-"
  };
  return statusMap[status] || "-";
}
const getData = async (frequencies: IUserReportFrequencyModel[], users: IUserModel[], exportAllUserData: boolean) => {
  let namedRows: Array<any> = [];

  const directory: IDirectoryModel = await Sdk.dynamic.bridge(`auth/v1.0/directory/uid/${Sdk.Api.authorization.activeProject.directory.uid}`, null, "GET")
  let props = directory.config.user_required_properties;

  for (let i = 0; i < frequencies.length; i++) {
    var frequency = frequencies[i];

    let user = users.find((user) => user.uid === frequency.user_uid);

    if (user && user.roles) {
      let isStudent = true;
      for (const role of user.roles) {
        if (role.type === "ReviewContributor" || role.type ===
          "ReviewAdmin" || role.type === "AdministratorGlobal") {
          isStudent = false;
          break;
        }
      }

      if (!isStudent) continue;
    }

    if (user) {
      let user_progress: number|string = 0;

      if (frequency.completed_activities_count !== null && frequency.activities_count !== null) {
        user_progress = ((frequency.completed_activities_count /
          frequency.activities_count) * 100).toFixed(0)
      }

      var namedColumns: {[k: string]: any} = {};

      namedColumns["Uid"] = user.uid;
      namedColumns["Aluno"] = user.detail?.name || "";
      namedColumns["Login"] = user.login;
      namedColumns["Curso"] = frequency.course_title;
      namedColumns["Turma"] = frequency.register_name;
      namedColumns["Status"] = getRegisterStatus(frequency.register_status);
      namedColumns["Status da Matrícula"] = getAccessStatus(frequency.access_status);
      namedColumns["Atividades Realizadas"] = `${frequency.completed_activities_count || 0} de ${frequency.activities_count || 0}`;
      namedColumns["Progresso"] = user_progress ? `${user_progress}%` : "0%";
      namedColumns["Data de início"] = formatDate(frequency.start_course);
      namedColumns["Data de conclusão"] = formatDate(frequency.end_course);


      if (directory.config.user_required_properties.length > 0 && exportAllUserData) {
        for (var j = 0; j < props.length; j++) {
          namedColumns[props[j].name] = user.user_properties?.find(item => item.name === props[j].name)?.value;
        }
      }

      namedRows.push(namedColumns);
    }
  }

  return namedRows;
}

const formatDate = (str: String) => {
  if (str === null) return "-";
  var items = str.split("T")[0].split("-");
  return `${items[2]}/${items[1]}/${items[0]}`;
}

export default async function downloadUserFrequencyCSV(registerUid: TGuid, exportAllUserData: boolean) {
  try {
    const { user } = Sdk.authorization;
    var filter = "";

    if (user.roles) {
      for (const role of user.roles) {
        if (
          role.type ==="TeamAdmin"
        ) {
          filter = "&filter_entities=true"
        }
      }
    }
    const resp: IPagedResult<IUserReportFrequencyModel> = await Sdk.dynamic.bridge(`cms/v1.0/learning/register/uid/${registerUid}/report/frequency/management?page_size=100000000${filter}`);
    const users: IUserModel[] = await Sdk.dynamic.bridge(`auth/v1.0/project/uid/${Sdk.Api.authorization.activeProject.uid}/users`);
    const directory: IDirectoryModel = await Sdk.dynamic.bridge(`auth/v1.0/directory/uid/${Sdk.Api.authorization.activeProject.directory.uid}`, null, "GET")

    if (directory.config.user_required_properties.length > 0 && exportAllUserData) {
      for (let i = 0; i < users.length; i++) {
        const tempUser: IUserModel = await Sdk.dynamic.bridge(`auth/v1.0/user/uid/${users[i].uid}?include_properties=true`);
        users[i].user_properties = tempUser.user_properties;
      }
    }

    if (resp && users) {
      let rows = await getData(resp.result, users, exportAllUserData);
      let file = objectToCsvFile(rows);
      let nowDate = new Date();
      let fileName = `relatorio_de_frequencia_${nowDate.getDate()}_${nowDate.getMonth()}_${nowDate.getFullYear()}.csv`;
      let anchorElement = document.createElement("a")
      anchorElement.href = URL.createObjectURL(file);
      anchorElement.download = fileName;
      anchorElement.click();
    }
  } catch (error) {
    console.error(error);
  }
}