import SDK from 'api.digitalpages.module.sdk.api';

export const useTerms = () => {
  const loadEntities = async (
    entity: IAppFormPreference<ITermsModel>,
    defaultValues = [] as any[]
  ) => {
    if (entity) {
      const entitiesCp = { ...entity };
      let idList = [];

      const res: any = await SDK.dynamic.bridge(
        `auth/v1.0/entity/management/schema/available?type=security`,
        null,
        'GET'
      );

      for (const [idx, entity] of res.entries()) {
        const tree = await SDK.dynamic.bridge(
          `auth/v1.0/entity/schema/uid/${entity.uid}/items`,
          null,
          'GET'
        );

        const schemaInfo: any = await SDK.dynamic.bridge(
          `auth/v1.0/entity/schema/uid/${entity.uid}`,
          null,
          'GET'
        );

        const newTree = orderByRows(tree);

        entitiesCp.fields.push({
          id: `container-category-${idx}`,
          group: entity.name,
          schema_properties: schemaInfo.properties,
          required: false,
          defaultValues,
          type: 'input-tree-checkbox',
          config: {},
          field: `entities-${idx}`,
          options: newTree,
        });

        idList.push(`container-category-${idx}`);
      }

      return {
        form: entitiesCp,
        uids: idList,
      };
    }
  };

  const getGroupAuthorizationReferences = (references = [] as any[]) => {
    return {
      users: references.filter((item) => item.type === 'User'),
      entities: references.filter((item) => item.type === 'Entity'),
    };
  };

  const saveAuthorizationUpdateTermFlow = async (
    connector: string,
    termUid: string,
    authorization: any
  ) => {
    try {
      if (!authorization || !authorization.references) return;
      if (!authorization.uid) {
        await createAuthorization(connector, termUid, authorization.references);
        return;
      }
      await updateAuthorization(connector, authorization);
    }

    catch(err) {
      console.log(err)
    }
  };

  const createAuthorization = async (
    connector: string,
    termUid: string,
    references: any[]
  ) => {
    try {
      await SDK.dynamic.bridge(
        `auth/v1.0/policies/connector/uid/${connector}/term/uid/${termUid}/authorization`,
        {
          name: `term-authorization-${termUid}`,
          references: references,
        },
        'POST'
      );
    }
    catch(err) {
      console.log(err)
    }
  };

  const updateAuthorization = async (connector: string, authorization: any) => {
    await SDK.dynamic.bridge(
      `auth/v1.0/policies/connector/uid/${connector}/term/authorization/uid/${authorization.uid}`,
      authorization,
      'PUT'
    );
  };

  return {
    loadEntities,
    createAuthorization,
    updateAuthorization,
    getGroupAuthorizationReferences,
    saveAuthorizationUpdateTermFlow,
  };
};

function orderByRows(tree: any) {
  const orderedObj = [...tree].sort((a, b) => a.row - b.row);
  return orderedObj;
}
