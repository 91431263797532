import styled from "styled-components";

export const Container = styled.div``;

export const Content = styled.div`
  background: #f5f7f8;
  position: absolute;
  top: 135px;
  left: 230px;
  bottom: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 15px;
  width: auto;
`;