import React, { useEffect, useState } from "react";
import $ from "jquery";
import uuidv1 from "uuid/v1";
import { Breadcrumb, Header, Sidebar } from "../../components";
import Sdk from "api.digitalpages.module.sdk.api";
import "./list-catalog.scss";
import { DAMService } from "../../services/dam";
import { getConnectorFromArray } from "../../utils/getConnectorFromArray";

export default function ViewCatalogList({ consumer, entity, history }) {
  const [config, setConfig] = useState({});
  const [preferences, setPreferences] = useState(null);
  const [courses, setCourses] = useState(null);
  const [tempToken, setTempToken] = useState(null);
  const [currentCourseCopy, setCurrentCourseCopy] = useState(null);
  const [currentCourseCopyNodesTotal, setCurrentCourseCopyNodesTotal] = useState(0);
  const [currentCourseCopyNodesCopied, setCurrentCourseCopyNodesCopied] = useState(0);
  const [currentCourseCopyActivitiesTotal, setCurrentCourseCopyActivitiesTotal] = useState(0);
  const [currentCourseCopyActivitiesCopied, setCurrentCourseCopyActivitiesCopied] = useState(0);
  const [principalProject, setPrincipalProject] = useState(null);
  const [initCopy, setInitCopy] = useState(false);
  const [isCopying, setIsCopying] = useState(false);

  useEffect(() => {
    configTable();
    principalSignIn()

    consumer.defineRoute({ name: 'Catálogo de cursos', uri: '/catalog' });
  }, []);

  useEffect(() => {
    if ((currentCourseCopyActivitiesTotal === currentCourseCopyActivitiesCopied) && (currentCourseCopyNodesTotal === currentCourseCopyNodesCopied) && currentCourseCopyActivitiesTotal > 0 && currentCourseCopyNodesTotal > 0) {
      setIsCopying(false);
    }
  }, [
    currentCourseCopyActivitiesTotal,
    currentCourseCopyActivitiesCopied,
    currentCourseCopyNodesTotal,
    currentCourseCopyNodesCopied
  ])

  async function configTable() {
    const res = await fetch('./preferences.json');
    const prefs = await res.json();
    setPreferences(prefs);

    const Configuration = {
      options: {
        hasCheckbox: false,
        hasEdit: true,
        hasDelete: true,
      }
    };

    setConfig(Configuration);
  }

  function getDomain() {
    return Sdk.Api.enviroment === "production" ? "https://composer.euconquisto.com" : "https://composer-hmg.euconquisto.com"
  }

  function principalSignIn() {
    var login = "admin.principal@euconquisto.com";
    var password = "37493749Q!W@e/#r4t5y6apresentacao";

    $.ajax({
      url: `${Sdk.domain}/auth/v1.0/signin/uid`,
      type: "POST",
      dataType: "json",
      data: JSON.stringify({
        login: login,
        password: password,
      }),
      headers: {
        "Project-Key": Sdk.projectKey,
        "Api-Env": Sdk.Api.enviroment,
      },
    }).then(
      function (data) {
        setTempToken(data.access_token);
        principalGetProjects(data.access_token);
      },
      function (error) {
        console.error(error);
      }
    );
  }

  function principalGetProjects(token) {
    $.ajax({
      url: `${Sdk.domain}/auth/v1.0/projects`,
      type: "GET",
      headers: {
        "Authorization": `Bearer ${token}`,
        "Project-Key": Sdk.projectKey,
        "Api-Env": Sdk.Api.enviroment
      },
    }).then(
      function (projects) {
        principalGetProject(token, projects[0]);
      },
      function (error) {
        console.error(error);
      }
    );
  }

  function principalGetProject(token, project) {
    $.ajax({
      url: `${Sdk.domain}/auth/v1.0/project/uid/${project.uid}`,
      type: "GET",
      headers: {
        "Authorization": `Bearer ${token}`,
        "Project-Key": Sdk.projectKey,
        "Api-Env": Sdk.Api.enviroment
      },
    }).then(
      function (project) {
        setPrincipalProject(project);
        principalGetCourses(token, project);
      },
      function (error) {
        console.error(error);
      }
    );
  }

  function principalGetCourses(token, project) {
    var connectorUid = project.connectors.filter(item => item.type === "Learning_1")[0].uid;
    $.ajax({
      url: `${Sdk.domain}/cms/v1.0/learning/connector/uid/${connectorUid}/courses/management?page_size=10000`,
      type: "GET",
      headers: {
        "Authorization": `Bearer ${token}`,
        "Project-Key": Sdk.projectKey,
        "Api-Env": Sdk.Api.enviroment
      },
    }).then(
      function (data) {
        setCourses(data.result)
      },
      function (error) {
        console.error(error);
      }
    );
  }

  const getBase64FromUrl = async (url) => {
    try {
      const data = await fetch(url);
      const blob = await data.blob();
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data);
        }
      });
    } catch (error) {
      return null;
    }
  }

  const b64toBlob = (base64, type = 'application/octet-stream') => 
    fetch(base64).then(res => res.blob())

  async function getThumbUrl(file, parentUid) {
    const response = await DAMService.uploadFileAxios(file, null, "ContentManager");
    const thumbFile = await updateFileMetadata(response.data[0], parentUid);
    if (thumbFile) {
      return await DAMService.getPublicUrl(thumbFile.content_uid);
    }
  }

  async function copyCourse(uid) {
    setInitCopy(true);
    setIsCopying(true);
    var courseData = await $.ajax({
      url: `${Sdk.domain}/cms/v1.0/learning/course/uid/${uid}/management`,
      type: "GET",
      headers: {
        "Authorization": `Bearer ${tempToken}`,
        "Project-Key": Sdk.projectKey,
        "Api-Env": Sdk.Api.enviroment
      },
    });

    var thumb = await getBase64FromUrl(courseData.thumb_url);
    
    var model = {
      "description": courseData.description,
      "internal_name": courseData.internal_name,
      "image": thumb,
      "title": courseData.title
    };

    try {
      const { connectors } = Sdk.authorization.activeProject;
      const learningConnector = getConnectorFromArray(connectors, "Learning_1", "ContentManager");
      if (!learningConnector) return;

      var blob = await b64toBlob(model.image);
      const searchParams = new URLSearchParams(courseData.thumb_url.split("?")[1]);
      const thumbUid = searchParams.get("uid");
      var thumb = await getThumbUrl(blob, thumbUid);

      var course = {
        "description": model.description,
        "entities": [],
        "internal_name": model.internal_name,
        "node_properties": [
          {
            "name": "title",
            "type": "Text",
            "regex_validation": ""
          },
          {
            "name": "description",
            "type": "Text",
            "regex_validation": ""
          },
          {
            "name": "thumb",
            "type": "Text",
            "regex_validation": ""
          }
        ],
        "properties": [
          {
            "name": "catalog",
            "type": "Bool",
            "regex_validation": "",
            "value": "true"
          },
          {
            "name": "parent_content_uid",
            "type": "Guid",
            "regex_validation": "",
            "value": uid
          }
        ],
        "thumb_url": thumb,
        "title": model.title,
        "type": "Content",
        "type_formula_score": "Sum"
      };

      const res = await Sdk.dynamic.bridge(
        `cms/v1.0/learning/connector/uid/${learningConnector.uid}/management/course`,
        course,
        'POST'
      );

      setInitCopy(false);

      copyCourseNodes(uid, res.uid);

    } catch (error) {
      console.error(error)
    }
  }

  async function copyCourseNodes(courseUid, newCourseUid) {
    var originNodes = await $.ajax({
      url: `${Sdk.domain}/cms/v1.0/learning/course/uid/${courseUid}/management/nodes`,
      type: "GET",
      headers: {
        "Authorization": `Bearer ${tempToken}`,
        "Project-Key": Sdk.projectKey,
        "Api-Env": Sdk.Api.enviroment
      },
    });

    setCurrentCourseCopyNodesTotal(originNodes.length);
    setCurrentCourseCopyNodesCopied(0);

    var totalActivities = 0;
    for (var i = 0; i < originNodes.length; i++) {
      totalActivities += originNodes[i].activities.length;
    };
    setCurrentCourseCopyActivitiesTotal(totalActivities);
    setCurrentCourseCopyActivitiesCopied(0);

    const promises = originNodes.map(async (originNode) => {
      var imageUrl = originNode.data.thumb;
      var blob = await b64toBlob(imageUrl);
      const searchParams = new URLSearchParams(imageUrl.split("?")[1]);
      const imageUid = searchParams.get("uid");
      var thumb = await getThumbUrl(blob, imageUid);

      var newNodeModel = {
        "column": originNode.column,
        "data": {
          "description": originNode.data.description,
          "tag": originNode.data.tag || "",
          "tag_type": originNode.data.tag_type || "",
          "thumb": thumb,
          "title": originNode.data.title
        },
        "products": [],
        "row": originNode.row
      }

      const newNode = await Sdk.dynamic.bridge(
        `cms/v1.0/learning/course/uid/${newCourseUid}/management/node`,
        newNodeModel,
        'POST'
      );

      setCurrentCourseCopyNodesCopied(prevCount => prevCount + 1);

      copyNodesActivities(originNode, newNode);
    })
    
    await Promise.all(promises);
  }

  function updateCompositionFile(composition) {
    let newComposition = {...composition};

    newComposition = addCompositionKeys(newComposition);
    newComposition = addCompositionOrigin(newComposition);

    return newComposition;
  }

  function addCompositionKeys(composition) {
    let newComposition = {...composition};
    
    if (!("keys" in newComposition)) {
      newComposition.keys = {
        "directory_uid": Sdk.authorization.activeProject.directory.uid,
        "project_uid": Sdk.authorization.activeProject.uid
      };
    }
    
    return newComposition;
  }
  
  function addCompositionOrigin(composition) {
    let newComposition = {...composition};
    
    if (!("origin" in newComposition) && principalProject) {
      newComposition.origin = {
        "directory_uid": principalProject.directory.uid,
        "project_uid": principalProject.uid,
      };
    }
    
    return newComposition;
  }
  
  async function updateFileMetadata(file, parentUid) {
    console.log("file", file)
    var content_info = [];

    if (file.document) {
      content_info = file.document.content_info;
    }
    
    content_info.push("catalog=true");
    
    if (parentUid !== undefined && parentUid !== null && typeof parentUid === "string") {
      content_info.push("parent_content_uid="+parentUid);
    }
    
    try {
      return await Sdk.dynamic.bridge(
        `storage/v1.0/content/metadata?manual_project_uid=${file.document.project_uid}&uid=${file.uid}`,
        {
          "content_info": content_info
        },
        'PUT'
      );
    } catch (error) {
      return null
    }
  }

  async function copyNodesActivities(originNode, newNode) {
    var tempActivities = originNode.activities;
    var activities = [];
    
    const promises = tempActivities.map(async (tempActivity) => {
      const activity = await $.ajax({
        url: `${Sdk.domain}/cms/v1.0/learning/activity/uid/${tempActivity.uid}/management`,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${tempToken}`,
          "Project-Key": Sdk.projectKey,
          "Api-Env": Sdk.Api.enviroment
        },
      });

      var file;
      var newFile;
      if (activity.mime_type === "digitalpages/composer") {
        file = await $.ajax({
          url: `${Sdk.domain}/storage/v1.0/content?uid=${activity.internal_content_uid}&access_token=${tempToken}&project_key=${Sdk.projectKey}&api_env=${Sdk.Api.enviroment}`,
          type: "GET",
          dataType: "JSON"
        });
        newFile = await uploadNewComposition(updateCompositionFile(file));
        if (newFile) {
          let updatedFile = await updateFileMetadata(newFile[0], activity.internal_content_uid);
          newFile[0].uid = updatedFile.content_uid;
        }
      } else {
        file = `${Sdk.domain}/storage/v1.0/content?uid=${activity.internal_content_uid}`;
        newFile = await downloadAsset(file);
        if (newFile) {
          let updatedFile = await updateFileMetadata(newFile[0], activity.internal_content_uid);
          newFile[0].uid = updatedFile.content_uid;
        }
      }

      var activityModel = [{
        "internal_content_uid": newFile[0].uid,
        "mime_type": tempActivity.mime_type,
        "name": tempActivity.name,
        "order": tempActivity.order
      }]

      const res = await Sdk.dynamic.bridge(
        `cms/v1.0/learning/node/uid/${newNode.uid}/management/activities`,
        activityModel,
        'POST'
      );
      
      setCurrentCourseCopyActivitiesCopied(prev => prev + 1);

      activity.structure = file;
      activities.push(activity);
    });
    
    await Promise.all(promises);

    return activities;
  }

  function getFileType(asset) {
    var fileType = null;
    if (asset.data && asset.data[0] && asset.data[0].document && asset.data[0].document.content_type) {
      fileType = asset.data[0].document.content_type;
    };
    return fileType;
  }

  async function getProcessedVideo(fileUid) {
    var file;

    var interval = await setInterval(async function() {
      var response = await Sdk.dynamic.bridge(`storage/v1.0/search?filter=(file_uid eq '${fileUid}')`);
      file = response.results[0];
      if (file.document.content_type === "digitalpages/video") {
        clearInterval(interval);
      };
    }, 1000);

    return file;
  }

  async function getFileByUid(fileUid) {
    var response = await Sdk.dynamic.bridge(`storage/v1.0/search?filter=(file_uid eq '${fileUid}')`);
    return response.results[0];
  }

  function checkIfPlaceholderAsset(asset) {
    return asset.search("pocs.digitalpages.com.br") > -1;
  }

  async function uploadNewComposition(composition) {
    var assets = composition.assets.filter(item => item.search("pocs.digitalpages.com.br") === -1);
    var assetsToReplace = [];
    
    const promises = assets.map(async (asset) => {
      var newAsset = await downloadAsset(asset);
      if (newAsset) {
        const searchParams = new URLSearchParams(asset.split("?")[1]);
        const assetUid = searchParams.get("uid");
        let updatedFile = await updateFileMetadata(newAsset[0], assetUid);
        newAsset[0].uid = updatedFile.content_uid;

        var obj;
        if (getFileType(newAsset) === "video/mp4") {
          obj = {
            "old": asset.split("uid=")[1],
            "new": newAsset[0].document.file_uid,
            "is_video": true
          };
        } else {
          obj = {
            "old": asset.split("uid=")[1],
            "new": newAsset[0].uid
          };
        }

        assetsToReplace.push(obj);
      }
    });
    
    await Promise.all(promises);

    var stringStructure = JSON.stringify(composition);

    assetsToReplace = await processVideos(assetsToReplace);

    for (var i = 0; i < assetsToReplace.length; i++) {
      var asset = assetsToReplace[i];
      stringStructure = stringStructure.replaceAll(asset.old, asset.new);
    };

    return await onSaveClick(stringStructure);
  }

  async function processVideos(assets) {

    for (let i = 0; i < assets.length; i++) {
      if (assets[i].is_video !== undefined) {
        var processedFile = await new Promise(function(resolve, reject) {
          var interval = setInterval(async function() {
            var result = await Sdk.dynamic.bridge(`storage/v1.0/search?filter=(file_uid eq '${assets[i].new}')`);
            var file = result.results[0];
            if (file.document.content_type === "digitalpages/video") {
              clearInterval(interval);
              resolve(file);
            };
          }, 1000);
        });
        assets[i].new = processedFile.document.content_uid;
      }
    }

    return assets;
  }

  function blobToFile(theBlob, fileName) {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }

  async function getCompositionFile(structure) {
    var myBlob = new Blob([structure], { type: "application/json" });
    return blobToFile(myBlob, "composition.rdpcomposer");
  }

  async function onSaveClick(structure) {
    structure = refreshIds(structure);
    const { connectors } = Sdk.authorization.activeProject;
    const learningConnector = getConnectorFromArray(connectors, "Learning_1", "ContentManager");
    var url = `${Sdk.domain}/storage/v1.0/upload/connector/uid/${learningConnector.uid}`;
    var fd = new FormData();
    var files = await getCompositionFile(structure);

    fd.append('file', files, "composition.rdpcomposer")
    
    let tries = 0;
    
    const fetchRequest = async () => {
      try {
        return await $.ajax({
          url: url,
          type: "POST",
          data: fd,
          contentType: false,
          processData: false,
          headers: {
            "Authorization": `Bearer ${Sdk.Api.authorization.credential.accessToken}`,
            "Project-Key": Sdk.projectKey,
            "Api-Env": Sdk.Api.enviroment
          }
        });
      } catch (error) {
        if (tries < 3) {
          tries++;
          return fetchRequest();
        }
        throw error;
      }
    }

    try {
      return await fetchRequest();
    } catch (error) {
      return null;
    }
  }

  function refreshIds(str) {
    var composition = JSON.parse(str);
    
    var structure = composition.structure;
    for (var i = 0; i < structure.length; i++) {
      var obj = structure[i];
      obj.id = uuidv1();

      var images = obj.images;
      if (images) {
        for (var j = 0; j < images.length; j++) {
          images[j].id = uuidv1();
        }
      }

      var items = obj.items;
      if (items) {
        for (var j = 0; j < items.length; j++) {
          items[j].id = uuidv1();
        }
      }

      var questions = obj.questions;
      if (questions) {
        for (var j = 0; j < questions.length; j++) {
          questions[j].id = uuidv1();

          var choices = questions[j].choices;
          if (choices) {
            for (var k = 0; k < choices.length; k++) {
              choices[k].id = uuidv1();
            }
          }
        }
      }
    }

    composition.structure = structure;

    return JSON.stringify(composition);
  }

  async function downloadAsset(asset) {
    const { connectors } = Sdk.authorization.activeProject;
    const learningConnector = getConnectorFromArray(connectors, "Learning_1", "ContentManager");
    asset += `&access_token=${tempToken}&project_key=${Sdk.projectKey}&api_env=${Sdk.Api.enviroment}`;

    try {
      return await Sdk.dynamic.bridge(
        `storage/v1.0/upload/connector/uid/${learningConnector.uid}`,
        [{
          "url": asset,
          "file_name": "Sem título",
        }],
        'POST'
      );
    } catch (error) {
      return null;
    }
  }

  function getNodesProgress() {
    return currentCourseCopyNodesCopied / currentCourseCopyNodesTotal * 100 + "%";
  }

  function getActivitiesProgress() {
    return currentCourseCopyActivitiesCopied / currentCourseCopyActivitiesTotal * 100 + "%";
  }

  function finishedImport() {
    return (currentCourseCopyActivitiesTotal === currentCourseCopyActivitiesCopied) && (currentCourseCopyNodesTotal === currentCourseCopyNodesCopied) && currentCourseCopyActivitiesTotal > 0 && currentCourseCopyNodesTotal > 0;
  }

  function onCloseCopyModal() {
    setCurrentCourseCopy(null);
    setCurrentCourseCopyNodesTotal(0);
    setCurrentCourseCopyNodesCopied(0);
    setCurrentCourseCopyActivitiesTotal(0);
    setCurrentCourseCopyActivitiesCopied(0);
  }

  if (preferences) {
    var projectUid = Sdk.authorization.activeProject.uid;
    var accessToken = Sdk.authorization.credential.accessToken;

    return <>
      <Header />
      <Sidebar
        currentRoute={consumer ? consumer.currentRoute : null}
        defineGroup={consumer ? consumer.defineGroup : null}
        defineRoute={consumer ? consumer.defineRoute : null}
        groups={consumer ? consumer.groups : null}
      />
      <Breadcrumb
        currentRoute={consumer ? consumer.currentRoute : null}
        currentGroup={consumer ? consumer.currentGroup : null}
        options={null}
        newOption={null}
        label="Catálogo de cursos"
      />
      <div className="rdp-manager-admin-list-catalog">
        
        {courses === null &&
          <div className="rdp-manager-admin-list-catalog-preloader"></div>
        }

        {courses &&
          <ul className="rdp-manager-admin-list-catalog-courses">
            {courses.map((course, index) => {
              return <li key={index} className="rdp-manager-admin-list-catalog-course">
                <div className="rdp-manager-admin-list-catalog-course-thumb" style={{backgroundImage: `url(${course.thumb_url})`}}></div>
                <div className="rdp-manager-admin-list-catalog-course-metadata">
                  <span className="rdp-manager-admin-list-catalog-course-title">{course.title}</span>
                  {/* <span className="rdp-manager-admin-list-catalog-course-copy" onClick={() => copyCourse(course.uid)}>Copiar curso</span> */}
                  {/* <span className="rdp-manager-admin-list-catalog-course-view">Visualizar curso</span> */}
                  <div className="rdp-manager-admin-list-catalog-course-actions">
                    <span className="rdp-manager-admin-list-catalog-course-title">{course.title}</span>
                    {/* <span className="rdp-manager-admin-list-catalog-course-view" onClick={() => setCurrentCourseCopy(course)}>Visualizar curso</span> */}
                    <span className="rdp-manager-admin-list-catalog-course-copy" onClick={() => setCurrentCourseCopy(course)}>Copiar curso</span>
                  </div>
                </div>
              </li>
            })}
          </ul>
        }

        {currentCourseCopy &&
          <div className="rdp-manager-admin-list-catalog-copy-confirmation-modal">
            <div className="rdp-manager-admin-list-catalog-copy-confirmation-modal-box">
              <div className="rdp-manager-admin-list-catalog-copy-confirmation-modal-header">
                <span className="rdp-manager-admin-list-catalog-copy-confirmation-modal-header-title">Copiar curso</span>
                {!isCopying &&
                  <button type="button" className="rdp-manager-admin-list-catalog-copy-confirmation-modal-header-close" onClick={() => onCloseCopyModal()}>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="14px" height="14px" viewBox="0 0 14 14" enableBackground="new 0 0 14 14">
                      <g extraneous="self">
                        <g>
                          <path fill="#C0C1C2" d="M13.79,0.21c-0.27-0.28-0.71-0.28-0.98,0L7,6.01l-5.81-5.8c-0.27-0.28-0.71-0.28-0.98,0 c-0.28,0.27-0.28,0.71,0,0.98L6.01,7l-5.8,5.81c-0.28,0.27-0.28,0.71,0,0.98C0.34,13.93,0.52,14,0.7,14s0.36-0.07,0.49-0.21 L7,7.99l5.81,5.8C12.94,13.93,13.12,14,13.3,14c0.18,0,0.36-0.07,0.49-0.21c0.28-0.27,0.28-0.71,0-0.98L7.99,7l5.8-5.81 C14.07,0.92,14.07,0.48,13.79,0.21z"></path>
                        </g>
                      </g>
                    </svg>
                  </button>
                }
              </div>
              <div className="rdp-manager-admin-list-catalog-copy-confirmation-modal-body">
                <div className="rdp-manager-admin-list-catalog-copy-confirmation-modal-body-info">
                  <img className="rdp-manager-admin-list-catalog-copy-confirmation-modal-body-info-thumb" src={currentCourseCopy.thumb_url}/>
                  <span className="rdp-manager-admin-list-catalog-copy-confirmation-modal-body-info-title">{currentCourseCopy.title}</span>
                  {/* <span className="rdp-manager-admin-list-catalog-copy-confirmation-dialog-body-info-description">{currentCourseCopy.description}</span> */}
                  {!isCopying &&
                    <span className="rdp-manager-admin-list-catalog-copy-confirmation-modal-body-info-submit" onClick={() => copyCourse(currentCourseCopy.uid)}>Iniciar cópia do curso</span>
                  }
                  </div>
                <div className="rdp-manager-admin-list-catalog-copy-confirmation-modal-body-progress">
                  {currentCourseCopyNodesTotal > 0 &&
                    <>
                      <span className="rdp-manager-admin-list-catalog-copy-confirmation-modal-body-progress-label">Progresso de cópia dos nós</span>
                      <div className="rdp-manager-admin-list-catalog-copy-confirmation-modal-body-progress-container">
                        <div style={{width: getNodesProgress()}} className="rdp-manager-admin-list-catalog-copy-confirmation-modal-body-progress-bar"></div>
                      </div>
                    </>
                  }

                  {currentCourseCopyActivitiesTotal > 0 &&
                    <>
                      <span className="rdp-manager-admin-list-catalog-copy-confirmation-modal-body-progress-label">Progresso de cópia das atividades</span>
                      <div className="rdp-manager-admin-list-catalog-copy-confirmation-modal-body-progress-container">
                        <div style={{width: getActivitiesProgress()}} className="rdp-manager-admin-list-catalog-copy-confirmation-modal-body-progress-bar"></div>
                      </div>
                    </>
                  }

                  {finishedImport() &&
                    <div className="rdp-manager-admin-list-catalog-copy-confirmation-modal-body-success-message">
                      Curso copiado com sucesso. Clique aqui para editar o curso copiado.
                    </div>
                  }

                  {initCopy &&
                    <div className="rdp-manager-admin-list-catalog-copy-confirmation-modal-body-message">
                      Aguarde, a cópia do curso será iniciada em alguns instantes...
                    </div>
                  }

                  {/* <span className="rdp-manager-admin-list-catalog-copy-confirmation-dialog-body-progress-label">Progresso da cópia de atividades</span>
                  <div className="rdp-manager-admin-list-catalog-copy-confirmation-dialog-body-progress-container">
                    <div style={{width: "50%"}} className="rdp-manager-admin-list-catalog-copy-confirmation-dialog-body-progress-bar"></div>
                  </div>

                  <span className="rdp-manager-admin-list-catalog-copy-confirmation-dialog-body-progress-label">Progresso total</span>
                  <div className="rdp-manager-admin-list-catalog-copy-confirmation-dialog-body-progress-container">
                    <div style={{width: "70%"}} className="rdp-manager-admin-list-catalog-copy-confirmation-dialog-body-progress-bar"></div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </>
  } else {
    return "";
  }
}