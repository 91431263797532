import React, { useRef } from 'react';

import './styles.scss';

export default function Modal({ onClickOutside, children }) {
  const optionsRef = useRef();

  return (
    <div className="iframe-box">
      <div
        className="iframe-overlay"
        onClick={() => onClickOutside()}
        style={{ position: 'fixed' }}
      ></div>
      <div ref={optionsRef} className="iframe-content">
        {children}
      </div>
    </div>
  );
}
