import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
// import { WithContext as ReactTags } from 'react-tag-input';
import Switch from 'react-switch';
import Components from 'rdp-builder-components';
import collapseArrow from '../../assets/images/icons/collapse-arrow.png';
import Properties from '../properties/properties';
import './form-dynamic-v2.scss';
import parseEntityName from '../../utils/parseEntityName';
import Sdk from "api.digitalpages.module.sdk.api";
import RDP_CONFIG from "../../config/config";
import { Iframe } from "../index";
import useAuthToken from "../../hooks/useAuthToken";
import Modal from '../modal';
import { FormUserList } from './form-user-list';

// KeyCodes para o componente de tags
const KeyCodes = {
  comma: 188,
  enter: [10, 13],
};
const delimiters = [...KeyCodes.enter, KeyCodes.comma];

const MultiCheckbox = ({ field, handleChange }) => {
  useEffect(() => {
    if (
      field.defaultValues &&
      field.defaultValues.length > 0 &&
      !!field.options
    ) {
      const targetOptions = field.options.filter((option) =>
        field.defaultValues.includes(option.uid)
      );
      targetOptions.forEach((option) => {
        if (typeof handleChange === 'function') handleChange(true, option.uid);
      });
    }
  }, []);

  const [open, setOpen] = useState(true);

  return (
    <div key={field.id} className="row">
      <div style={{ color: 'black' }}>
        <div className="row-header" onClick={(e) => setOpen(!open)}>
          <img
            style={{
              transform: `rotate(${open ? '0deg' : '-90deg'})`,
            }}
            src={collapseArrow}
          />
          {field.group}
          {field.required && <span style={{ color: 'red' }}>*</span>}
        </div>
      </div>
      <div
        style={{ visibility: open ? 'visible' : 'visible' }}
        className={`checkbox-row ${
          !open ? 'checkbox-row-hide' : 'checkbox-row-show'
        }`}
      >

        {field.options.map((option) => (
        <ul style={{marginTop: '5px', marginLeft: option.column * 15}}>
          <Components.Buttons.Checkbox
          label={option.data.title}
          checkedColor="#764AB0"
            defaultChecked={
              field.defaultValues
                ? field.defaultValues.includes(option.uid)
                : false
            }
            onChange={(value, label, checked) =>
              handleChange(checked, option.uid)
            }
            />
            </ul>
            ))}
      </div>
    </div>
  );
};

const TreeCheckbox = ({ field, handleChange }) => {

  useEffect(() => {
    if (
      field.defaultValues &&
      field.defaultValues.length > 0 &&
      !!field.options
    ) {
      const targetOptions = field.options.filter((option) =>
        field.defaultValues.includes(option.uid)
      );
      targetOptions.forEach((option) => {
        if (typeof handleChange === 'function') handleChange(true, option.uid);
      });
    }
  }, []);

  const [open, setOpen] = useState(true);

  function renderChildren(array, schema) {
    let children = [];

{

  array.map((i) => {
    children.push(
        <li>
          <ul style={{ marginLeft: '15px' }}>
            <li style={{ marginTop: '5px' }}>
              <Components.Buttons.Checkbox
                label={
                  i.data[
                    field.schema_properties &&
                    field.schema_properties.find((prop) => prop.name === 'name')?.name
                  ] || i.data.name || i.data.title
                }
                checkedColor="#764AB0"
                defaultChecked={
                  field.defaultValues
                  ? field.defaultValues.includes(i.uid)
                    : false
                  }
                  onChange={(value, label, checked) =>
                    handleChange(checked, i.uid)
                  }
                  />
            </li>
          </ul>
        </li>
      );
      if (i.childs && i.childs.length > 0) {
        children.push(
          <ul style={{ marginLeft: '15px' }}>
            {renderChildren(i.childs, schema)}
          </ul>
        );
      }
    });
  }

    return <ul>{children}</ul>;
  }

  return (
    <div key={field.id} className="row">
      <div style={{ color: 'black' }}>
        <div className="row-header" onClick={(e) => setOpen(!open)}>
          <img
            style={{
              transform: `rotate(${open ? '0deg' : '-90deg'})`,
            }}
            src={collapseArrow}
          />
          {field.group}
          {field.required && <span style={{ color: 'red' }}>*</span>}
        </div>
      </div>
      {open && (
        <div
          style={{ visibility: open ? 'visible' : 'visible' }}
          className={`checkbox-row ${
            !open ? 'checkbox-row-hide' : 'checkbox-row-show'
          }`}
        >
          <ul>
            {field.array
              ? field.array.map((item) => (
                  <>
                    <li style={{ marginTop: '5px' }}>
                      <Components.Buttons.Checkbox
                        label={
                          item.data[
                            field.schema_properties &&
                              field.schema_properties[0].name
                          ]
                        }
                        checkedColor="#764AB0"
                        defaultChecked={
                          field.defaultValues
                            ? field.defaultValues.includes(item.uid)
                            : false
                        }
                        onChange={(value, label, checked) =>
                          handleChange(checked, item.uid)
                        }
                      />
                    </li>
                    <li>
                      {item.childs &&
                        item.childs.length > 0 &&
                        renderChildren(item.childs, field)}
                    </li>
                  </>
                ))
              : field.options.map((item) => (
                
                  <>
                    <li style={{ marginTop: '5px', marginLeft: item.column * 15 }}>
                      <Components.Buttons.Checkbox
                        label={
                          item.data[
                            field.schema_properties &&
                            field.schema_properties.find((prop) => prop.name === 'title')?.name
                          ] || item.data.name || item.data.title
                        }
                        checkedColor="#764AB0"
                        defaultChecked={
                          field.defaultValues
                            ? field.defaultValues.includes(item.uid)
                            : false
                        }
                        onChange={(value, label, checked) =>
                          handleChange(checked, item.uid)
                        }
                      />
                    </li>
                    <li style={{ marginTop: '5px' }}>
                      {item.childs &&
                        item.childs.length > 0 &&
                        renderChildren(item.childs, field)}
                    </li>
                  </>
                ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default function FormDynamicV2({
  handleSubmit,
  handleSave,
  errors = {},
  form,
  defaultValues,
  defaultUserAuthorizations,
  submitText,
  autoComplete = undefined,
  language = "pt_br",
  parentRef,
}) {
  const [inputs, setInputs] = useState(defaultValues ? defaultValues : {});
  const [compositionsIframeUrl, setCompositionsIframeUrl] = useState(null);
  const [composerIframeUrl, setComposerIframeUrl] = useState(null);
  const [loadingIframes, setLoadingIframes] = useState([]);
  const [loadedIframes, setLoadedIframes] = useState([]);
  const [openModalUsers, setOpenModalUsers] = useState(false)
  const [userList, setUserList] = useState([]);
  const token = useAuthToken();
  const users = userList;

  useEffect(() => {
    if (!form.fields) return;

    const enableUsersSelector = form.fields.some(item => item.id === 'users_uid');

    if (enableUsersSelector) {
      try {
        Sdk.Api.authorization.usersByProject().then(setUserList)
      }
      catch(err) {
        console.log(err)
      }
    };
  }, []);

  useEffect(() => {
    if (defaultUserAuthorizations) {
      setInputs(prev => ({ ...prev, 
        users: defaultUserAuthorizations,
      }))
    };
  }, [defaultUserAuthorizations]);

  useEffect(() => {
    const formFields = [...form.fields];
    formFields.forEach((field) => {
      if (field.id.includes('range')) {
        const defaultStartDate = new Date();
        defaultStartDate.setDate(defaultStartDate.getDate() - 1);
        const defaultEndDate = new Date();
        defaultEndDate.setDate(defaultEndDate.getDate() + 1825);
       
        const startDateId = field.start_field.id;
        const endDateId = field.end_field.id;
        if (!inputs[startDateId]) {
          setInputs((oldSt) => ({
            ...oldSt,
            [startDateId]: format(defaultStartDate, 'yyyy-MM-dd') + 'T15:00Z',
          }));
        }
        if (!inputs[endDateId]) {
          setInputs((oldSt) => ({
            ...oldSt,
            [endDateId]: format(defaultEndDate, 'yyyy-MM-dd') + 'T15:00Z',
          }));
        }
      }
    });
  }, []);

  const handleTagDelete = (tagId, index) => {
    setInputs((oldSt) => ({
      ...oldSt,
      [tagId]: oldSt[tagId].filter((tag, i) => i !== index),
    }));
  };
  const handleTagAddition = (tagId, tag) => {
    const tags = Array.isArray(inputs[tagId]) ? inputs[tagId] : [];
    setInputs((oldSt) => ({ ...oldSt, [tagId]: [...tags, tag] }));
  };
  const handleTagDrag = (tagId, tag, currPos, newPos) => {
    const tags = Array.isArray(inputs[tagId]) ? inputs[tagId] : [];
    const newTags = tags.slice();
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setInputs((oldSt) => ({ ...oldSt, [tagId]: newTags }));
  };
  const parseItemAccessStatus = (status) => {
    const statuses = {
      Undefined: 'Não definido',
      PendingPayment: 'Pagamento pendente',
      PaymentFailed: 'Pagamento falhou',
      Active: 'Ativo',
      Blocked: 'Bloqueado',
      Inactive: 'Inativo'
    }
    return statuses[status]
  }
  const getEntityPropertyFieldType = (type) => {
    const types = {
      Text: 'text',
      NumberInteger: 'number',
      NumberDecimal: 'number',
      DateTime: 'datetime-local',
    };
    return types[type];
  };

  async function openCompositionsIframe(cb) {
    setCompositionsIframeUrl(`${Sdk.Api.domain}/product/dam/current/${RDP_CONFIG.projectKey}/p/-/${Sdk.Api.enviroment === "production" ? "p" : "h"}/#/auth-with-token/${language}/embed/${Sdk.authorization.activeProject.uid}/${token}`);
    const handler = async (event) => {
      if (event.data.content_type && cb) {
        cb(event.data);
      }
    };

    window.addEventListener('message', handler);

    // clean up
    return () => window.removeEventListener('message', handler);
  }

  async function openCompositionIframe(uid, cb) {
    let domain = Sdk.Api.enviroment === "production" ? "https://composer.euconquisto.com" : "https://composer-hmg.euconquisto.com"
    setComposerIframeUrl(`${domain}/#/embed/auth-with-token/${language}/home/${Sdk.authorization.activeProject.uid}/${token}/${uid}`);
    const handler = async (event) => {
      if (event.data.new_composition_uid && cb) {
        cb(event.data);
      }
    };

    window.addEventListener('message', handler);

    // clean up
    return () => window.removeEventListener('message', handler);
  }
  
  const getComposerPlayerPreviewUrl = (uid, field) => {
    if (!loadingIframes.includes(field.id) && !loadedIframes.includes(field.id)) {
      setLoadingIframes([...loadingIframes, field.id]);
    }
    
    const optionObj = {
      content_uid: uid,
      access_token: token,
    };
    const options = window.btoa(JSON.stringify(optionObj));
    

    return `${Sdk.Api.domain}/product/composer_player/current/${RDP_CONFIG.projectKey}/p/${RDP_CONFIG.projectUid}/${Sdk.Api.enviroment === "production" ? "p" : "h"}/?custom_options=${options}`;
  }
  
  const getInputTemplate = {
    'input-text': (field) => (
      <div key={field.id} className="row">
        <label>
          {field.group}
          {field.required && <span style={{ color: 'red' }}>*</span>}
          <input
            type={field.inputType ? field.inputType : 'text'}
            className={`text-input ${errors[field.id] ? "invalid" : ""}`}
            required={field.required}
            value={inputs && inputs[field.id] ? inputs[field.id] : ''}
            placeholder={field.placeholder}
            onChange={(evt) =>
              setInputs({ ...inputs, [field.id]: evt.target.value })
            }
          />
        </label>
        {errors[field.id] && (
          <span className="help-block error">{errors[field.id]}</span>
        )}
      </div>
    ),
    'input-select-small': (field) => (
      <div key={field.id} className="row">
        <Components.Buttons.Select
          title={field.group}
          items={field.options.map((option) => ({
            value: option,
            label: option,
          }))}
          onChange={(value) => setInputs({ ...inputs, [field.id]: value })}
          width="298px"
        />
      </div>
    ),
    'input-select': (field) => (
      <div key={field.id} className="row">
        <p style={{ marginBottom: '0.7rem' }}>
          {field.group}
          {field.required && <span style={{ color: 'red' }}>*</span>}
        </p>
        <Components.Buttons.Select
          borderColor="#d3d5dd"
          title={field.group}
          defaultValue={inputs[field.field]}
          items={field.options.map((option) => ({
            value: option.value,
            label: option.label,
          }))}
          onChange={(value) => setInputs({ ...inputs, [field.id]: value })}
        />
      </div>
    ),
    'input-option': (field) => (
      <div>
        <Components.Buttons.Option
          defaultValue={inputs && inputs[field.id] ? inputs[field.id] : ''}
          title={field.group}
          checkedColor="#764AB0"
          onChange={(e) =>
            setInputs({ ...inputs, [field.id]: e.currentTarget.value })
          }
          items={field.options.map((option) => ({
            value: option,
            label: option,
          }))}
        />
      </div>
    ),
    'input-checkbox': (field) => (
      <div
        style={{
          marginTop: '40px',
        }}
      >
        <Components.Buttons.Checkbox
          label={field.group}
          checkedColor="#764AB0"
          defaultChecked={
            inputs[field.field]
          }
          onChange={(value, label, checked) =>
            setInputs({ ...inputs, [field.id]: checked })
          }
        />
      </div>
    ),
    'input-multi-checkbox': (field, defaultValues = []) => (
      <MultiCheckbox
        field={field}
        defaultValues={defaultValues}
        handleChange={(checked, option) => {
          setInputs((oldSt) => ({
            ...oldSt,
            [field.id]: { ...oldSt[field.id], [option]: checked },
          }));
        }}
      />
    ),
    'input-tree-checkbox': (field, defaultValues = []) => (
      <TreeCheckbox
        field={field}
        defaultValues={defaultValues}
        handleChange={(checked, option) => {
          setInputs((oldSt) => ({
            ...oldSt,
            [field.id]: { ...oldSt[field.id], [option]: checked },
          }));
        }}
      />
    ),
    'input-textarea': (field) => (
      <div key={field.id} className="row">
        <label>
          {field.group}
          {field.required && <span style={{ color: 'red' }}>*</span>}
          <textarea
            required={field.required}
            className="text-input"
            style={{ height: '200px', resize: 'none' }}
            value={inputs && inputs[field.id] ? inputs[field.id] : ''}
            onChange={(evt) =>
              setInputs({ ...inputs, [field.id]: evt.target.value })
            }
          >
            {field.placeholder}
          </textarea>
        </label>
      </div>
    ),
    'input-user-list': (field) => users && users.length > 0 && (
      <div key={field.id} className="row input-composer-container">
        <div className="pick-file-container">
            <input
              className="pick-file-container-input-hidden"
              type="text" 
              required={field.required}
              name={field.id}
              onInvalid={field.required ? (e) => e.target.setCustomValidity('Selecione os usuários') : null}
            />
            <button
              className="composer-button"
              type="button"
              onClick={() => {
                setOpenModalUsers(true);
                if (parentRef && parentRef.current) {
                  parentRef.current.scrollTop = 0;
                }
              }}
            >
              <span>
              <svg
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  width="35.695px"
                  height="35.695px"
                  viewBox="0 0 35.695 35.695"
                  xmlSpace="preserve"
                  fill="currentColor"
                >
                  <g>
                    <path d="M11.558,10.767c0-3.473,2.815-6.29,6.289-6.29c3.476,0,6.289,2.817,6.289,6.29c0,3.475-2.813,6.29-6.289,6.29 C14.373,17.057,11.558,14.243,11.558,10.767z M35.667,22.607l-0.879-5.27c-0.158-0.954-0.961-1.754-1.896-1.984 c-0.836,0.74-1.932,1.191-3.136,1.191c-1.203,0-2.3-0.452-3.135-1.191c-0.938,0.229-1.739,1.03-1.897,1.984l-0.021,0.124 c-0.522-0.503-1.17-0.881-1.868-1.052c-1.33,1.176-3.072,1.896-4.987,1.896s-3.657-0.72-4.987-1.896 c-0.698,0.171-1.346,0.549-1.868,1.052l-0.021-0.124c-0.158-0.954-0.962-1.754-1.897-1.984c-0.835,0.74-1.932,1.191-3.135,1.191 c-1.204,0-2.3-0.452-3.136-1.191c-0.936,0.229-1.738,1.03-1.896,1.984l-0.879,5.27c-0.189,1.131,0.596,2.057,1.741,2.057h7.222 l-0.548,3.283c-0.3,1.799,0.948,3.271,2.771,3.271H24.48c1.823,0,3.071-1.475,2.771-3.271l-0.548-3.283h7.222 C35.071,24.662,35.855,23.738,35.667,22.607z M29.755,15.762c2.184,0,3.954-1.77,3.954-3.954c0-2.183-1.771-3.954-3.954-3.954 s-3.953,1.771-3.953,3.954C25.802,13.992,27.574,15.762,29.755,15.762z M5.938,15.762c2.183,0,3.953-1.77,3.953-3.954 c0-2.183-1.771-3.954-3.953-3.954c-2.184,0-3.954,1.771-3.954,3.954C1.984,13.992,3.755,15.762,5.938,15.762z" />
                  </g>
              </svg>
              </span>
              Usuários {inputs.users ? `(${inputs.users.length})` : ''}
            </button>
        </div>
      </div>
    ),
    'input-composition-file': (field) => (
      <div key={field.id} className="row input-composer-container">
        {inputs && inputs[field.id] ? (
          <div
            className="composer-preview"
          >
            <span className="composer-preview-title">
              {field.config.preview.title || 'Prévia'}
            </span>
            <iframe
              loading="lazy"
              className="input-composer-preview-iframe"
              src={getComposerPlayerPreviewUrl(inputs[field.id], field)}
              onLoad={() => {
                setLoadingIframes(() => loadingIframes.filter((id) => id !== field.id));
                setLoadedIframes([...loadedIframes, field.id]);
              }}
            />
            {loadingIframes.includes(field.id) && (
              <Components.Loaders.ContentPreloader
                background="transparent"
                position="absolute"
              />
            )}
            <button 
              className="composer-preview-edit"
              type="button"
              onClick={() => openCompositionIframe(inputs[field.id], data => {
                setInputs({ ...inputs, [field.id]: data.new_composition_uid });
                setComposerIframeUrl(null);
                setLoadedIframes(loadedIframes.filter(id => id !== field.id));
              })}
            >
              <span className="composer-preview-edit-icon">
                <svg width="24" height="24" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
                </svg>
              </span>
              <span className="composer-preview-edit-text">
                {field.config.preview.buttonText || 'Editar composição'}
              </span>
            </button>
            <button
              className="composer-preview-change"
              type="button"
              onClick={() => openCompositionsIframe(data => {
                setInputs({ ...inputs, [field.id]: data.uid });
                setCompositionsIframeUrl(null);
                setLoadedIframes(loadedIframes.filter(id => id !== field.id));
              })}
            >
              <span className="composer-preview-edit-icon">
                <svg width="24" height="24" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M13,9V3.5L18.5,9M6,2C4.89,2 4,2.89 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6Z" />
                </svg>
              </span>
              <span className="composer-preview-edit-text">
                {field.config.preview.buttonText || 'Escolher outra composição'}
              </span>
            </button>
          </div>
        ) : (
          <div className="pick-file-container">
            <input
              className="pick-file-container-input-hidden"
              type="text" 
              required={field.required}
              name={field.id}
              onInvalid={field.required ? (e) => e.target.setCustomValidity('Selecione uma composição') : null}
            />
            <button
              className="composer-button"
              type="button"
              onClick={() => openCompositionsIframe(data => {
                setInputs({ ...inputs, [field.id]: data.uid });
                setCompositionsIframeUrl(null);
                setLoadedIframes(loadedIframes.filter(id => id !== field.id));
              })}
            >
              <span>
                <svg width="24" height="24" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M13,9V3.5L18.5,9M6,2C4.89,2 4,2.89 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6Z" />
                </svg>
              </span>
              {field.placeholder}
            </button>
          </div>
        )}
      </div>
    ),
    'input-color-picker': (field) => (
      <div key={field.id} className="row">
        <label>
          {field.group}
          {field.required && <span style={{ color: 'red' }}>*</span>}
          <div className="color-picker-row">
            <span>
              {inputs[field.id] ? inputs[field.id] : 'Select a color'}
            </span>
            <div
              style={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                overflow: 'hidden',
                position: 'relative',
              }}
            >
              <input
                type="color"
                style={{
                  position: 'absolute',
                  width: '50px',
                  height: '50px',
                  border: 'none',
                  left: -15,
                  top: -15,
                  right: 0,
                  bottom: 0,
                }}
                value={
                  inputs && inputs[field.id] ? inputs[field.id] : '#BFBFBF'
                }
                onChange={(evt) =>
                  setInputs({ ...inputs, [field.id]: evt.target.value })
                }
              />
            </div>
          </div>
        </label>
      </div>
    ),
    'input-tree': (field) => (
      <div style={{ marginTop: '120px' }}>
        <Components.Recursive.TreeEdit
          labelNode="Create node"
          labelActivity="Create activity"
          labelColor="#764ab0"
          nodeTitle="Title"
          nodeDescription="Description"
          nodeThumbnail="Thumbnail"
          activityTitle="Title"
          activityDescription="Description"
          items={[]}
          onSaveNode={(data) => console.log(data)}
          onSaveActivity={(data) => console.log(data)}
          handleDamClick={() => {}}
          contentUploadRequiredEnabled={false}
        />
      </div>
    ),
    'input-datetime': (field) => (
      <div key={field.id} className="row">
        <label>
          {field.group}
          {field.required && <span style={{ color: 'red' }}>*</span>}
          <input
            type="datetime-local"
            className="text-input"
            required={field.required}
            value={inputs[field.id] ? inputs[field.id].replace('Z', '') : null}
            placeholder={field.placeholder}
            onChange={(evt) =>
              setInputs({ ...inputs, [field.id]: evt.target.value })
            }
          />
        </label>
      </div>
    ),
    'input-datetime-range': (field) => (
      <div key={field.id} className="row date-time-range-container">
        <label>
          {field.start_field.group}
          {field.required && <span style={{ color: 'red' }}>*</span>}
          <input
            type="datetime-local"
            className="text-input date-time-range"
            required={field.start_field.required}
            value={
              inputs[field.start_field.id]
                ? inputs[field.start_field.id].replace('Z', '')
                : null
            }
            placeholder={field.start_field.placeholder}
            onChange={(evt) =>
              setInputs({ ...inputs, [field.start_field.id]: evt.target.value })
            }
          />
        </label>
        <label>
          {field.end_field.group}
          {field.required && <span style={{ color: 'red' }}>*</span>}
          <input
            type="datetime-local"
            className="text-input date-time-range"
            required={field.end_field.required}
            value={
              inputs[field.end_field.id]
                ? inputs[field.end_field.id].replace('Z', '')
                : null
            }
            placeholder={field.end_field.placeholder}
            onChange={(evt) =>
              setInputs({ ...inputs, [field.end_field.id]: evt.target.value })
            }
          />
        </label>
      </div>
    ),
    'input-row': (row) => (
      <div key={row.id} className="row date-time-range-container">
        {row.fields.map((field) => (
          <label>
            {field.group}
            {field.required && <span style={{ color: 'red' }}>*</span>}
            <input
              type={field.inputType}
              className="text-input"
              required={field.required}
              autoComplete={field?.autoComplete}
              value={inputs && inputs[field.id] ? inputs[field.id] : ''}
              placeholder={field.placeholder}
              onChange={(evt) =>
                setInputs({ ...inputs, [field.id]: evt.target.value })
              }
            />
          </label>
        ))}
      </div>
    ),
    switch: (field) => (
      <>
        <p className="switch">{field.group}</p>
        <Switch
          checked={inputs && inputs[field.id] ? inputs[field.id] : false}
          onChange={(e) => setInputs({ ...inputs, [field.id]: e })}
          onColor="#4cbf69"
          onHandleColor="#FFF"
          handleDiameter={23}
          uncheckedIcon={false}
          checkedIcon={false}
          height={25}
          width={48}
          className="react-switch"
          key={'field-' + field.identifier}
          id={'field-' + field.identifier}
        />
      </>
    ),
    // 'input-tags': (field) => (
    //   <div className="tag-input-container">
    //     <div style={{ marginBottom: 12 }}>
    //       <label>
    //         {field.group}
    //         {field.required && <span style={{ color: 'red' }}>*</span>}
    //       </label>
    //     </div>
    //     <ReactTags
    //       tags={inputs && inputs[field.id] ? inputs[field.id] : []}
    //       handleDelete={(index) => {
    //         handleTagDelete(field.id, index);
    //       }}
    //       placeholder="Pressione Enter para adicionar novas tags"
    //       handleAddition={(tag) => handleTagAddition(field.id, tag)}
    //       handleDrag={(tag, currPos, newPos) =>
    //         handleTagDrag(field.id, tag, currPos, newPos)
    //       }
    //       delimiters={delimiters}
    //     />
    //   </div>
    // ),
    'input-properties': (field) => {
      return (
        <div style={{ marginTop: '40px' }}>
          <div style={{ marginBottom: 12 }}>
            <label>
              {field.group}
              {field.required && <span style={{ color: 'red' }}>*</span>}
            </label>
          </div>
          <Properties
            onChange={(properties) =>
              setInputs((oldSt) => ({ ...oldSt, [field.id]: properties }))
            }
          />
        </div>
      );
    },
    'input-entity-properties': (field) => {
      return (
        <div className="input-entity-properties" style={{ marginTop: '40px' }}>
          {field.options &&
            field.options.map((option) => (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: '20px',
                }}
              >
                <label>
                  {parseEntityName(option.name) || option.name}
                  {field.required && <span style={{ color: 'red' }}>*</span>}
                </label>
                <input
                  key={option.name}
                  value={
                    inputs[`property-${option.name}`]
                      ? inputs[`property-${option.name}`]
                      : ''
                  }
                  onChange={(e) => {
                    const textValue = e.target.value;
                    setInputs((oldSt) => ({
                      ...oldSt,
                      [`property-${option.name}`]: textValue,
                    }));
                  }}
                  type={getEntityPropertyFieldType(option.type)}
                  className="text-input"
                  required={field.required}
                />
              </div>
            ))}
        </div>
      );
    },
    'input-tree-list': (field) => (
      <div style={{marginTop: '40px'}}>
        <div style={{ marginBottom: 12 }}>
          <label>
            {field.group}
            {field.required && <span style={{ color: 'red' }}>*</span>}
          </label>
        </div>
        <table className="rdp-admin-table" style={{padding: '8px'}}>
          <tr>
            <th>ID</th>
            <th>Nome da Turma</th>
            <th>Nome do Curso</th>
            <th>Status Matrícula</th>
            <th>Descrição</th>
            <th>Criado em</th>
            <th>Matrícula</th>
          </tr>
          {field.items && field.items.map(item => (
            <tr key={item.uid}>
              <td>{item.uid}</td>
              <td>{item.name || item.register.name}</td>
              <td>{item.course.title}</td>
              <td style={{background: !item.access_status ? '#ddd' : 'inherit'}}>{parseItemAccessStatus(item.access_status) || ''}</td>
              <td>{item.description || item.register.description}</td>
              <td>{item.created_at}</td>
              <td>
                <select 
                  onChange={(e) => {
                    if (!e.target.value) return
                    setInputs({...inputs, 'container-registers':{
                      ...inputs['container-registers'],
                      [item.register ? item.register.uid : item.uid]: e.target.value
                    }})
                  }}
                  value={inputs['container-registers'] ? inputs['container-registers'][item.uid] : item.access_status}
                  style={{paddingRight: 12}}
                >
                  <option value="-">Sem Matrícula</option>
                  {item.access_status && <option value="Blocked">Cancelado (admin)</option>}
                  <option value="Active">Inscrito</option>
                </select>
              </td>
            </tr> 
          ))}
        </table>
      </div>
    )
  };
  const renderCheckIcon = () => {
    return (
      <svg
        width="22"
        height="23"
        viewBox="0 0 22 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 0.5C4.925 0.5 0 5.425 0 11.5C0 17.575 4.925 22.5 11 22.5C17.075 22.5 22 17.575 22 11.5C22 5.425 17.075 0.5 11 0.5ZM15.768 9.64C15.8558 9.53964 15.9226 9.42274 15.9646 9.29617C16.0065 9.1696 16.0227 9.03591 16.0123 8.90298C16.0018 8.77005 15.9648 8.64056 15.9036 8.52213C15.8423 8.40369 15.758 8.29871 15.6555 8.21334C15.5531 8.12798 15.4346 8.06396 15.3071 8.02506C15.1796 7.98616 15.0455 7.97316 14.9129 7.98683C14.7802 8.00049 14.6517 8.04055 14.5347 8.10463C14.4178 8.16872 14.3149 8.25554 14.232 8.36L9.932 13.519L7.707 11.293C7.5184 11.1108 7.2658 11.01 7.0036 11.0123C6.7414 11.0146 6.49059 11.1198 6.30518 11.3052C6.11977 11.4906 6.0146 11.7414 6.01233 12.0036C6.01005 12.2658 6.11084 12.5184 6.293 12.707L9.293 15.707C9.39126 15.8052 9.50889 15.8818 9.63842 15.9321C9.76794 15.9823 9.9065 16.005 10.0453 15.9986C10.184 15.9923 10.32 15.9572 10.4444 15.8954C10.5688 15.8337 10.6791 15.7467 10.768 15.64L15.768 9.64Z"
          fill="white"
        />
      </svg>
    );
  };
  const renderUpperSection = () => {
    const formUpperSection = form.fields.slice(0, 3);
    return (
      <div className="upper-section-wrapper">
        <section className="upper-section-input-wrapper">
          {formUpperSection.map((field) => {
            if (typeof getInputTemplate[field.type] === 'function') {
              const renderField = getInputTemplate[field.type];
              return renderField(field);
            }
          })}
        </section>
        {form.image && (
          <section className="photo-container">
            <p>{form.image.title}</p>
            <Components.Forms.Dropzone
              title="Image Upload"
              titleSize="small1"
              width="300px"
              placeholderBorderType="dashed"
              placeholderIconWidth="25px"
              iconColor="#5F6368"
              buttonText={form.image.label || 'Select a File'}
              buttonFontSize="small1"
              buttonBorderRadius="5px"
              buttonFontColor="#764AB0"
              buttonColor="#764AB0"
              defaultImage={
                inputs[form.image.id] ? inputs[form.image.id] : null
              }
              onChange={(e) => {
                setInputs({ ...inputs, [form.image.id]: e.target.files[0] });
              }}
              bordered
            />
          </section>
        )}
      </div>
    );
  };
  const renderLowerSection = () => {
    const formLowerSection = form.fields.slice(3);
    return (
      <div className="row lower-section-wrapper">
        {formLowerSection.map((field) => {
          if (typeof getInputTemplate[field.type] === 'function') {
            const renderField = getInputTemplate[field.type];
            return renderField(field);
          }
        })}
      </div>
    );
  };
  return (
    <form
      className="dynamic-form-root"
      autoComplete={autoComplete}
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(inputs, setInputs);
      }}
    >
      <main className="paper">
        {renderUpperSection()}
        {renderLowerSection()}

        {compositionsIframeUrl && (
          <Iframe
            onClickOutside={() => setCompositionsIframeUrl(null)}
            className="iframe-content"
            src={compositionsIframeUrl}
          />
        )}
        {composerIframeUrl && (
          <Iframe
            onClickOutside={() => setComposerIframeUrl(null)}
            className="iframe-content"
            src={composerIframeUrl}
          />
        )}

        {openModalUsers && users && users.length > 0 && (
          <Modal onClickOutside={() => setOpenModalUsers(false)}>
            <FormUserList
              defaultValue={(inputs.users || []).map(item => item.reference_uid)}
              users={users}
              onSave={(selectds) => {
                setInputs((prev) => ({
                  ...prev,
                  users: selectds.map((item) => ({ reference_uid: item, type: "User" })),
                }));
                setOpenModalUsers(false);
              }}
              
            />
          </Modal>
        )}
      </main>
      <footer className="dynamic-form-footer">
        {
          handleSubmit && (
            <button className="register-button">
              <span>{renderCheckIcon()}</span>
              <div>{submitText}</div>
            </button>
          )
        }
      </footer>
    </form>
  );
}
