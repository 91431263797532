import {atom} from "recoil";
import {persistAtomEffect} from "../effects/persistAtom";

export const selectedCourseUidState = atom<string | undefined>({
  key: 'SelectedCourseUidState',
  default: undefined,
  effects: [
    persistAtomEffect
  ]
});
