import React from "react";
import * as S from "./label.styled";

interface ILabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {}

function Label(props: ILabelProps) {
  const {
    children,
  } = props;
  
  return (
    <React.Fragment>
      <S.Label {...props}>
        {children}
      </S.Label>
    </React.Fragment>
  );
}

export default Label;