import useSWR from "swr";
import authService from "../services/auth-service";
import useLocalization from "./useLocalization";
import toast from "react-hot-toast";
import IEntityMember from "../@types/IEntityMember";

export default function useOptimisticFetchEntityItemMembers(entityItemUid?: Guid) {
  const l = useLocalization();
  const resp = useSWR(entityItemUid, authService.entityEndpoint.entityMembers);
  let data: Array<IEntityMember> | undefined = resp.data;

  return {
    ...resp,
    data,
    createMember: async (entityUid: Guid, data: IEntityMember, datas: Array<IEntityMember>) => {
      return toast.promise(
        resp.mutate(authService.entityEndpoint.entityRegisterMember(entityUid, data, datas), {
          optimisticData: [data, ...datas],
          rollbackOnError: true,
          populateCache: true,
          revalidate: false
        }),
        {
          loading: l('Saving...'),
          success: l('Saved!'),
          error: l('Could not save.'),
        }
      );
    },
    // updateEntity: async (entityUid: Guid, data: IEntityItem<IEntityItemData>, datas: IEntityItem<IEntityItemData>[]) => {
    //   return toast.promise(
    //     resp.mutate(authService.entityEndpoint.updateEntity(entityUid, data, datas), {
    //       optimisticData: [data, ...datas.filter(r => r.uid !== entityUid)],
    //       rollbackOnError: true,
    //       populateCache: true,
    //       revalidate: false
    //     }),
    //     {
    //       loading: l('Saving...'),
    //       success: l('Saved!'),
    //       error: l('Could not save.'),
    //     }
    //   );
    // },
    // deleteEntity: async (entityUid: Guid, datas: IEntityItem<IEntityItemData>[]) => {
    //   return toast.promise(
    //     resp.mutate(authService.entityEndpoint.deleteEntity(entityUid, datas), {
    //       optimisticData: datas.filter((item) => item.uid !== entityUid),
    //       rollbackOnError: true,
    //       populateCache: true,
    //       revalidate: false
    //     }),
    //     {
    //       loading: l('Deleting...'),
    //       success: l('Deleted!'),
    //       error: l('Could not delete.'),
    //     }
    //   );
    // }
  };
}