import React, { Component } from "react";
import Sdk from "api.digitalpages.module.sdk.api";
import Routes from "./routes";

import "./reset.css";
import "./App.css";
import RDP_CONFIG from "./config/config";
import Loading from "./components/loading";
import { ThemeProvider } from "styled-components";
import theme from "./theme";

class App extends Component {
  state = {
    ready: false,
    loading: false,
  };

  componentDidMount() {
    const _this = this;
    const pathComponents = window.location.pathname.split('/');

    for (var i = 0; i < pathComponents.length; i++) {
      var component = pathComponents[i];
      if (component != 'config') continue;

      RDP_CONFIG.configBase64 = pathComponents[i + 1];
      const data = JSON.parse(atob(RDP_CONFIG.configBase64));
      this.loadConfig(data);
      break;
    }

    if (RDP_CONFIG.configBase64) return;

    fetch('./api_context.json').then(async (response) => {
      const config = await response.json();
      this.loadConfig(config);
    });
  }

  async loadConfig(data) {
    RDP_CONFIG.config = data;
    RDP_CONFIG.projectKey = data.project_key || data.pk;
    RDP_CONFIG.api_env = data.api_env;

    if (data.custom_login_background != undefined || data.clb != undefined) {
      RDP_CONFIG.customLoginBackground =
        data.custom_login_background || data.clb;
    }

    if (data.api_env != undefined || data.ae != undefined) {
      RDP_CONFIG.apiEnv = data.api_env || data.ae;
    }

    if (data.project_uid != undefined || data.pu != undefined) {
      RDP_CONFIG.projectUid = data.project_uid || data.pu;
    }

    if (data.domain != undefined) {
      RDP_CONFIG.domain = data.domain;
    }

    // if (RDP_CONFIG.directToken){
    //   UserService.setUser({access_token:RDP_CONFIG.directToken, type:"Bearer"});
    //   await UserService.setActiveProject(RDP_CONFIG.projectUid, "Bearer", RDP_CONFIG.directToken);
    // }

    this.setState({ loading: false });
    this.initialize();
  }

  getActiveProject() {
    var saved = window.localStorage.getItem("rdp-manager-active-project");
    if (saved === null) {
      return Sdk.authorization.projects[0];
    } else {
      return Sdk.authorization.projects.find((project) => project.uid === saved);
    }
  }

  initialize() {
    var _this = this;

    if (RDP_CONFIG.config.domain != undefined) {
      Sdk.Api.domain = 'https://' + RDP_CONFIG.config.domain;
      //todo:corrigir sdk
      Sdk.Api.authorization.domain = Sdk.Api.domain;
      Sdk.Api.storage.domain = Sdk.Api.domain;
      Sdk.Api.store.domain = Sdk.Api.domain;
      Sdk.Api.insights.domain = Sdk.Api.domain;
      Sdk.Api.cms.domain = Sdk.Api.domain;
      Sdk.Api.iot.domain = Sdk.Api.domain;
      Sdk.Api.dynamic.domain = Sdk.Api.domain;
      Sdk.Api.dam.domain = Sdk.Api.domain;
      Sdk.Api.guide.domain = Sdk.Api.domain;
    }

    if (RDP_CONFIG.apiEnv != undefined) Sdk.setEnviroment(RDP_CONFIG.apiEnv);
    if (RDP_CONFIG.projectKey != undefined)
      Sdk.setProjectKey(RDP_CONFIG.projectKey);

    if (Sdk.Api.initialized === false) {
      const initializeFn = RDP_CONFIG.ProjectUid
        ? 'initializeFromProject'
        : 'initialize';
      const initializeParams = RDP_CONFIG.ProjectUid
        ? [RDP_CONFIG.ProjectUid, true]
        : [
            {
              initializeConnectors: true,
              autoInitializeProject: true,
              initializeProject: RDP_CONFIG.ProjectUid,
            },
            'News_1',
            'News_1',
          ];


      Sdk.Api[initializeFn](...initializeParams).then(function () {
        _this.setState({ ready: true });

        Sdk.authorization.setActiveProject(_this.getActiveProject())

        Sdk.Api.request.Request.handleError = async function (response) {
          if (response && response.status) {
            const { credential } = Sdk.Api.authorization;

            let currentTimestamp = new Date().getTime() / 1000;
            if (
              credential &&
              401 == response.status &&
              credential.expire &&
              currentTimestamp > credential.expire
            ) {
              window.dispatchEvent(
                new Event('@rdpmanager-admin.event(logout)')
              );
            }
          }
        };
        _this.redirectToLogin();
      });
    } else {
      this.redirectToLogin();
    }
  }

  // componentDidUpdate() {
  //   this.redirectToLogin();
  // }

  redirectToLogin() {
    if (Sdk.Api && !Sdk.Api.authorization.authenticated) {
      window.location = `${window.location.origin}${window.location.pathname}#/login`;
    }
  }

  render() {
    const { loading } = this.state;

    if (loading) return <div></div>;

    return (
      <ThemeProvider theme={theme}>
        <div id="root" className={`rdp-composer-player${window.self !== window.top ? '-embed' : ''}`}>
          {!this.state.ready ? (
            <Loading globalLoading={true} msg="Aguarde, carregando..." />
            ) : (
              <Routes />
          )}
        </div>
      </ThemeProvider>
    );
  }
}

export default App;
