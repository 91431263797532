export function objectToCsvFile(data: any[]) {
  const csv = [
    [...Object.keys((data[0]))], //CSV Header
    ...data.map(item => Object.values(item).map(value => '\"' + value + '\"')),
  ].map(e => e.join(';')).join('\n');

  // Muitos softwares do Microsoft Windows necessitam da Byte Order Mark(BOM) no começo do arquivo,
  // isso faz com que as ascentuações funcionem como o esperado ao representar o caracter usando códificação Windows-1252(CP1252)
  // FONTE: https://en.wikipedia.org/wiki/Byte_order_mark
  // Abaixo é criado um array com a representação UTF-8 do BOM.
  let BOM = new Uint8Array([0xEF, 0xBB, 0xBF]);
  return new Blob([BOM, csv], {
    type: 'text/csv',
  });
}