import SDK from 'api.digitalpages.module.sdk.api';
import api from './api';
import RDP_CONFIG from '../config/config';
import { getConnectorFromArray } from '../utils/getConnectorFromArray';

async function getConfig() {
  const config = await fetch('./api_context.json').then(async (response) => {
    const config = await response.json();
    this.loadConfig(config);
  });

  return config;
}

const DamService = function () {
  this.searchContent = async function (text = '', skip = 0, limit = 10) {
    return await SDK.Api.storage.searchContent('IMAGES', text, skip, limit);
  };

  this.getPublicUrl = async function (uid) {
    const token = await SDK.authorization.credential.accessToken;

    const response = await SDK.dynamic.bridge(
      `storage/v1.0/content/share/scope/public?uid=${uid}&access_token=${token}`,
      null,
      'POST'
    );

    return `${SDK.Api.domain}/storage/${SDK.Api.version}/content?uid=${response.share_uid}&project_key=${RDP_CONFIG.projectKey}&api_env=${RDP_CONFIG.api_env}`;
  };

  this.uploadFileAxios = async function (file, config, connectorService) {
    const connectors = await SDK.authorization.activeProject.connectors;

    const storageConnector = getConnectorFromArray(
      connectors,
      'Learning_1',
      'ContentManager'
    );

    const url = `${SDK.Api.domain}/storage/v1.0/upload/connector/uid/${storageConnector.uid}`;

    const data = new FormData();
    data.append('file', file);

    return api
      .post(url, data, config)
      .then(
        (response) =>
          // console.log("SUCCESS", response);
          response
      )
      .catch((error) => error.response);
  };

  return this;
};

const DAMService = new DamService();

export { DAMService };
