import styled, {css} from "styled-components";

export const MainContainer = styled.div`
  display: block;
  overflow: hidden;
  min-height: 800px;
`;

export const Filters = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
`;

export const Filter = styled.div`
  width: 300px;
`;

export const TableContainer = styled.div`
  overflow: auto;
`;

export const PaginationContainer = styled.div`
  margin: 20px;
`

export const Th = styled.th`
  ${props => props.highlighted && css`
    background-color: #EFEFEFFF;
    color: #764ab0;
    //border-left: 2px solid #764ab0;
    //border-right: 2px solid #764ab0;
  `}
`

export const Td = styled.td`
  ${props => props.highlighted && css`
    background-color: #EFEFEFFF;
    color: #764ab0;
    //border-left: 2px solid #764ab0;
    //border-right: 2px solid #764ab0;
  `}
`

export const NoContent = styled.td`
  height: 300px;
`;

export const Table = styled.table`
  &.rdp-admin-table {
    tr {
      td.no-content {
        text-align: center;
        vertical-align: middle;
      }
    }
  }
`;