import React, { useEffect, useState } from "react";
import uuidv1 from "uuid/v1";
import "./edit-tree.scss";
import RDP_CONFIG from "../../config/config";
import {
  Breadcrumb,
  ButtonIcon,
  Footer,
  Header,
  Iframe,
  MultiCheckbox,
  NotifyBox,
  Sidebar,
  TableDynamic
} from "../../components";
import Loading from "../../components/loading";
import Sdk from "api.digitalpages.module.sdk.api";
import Components from "rdp-builder-components";
import ButtonDynamic from "../../components/button-dynamic/button-dynamic";
import { DAMService } from "../../services/dam";
import axios from "axios";
import useAuthToken from "../../hooks/useAuthToken";
import Select from "react-select";
import RDP_UTILS from "../../config/util";
import { IconUpload } from "../../components/icon";
import * as S from "./edit-tree.styled";
import PropertiesDynamic from "../../components/properties-dynamic/properties-dynamic";
import toast from "react-hot-toast";
import $ from "jquery";
import { getConnectorFromArray } from "../../utils/getConnectorFromArray";

const course_type_evaluative = "EvaluativeContent";
const course_type_content = "Content";
const course_score_formula_type_sum = "Sum";
const course_score_formula_type_average = "Average";

export default function EditTree({ consumer, match, history }) {
  const [formIndex, setFormIndex] = useState(0);
  const [entity, setEntity] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [config, setConfig] = useState(null);
  const [tagTypes, setTagTypes] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [title, setTitle] = useState('');
  const [internalName, setInternalName] = useState('');
  const [description, setDescription] = useState('');
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [iframe, setIframe] = useState(null);
  const [content, setContent] = useState(null);
  const [newCourseThumb, setNewCourseThumb] = useState(null);
  const [thumb, setThumb] = useState(null);
  const [tree, setTree] = useState(null);
  const [copyTree, setCopyTree] = useState();
  const [course, setCourse] = useState(null);
  const [previewUrl, setPreviewUrl] = useState({
    type: '',
    url: '',
  });
  const [loading, setLoading] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState();
  const [msgError, setMsgError] = useState('');
  const [categoriesFields, setCategoriesFieldEntities] = useState({});
  const token = useAuthToken();
  const [categories, setCategories] = useState({});
  const [reviewFlows, setReviewFlows] = useState([]);
  const [products, setProducts] = useState([]);
  const [language, setLanguage] = useState('en');
  const [currentRegisterOption, setCurrentRegisterOption] = useState('null');
  const [overrides, setOverrides] = useState(null);
  const [register, setRegister] = useState(null);
  const [thumbIframe, setThumbIframe] = useState(null);
  const [isThumbModalOpen, setIsThumbModalOpen] = useState(false);
  const [courseType, setCourseType] = useState("");
  const [courseFormulaType, setCourseFormulaType] = useState("");
  const [composerIframeUrl, setComposerIframeUrl] = useState(null);
  const [updateCurrentActivity, setUpdateCurrentActivity] = useState(false);
  const [scormActivities, setScormActivities] = useState([]);
  const [selectedScormActivity, setSelectedScormActivity] = useState(null);
  const [currentActivityModal, setCurrentActivityModal] = useState(null);
  const [renderScormModal, setRenderScormModal] = useState(false);
  const [renderGroupCompositionModal, setRenderGroupCompositionModal] = useState(false);
  const [isGroupingCompositions, setIsGroupingCompositions] = useState(false);
  const [groupingCompositionsLength, setGroupingCompositionsLength] = useState(0);
  const [groupingCompositionsDone, setGroupingCompositionsDone] = useState(0);
  const [isFinishedGroupCompositions, setIsFinishedGroupCompositions] = useState(false);

  const openScormModal = () => {
    setRenderScormModal(true);
  }

  const closeScormModal = () => {
    setRenderScormModal(false);
  }

  const openGroupCompositionsModal = () => {
    setRenderGroupCompositionModal(true);
  }

  const handleOpenThumbIframe = () => {
    setThumbIframe(`${Sdk.Api.domain}/product/dam/current/${RDP_CONFIG.projectKey}/p/-/${Sdk.Api.enviroment === "production" ? "p" : "h"}/#/auth-with-token/${language}/images/${Sdk.authorization.activeProject.uid}/${token}`);
    setNewCourseThumb(null);
    const handler = async (event) => {
      if (event.data.content_type) {
        setContent(event.data);
        setThumbIframe(null);
        const url = await DAMService.getPublicUrl(event.data.uid);
        setNewCourseThumb(url);
      }
    };

    window.addEventListener('message', handler);

    // clean up
    return () => window.removeEventListener('message', handler);
  };


  async function handleSaveThumb() {
    setLoadingSubmit(true);
    let obj = { ...course };
    if (newCourseThumb) {
      obj.thumb_url = newCourseThumb;
    }

    try {
      await Sdk.dynamic.bridge(
        `cms/v1.0/learning/course/uid/${course.uid}/management`,
        obj,
        'PUT',
      );

      const tempCourse = await Sdk.dynamic.bridge(
        `cms/v1.0/learning/course/uid/${match.params.treeUid}/management`,
        null,
        'GET',
      );
      setCourse(tempCourse);
    } catch (error) {
      setMsgError('Erro ao atualizar');
    } finally {
      setIsThumbModalOpen(false);
      setNewCourseThumb(null);
      setLoadingSubmit(false);
    }
  }

  async function getConfig() {
    const response = await fetch('./preferences.json');
    const json = await response.json();

    setConfig(json.tables.offer);
    setTagTypes(json.general.tag_types);
    setLanguage(json.general.language);
  }

  async function getFormFields() {
    const response = await fetch('./preferences.json');

    const { forms,  } = await response.json();

    setEntity(forms['edit-tree']);
  }

  async function verifyChanges() {
    if (course.title !== title && title) {
      setDisabled(false);
      return;
    }

    if (course.description !== description && description) {
      setDisabled(false);
      return;
    }

    if (course.thumb_url) {
      setDisabled(false);
      return;
    }

    if (Object.keys(categories).length > 0) {
      setDisabled(false);
      return;
    }

    if (course.type !== courseType && courseType) {
      setDisabled(false);
      return;
    }

    if (course.type_formula_score !== courseFormulaType && courseFormulaType) {
      setDisabled(false);
      return;
    }

    setDisabled(true);
    return;
  }

  useEffect(() => {
    getFormFields();

    getTreeByUid(match.params.treeUid);

    getConfig();
  }, []);

  async function getCategoryEntities() {
    try {
      const categorySchemas = await Sdk.dynamic.bridge(
        'auth/v1.0/entity/management/schema/available?type=category'
      );

      if (!categorySchemas || categorySchemas.length === 0) {
        return [];
      }

      const entitiesPromises = categorySchemas.map(async (category) => {
        const schemaItems = await Sdk.dynamic.bridge(
          `auth/v1.0/entity/management/schema/uid/${category.uid}/items`
        );

        const options = schemaItems.map((item) => ({
          uid: item.uid,
          label: item.name || item.data['Nome'] || item.data['Name'],
        }));

        return {
          id: category.uid,
          group: category.name,
          required: true,
          options: options,
        };
      });

      const entities = await Promise.all(entitiesPromises);
      return entities;
    } catch (error) {
      console.error('Erro ao obter entidades de categoria:', error);
      return [];
    }
  }


  const loadEntities = async () => {
    if (
      Array.isArray(categoriesFields.options) &&
      categoriesFields.options.length > 0
    ) {
      return;
    }
    const categoryEntities = await getCategoryEntities();
    setCategoriesFieldEntities(categoryEntities);
  };

  useEffect(() => {
    setCategoriesFieldEntities([]);
    loadEntities();
  }, [entity]);

  useEffect(() => {
    if (course) verifyChanges();
  }, [title, description, thumb, categories, courseType, courseFormulaType]);

  const setPreviewScorm = async  (data, activity, internal_content_options) => {
    const preview = {};

    const activityUrl = `${Sdk.Api.domain}/storage/v1.0/content/?uid=${data.internal_content_uid || data.uid}`;

    const manifestUrl = `${Sdk.Api.domain}/storage/v1.0/content/path/manifest.json/?uid=${data.internal_content_uid || data.uid}&project_key=${Sdk.Api.projectKey}&api_env=${Sdk.Api.enviroment}`;

    const manifest = await fetch(manifestUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Project-Key': Sdk.Api.projectKey,
      }
    }).then((res) => res.json())
      .catch(console.error);

    if (manifest && "activities" in manifest && manifest.activities.length > 0) {
      setScormActivities(manifest.activities);
    } else {
      console.info("No activities found in manifest");
      setScormActivities([]);
    }

    const optionObj = {
      content_uid: data.internal_content_uid || data.uid,
      access_token: token,
      interaction_uid: "",
      activity: {
        uid: activity ? activity.uid : "",
        url: activityUrl,
        name: activity ? activity.name : "",
        mime_type: "package/scorm",
        internal_content_options: internal_content_options || selectedScormActivity,
      },
      course: {
        uid: match.params.treeUid,
        title: course.title || ""
      }
    };

    const options = window.btoa(JSON.stringify(optionObj));

    // o domínio necessita ser o mesmo do player para evitar erro de cors quando o conteúdo buscar a API do SCORM
    preview.url = `https://content-euconquisto.azureedge.net/product/learning_player/current/${Sdk.Api.authorization.projectKey}/p/${Sdk.Api.authorization.activeProject.uid}/${Sdk.Api.enviroment === "production" ? "p" : "h"}/?custom_options=${options}`;
    // preview.url = `http://localhost:3001/?custom_options=${options}#/`;
    preview.type = "scorm";

    setPreviewUrl(preview);
  }

  async function openIframe(url) {
    setIframe(url);
    const handler = async (event) => {
      if (event.data.content_type) {
        setContent(event.data);
        setIframe(null);
        setUpdateCurrentActivity(true);

        if (event.data.content_type?.includes('composer')) {
          const optionObj = {
            content_uid: event.data.uid,
            access_token: token,
          };
          const options = window.btoa(JSON.stringify(optionObj));

          setPreviewUrl({
            type: await activityPreviewType(event.data.content_type),
            content_type: event.data.content_type,
            url: `${Sdk.Api.domain}/product/composer_player/current/${RDP_CONFIG.projectKey}/p/${RDP_CONFIG.projectUid}/${Sdk.Api.enviroment === "production" ? "p" : "h"}/?custom_options=${options}`,
          });

          // setSelectedScormActivity(null);
          setScormActivities([]);
          setUpdateCurrentActivity(false);
        }
        else if (event.data.content_type?.includes('package/scorm')) {
          setPreviewScorm(event.data, currentActivityModal);
        }
        else {
          setPreviewUrl({
            type: await activityPreviewType(event.data.content_type),
            url: `${Sdk.Api.domain}/storage/v1.0/content/default/target?uid=${event.data.uid}&api_env=${RDP_CONFIG.api_env}&project_key=${RDP_CONFIG.projectKey}&access_token=${token}`,
          });
        }
      }
    };

    window.addEventListener('message', handler);

    // clean up
    return () => window.removeEventListener('message', handler);
  }

  async function getTreeByUid(treeUid) {
    setLoading(true);
    const tempTree = await Sdk.dynamic.bridge(
      `cms/v1.0/learning/course/uid/${treeUid}/management/nodes/tree`,
      null,
      'GET',
    ).then(fixNodesRow);

    const tempCourse = await Sdk.dynamic.bridge(
      `cms/v1.0/learning/course/uid/${treeUid}/management`,
      null,
      'GET',
    );

    try {
      const tempReviewFlows = await Sdk.dynamic.bridge(`cms/v1.0/review/flows`);
      setReviewFlows(tempReviewFlows);
    } catch (e) {
      console.log(e);
    }

    try {
      const tempProducts = await Sdk.dynamic.bridge(
        `store/v1.0/product/available`,
      );
      setProducts(tempProducts);
    } catch (e) {
      console.log(e);
    }

    await getRegisters(treeUid);

    setLoading(false);
    setCourse(tempCourse);
    setTitle(tempCourse.title);
    setDescription(tempCourse.description);
    setCourseType(tempCourse.type);
    setCourseFormulaType(tempCourse.type_formula_score);
    setCopyTree(JSON.parse(JSON.stringify(tempTree)));
    setTree(tempTree);


    if (currentRegisterOption !== 'null') {
      const tempRegister = await Sdk.dynamic.bridge(
        `cms/v1.0/learning/register/uid/${currentRegisterOption}/management`,
        null,
        'GET',
      );
      setOverrides(tempRegister.node_overrides);
      setTree(
        mergeOverrides(
          JSON.parse(JSON.stringify(copyTree)),
          tempRegister.node_overrides,
        ),
      );
    }
  }

  /**
   * Faz que todos os "nós" fiquem em sequência, algumas trilhas possuem "nós"
   * que estão na mesma linha ou com lacunas, ocasionando erros ao mudar a ordem
   *
   * @param tree
   * @returns {Promise<unknown>}
   */
  async function fixNodesRow(tree) {
    let nodesList = [];

    const mapTree = (tree) => {
      tree.forEach((node) => {
        nodesList.push(node);
        if (node.childs) {
          mapTree(node.childs);
        }
      });
    }

    mapTree([...tree]);

    if (nodesList.length > 0) {
      const sortedNodesList = [...nodesList]
        .sort((a, b) => a.row - b.row);

      const hasNodesInSameRow = sortedNodesList.some((node, index) => {
        if (index === 0) return false;
        return node.row === sortedNodesList[index - 1].row;
      });

      const hasRowGaps = sortedNodesList.some((node, index) => {
        if (index === 0) return false;
        return node.row - sortedNodesList[index - 1].row > 1;
      });

      const dontStartInZero = sortedNodesList[0].row !== 0;

      if (hasNodesInSameRow || hasRowGaps || dontStartInZero) {
        const promises = sortedNodesList.map(async (node, index) => {
          await Sdk.dynamic.bridge(
            `cms/v1.0/learning/node/uid/${node.uid}/management`,
            {
              ...node,
              row: index,
              activities: undefined
            },
            'PUT',
          );
        })

        await Promise.all(promises);

        return await Sdk.dynamic.bridge(
          `cms/v1.0/learning/course/uid/${match.params.treeUid}/management/nodes/tree`,
          null,
          'GET',
        );
      }
    }

    return tree;
  }

  async function getRegisters(treeUid) {
    const tempRegisters = await Sdk.dynamic.bridge(
      `cms/v1.0/learning/course/uid/${treeUid}/management/registers`,
      null,
      'GET',
    );

    const tempStructure = {
      result: tempRegisters,
      current_page: 1,
      page_count: 1,
      page_size: tempRegisters.length,
      row_count: tempRegisters.length,
    };

    setTableData(tempStructure);
  }

  async function onSaveNode(data) {
    setLoading(true);
    setTree([]);

    var node = data.node;
    var obj = {
      data: node.data,
    };

    if (newCourseThumb) {
      obj.data.thumb = await getThumbUrl(newCourseThumb);
    }

    obj.data.title = data.new_title;
    obj.data.description = data.new_description;
    obj.data.tag = data.new_tag_name;
    obj.data.tag_type = data.tag_type;
    obj.products = data.new_products;
    obj.type = data.type;
    obj.type_formula_score = data.type_formula_score;

    if (currentRegisterOption === 'null') {
      await Sdk.dynamic.bridge(
        `cms/v1.0/learning/node/uid/${node.uid}/management`,
        obj,
        'PUT',
      );
    } else {
      var overrideObj = {
        node_uid: node.uid,
        data: {
          title: data.new_title,
          description: data.new_description,
          tag: data.new_tag_name,
          tag_type: data.tag_type,
        },
        products: data.new_products,
      };

      if (newCourseThumb) {
        overrideObj.data.thumb = await getThumbUrl(newCourseThumb);
      }

      if (nodeExists(node.uid)) {
        delete overrideObj.node_uid;
        await Sdk.dynamic.bridge(
          `cms/v1.0/learning/node/override/uid/${
            nodeExists(node.uid).uid
          }/management`,
          overrideObj,
          'PUT',
        );
      } else {
        await Sdk.dynamic.bridge(
          `cms/v1.0/learning/register/uid/${currentRegisterOption}/management/override/node`,
          overrideObj,
          'POST',
        );
      }
    }

    getTreeByUid(match.params.treeUid);
  }

  function nodeExists(nodeUid) {
    var exist = null;
    if (overrides) {
      for (var i = 0; i < overrides.length; i++) {
        if (overrides[i].node_uid === nodeUid) {
          exist = overrides[i];
        }
      }
    }
    return exist;
  }

  async function onNodeDeadlineUpdate(nodeId, deadlines) {
    if (!nodeId || !deadlines) return;
    let interactionInterval = {
      interaction_interval_start: null,
      interaction_interval_end: null,
    };
    if (nodeId) {
      //Caso possua valores, estes são restaurados
      //deadlines contém apenas o input que foi alterado, ou seja, dias ou horas
      //ao salvar deve-se concatenar com os valores que já existem
      const node = tree.find((node) => node.uid == nodeId);

      if (node) {
        interactionInterval.interaction_interval_start =
          node.interaction_interval_start;
        interactionInterval.interaction_interval_end =
          node.interaction_interval_end;
      }
    }

    // Início após
    if (deadlines[0]) {
      //Dias
      const interval_start = deadlines[0].toString().padStart(2, '0');
      if (interactionInterval.interaction_interval_start) {
        const remaining_interval_start =
          interactionInterval.interaction_interval_start.substr(3);
        interactionInterval.interaction_interval_start = `${interval_start}.${remaining_interval_start}`;
      } else {
        interactionInterval.interaction_interval_start = `${interval_start}:00:00`;
      }
    }
    if (deadlines[1]) {
      //Horas
      let interval_start = '00';
      if (interactionInterval.interaction_interval_start) {
        interval_start = interactionInterval.interaction_interval_start.substr(
          0,
          2,
        );
      }
      const remaining_interval_start = deadlines[1].toString().padStart(2, '0');
      interactionInterval.interaction_interval_start = `${interval_start}.${remaining_interval_start}:00:00`;
    }
    // Término após
    if (deadlines[2]) {
      //Dias
      const interval_end = deadlines[2].toString().padStart(2, '0');
      if (interactionInterval.interaction_interval_end) {
        const remaining_interval_end =
          interactionInterval.interaction_interval_end.substr(3);
        interactionInterval.interaction_interval_end = `${interval_end}.${remaining_interval_end}`;
      } else {
        interactionInterval.interaction_interval_end = `${interval_end}:00:00`;
      }
    }
    if (deadlines[3]) {
      //Horas
      const remaining_interval_end = deadlines[3].toString().padStart(2, '0');
      if (interactionInterval.interaction_interval_end) {
        const interval_end =
          interactionInterval.interaction_interval_end.substr(0, 2);
        interactionInterval.interaction_interval_end = `${interval_end}.${remaining_interval_end}:00:00`;
      } else {
        interactionInterval.interaction_interval_end = `00.${remaining_interval_end}:00:00`;
      }
    }

    if (currentRegisterOption === 'null') {
      await Sdk.dynamic.bridge(
        `cms/v1.0/learning/node/uid/${nodeId}/management`,
        interactionInterval,
        'PUT',
      );
    } else {
      if (nodeExists(nodeId)) {
        await Sdk.dynamic.bridge(
          `cms/v1.0/learning/node/override/uid/${
            nodeExists(nodeId).uid
          }/management`,
          {
            interaction_interval_start:
              interactionInterval.interaction_interval_start.replace('..', '.'),
            interaction_interval_end:
              interactionInterval.interaction_interval_end.replace('..', '.'),
          },
          'PUT',
        );
      } else {
        await Sdk.dynamic.bridge(
          `cms/v1.0/learning/register/uid/${currentRegisterOption}/management/override/node`,
          {
            node_uid: nodeId,
            interaction_interval_start:
              interactionInterval.interaction_interval_start.replace('..', '.'),
            interaction_interval_end:
              interactionInterval.interaction_interval_end.replace('..', '.'),
          },
          'POST',
        );
      }
    }
  }

  async function onSaveActivity(data) {
    if (!data.keep_on_screen) {
      setUpdateCurrentActivity(false);
      setLoading(true);
      setTree(null);
    }

    var activity = data.activity;

    var obj = {
      name: data.new_title,
      maximum_attempts_after_completed:
      data.new_maximum_attempts_after_completed,
      maximum_attempts_after_failed: data.new_maximum_attempts_after_failed,
      maximum_attempts_after_passed: data.new_maximum_attempts_after_passed,
      score_max: data.score_max,
      score_min: data.score_min,
      score_weight: data.score_weight,
      duration: data.duration,
      type: data.type,
      type_formula_score: data.type_formula_score
    };

    if (content) {
      obj.internal_content_uid = content.uid;
      obj.mime_type = content.content_type;
    }

    if (selectedScormActivity) {
      obj.internal_content_options = selectedScormActivity;
    }

    obj.content_upload_required = data.activity.content_upload_required;
    obj.accepted_formats_user_upload =
      data.activity.content_upload_required === true
        ? ['application/pdf']
        : null;
    obj.review_flows = data.activity.review_flows;

    await Sdk.dynamic.bridge(
      `cms/v1.0/learning/activity/uid/${activity.uid}/management`,
      obj,
      'PUT',
    );

    if (!data.keep_on_screen) {
      setLoadingSubmit(false);
      setMsgSuccess('Atividade editada com sucesso');
      getTreeByUid(match.params.treeUid);
    };

    setCurrentActivityModal(null);
    setSelectedScormActivity(null);
  }

  async function onNewActivity(activity) {
    const nodeActivities = activity.current_node.activities;

    setLoadingSubmit(true);
    setTree(null);
    try {
      const obj = [
        {
          name: activity.new_title,
          internal_content_options: selectedScormActivity ? selectedScormActivity : null,
          internal_content_uid: content?.uid,
          mime_type: content?.content_type,
          order:
            nodeActivities.length > 0
              ? nodeActivities[nodeActivities.length - 1].order + 1
              : 1,
          type: activity.type,
          type_formula_score: activity.type_formula_score,
        },
      ];

      await Sdk.dynamic.bridge(
        `cms/v1.0/learning/node/uid/${activity.current_node.uid}/management/activities`,
        obj,
        'POST',
      );

      setLoadingSubmit(false);
      setMsgSuccess('Atividade cadastrada com sucesso');
      getTreeByUid(match.params.treeUid);
    } catch (error) {
      setLoadingSubmit(false);
      setMsgError('Erro ao criar atividade');
    }
  }

  function findLastNode(nodes) {
    let lastNode = null;
    nodes.map((node) => {
      if (lastNode === null) {
        lastNode = node;
      }
      if (node.row > lastNode.row) {
        lastNode = node;
      }
      if (node.childs) {
        let lastChild = findLastNode(node.childs);
        if (lastChild && lastChild.row > lastNode.row) {
          lastNode = lastChild;
        }
      }
    });
    return lastNode;
  }

  async function onNewNode(node) {
    let treeCp = [...tree];
    const treeBkp = tree;
    setLoadingSubmit(true);
    try {
      const obj = {
        data: {
          title: node.new_title,
          description: node.new_description,
          thumb: node?.new_thumb,
          tag: node.new_tag_name,
          tag_type: node.tag_type,
        },
        products: node.new_products,
        type: node.type,
        type_formula_score: node.type_formula_score,
      };

      // Possui um nó pai
      if (node.current_node) {
        obj.column = node.current_node.column + 1;
        if (node.current_node.childs) {
          // necessário mover todos os nodes após este
          obj.row = node.current_node.childs[node.current_node.childs.length - 1].row + 1;
        } else {
          obj.row = node.current_node.row + 1;
        }
      } else if (treeCp?.length > 0) {
        const nodes = await Sdk.dynamic.bridge(
          `cms/v1.0/learning/course/uid/${match.params.treeUid}/management/nodes`,
          null,
          'GET',
        );
        // Novo nó abaixo do último nó
        let lastNode = findLastNode(nodes);
        obj.column = 0;
        obj.row = lastNode.row + 1;
      } else {
        // Primeiro nó da trilha
        obj.column = 0;
        obj.row = 0;
      }

      const newNode = await Sdk.dynamic.bridge(
        `cms/v1.0/learning/course/uid/${match.params.treeUid}/management/node`,
        obj,
        'POST',
      );

      setMsgSuccess('Atividade cadastrada com sucesso');
      setTree([]);
      await getTreeNodes(match.params.treeUid);
      setLoadingSubmit(false);
    } catch (error) {
      setMsgError('Erro ao criar nó');
      setTree(treeBkp);
      setLoadingSubmit(false);
      throw error;
    }
  }

  async function getTreeNodes(treeUid) {
    const tempTree = await Sdk.dynamic.bridge(
      `cms/v1.0/learning/course/uid/${treeUid}/management/nodes/tree`,
      null,
      'GET',
    );
    setCopyTree(JSON.parse(JSON.stringify(tempTree)));
    setTree(tempTree);

    if (currentRegisterOption !== 'null') {
      const tempRegister = await Sdk.dynamic.bridge(
        `cms/v1.0/learning/register/uid/${currentRegisterOption}/management`,
        null,
        'GET',
      );
      setOverrides(tempRegister.node_overrides);
      setTree(
        mergeOverrides(
          JSON.parse(JSON.stringify(copyTree)),
          tempRegister.node_overrides,
        ),
      );
    }
  }

  async function activityPreviewType(mime_type) {
    if (mime_type?.includes('web') || mime_type?.includes('composer')) {
      return 'web';
    }
    if (mime_type?.includes('scorm')) {
      return 'scorm';
    }
    if (mime_type?.includes('image')) {
      return 'image';
    }
    if (mime_type?.includes('video')) {
      return 'video';
    }
  }

  async function getActivityPreviewUrl(activity, defineCurrentActivity = false, scormActivity) {
    const preview = {};

    const { data } = await axios.get(
      `${Sdk.Api.domain}/cms/v1.0/learning/activity/uid/${activity.uid}/management?access_token=${token}&project_key=${Sdk.Api.authorization.projectKey}&api_env=${Sdk.Api.enviroment}`,
    );

    let internal_content_options = data.internal_content_options;

    preview.type = await activityPreviewType(data.mime_type);

    if (defineCurrentActivity) {
      setCurrentActivityModal(data)
      setSelectedScormActivity(data.internal_content_options);
    }

    if (scormActivity) {
      setSelectedScormActivity(scormActivity);
      internal_content_options = scormActivity;
    }

    if (data.url) {
      preview.url = data.url;
      setPreviewUrl(preview);
      return;
    }

    if (data.internal_content_uid && data.mime_type?.includes('composer')) {
      const optionObj = {
        content_uid: data.internal_content_uid,
        access_token: token,
      };
      const options = window.btoa(JSON.stringify(optionObj));

      preview.content_type = data.mime_type;

      preview.url = `${Sdk.Api.domain}/product/composer_player/current/${Sdk.Api.authorization.projectKey}/p/${Sdk.Api.authorization.activeProject.uid}/${Sdk.Api.enviroment === "production" ? "p" : "h"}/?custom_options=${options}`;

      setPreviewUrl(preview);
    } else if (data.internal_content_uid && data.mime_type?.includes('scorm')) {
      setPreviewScorm(data, activity, internal_content_options);
    } else if (data.mime_type === 'text/html') {
      preview.url = `${Sdk.Api.domain}/storage/v1.0/content/?uid=${data.internal_content_uid}&api_env=${Sdk.Api.enviroment}&project_key=${Sdk.Api.authorization.projectKey}&access_token=${token}`;
      setPreviewUrl(preview);
    } else if (data.internal_content_uid) {
      preview.url = `${Sdk.Api.domain}/storage/v1.0/content/default/target?uid=${data.internal_content_uid}&api_env=${Sdk.Api.enviroment}&project_key=${Sdk.Api.authorization.projectKey}&access_token=${token}`;
      setPreviewUrl(preview);
    }
  }

  async function handleSubmit(data) {
    setLoadingSubmit(true);
    let obj = { ...course };
    if (thumb) {
      obj.thumb_url = await getThumbUrl(thumb);
    }

    obj.title = title;
    obj.description = description;
    obj.internal_name = internalName;
    obj.entities = Object.keys(categories).filter((key) => categories[key]);
    if (courseType !== "" ) obj.type = courseType;
    if (courseFormulaType !== "" && courseType !== "Content") {
      obj.type_formula_score = courseFormulaType;
    } else {
      obj.type_formula_score = null;
    }

    try {
      await Sdk.dynamic.bridge(
        `cms/v1.0/learning/course/uid/${course.uid}/management`,
        obj,
        'PUT',
      );

      history.push('/tree');
    } catch (error) {
      getTreeByUid(match.params.treeUid);
      setLoadingSubmit(false);
      setMsgError('Erro ao atualizar');
    }
  }

  async function getThumbUrl(file) {
    const response = await DAMService.uploadFileAxios(file, null, 'Storage');
    return await DAMService.getPublicUrl(response.data[0].uid);
  }

  async function handleReorderActivities(newOrder, activities) {
    let itemsToUpdate = newOrder.map((item, idx) => {
      if (item.uid !== activities[idx].uid) {
        return { uid: item.uid, order: activities[idx].order }; //save the id of the new item and the order of the old item to reorder them
      }
    });

    var filtered = itemsToUpdate.filter(function(x) {
      return x !== undefined;
    });

    if (filtered.length === 0) return;

    setLoading(true);
    setTree(null);

    try {
      for (const item of filtered) {
        var obj = {
          order: item.order,
        };

        await Sdk.dynamic.bridge(
          `cms/v1.0/learning/activity/uid/${item.uid}/management`,
          obj,
          'PUT',
        );
      }

      setLoadingSubmit(false);
      setMsgSuccess('Atividade registrada com sucesso');
      getTreeByUid(match.params.treeUid);
    } catch (error) {
      setLoadingSubmit(false);
      setMsgSuccess();
      setMsgError(
        'Ocorreu um erro ao reordenar algumas atividades, tente novamente',
      );
      getTreeByUid(match.params.treeUid);
    }
  }

  async function calcChildrenRow(arr, firstNumber) {
    let i = firstNumber;
    let cpy = [...arr];

    for (const item of cpy) {
      item.row = i;
      i++;
      if (item.childs?.length > 0) {
        const [newArr, newNumber] = await calcChildrenRow(item.childs, i);

        i = newNumber;
      }
    }

    return [cpy, i];
  }

  async function getArrayToUpdate(newArr, oldArr) {
    let toUpdate = [];

    for (const [idx, item] of newArr.entries()) {
      if (!oldArr) {
        toUpdate.push({ uid: item.uid, row: item.row });
      } else if (!oldArr[idx]) {
        toUpdate.push({ uid: item.uid, row: item.row });
      } else if (item.uid !== oldArr[idx].uid) {
        toUpdate.push({ uid: item.uid, row: item.row });
      }

      if (item.childs?.length > 0) {
        const childrenArr = await getArrayToUpdate(
          item.childs,
          oldArr ? oldArr[idx]?.childs : null,
        );

        toUpdate = toUpdate.concat(childrenArr);
      }
    }

    return toUpdate;
  }

  const handleNodeDamClick = (event, cb) => {
    setThumbIframe(`${Sdk.Api.domain}/product/dam/current/${RDP_CONFIG.projectKey}/p/-/${Sdk.Api.enviroment === "production" ? "p" : "h"}/#/auth-with-token/${language}/images/${Sdk.authorization.activeProject.uid}/${token}`);
    setNewCourseThumb(null);
    const handler = async (event) => {
      if (event.data.content_type) {
        setContent(event.data);
        setThumbIframe(null);
        const url = await DAMService.getPublicUrl(event.data.uid);
        cb(url);
      }
    };

    window.addEventListener('message', handler);

    // clean up
    return () => window.removeEventListener('message', handler);
  };

  async function handleReorderNodes(newOrder, nodes) {
    const [array, lastRow] = await calcChildrenRow(newOrder, nodes[0].row);

    const toUpdate = await getArrayToUpdate(array, nodes);

    setLoading(true);
    setTree(null);

    try {
      for (const item of toUpdate) {
        var obj = {
          row: item.row,
        };

        await Sdk.dynamic.bridge(
          `cms/v1.0/learning/node/uid/${item.uid}/management`,
          obj,
          'PUT',
        );
      }

      setLoadingSubmit(false);
      setMsgSuccess('Nós reordenados com sucesso');
      getTreeByUid(match.params.treeUid);
    } catch (error) {
      setLoadingSubmit(false);
      setMsgSuccess();
      setMsgError(
        'Ocorreu um erro ao reordenar algumas atividades, tente novamente',
      );
      getTreeByUid(match.params.treeUid);
    }
  }

  async function handleDeleteActivity(activityUid) {
    setLoadingSubmit(true);
    setTree(null);

    try {
      await Sdk.dynamic.bridge(
        `cms/v1.0/learning/activity/uid/${activityUid}/management?force=true`,
        null,
        'DELETE',
      );

      setLoadingSubmit(false);
      setMsgSuccess('Activity Successfully deleted');
      getTreeByUid(match.params.treeUid);
    } catch (error) {
      setLoadingSubmit(false);
      setMsgError('Erro ao excluir atividade, tente novamente');
    }
  }

  async function handleDeleteNodeChildrens(nodeUid) {
    try {
      let treeCopy = [...tree];

      const findNode = (node) => {
        if (node.uid === nodeUid) {
          return node;
        }
        if (node.childs) {
          for (const child of node.childs) {
            const result = findNode(child);
            if (result) {
              return result;
            }
          }
        }
      }

      const forEachNodeChild = (node, cb) => {
        if (node.childs) {
          for (const child of node.childs) {
            cb(child);
            forEachNodeChild(child, cb);
          }
        }
      }

      let node = null;

      for (const findNodeElement of treeCopy) {
        node = findNode(findNodeElement);
        if (node) {
          break;
        }
      }

      if (node) {
        forEachNodeChild(node, (child) => {
          Sdk.dynamic.bridge(
            `cms/v1.0/learning/node/uid/${child.uid}/management`,
            null,
            'DELETE',
          );
        });
      }
    } catch (e) {
      console.error(e)
    }
  }

  async function handleDeleteNode(nodeUid) {
    setLoadingSubmit(true);

    await handleDeleteNodeChildrens(nodeUid);

    try {
      await Sdk.dynamic.bridge(
        `cms/v1.0/learning/node/uid/${nodeUid}/management`,
        null,
        'DELETE',
      );

      setMsgError('');
      setLoadingSubmit(false);
      setMsgSuccess('Nó excluído com sucesso');
      setTree(null);
      getTreeByUid(match.params.treeUid);
    } catch (error) {
      setLoadingSubmit(false);
      setMsgError('Erro ao excluir o nó, tente novamente');
    }
  }

  async function handleRegisterDelete(uid) {
    await toast.promise(
      Sdk.dynamic.bridge(
        `cms/v1.0/learning/register/uid/${uid}/management?force=true`,
        null,
        'DELETE',
      ),
      {
        loading: "Apagando turma...",
        success: `Turma apagada com sucesso`,
        error: "Erro ao apagar turma",
      }
    )

    getRegisters(match.params.treeUid);
  }

  const getRegistersOptions = () => {
    var registers = tableData.result;
    var temp = [
      {
        label: 'Estrutura padrão do curso',
        value: 'null',
      },
    ];
    for (var i = 0; i < registers.length; i++) {
      temp.push({
        label: registers[i].name,
        value: registers[i].uid,
      });
    }
    return temp;
  };

  async function getRegister(uid) {
    setLoading(true);
    setCurrentRegisterOption(uid);
    setTree([]);
    if (uid !== 'null') {
      const tempRegister = await Sdk.dynamic.bridge(
        `cms/v1.0/learning/register/uid/${uid}/management`,
        null,
        'GET',
      );
      setRegister(tempRegister);
      setOverrides(tempRegister.node_overrides);
      setTree(
        mergeOverrides(
          JSON.parse(JSON.stringify(copyTree)),
          tempRegister.node_overrides,
        ),
      );
      setLoading(false);
    } else {
      setTimeout(function() {
        setOverrides(null);
        setTree(copyTree);
        setLoading(false);
      }, 300);
    }
  }

  const mergeOverrides = (tmpNodes, nodes) => {
    for (var i = 0; i < tmpNodes.length; i++) {
      for (var j = 0; j < nodes.length; j++) {
        if (tmpNodes[i].uid === nodes[j].node_uid) {
          if (nodes[j].interaction_interval_end !== null)
            tmpNodes[i].interaction_interval_end =
              nodes[j].interaction_interval_end;
          if (nodes[j].interaction_interval_limit !== null)
            tmpNodes[i].interaction_interval_limit =
              nodes[j].interaction_interval_limit;
          if (nodes[j].interaction_interval_start !== null)
            tmpNodes[i].interaction_interval_start =
              nodes[j].interaction_interval_start;
          if (nodes[j].products.length > 0)
            tmpNodes[i].products = nodes[j].products;
          if (nodes[j].tags.length > 0) tmpNodes[i].tags = nodes[j].tags;
          if (nodes[j].data !== null) {
            if (nodes[j].data.title)
              tmpNodes[i].data.title = nodes[j].data.title;
            if (nodes[j].data.description)
              tmpNodes[i].data.description = nodes[j].data.description;
            if (nodes[j].data.tag) tmpNodes[i].data.tag = nodes[j].data.tag;
            if (nodes[j].data.tag_type)
              tmpNodes[i].data.tag_type = nodes[j].data.tag_type;
            if (nodes[j].data.thumb)
              tmpNodes[i].data.thumb = nodes[j].data.thumb;
          }
        }
      }

      if (tmpNodes[i].childs) {
        tmpNodes[i].childs = mergeOverrides(tmpNodes[i].childs, nodes);
      }
    }

    return tmpNodes;
  };

  async function deadlineOnSave(deadline) {
    var nodeId = deadline.node.uid;

    // "start": startDate,
    // "end": endDate,
    // "node": node.uid

    if (currentRegisterOption === 'null') {
      await Sdk.dynamic.bridge(
        `cms/v1.0/learning/node/uid/${nodeId}/management`,
        {
          'interaction_interval_start': deadline.start,
          'interaction_interval_end': deadline.end,
        },
        'PUT',
      );
    } else {
      if (nodeExists(nodeId)) {
        await Sdk.dynamic.bridge(
          `cms/v1.0/learning/node/override/uid/${
            nodeExists(nodeId).uid
          }/management`,
          {
            'interaction_interval_start': deadline.start,
            'interaction_interval_end': deadline.end,
          },
          'PUT',
        );
      } else {
        await Sdk.dynamic.bridge(
          `cms/v1.0/learning/register/uid/${currentRegisterOption}/management/override/node`,
          {
            'node_uid': nodeId,
            'interaction_interval_start': deadline.start,
            'interaction_interval_end': deadline.end,
          },
          'POST',
        );
      }
    }

    getRegister(currentRegisterOption);
  }

  function handleChangeCourseProperties(value, prop) {
    const courseCopy = {...course};

    courseCopy.properties = courseCopy.properties.map((property) => {
      if (property.uid === prop.uid) {
        property.value = value;
      }
      return property;
    });

    setCourse(courseCopy);
  }

  function getComposerDomain() {
    // return "http://localhost:3001";
    return Sdk.Api.enviroment === "production" ? "https://composer.euconquisto.com" : "https://composer-hmg.euconquisto.com"
  }

  async function openComposer(activity) {
    var projectUid = Sdk.authorization.activeProject.uid;
    var accessToken = Sdk.authorization.credential.accessToken;

    const act = await Sdk.dynamic.bridge(`cms/v1.0/learning/activity/uid/${activity.uid}/management`);

    setComposerIframeUrl(`${getComposerDomain()}/#/embed/auth-with-token/pt_br/home/${projectUid}/${accessToken}/${act.internal_content_uid}`)

    const handler = async (event) => {
      if (event.data && event.data.origin === "composer") {
        if (event.data.action === "close") {
          setComposerIframeUrl(null);
          if (event.data.new_composition_uid) {
            setContent({
              "uid": event.data.new_composition_uid,
              "content_type": "digitalpages/composer"
            });
            setUpdateCurrentActivity(true);
            const optionObj = {
              content_uid: event.data.new_composition_uid,
              access_token: token,
            };
            const options = window.btoa(JSON.stringify(optionObj));

            setPreviewUrl({
              type: "web",
              content_type: "digitalpages/composer",
              url: `${Sdk.Api.domain}/product/composer_player/current/${RDP_CONFIG.projectKey}/p/${RDP_CONFIG.projectUid}/${Sdk.Api.enviroment === "production" ? "p" : "h"}/?custom_options=${options}`,
            });
          }
        }
      }
    };

    window.addEventListener('message', handler);

    // clean up
    return () => window.removeEventListener('message', handler);
  }

  function getScorms() {
    var scorms = [];
    if (tree) {
      for (var i = 0; i < tree.length; i++) {
        var childs = tree[i].childs;
        for (var j = 0; j < childs.length; j++) {
          var activities = childs[j].activities;
          for (var k = 0; k < activities.length; k++) {
            activities[k].scorm_name = `${tree[i].data.title} > ${childs[j].data.title} > ${activities[k].name}`;
            scorms.push(activities[k]);
          };
        };
      };
    };
    return scorms;
  }

  function formatScormName(name) {
    name = name.toLowerCase();
    name = name.replaceAll("?", "");
    name = name.replaceAll("!", "");
    name = name.replaceAll(" ", "-");
    name = name.replaceAll(">", "-");
    name = name.replaceAll(/[ÀÁÂÃÄÅ]/g,"A");
    name = name.replaceAll(/[àáâãäå]/g,"a");
    name = name.replaceAll(/[óòõô]/g,"o");
    name = name.replaceAll(/[ÈÉÊË]/g,"E");
    name = name.replaceAll(/[èéê]/g,"e");
    name = name.replaceAll(/[ùúû]/g,"u");
    name = name.replaceAll(/[ìíî]/g,"i");
    name = name.replaceAll(",", "");
    name = name.replaceAll("ç", "c");
    name = name.replaceAll("_", "");
    name = name.replaceAll("---", "-");
    name = name.replaceAll(".", "_");
    return name;
  }

  async function downloadScorm(uid, name) {
    document.querySelector(`span[data-download-scorm-uid="${uid}"]`).classList.add("downloading");
    const { data } = await axios.get(
      `${Sdk.Api.domain}/cms/v1.0/learning/activity/uid/${uid}/management?access_token=${token}&project_key=${Sdk.Api.authorization.projectKey}&api_env=${Sdk.Api.enviroment}`,
    );

    $.ajax({
      url: `${Sdk.Api.domain}/storage/v1.0/content/private/convert/format/scorm?external_structure=true&uid=${data.internal_content_uid}`,
      type: "GET",
      xhrFields:{
        responseType: 'blob'
      },
      headers: {
        "Authorization": "Bearer " + token,
        "Project-Key" : Sdk.Api.authorization.projectKey,
        "Api-Env" : Sdk.Api.enviroment
      }
    }).then(function(blob) {
      downloadBlob(blob, formatScormName(name), uid);
    }, function(error) {
      console.log(error)
    });
  }

  function downloadBlob(blob, name, uid) {
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.href = blobUrl;
    link.download = name;

    document.body.appendChild(link);

    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );

    document.body.removeChild(link);

    document.querySelector(`span[data-download-scorm-uid="${uid}"]`).classList.remove("downloading");
  }

  function canDownloadScorms() {
    var can = false;
    var connectors = Sdk.Api.authorization.activeProject.connectors;
    var customizationConnectors = connectors.filter(connector => connector.type === "Customization_1");
    if (customizationConnectors.length > 0) {
      var customizationConnector = customizationConnectors[0];

      if (customizationConnector.configuration) {
        var configuration = JSON.parse(customizationConnector.configuration);
        var configs = configuration.configs;
        var composerConfig = configs.find(cnf => cnf.uid === "composer");

        if (composerConfig !== undefined && composerConfig.value) {
          var composerConfigItems = JSON.parse(composerConfig.value);

          for (var i = 0; i < composerConfigItems.length; i++) {
            if (composerConfigItems[i].uid === "enable_download") {
              can = composerConfigItems[i].value;
            };
          };
        };
      };
    };

    return can;
  };

  function refreshIds(str) {
    var composition = JSON.parse(JSON.stringify(str));

    var structure = composition.structure;
    for (var i = 0; i < structure.length; i++) {
      var obj = structure[i];
      obj.id = uuidv1();

      var images = obj.images;
      if (images) {
        for (var j = 0; j < images.length; j++) {
          images[j].id = uuidv1();
        }
      }

      var items = obj.items;
      if (items) {
        for (var j = 0; j < items.length; j++) {
          items[j].id = uuidv1();
        }
      }

      var questions = obj.questions;
      if (questions) {
        for (var j = 0; j < questions.length; j++) {
          questions[j].id = uuidv1();

          var choices = questions[j].choices;
          if (choices) {
            for (var k = 0; k < choices.length; k++) {
              choices[k].id = uuidv1();
            }
          }
        }
      }
    }

    composition.structure = structure;

    return JSON.stringify(composition);
  }

  async function groupCompositions() {
    var scorms = getScorms()
    setGroupingCompositionsLength(scorms.length);
    console.log(scorms);

    for (var i = 0; i < scorms.length; i++) {
      const { data } = await axios.get(
        `${Sdk.Api.domain}/cms/v1.0/learning/activity/uid/${scorms[i].uid}/management?access_token=${token}&project_key=${Sdk.Api.authorization.projectKey}&api_env=${Sdk.Api.enviroment}`,
      );

      const composerContent = await axios.get(
        `${Sdk.Api.domain}/storage/v1.0/content?uid=${data.internal_content_uid}&access_token=${token}&project_key=${Sdk.Api.authorization.projectKey}&api_env=${Sdk.Api.enviroment}`,
      );

      scorms[i].composer_content = composerContent.data;
      console.log("DOWNLOADED: " + (i + 1))

      if (scorms.length !== (i + 1)) {
        setGroupingCompositionsDone(i + 1);
      }
    }

    var initialStructure = scorms[0].composer_content;

    var tempInitialStructure = [{
      "id": uuidv1(),
      "content_title": initialStructure.metadata.title,
      "title": initialStructure.metadata.title,
      "type": "virtual-index-1",
      "dam_assets": []
    }];

    initialStructure.structure = tempInitialStructure.concat(initialStructure.structure);

    for (var i = 1; i < scorms.length; i++) {
      initialStructure.version = "1.0";
      initialStructure.metadata.title = course.title;
      initialStructure.interface.finish_btn = "enabled";
      initialStructure.interface.index_option = "buttons";
      initialStructure.interface.show_summary = "enabled";
      initialStructure.assets = initialStructure.assets.concat(scorms[i].composer_content.assets);
      initialStructure.structure.push({
        "id": uuidv1(),
        "content_title": scorms[i].composer_content.metadata.title,
        "title": scorms[i].composer_content.metadata.title,
        "type": "virtual-index-1",
        "dam_assets": []
      })
      initialStructure.structure = initialStructure.structure.concat(scorms[i].composer_content.structure);
    }

    console.log(initialStructure);

    saveComposition(initialStructure);
  }

  function blobToFile(theBlob, fileName) {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }

  async function getCompositionFile(structure) {
    var myBlob = new Blob([structure], { type: "application/json" });
    return blobToFile(myBlob, "composition.rdpcomposer");
  }

  function stringify(obj) {
    let cache = [];
    let str = JSON.stringify(obj, function(key, value) {
      if (typeof value === "object" && value !== null) {
        if (cache.indexOf(value) !== -1) {
          // Circular reference found, discard key
          return;
        }
        // Store value in our collection
        cache.push(value);
      }
      return value;
    });
    cache = null; // reset the cache
    return str;
  }

  async function saveComposition(structure) {
    structure = refreshIds(structure);
    // var temp = stringify(structure)
    const { connectors } = Sdk.authorization.activeProject;
    const learningConnector = getConnectorFromArray(connectors, "Learning_1", "ContentManager");
    var url = `${Sdk.domain}/storage/v1.0/upload/connector/uid/${learningConnector.uid}`;
    var fd = new FormData();
    var files = await getCompositionFile(structure);

    fd.append('file', files, "composition.rdpcomposer")

    let tries = 0;

    const fetchRequest = async () => {
      try {
        var uploaded = await $.ajax({
          url: url,
          type: "POST",
          data: fd,
          contentType: false,
          processData: false,
          headers: {
            "Authorization": `Bearer ${Sdk.Api.authorization.credential.accessToken}`,
            "Project-Key": Sdk.projectKey,
            "Api-Env": Sdk.Api.enviroment
          }
        });

        setIsFinishedGroupCompositions(true);

        return uploaded;
      } catch (error) {
        if (tries < 3) {
          tries++;
          return fetchRequest();
        }
        throw error;
      }
    }

    try {
      return await fetchRequest();
    } catch (error) {
      return null;
    }
  }

  return (
    <>
      {entity ? (
        <div className='rdp-admin-edit-tree'>
          <Header />
          <Sidebar
            defineGroup={consumer ? consumer.defineGroup : null}
            defineRoute={consumer ? consumer.defineRoute : null}
            groups={consumer ? consumer.groups : null}
          />
          <Breadcrumb currentRoute={consumer ? consumer.currentRoute : null} />
          <Footer />
          <div className='rdp-admin-content rdp-admin-component-form-dynamic'>
            {loading && <Loading msg={entity && entity.loadingMessage} />}
            {loadingSubmit && <Loading msg='Salvando...' />}
            {!loadingSubmit && msgSuccess && (
              <NotifyBox
                type='success'
                onClose={() => setMsgSuccess('')}
                message={msgSuccess}
              />
            )}
            {!loadingSubmit && msgError && (
              <NotifyBox
                type='error'
                onClose={() => setMsgError('')}
                message={msgError}
              />
            )}
            {tree && (
              <>
                <div className='form-main-content all-page'>
                  <div className='content-fields'>
                    <div className='form-buttons'>
                      <button
                        className={`tab-button ${
                          formIndex === 0 ? 'active' : null
                        }`}
                        onClick={() => setFormIndex(0)}
                      >
                        {entity && entity.labelInformations}
                      </button>
                      <button
                        className={`tab-button ${
                          formIndex === 1 ? 'active' : null
                        }`}
                        onClick={() => setFormIndex(1)}
                      >
                        {entity && entity.labelRegisters}
                      </button>
                      <button
                        className={`tab-button ${
                          formIndex === 2 ? 'active' : null
                        }`}
                        onClick={() => setFormIndex(2)}
                      >
                        {entity && entity.labelStructure}
                      </button>
                    </div>
                    <div className={`item ${formIndex === 0 ? 'open' : null}`}>
                      <div className='tree-form'>
                        <div className='edit-tier-upper-section-wrapper'>
                          <div className='content-info'>
                            <label className='label' htmlFor={'title'}>
                              {entity && entity.labelTitle}
                            </label>
                            <Components.Forms.InputText
                              name='title'
                              type='text'
                              placeholder='Nome do curso'
                              defaultValue={course.title}
                              onChange={(e) => {
                                setTitle(e.target.value);
                              }}
                              border='1px solid #dbdfe6'
                              borderRadius='4px'
                              marginLeft='20px'
                              marginRight='20px'
                              marginBottom='20px'
                              height='44px'
                              fontColor='#575757'
                            />
                            <label className='label' htmlFor={'title'}>
                              {entity && entity.labelInternalName}
                            </label>
                            <Components.Forms.InputText
                              name='title'
                              type='text'
                              placeholder='Nome do curso'
                              defaultValue={course.internal_name}
                              onChange={(e) => {
                                setInternalName(e.target.value);
                              }}
                              border='1px solid #dbdfe6'
                              borderRadius='4px'
                              marginLeft='20px'
                              marginRight='20px'
                              marginBottom='20px'
                              height='44px'
                              fontColor='#575757'
                            />
                            <label className='label' htmlFor={'type'}>
                              {entity && entity.labelType}
                            </label>
                            <S.SelectContainer>
                              <Select
                                placeholder={entity.labelType}
                                defaultValue={String(
                                  courseType
                                )}
                                options={[
                                  {
                                    label: entity.labelTypeContent,
                                    value: course_type_content,
                                  },
                                  {
                                    label: entity.labelTypeEvaluativeContent,
                                    value: course_type_evaluative,
                                  },
                                ]}
                                styles={RDP_UTILS.MultipleSelect.fullWidthStyleFilter}
                                onChange={(option) => setCourseType(option.value)}
                              />
                            </S.SelectContainer>
                            {
                              courseType === course_type_evaluative
                              && (
                                <>
                                  <label className='label' htmlFor={'type_formula_score'}>
                                    {entity && entity.labelTypeFormula}
                                  </label>
                                  <S.SelectContainer>
                                    <Select
                                      placeholder={entity.labelTypeFormula}
                                      defaultValue={String(
                                        courseFormulaType
                                      )}
                                      options={[
                                        {
                                          label: entity.labelTypeFormulaSum,
                                          value: course_score_formula_type_sum,
                                        },
                                        {
                                          label: entity.labelTypeFormulaAverage,
                                          value: course_score_formula_type_average,
                                        },
                                      ]}
                                      styles={RDP_UTILS.MultipleSelect.fullWidthStyleFilter}
                                      onChange={(option) => setCourseFormulaType(option.value)}
                                    />
                                  </S.SelectContainer>
                                </>
                              )
                            }
                            <label className='label' htmlFor={'title'}>
                              {entity && entity.labelDescription}
                            </label>
                            <Components.Forms.Textarea
                              height='200px'
                              name='description'
                              type='text'
                              placeholder='Descrição do curso'
                              defaultValue={course.description}
                              onChange={(e) => setDescription(e.target.value)}
                              border='1px solid #dbdfe6'
                              borderRadius='4px'
                              marginLeft='20px'
                              marginRight='20px'
                              fontColor='#575757'
                            />
                          </div>
                          <div className='image-dropzone'>
                            <label className='label' htmlFor={'title'}>
                              {entity && entity.labelThumbnail}
                            </label>
                            <Components.Images.ImagePreview
                              src={course ? course.thumb_url : ''}
                              alt='thumb image'
                              border='dashed'
                              lazy
                              onClick={() => setIsThumbModalOpen(true)}
                            />
                            <ButtonIcon
                              tooltip={entity.labelSelectFile}
                              actionClick={() => setIsThumbModalOpen(true)}
                              color='primary'
                            >
                              <IconUpload />
                            </ButtonIcon>
                            {
                              isThumbModalOpen &&
                              <Components.Modals.ModalContentSave
                                title={entity && entity.labelThumbnail}
                                saveLabel={loadingSubmit ? entity.labelWaintingSaveThumb : entity.labelSaveThumb}
                                saveIcon={<Components.Icons.IconSave />}
                                onSave={e => {
                                  if (!loadingSubmit)
                                    handleSaveThumb();
                                }}
                                onClose={() => setIsThumbModalOpen(false)}
                              >
                                <Components.Buttons.CardButton
                                  title={entity.modalLabelFileSelect}
                                  subtitle={entity.modalThumbFileAddDescription}
                                  onClick={() => handleOpenThumbIframe()}
                                />
                                <label className='label'>
                                  {entity.modalPreviewLabel}
                                </label>
                                <Components.Images.ImagePreview
                                  src={newCourseThumb ?? ''}
                                  alt='thumb image'
                                  border='dashed'
                                  lazy
                                  onClick={() => handleOpenThumbIframe()}
                                />
                              </Components.Modals.ModalContentSave>
                            }
                          </div>
                        </div>
                        {categoriesFields &&
                          categoriesFields.map((item, index) => (
                            <div key={index} className='edit-tree-categories'>
                              <MultiCheckbox
                                field={categoriesFields[index]}
                                defaultValues={course.entities}
                                handleChange={(checked, optionUid) => {
                                  setCategories((oldSt) => ({
                                    ...oldSt,
                                    [optionUid]: checked,
                                  }));
                                }}
                              />
                            </div>
                          ))}
                        {course && course.properties && (
                          <div className="edit-tree-props-container">
                            <PropertiesDynamic
                              values={course.properties}
                              onChange={handleChangeCourseProperties}
                              translations={entity}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={`item ${formIndex === 1 ? 'open' : null}`}>
                      <div className='tree-form'>
                        <div className='content-registers'>
                          <div className='btn-new-offer'>
                            <ButtonDynamic
                              disabled={false}
                              className='teste'
                              size='fit'
                              color='blue'
                              icon='plus'
                              actionClick={() =>
                                history.push(
                                  `/offer/new/${match.params.treeUid}`,
                                )
                              }
                              iconDiv={true}
                            >
                              {entity && entity.labelNewOffer}
                            </ButtonDynamic>
                          </div>
                          <TableDynamic
                            title={'Offers'}
                            configuration={config.details}
                            headers={(config.details || []).slice(0, 4)}
                            defaultFields={config.fields}
                            data={tableData.result}
                            hasCheckbox={false}
                            hasEdit={true}
                            hasDelete={true}
                            hasClone={false}
                            onUpdate={(e) =>
                              history.push(
                                `/offer/${match.params.treeUid}/${e}`,
                              )
                            }
                            onDelete={e => handleRegisterDelete(e)}
                            onClone={() => {
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={`item ${formIndex === 2 ? 'open' : null}`}>
                      <div className='content-structure'>
                        <div className='btn-course-content-actions'>
                          <>
                            {canDownloadScorms() &&
                              <ButtonDynamic
                                disabled={false}
                                className='teste'
                                size='fit'
                                color='blue'
                                icon='plus'
                                actionClick={() =>
                                  openScormModal()
                                }
                                iconDiv={true}
                              >
                                {entity && entity.labelDownloadScorm}
                              </ButtonDynamic>
                            }
                            <ButtonDynamic
                              disabled={false}
                              className='teste'
                              size='fit'
                              color='blue'
                              icon='list'
                              actionClick={() =>
                                openGroupCompositionsModal()
                              }
                              iconDiv={true}
                            >
                              {entity && entity.labelCreateUnifiedComposition}
                            </ButtonDynamic>
                          </>
                        </div>
                        <label className='label-2'>
                          Escolha a estrutura da trilha que você deseja realizar
                          as alterações:
                        </label>
                        <div style={{marginBottom: "25px"}}>
                          <Select
                            placeholder='Estrutura padrão do curso'
                            defaultValue='null'
                            options={getRegistersOptions()}
                            styles={RDP_UTILS.MultipleSelect.fullWidthStyleFilter}
                            onChange={(option) => getRegister(option.value)}
                          />
                        </div>
                        <>
                          <label className='label-2'>
                            Estrutura da trilha:
                          </label>
                          <Components.Recursive.TreeEdit
                            deadlineOnChange={(deadline) => deadlineOnSave(deadline)}
                            deadlineVersion='v2'
                            isDragDisabled={currentRegisterOption !== 'null'}
                            disableEdit={currentRegisterOption !== 'null'}
                            labelNode={entity.modalLabelNode}
                            labelActivity={entity.modalLabelActivity}
                            labelColor={entity.modalLabelColor}
                            previewLabel={entity.modalPreviewLabel}
                            nodeTitle={entity.modalNodeTitle}
                            nodeDescription={entity.modalNodeDescription}
                            nodeThumbnail={entity.modalNodeThumbnail}
                            nodeTagName={entity.modalNodeTagName}
                            nodeTagType={entity.modalNodeTagType}
                            nodeProductsLabel={entity.modalNodeProductsLabel}
                            nodeProductsWithoutValueLabel={
                              entity.modalNodeProductsWithoutValueLabel
                            }
                            nodeProductsRender={
                              entity.modalNodeProductsRender || true
                            }
                            duration={entity.modalNodeProductsWithoutValueLabel}
                            activityTitle={entity.modalActivityTitle}
                            activityDescription={
                              entity.modalActivityDescription
                            }
                            labelCreateNode={entity.modalLabelCreateNode}
                            labelCreateActivity={
                              entity.modalLabelCreateActivity
                            }
                            labelEditNode={entity.modalLabelEditNode}
                            labelEditActivity={entity.modalLabelEditActivity}
                            labelRemoveNodeConfirmation={
                              entity.modalLabelRemoveNodeConfirmation
                            }
                            labelRemoveActivityConfirmation={
                              entity.modalLabelRemoveActivityConfirmation
                            }
                            labelAddNode={entity.modalLabelAddNode}
                            labelAddActivity={entity.modalLabelAddActivity}
                            labelConfirmRemove={entity.modalLabelConfirmRemove}
                            labelSaveInfo={entity.modalLabelSaveInfo}
                            fileAddLabel={entity.modalFileAddLabel}
                            labelFileSelect={entity.modalLabelFileSelect}
                            fileAddDescription={entity.modalFileAddDescription}
                            contentUploadRequiredEnabled={false}
                            contentUploadRequiredLabel={
                              entity.modalContentUploadRequiredLabel
                            }
                            contentUploadRequiredEnabledLabel={
                              entity.modalContentUploadRequiredEnabledLabel
                            }
                            contentUploadRequiredDisabledLabel={
                              entity.modalContentUploadRequiredDisabledLabel
                            }
                            reviewFlowsWithoutValueLabel={
                              entity.modalReviewFlowsWithoutValueLabel
                            }
                            reviewFlowsLabel={entity.modalReviewFlowsLabel}
                            maximumAttemptsAfterCompletedLabel={
                              entity.modalMaximumAttemptsAfterCompletedLabel
                            }
                            maximumAttemptsAfterPassedLabel={
                              entity.modalMaximumAttemptsAfterPassedLabel
                            }
                            maximumAttemptsAfterFailedLabel={
                              entity.modalMaximumAttemptsAfterFailedLabel
                            }
                            contentTypeLabel={entity.modalContentTypeLabel}
                            contentTypeFormulaScoreLabel={entity.modalContentTypeFormulaScoreLabel}
                            contentTypeFormulaScoreAverageLabel={entity.modalContentTypeFormulaScoreAverageLabel}
                            contentTypeFormulaScoreSumLabel={entity.modalContentTypeFormulaScoreSumLabel}
                            contentTypeContentLabel={entity.modalContentTypeContentLabel}
                            contentTypeEvaluativeLabel={entity.modalContentTypeEvaluativeLabel}
                            // reviewFlows={reviewFlows}
                            reviewFlows={null}
                            products={products}
                            items={tree}
                            tagTypes={tagTypes}
                            onReorderActivities={(newOrder, activities) =>
                              handleReorderActivities(newOrder, activities)
                            }
                            onReorderNodes={(newOrder, nodes) =>
                              handleReorderNodes(newOrder, nodes)
                            }
                            onOpenActivityModal={(data) => {
                              getActivityPreviewUrl(data, true)
                            }}
                            onSaveNode={(data) => onSaveNode(data)}
                            onSaveActivity={(data) => onSaveActivity(data)}
                            handleDamClick={() =>
                              openIframe(
                                `https://api-euconquisto.azure-api.net/product/dam/current/${RDP_CONFIG.projectKey}/p/-/${Sdk.Api.enviroment === "production" ? "p" : "h"}/#/auth-with-token/${language}/embed/${Sdk.authorization.activeProject.uid}/${token}`,
                              )
                            }
                            handleComposerClick={(activity) =>
                              openComposer(activity)
                            }
                            handleNodeDamClick={handleNodeDamClick}
                            handleNewNodeDamClick={handleNodeDamClick}
                            onNewActivity={(data) => onNewActivity(data)}
                            onNewNode={(data) => onNewNode(data)}
                            onModalClose={(keepContent) => {
                              if (!keepContent) {
                                setContent(null);
                                setNewCourseThumb(null);
                                setPreviewUrl({
                                  type: '',
                                  url: '',
                                });
                                setCurrentActivityModal(null);
                                setSelectedScormActivity(null);
                                setScormActivities([]);
                              };
                            }}
                            handleDeleteActivity={(data) =>
                              handleDeleteActivity(data.uid)
                            }
                            handleDeleteNode={(data) =>
                              handleDeleteNode(data.uid)
                            }
                            onChangeNewNode={(e) =>
                              setNewCourseThumb(e.target.files[0])
                            }
                            onNodeDeadlineUpdate={onNodeDeadlineUpdate}
                            interactionStart={register ? register.interaction_start : null}
                            interactionEnd={register ? register.interaction_end : null}
                            contentPreviewUrl={previewUrl}
                            updateCurrentActivity={updateCurrentActivity}
                            scormActivities={scormActivities}
                            selectedScormActivity={selectedScormActivity}
                            onChangeSelectedScormActivity={(internal_content_options, currentActivity) => {
                              getActivityPreviewUrl(currentActivity, false, internal_content_options);
                            }}
                          />
                        </>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='action-btn'>
                  <ButtonDynamic
                    tooltip='Atualizar'
                    actionClick={() => {
                      handleSubmit();
                    }}
                    size='fit'
                    color='green'
                    icon='publish'
                    iconDiv={true}
                    disabled={disabled}
                  >
                    {entity && entity.labelSaveData}
                  </ButtonDynamic>
                </div>
              </>
            )}
          </div>
          {iframe && (
            <Iframe
              onClickOutside={() => setIframe(null)}
              className='iframe-content'
              src={iframe}
            />
          )}
          {thumbIframe && (
            <Iframe
              onClickOutside={() => setThumbIframe(null)}
              className='iframe-content'
              src={thumbIframe}
            />
          )}
          {composerIframeUrl &&
            <Iframe
              onClickOutside={() => setComposerIframeUrl(null)}
              className='iframe-content'
              src={composerIframeUrl}
            />
          }
        </div>
      ) : (
        <Loading msg='Montando Formulário...' />
      )}

      {renderScormModal &&
        <div className="rdp-admin-edit-tree-scorm-modal">
          <div className="rdp-admin-edit-tree-scorm-modal-overlay"></div>
          <div className="rdp-admin-edit-tree-scorm-modal-box">
            <div className="rdp-admin-edit-tree-scorm-modal-header">
              <span className="rdp-admin-edit-tree-scorm-modal-header-title">Baixar SCORMS</span>
              <span className="rdp-admin-edit-tree-scorm-modal-header-close" onClick={() => setRenderScormModal()}>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="768" height="768" viewBox="0 0 768 768">
                  <path fill="#646871" d="M607.5 205.5l-178.5 178.5 178.5 178.5-45 45-178.5-178.5-178.5 178.5-45-45 178.5-178.5-178.5-178.5 45-45 178.5 178.5 178.5-178.5z"></path>
                </svg>
              </span>
            </div>
            <div className="rdp-admin-edit-tree-scorm-modal-items">
              {getScorms().length > 0 && getScorms().map((act, index) => (
                <div className="rdp-admin-edit-tree-scorm-modal-item" key={index}>
                  <span className="rdp-admin-edit-tree-scorm-modal-item-name">{act.scorm_name}</span>
                  <span className="rdp-admin-edit-tree-scorm-modal-item-download" data-download-scorm-uid={act.uid} onClick={() => downloadScorm(act.uid, act.scorm_name)}>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="768" height="768" viewBox="0 0 768 768">
                      <path fill="#393939" d="M640 480v128c0 8.832-3.552 16.8-9.376 22.624s-13.792 9.376-22.624 9.376h-448c-8.832 0-16.8-3.552-22.624-9.376s-9.376-13.792-9.376-22.624v-128c0-17.664-14.336-32-32-32s-32 14.336-32 32v128c0 26.496 10.784 50.56 28.128 67.872s41.376 28.128 67.872 28.128h448c26.496 0 50.56-10.784 67.872-28.128s28.128-41.376 28.128-67.872v-128c0-17.664-14.336-32-32-32s-32 14.336-32 32zM416 402.752v-306.752c0-17.664-14.336-32-32-32s-32 14.336-32 32v306.752l-105.376-105.376c-12.512-12.512-32.768-12.512-45.248 0s-12.512 32.768 0 45.248l160 160c2.944 2.944 6.464 5.312 10.368 6.944s8.096 2.432 12.256 2.432c8.192 0 16.384-3.136 22.624-9.376l160-160c12.512-12.512 12.512-32.768 0-45.248s-32.768-12.512-45.248 0z"></path>
                    </svg>
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      }

      {renderGroupCompositionModal &&
        <div className="rdp-admin-edit-tree-scorm-modal">
        <div className="rdp-admin-edit-tree-scorm-modal-overlay"></div>
        <div className="rdp-admin-edit-tree-scorm-modal-box" style={{height: "390px", marginTop: "-195px"}}>
          <div className="rdp-admin-edit-tree-scorm-modal-header">
            <span className="rdp-admin-edit-tree-scorm-modal-header-title">Agrupe as composições do curso em uma única composição</span>
            <span className="rdp-admin-edit-tree-scorm-modal-header-close" onClick={() => {
                setRenderGroupCompositionModal()
              }}>
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="768" height="768" viewBox="0 0 768 768">
                <path fill="#646871" d="M607.5 205.5l-178.5 178.5 178.5 178.5-45 45-178.5-178.5-178.5 178.5-45-45 178.5-178.5-178.5-178.5 45-45 178.5 178.5 178.5-178.5z"></path>
              </svg>
            </span>
          </div>
          <div className="rdp-admin-edit-tree-scorm-modal-items">
            <div className="rdp-admin-edit-tree-scorm-modal-description">
              A funcionalidade de <b>Agrupar Composições do Curso</b> gera uma nova composição que reúne todas as composições relacionadas a um curso específico. Nessa nova composição, são implementadas divisões por módulos, além de um menu lateral que facilita a navegação entre eles. A composição recém-criada fica acessível na lista de composições, identificada pelo nome do curso seguido do termo <b>Agrupado</b>, por exemplo <b>Curso de LGPD - Agrupado</b>.
            </div>
            {!isGroupingCompositions &&
              <button className="rdp-admin-edit-tree-scorm-modal-group-compositions" onClick={() => {
                setIsGroupingCompositions(true);
                groupCompositions()
              }}>Agrupar Composições do Curso</button>
            }
            {isGroupingCompositions &&
              <div class="rdp-admin-edit-tree-scorm-modal-loading">
                <div class="rdp-admin-edit-tree-scorm-modal-loading-progress" style={{width: `${isFinishedGroupCompositions ? 100 : (groupingCompositionsDone / groupingCompositionsLength * 100)}%`}}></div>
                <span class="rdp-admin-edit-tree-scorm-modal-loading-percentage">{isFinishedGroupCompositions ? 100 : (groupingCompositionsDone / groupingCompositionsLength * 100)}%</span>
              </div>
            }
            {isFinishedGroupCompositions &&
              <div class="rdp-admin-edit-tree-scorm-modal-message-done">
                Pronto! A composição foi criada e está disponível na área <b>GESTÃO DE CONTEÚDOS → COMPOSIÇÕES</b>.
              </div>
            }
          </div>
        </div>
      </div>
      }
    </>
  );
}
