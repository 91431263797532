import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { currentRouteState } from "../recoil/atoms/currentRouteState";
import { groupsState } from "../recoil/atoms/groupsState";
import { currentGroupState } from "../recoil/atoms/currentGroupState";

export default function useCurrentRoute(path: string) {
  const [currentRoute, setCurrentRoute] = useRecoilState(currentRouteState);
  const groups = useRecoilValue(groupsState);

  if (currentRoute === null) {
    const group = groups.find(g => ("uri" in g && path === g.uri));

    if (group) {
      return [group, setCurrentRoute]
    } else {
      return [{
        path,
        name: "dashboard"
      }, setCurrentRoute]
    }
  }
  
  return [currentRoute, setCurrentRoute];
}