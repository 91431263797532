import {atom} from "recoil";
import {persistAtomEffect} from "../effects/persistAtom";

export const langState = atom<string>({
  key: 'LangState',
  default: "pt-br",
  effects: [
    persistAtomEffect
  ]
});
