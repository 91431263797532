import Sdk from 'api.digitalpages.module.sdk.api';
import api from './api';
import RDP_CONFIG from '../config/config';
// import { sidebar } from '../../public/preferences.json'
const groupService = function () {
  this.addRoute = function (routes, newRoute) {
    const route = routes.find((r) => r.uri === newRoute.uri);
    if (route !== undefined) return;

    routes.push(newRoute);
  };

  /** @returns {Promise<{name: string, uri: string, icon: string, subItems?: {name: string, uri: string, icon: string}[]}[]>} */
  this.getGroups = async function () {
    const _this = this;
    const connectors = Sdk.authorization.activeProject
      ? Sdk.authorization.activeProject.connectors
      : [];
    const result = [];
    if (connectors == null) {
      return result;
    }
    if (
      Sdk.hasRole([
        'PlatformSuper',
        'PlatformAdministrator',
        'AdministratorGlobal',
        'TeamAdmin'
      ])
    ) {
      const analyticsItem = {
        "name": "Analytics",
        "icon": "icon-home",
        "subItems": [
          {
            "name": "Dashboard",
            "icon": "icon-name",
            "uri": "/"
          },
          {
            "name": "Relatório de notas",
            "icon": "icon-name",
            "uri": "/report/scores"
          },
          {
            "name": "Relatório de frequência",
            "icon": "icon-name",
            "uri": "/report/attendance"
          }
        ]
      };

      if (Sdk.hasRole(['TeamAdmin'])) {
        // Remover filtro quando definido o filtro em Dashboard
        analyticsItem.subItems = analyticsItem.subItems.filter(subItem => subItem.name !== "Dashboard");
      }

      result.push(analyticsItem);
    }
    const connectorsData = await Sdk.authorization.getUserConnectorData();
    // const userToken = Sdk.authorization.credential.accessToken;

    const appOptions = { name: 'RDP Apps', icon: '', routes: [] };
    const setupOptions = { name: 'Setup Plataforma', icon: '', routes: [] };
    const configOptions = { name: 'Geral', icon: '', routes: [] };
    const insightsOptions = { name: 'Analytics', icon: '', routes: [] };
    const storeOptions = { name: 'Loja', icon: '', routes: [] };
    const managementOptions = { name: 'Gestão', icon: '', routes: [] };
    const cmsOptions = { name: 'CMS', icon: '', routes: [] };
    const dynContentOptions = {
      name: 'Conteúdo dinâmico',
      icon: '',
      routes: [],
    };
    const educationOptions = { name: 'Educacional', icon: '', routes: [] };
    const iotOptions = { name: 'IOT', icon: '', routes: [] };

    if (
      Sdk.hasRole([
        'PlatformSuper',
        'PlatformAdministrator'
      ])) {
      // this.addRoute(setupOptions.routes, { name : "Diretórios", uri : "/config/entidades"});
      // this.addRoute(setupOptions.routes, { name : "Conectores", uri : "/connectors/edit"});
      // this.addRoute(setupOptions.routes, { name : "Projetos", uri : "/projects/edit"});
    }

    const isAnalyticAdmin = Sdk.hasRole(['TeamAdmin']);

    if (
      Sdk.hasRole([
        'PlatformSuper',
        'PlatformAdministrator',
        'AdministratorGlobal'
      ]) && !isAnalyticAdmin
    ) {
      // this.addRoute(managementOptions.routes, {
      //   name: 'Usuários',
      //   uri: '/users',
      // });
      result.push(
        {
          "name": "Gestão de conteúdos",
          "icon": "icon-home",
          "subItems": [
            {
              "name": "Composições",
              "icon": "icon-name",
              "uri": "/compositions"
            },
            {
              "name": "Arquivos",
              "icon": "icon-name",
              "uri": "/files"
            },
            {
              "name": "Certificados",
              "icon": "icon-name",
              "uri": "/certificates"
            },
            {
              "name": "Termos",
              "icon": "icon-name",
              "uri": "/terms"
            }
          ]
        },
        {
          "name": "Gestão de usuários",
          "uri": "/users",
          "icon": "icon-user"
        },
        {
          "name": "Gestão de cursos",
          "uri": "/tree",
          "icon": "icon-user"
        },
        {
          "name": "Envio de e-mails",
          "uri": "/emails",
          "icon": "icon-emails"
        },
        {
          "name": "Catálogo de cursos",
          "uri": "/catalog",
          "icon": "icon-emails"
        }
      )
      console.log(Sdk.hasRole);
      this.addRoute(managementOptions.routes, {
        name: 'Grupos',
        uri: '/management/groups',
      });
      this.addRoute(cmsOptions.routes, {
        name: 'Gestão de cursos',
        uri: '/learning/courses',
      });

      connectors.map((connector, i) => {
        switch (connector.type) {
          case 'Iot_1': {
            this.addRoute(iotOptions.routes, {
              name: 'Dispositivos',
              uri: '/devices',
            });
            this.addRoute(iotOptions.routes, {
              name: 'Grupos',
              uri: '/iot/groups',
            });
            break;
          }
          case 'Voucher_1': {
            this.addRoute(storeOptions.routes, {
              name: 'Gerenciar produtos',
              uri: '/store/products',
            });
            this.addRoute(storeOptions.routes, {
              name: 'Cadastrar produto',
              uri: '/store/product/new',
            });
            break;
          }
          case 'Apple_1': {
            this.addRoute(storeOptions.routes, {
              name: 'Gerenciar produtos',
              uri: '/store/products',
            });
            this.addRoute(storeOptions.routes, {
              name: 'Cadastrar produto',
              uri: '/store/product/new',
            });
            break;
          }
          case 'Google_1': {
            this.addRoute(storeOptions.routes, {
              name: 'Gerenciar produtos',
              uri: '/store/products',
            });
            this.addRoute(storeOptions.routes, {
              name: 'Cadastrar produto',
              uri: '/store/product/new',
            });
            break;
          }
          case 'Manager_1': {
            const connectorData = connectorsData.find(
              (p) => p.source_uid === connector.uid
            );
            const config = JSON.parse(connector.configuration);
            const token = connectorData.data.AccessToken;
            const urlBase = `${config.admin_uri}/#!/login?sso=${token}`;

            this.addRoute(educationOptions.routes, {
              name: 'Gerenciar Contextos',
              uri: '/reference/name/m-contexts',
              externalUri: `${urlBase}&redirectRoute=dashboard.context-list`,
            });
            this.addRoute(educationOptions.routes, {
              name: 'Gerenciar Cursos',
              uri: '/reference/name/m-courses',
              externalUri: `${urlBase}&redirectRoute=dashboard.offer`,
            });
            this.addRoute(educationOptions.routes, {
              name: 'Gerenciar Grupos',
              uri: '/reference/name/m-groups',
              externalUri: `${urlBase}&redirectRoute=dashboard.group-list`,
            });
            break;
          }
          case 'DirectRegister_1': {
            // this.addRoute(configOptions.routes, { name : "Cadastro direto", uri : "/authorization/direct_register"});
            break;
          }
          case 'Insights_1': {
            break;
          }
        }
      });

      // this.addRoute(appOptions.routes, { name : "Analytics", uri : "/reference/name/analytics"});
    }

    if (connectors.find((c) => c.type === 'Composer_1')) {
      // this.addRoute(appOptions.routes, { name : "Composer", uri : "/reference/name/dam", externalUri:`https://composer.digitalpages.com.br`});///config/${RDP_CONFIG.configBase64}/init#/login?sso=${userToken}`});
    }

    if (connectors.find((c) => c.type === 'News_1')) {
      const groups = await Sdk.cms.news.getGroups();
      groups.result.map((group) => {
        _this.addRoute(dynContentOptions.routes, {
          name: group.name,
          uri: `/cms/news/group/uid/${group.uid}`,
        });
      });
    }

    // result.push(appOptions);
    // result.push(dynContentOptions);
    // result.push(educationOptions);
    // result.push(storeOptions);
    // result.push(insightsOptions);
    // result.push(configOptions);
    // result.push(setupOptions);
    // // result.push(cmsOptions);
    // // result.push(managementOptions);
    // result.push(iotOptions);
    const response = await fetch('./preferences.json');
    const preferences = await response.json();

    result.push(...preferences.sidebar.groups);

    const customizationConnector = connectors.find(c => c.type === 'Customization_1');

    if (customizationConnector) {
      const configuration = JSON.parse(customizationConnector.configuration || '{}');
      const configs = configuration.configs || [];
      const users_group = configs.find(c => c.uid === 'users_group');

      if (users_group) {
        const value = JSON.parse(users_group.value || '{}') || [];
        const enabledConfig = value.find(v => v.uid === "enabled") || {};

        if (enabledConfig.value && !(isAnalyticAdmin)) {
          const titleConfig = value.find(v => v.uid === "title") || {};
          result.push({
            "name": titleConfig.value || "Gestão de grupos de usuários",
            "uri": "/users-group",
            "icon": "icon-user"
          });
        }
      }
    }



    return result;
  };
};

const GroupService = new groupService();

export { GroupService };
