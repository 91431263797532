import React, {useState, useEffect} from 'react'
import {
  Header,
  Sidebar,
  Breadcrumb,
  Footer,
  Loading,
  NotifyBox,
} from '../../components';
import DynamicForm from '../../components/form-dynamic-v2'
import Sdk from 'api.digitalpages.module.sdk.api';
import './edit-entity.scss'
export default function EditEntity({consumer, match}) {
  const [entity, setEntity] = useState()
  const [entityUpd, setEntityUpd] = useState()
  const [defaultValues, setDefaultValues] = useState()
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState('');
  const [msgError, setMsgError] = useState('');
  const [loadedProperties, setLoadedProperties] = useState(false);
  const [loadedEntity, setLoadedEntity] = useState(false);
  const [schemaDetails, setSchemaDetails] = useState(null);


  useEffect(() => {
    getFormFields()
  }, [])
  useEffect(() => {
    if(!loadedProperties && entity) loadProperties()
  }, [entity])
  useEffect(() => {
    if(!loadedEntity && entity) loadEntity()
  },[loadedProperties])
  const getFormFields = async () => {
    const response = await fetch('./preferences.json');

    const { forms } = await response.json();
    setEntity(forms['new-entity']);

    consumer.defineRoute({
      name: 'Editar entidade'
    });
  }
  const loadProperties = async () => {
    const project = await Sdk.dynamic.bridge(
      `auth/v1.0/project/uid/${Sdk.authorization.activeProject.uid}`,
      null,
      'GET'
    );
    const { directory } = project;

    const schema = await Sdk.Api.authorization.entity.schema(match.params.schemaId)
    const schemaDetailsData = await Sdk.dynamic.bridge(`auth/v1.0/entity/schema/available/directory/uid/${directory.uid}`);
    var tempSchema = schemaDetailsData.find(item => item.uid === match.params.schemaId);
    setSchemaDetails(tempSchema);

    if (!schema) {
      setLoadedProperties(true)
      return
    }
    const {properties} = schema
    const entityCp = {...entity}
    const propertiesField = entityCp.fields.find(field => field.type === 'input-entity-properties')
    propertiesField.options = properties
    console.log({entityCp})
    setEntity(entityCp)
    setLoadedProperties(true)
  }
  const loadEntity = async () => {
    const entity = await Sdk.dynamic.bridge(`auth/v1.0/entity/uid/${match.params.entityId}`)
    const defaultValues = {}
    Object.keys(entity.data).forEach(key => {
      defaultValues[`property-${key}`] = entity.data[key]
    })
    setDefaultValues(defaultValues)
  }
  const handleSubmit = async (form) => {
    setLoadingSubmit(true)
    try {
      const propertiesField = entity.fields.find(field => field.type === 'input-entity-properties')
      if (!propertiesField) return
    
      const entityDto = {
        data: {

        }
      }
      propertiesField.options.forEach(property => {
        entityDto.data[property.name] = form['property-' + property.name]
      })
      await Sdk.dynamic.bridge(`auth/v1.0/entity/uid/${match.params.entityId}`, entityDto, 'PUT')
      setMsgSuccess('Entidade atualizada com sucesso!')
    } catch (error) {
      setMsgError('Algo deu errado, tente novamente mais tarde...')
    }
    setLoadingSubmit(false)
  }
  return (
    <div>
      <Header />
      <Sidebar
        currentRoute="/users/new"
        defineGroup={consumer ? consumer.defineGroup : null}
        defineRoute={consumer ? consumer.defineRoute : null}
        groups={consumer ? consumer.groups : null}
      />
      <Breadcrumb currentRoute={consumer ? consumer.currentRoute : null} />
      <div className="main-content">
        {loadingSubmit && <Loading msg="Salvando entidade..." />}
        {!loadingSubmit && msgSuccess && (
          <NotifyBox
            type="success"
            onClose={() => setMsgSuccess('')}
            message={msgSuccess}
          />
        )}
        {!loadingSubmit && msgError && (
          <NotifyBox
            type="error"
            onClose={() => setMsgError('')}
            message={msgError}
          />
        )}
        {entity && defaultValues ? (
          <DynamicForm
            handleSubmit={handleSubmit}
            form={entityUpd ? entityUpd : entity}
            submitText="SALVAR ENTIDADE"
            defaultValues={defaultValues}
          />
        ) : (
          <Loading msg="Configurando formulário ..." />
        )}
      </div>
      <Footer version="01" />      
    </div>
  )
}
