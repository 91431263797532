import React, { Component } from 'react';
import './course-management.scss';
import Sdk from 'api.digitalpages.module.sdk.api';
import { IconCloseModal } from '../../components/icon';
import Modal from 'react-responsive-modal';
import {
  Header,
  Sidebar,
  Breadcrumb,
  FormDynamic,
  NotifyBox,
} from '../../components';

import Loading from '../../components/loading';
import Configuration from './course-management.config';

export default class ViewCourseManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        nodes: [],
      },
      configurationValues: {
        _old: {
          members: [],
        },
        _new: {
          members: [],
        },
      },
      selectOptions: [
        {
          identifier: 'members->user',
          data: [],
        },
        {
          identifier: 'members->role',
          data: [],
        },
      ],
      entity: Configuration.getEntity(),
      ready: false,
      loading: false,
      loadingSubmit: false,
      msgSuccess: null,
      msgError: null,
    };
  }

  componentDidMount() {
    const { courseUid } = this.props.match.params;
    if (courseUid) {
      this.setState({ ready: false, loading: true }, this.getSchemas);
    }
  }

  getMembers = async (current) => {
    if (!current._exists) return;
    if (this.state.configurationValues._old.members[current._uid]) return;

    const uid = current._uid;

    const members = await Sdk.Api.authorization.entity.members(uid);

    this.setState({
      configurationValues: {
        ...this.state.configurationValues,
        _old: {
          ...this.state.configurationValues._old,
          members: {
            ...this.state.configurationValues._old.members,
            [uid]: members.map((member) => ({
              user: member.uid,
              role: member.roles.map((role) => role.type),
            })),
          },
        },
      },
    });
  };

  hydrateEntity = (obj) => {
    const { schemaProps = [] } = this.state;

    obj.data = obj.data || {};

    schemaProps.forEach((prop) => {
      obj.data[prop.identifier] = obj[prop.identifier];
      delete obj[prop.identifier];
    });

    return obj;
  };

  hydratenodes = (arr, parent, parentKey = -1, parentRow = -1) => {
    let currentRow = parentRow;

    return arr.reduce((result, current) => {
      const obj = { ...current };

      if (parent === obj._parent) {
        obj.row = currentRow + 1;
        obj.column = parentKey + 1;

        const children = this.hydratenodes(arr, obj._uid, obj.column, obj.row);

        currentRow = obj.row + children.length;

        result = [...result, ...children, this.hydrateEntity(obj)];
      }
      return result;
    }, []);
  };

  rehydratenodes = (nodes, props) => {
    return nodes.map((entity, key) => {
      const parent = nodes
        .filter(
          ({ column, row }) => column == entity.column - 1 && row < entity.row
        )
        .sort((a, b) => (a.row < b.row ? 1 : b.row < a.row ? -1 : 0));

      if (parent.length) {
        entity._parent = parent[0].uid;
      }

      entity._uid = entity.uid;
      entity._exists = true;

      props.forEach(
        (prop) => (entity[prop.identifier] = entity.data[prop.identifier])
      );

      return entity;
    });
  };

  getSchemas = async () => {
    const { courseUid } = this.props.match.params;

    const types = {
      Text: 'input-text',
    };

    const course = await Sdk.Api.cms.course(courseUid);
    const props = course.node_properties.map((prop) => ({
      identifier: prop.name,
      field: prop.name,
      label: prop.name,
      type: types[prop.type],
    }));

    const { entity } = this.state;

    const configuration = entity.configuration.map((value) => {
      if (value.identifier == 'nodes') {
        value.fields_configuration = [...value.fields_configuration, ...props];
      }

      return value;
    });

    this.setState({
      schemaProps: props,
      entity: {
        ...entity,
        configuration,
      },
    });

    const roles = await Sdk.Api.authorization.roles();
    const users = await Sdk.Api.authorization.usersByProject();

    this.setState({
      selectOptions: this.state.selectOptions.map((combo) => {
        if (combo.identifier == 'members->role') {
          return {
            identifier: 'members->role',
            data: roles.map((role) => ({
              uid: role.type,
              data: {
                name: role.type,
              },
            })),
          };
        }

        if (combo.identifier == 'members->user') {
          return {
            identifier: 'members->user',
            data: users.map((user) => ({
              uid: user.uid,
              data: {
                name: user.detail.name,
              },
            })),
          };
        }

        return combo;
      }),
    });

    if (courseUid != 'new') {
      const nodes = await Sdk.Api.authorization.entity.schemaItems(courseUid);

      this.setState({
        values: {
          ...this.state.values,
          nodes: this.rehydratenodes(nodes, props),
        },
      });
    }

    this.setState({
      ready: true,
      loading: false,
    });
  };

  saveMembers = async (uid) => {
    const newMembers = this.state.configurationValues._new.members[uid] || [];
    const oldMembers = this.state.configurationValues._old.members[uid] || [];

    const registerRoleMembers = async (member) => {
      if (!member.user) return;

      await Sdk.Api.authorization.entity.registerMember(uid, member.user, {
        role: member.role.join(','),
      });
    };

    const deleteRoleMembers = async () => {
      return oldMembers
        .filter(
          (member) =>
            !newMembers.map((member) => member.user).includes(member.user)
        )
        .map(
          async (member) =>
            await Sdk.Api.authorization.entity.removeMember(uid, member.user)
        );
    };

    const tasksCreate = newMembers.map(registerRoleMembers);
    const tasksDelete = newMembers.map(deleteRoleMembers);

    await Promise.all(tasksCreate);
    await Promise.all(tasksDelete);
  };

  saveEntity = async (entity) => {
    const { courseUid } = this.props.match.params;

    if (entity._removed && entity._exists) {
      await Sdk.Api.authorization.entity.deleteSchemaItem(entity._uid, entity);
      return;
    }

    if (entity._parent) {
      const parent = this.state.values.nodes.find(
        (_entity) => _entity._uid == entity._parent
      );
      if (!parent._exists) {
        entity._parent = this.saveEntity(parent);
      }
    }

    let stored = {};

    if (entity._exists) {
      stored = await Sdk.Api.authorization.entity.updateSchemaItem(
        entity._uid,
        entity
      );
    } else {
      stored = await Sdk.Api.authorization.entity.createSchemaItem(
        courseUid,
        entity
      );
    }

    this.setState(
      {
        configurationValues: {
          ...this.state.configurationValues,
          _old: {
            ...this.state.configurationValues._old,
            members: {
              ...this.state.configurationValues._old.members,
              [stored.uid]: this.state.configurationValues._old.members[
                entity._uid
              ],
            },
          },
          _new: {
            ...this.state.configurationValues._new,
            members: {
              ...this.state.configurationValues._new.members,
              [stored.uid]: this.state.configurationValues._new.members[
                entity._uid
              ],
            },
          },
        },
        values: {
          ...this.state.values,
          nodes: this.state.values.nodes.map((_entity) => {
            if (_entity._uid == entity._uid) {
              _entity._exists = true;
              _entity._uid = stored.uid;
            }
            if (_entity._parent == entity._uid) {
              _entity._parent = stored.uid;
            }
            return _entity;
          }),
        },
      },
      () => {
        this.saveMembers(stored.uid);
      }
    );

    return stored.uid;
  };

  saveAll = async () => {
    const nodes = this.hydratenodes(
      this.state.values.nodes.filter((entity) => !entity._empty)
    );

    try {
      const saved = nodes.map(this.saveEntity);

      await Promise.all(saved);

      this.setState({
        ready: true,
        loading: false,
        loadingSubmit: false,
        msgSuccess: 'Salvo com sucesso!',
      });
    } catch (e) {
      this.setState({
        ready: true,
        loading: false,
        loadingSubmit: false,
        msgError: 'Erro ao salvar.',
      });
    }
  };

  handleSubmit = (form) => {
    this.setState(
      {
        ready: true,
        loading: false,
        loadingSubmit: true,
      },
      () => this.saveAll(form.data)
    );
  };

  resetMsg = () => {
    this.setState({ msgSuccess: null, msgError: null });
  };

  handleModalSubmit = (form, current) => {
    this.setState(
      {
        configurationValues: {
          ...this.state.configurationValues,
          _new: {
            ...this.state.configurationValues._new,
            members: {
              ...this.state.configurationValues._new.members,
              [current._uid]: form.data.members.map((member) => ({
                user: member.user,
                role: member.role,
              })),
            },
          },
        },
        open: false,
      },
      () => {
        console.log(
          'this.state.configurationValues._new',
          this.state.configurationValues._new
        );
      }
    );
  };

  rendernodesModal = () => {
    const { fields_configuration } = this.state.entity.configuration.find(
      ({ identifier }) => identifier == 'nodes'
    );

    const configuration = fields_configuration.filter(
      ({ identifier }) =>
        !['name', 'title', 'nome', 'título'].includes(identifier.toLowerCase())
    );
    const { open } = this.state;

    let values = {};

    if (this.state.open && open.current) {
      const { configurationValues } = this.state;

      values = {
        members:
          configurationValues._new.members[open.current._uid] ||
          configurationValues._old.members[open.current._uid],
      };
    }

    return (
      <Modal
        open={!!open}
        onClose={() => this.setState({ open: false })}
        styles={{
          overlay: { backgroundColor: 'rgba(125, 125, 125, 0.2)' },
          modal: {
            borderRadius: '4px',
            maxWidth: '100%',
            position: 'absolute',
            padding: '0px',
            top: '84px',
            bottom: '0',
            left: '0',
            backgroundColor: '#f5f7f8',
          },
        }}
        showCloseIcon={false}
        focusTrapped={false}
        center
      >
        <div className="rdp-modal-hierarchy-container">
          <div className="rdp-modal-container-header">
            <div className="header-left">
              <span>Detalhes</span>
            </div>
            <button
              type="button"
              className="rdp-modal-close"
              onClick={() => this.setState({ open: false })}
            >
              <IconCloseModal />
            </button>
          </div>

          <FormDynamic
            handleSubmit={(e) => this.handleModalSubmit(e, open.current)}
            values={values}
            entity={{
              configuration: configuration,
              layout_configuration: {
                hide_event_period: true,
                hide_products_restriction: true,
              },
            }}
            hideDraftButton={true}
            submitText={'Salvar'}
            submitEnabled={true}
          />
        </div>
      </Modal>
    );
  };

  handleOnDetails = async (current) => {
    await this.getMembers(current);
    this.setState({
      open: {
        current,
      },
    });
  };

  render() {
    const { consumer } = this.props;
    const {
      values,
      entity,
      loading,
      loadingSubmit,
      msgSuccess,
      msgError,
    } = this.state;

    return (
      <div className="rdp-admin-new-entity">
        <Header />
        <Sidebar
          defineGroup={consumer ? consumer.defineGroup : null}
          defineRoute={consumer ? consumer.defineRoute : null}
          groups={consumer ? consumer.groups : null}
        />
        <Breadcrumb currentRoute={consumer ? consumer.currentRoute : null} />
        <div id="rdp-admin-content-area" className="rdp-admin-content">
          {loading && <Loading msg="Carregando formulário..." />}
          {loadingSubmit && <Loading msg="Enviando..." />}
          {!loadingSubmit && msgSuccess && (
            <NotifyBox
              type="success"
              onClose={this.resetMsg}
              message={msgSuccess}
            />
          )}
          {!loadingSubmit && msgError && (
            <NotifyBox
              type="error"
              onClose={this.resetMsg}
              message={msgError}
            />
          )}
          {!loading && entity && (
            <FormDynamic
              actions={{
                nodes: { onDetails: this.handleOnDetails },
              }}
              selectOptions={this.state.selectOptions}
              values={values}
              blocked={msgSuccess || msgError ? true : false}
              isNew={true}
              cleanValues={msgSuccess ? true : false}
              handleSubmit={this.handleSubmit}
              entity={entity}
              hideDraftButton={true}
              submitText={'Salvar'}
              submitEnabled={true}
            />
          )}
          {this.rendernodesModal()}
        </div>
      </div>
    );
  }
}
