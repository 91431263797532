import {atom} from "recoil";
import {persistAtomEffect} from "../effects/persistAtom";
import IEntitySchema from "../../@types/IEntitySchema";

export const schemasState = atom<IEntitySchema[]>({
  key: 'SchemasState',
  default: [],
  effects: [
    persistAtomEffect
  ]
});
