import ptBR from "../i18n/pt-br.json";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { langState } from "../recoil/atoms/langState";

const langs = {
  ptbr: ptBR as { [key: string]: string },
};

export const serverLangs = {
  enus: "en-us",
  ptbr: "pt-br",
};

export function getUserLang() {
  const userLang = navigator.language;
  const savedLang = localStorage.getItem("lang");

  if (savedLang && savedLang.length && savedLang !== userLang) {
    return savedLang;
  }

  return userLang;
}

export function l(text: string): string {
  try {
    let subdomain = window.location.host.split(".")[0];
    
    const texts = ptBR as { [key: string]: string };

    switch (subdomain) {
      case serverLangs.enus:
        return texts[text] ?? text;
      default:
        // @ts-ignore
        let defaultLang = langs[getUserLang().replace("-", "").toLowerCase()];

        return defaultLang[text] ?? text;
    }
  } catch (e) {
    return text;
  }
}

function useLocalization() {
  const lang = useRecoilValue(langState);
  const doc = useMemo(() => {
    return document.querySelector("html");
  }, [lang]);

  if (doc)
    doc.setAttribute("lang", lang);

  return l;
}

export default useLocalization;
