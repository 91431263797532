import React, {useState, useEffect} from 'react'
import './new-schema.scss'
import {
  Header,
  Sidebar,
  Breadcrumb,
  NotifyBox,
  Footer,
  Loading
} from '../../components';
import DynamicForm from '../../components/form-dynamic-v2'
import Sdk from 'api.digitalpages.module.sdk.api';
export default function NewSchema({consumer, history}) {
  const [entity, setEntity] = useState(null);
  const [entityUpd, setEntityUpd] = useState(null);
  const [canAddEntity, setCanAddEntity] = useState(null);
  const [ids, setIds] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState('');
  const [msgError, setMsgError] = useState('');
  useEffect(() => {
    getFormFields()
  }, [])
  const getFormFields = async () => {
    const response = await fetch('./preferences.json');

    const { forms } = await response.json();
    setEntity(forms['new-schema']);

    consumer.defineRoute({
      name: forms['new-schema'].pageTitle,
      path: 'schema/new',
    });
  }
  const handleSubmit = async (data) => {
    setLoadingSubmit(true)
    const schemaDto = {
      name: data.name,
      type: data['schema-type'],
      limit_depth_in_hierarchy: data['schema-limit-visibility'] ? parseInt(data['schema-limit-visibility']) : 0,
      properties: data['schema-properties'],
      public: data['schema-public']
    }
    const res = await Sdk.dynamic.bridge('auth/v1.0/entity/management/schema/directory', schemaDto, 'POST')
    if (res.uid) {
      setLoadingSubmit(false)
      setMsgSuccess('Esquema criado com sucesso!')
    }
/*
name: "Nome"
schema-limit-visibility: "100"
schema-properties: (3) [{…}, {…}, {…}]
schema-public: true
schema-tags: [{…}]
schema-type: "Tipo"
*/
  }
  return (
    <>
      {entity ? (
        <div className="rdp-admin-new-entity">
          <Header />
          <Sidebar
            currentRoute="/users/new"
            defineGroup={consumer ? consumer.defineGroup : null}
            defineRoute={consumer ? consumer.defineRoute : null}
            groups={consumer ? consumer.groups : null}
          />
          <Breadcrumb currentRoute={consumer ? consumer.currentRoute : null} />
          <div id="rdp-admin-content-area" className="rdp-admin-content">
            {loadingSubmit && <Loading msg="Criando Esquema..." />}
            {!loadingSubmit && msgSuccess && (
              <NotifyBox
                type="success"
                onClose={() => setMsgSuccess('')}
                message={msgSuccess}
              />
            )}
            {!loadingSubmit && msgError && (
              <NotifyBox
                type="error"
                onClose={() => setMsgError('')}
                message={msgError}
              />
            )}
            <div className="test">
              {entity && (
                <DynamicForm
                  handleSubmit={handleSubmit}
                  form={entityUpd ? entityUpd : entity}
                  submitText={entity.submitText}
                  // defaultValues={defaultValues}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <Loading msg="Montando Formulário..." />
      )}
      <Footer />
    </>
  )
}
