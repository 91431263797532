import React from "react";
import * as S from "./time.styled";

interface ITimeProps extends React.InputHTMLAttributes<HTMLInputElement> {
  fullWidth?: boolean;
}

function Time(props: ITimeProps) {
  const {
    type = "time",
    step = "2"
  } = props;
  
  return (
    <React.Fragment>
      <S.Input
        type={type}
        step={step}
        width={props.fullWidth ? "100%" : "auto"}
        {...props}
      />
    </React.Fragment>
  );
}

export default Time;