import Sdk from 'api.digitalpages.module.sdk.api';
import { objectToCsvFile } from './objectToCsvFile';
import ICourseUserRegisterReportScore from "../@types/ICourseUserRegisterReportScore";

const getData = (scores: ICourseUserRegisterReportScore[], users: IUserModel[]) => {
  let namedRows: {[index: string]: string }[] = [];

  scores.forEach((score) => {
    let user = (users || []).find((user) => user.uid === score.user_uid);

    if (user) {
      // if ("roles" in user) {
      //   let isStudent = true;
      //   for (const role of (user?.roles || [])) {
      //     if (role.type === 'ReviewContributor' || role.type === 'ReviewAdmin' || role.type === 'AdministratorGlobal') {
      //       isStudent = false;
      //       break;
      //     }
      //   }

      //   if (!isStudent) return;
      // }

      let columns: {[index: string]: any} = {
        name: user?.detail?.name || '',
        email: user.login,
        course: score.course_title,
        register: score.register_name,
      };

      let namedColumns: {[index: string]: any} = {
        'Aluno': user?.detail?.name || '',
        'Login': user.login,
        'Curso': score.course_title,
        'Turma': score.register_name,
      };

      let node_score_sum = 0;

      (score?.nodes || []).forEach((node) => {
        const { activity_scores = [] } = node;

        let activities_score_sum = 0;

        if (activity_scores.length > 0) {
          activity_scores.forEach((activity) => {
            let score = activity.score || 0;
            let score_weight = activity.score_weight || 10;

            if (activity.score_weight !== null) {
              score = (score * score_weight) / 10;
            }

            activities_score_sum += score || 0;

            const scoreParts = String(activity.score).split('.');
            if (scoreParts.length > 1) {
              activity.score = parseInt((activity.score || 0).toFixed(2));
            }

            columns[activity.activity_uid] = activity.score || "-";
            namedColumns[activity.name + " (Atividade)"] = activity.score || "-";
          });
        }

        if (activities_score_sum > 0) {
          switch (String(node.type_formula_score).toLowerCase()) {
            case 'sum':
              columns[node.node_uid] = parseFloat(activities_score_sum.toFixed(2));
              break;
            case 'average':
              columns[node.node_uid] = parseFloat((activities_score_sum / activity_scores.length).toFixed(2));
              break;
            default:
              columns[node.node_uid] = 0;
          }
        } else {
          columns[node.node_uid] = 0;
        }

        namedColumns[node.node_title + " (Nó)"] = columns[node.node_uid];
        node_score_sum += columns[node.node_uid];
      });

      if (node_score_sum > 0) {
        switch (String(score.type_formula_score).toLowerCase()) {
          case 'sum':
            columns['final_course_score'] = parseFloat(node_score_sum.toFixed(2));
            break;
          case 'average':
            columns['final_course_score'] = parseFloat((node_score_sum / score.nodes.length).toFixed(2));
            break;
          default:
            columns['final_course_score'] = 0;
        }
      } else {
        columns['final_course_score'] = 0;
      }

      namedColumns['Nota final Curso/Turma'] = columns['final_course_score'];

      namedRows.push(namedColumns);
    }
  });

  return namedRows;
}

export default async function downloadUserScoresCSV(registerUid: TGuid) {
  try {
    const { user } = Sdk.authorization;
    var filter = "";

    if (user.roles) {
      for (const role of user.roles) {
        if (
          role.type ==="TeamAdmin"
        ) {
          filter = "&filter_entities=true"
        }
      }
    }
    const resp: IPagedResult<ICourseUserRegisterReportScore> = await Sdk.dynamic.bridge(`cms/v1.0/learning/register/uid/${registerUid}/report/scores/management?page_size=100000000${filter}`);
    const users: IUserModel[] = await Sdk.dynamic.bridge(`auth/v1.0/project/uid/${Sdk.Api.authorization.activeProject.uid}/users`);

    if (resp && users) {
      let rows = getData(resp.result, users);
      let file = objectToCsvFile(rows);
      let nowDate = new Date();
      let fileName = `relatorio_de_nota_${nowDate.getDate()}_${nowDate.getMonth()}_${nowDate.getFullYear()}.csv`;
      let anchorElement = document.createElement("a")
      anchorElement.href = URL.createObjectURL(file);
      anchorElement.download = fileName;
      anchorElement.click();
    }
  } catch (error) {
    console.error(error);
  }
}