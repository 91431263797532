import React, { Suspense, useEffect, useRef, useState } from 'react';
import * as S from './edit-terms.styled';
import { Breadcrumb, Header, Sidebar } from '../../components';
import Loading from '../../components/loading';
import { useHistory } from 'react-router-dom';
import SDK from 'api.digitalpages.module.sdk.api';
import DynamicForm from '../../components/form-dynamic-v2';
import { useTerms } from '../new-terms/useTerms';

interface IEditTermsProps extends IConsumerContext {
  match: any;
}

function EditTerms(props: IEditTermsProps) {
  const history = useHistory();
  const [consumer] = React.useState(props.consumer);
  const [entity, setEntity] = React.useState<IAppFormPreference<ITermsModel>>();
  const [data, setData] = React.useState<ITermsModel | null>();
  const [language, setLanguage] = React.useState<string>('pt_br');
  const [termsConnector, setTermsConnector] = React.useState<IConnectorModel>();
  const [ids, setIds] = React.useState<string[]>([]);
  const [authorization, setAuthorization] = useState({} as any);
  const [entityUpd, setEntityUpd] =
    React.useState<IAppFormPreference<ITermsModel>>();
  const [defaultUsersSelecteds, setDefaultUsersSelected] = useState(
    [] as any[]
  );
  const {
    loadEntities,
    getGroupAuthorizationReferences,
    saveAuthorizationUpdateTermFlow,
  } = useTerms();
  const containerRef = useRef(null);

  useEffect(() => {
    if (entity && data) {
      const cpData: any = data;
      let references = [] as any[];
      if (cpData.authorizations && cpData.authorizations[0]) {
        const [authData] = cpData.authorizations;
        setAuthorization(authData);

        if (authData.references) {
          references = authData.references;
        }
      }

      const { entities, users } = getGroupAuthorizationReferences(
        references || []
      );

      setDefaultUsersSelected(users);

      loadEntities(
        entity,
        entities.map((item) => item.reference_uid)
      ).then((res) => {
        if (res) {
          setIds(res.uids);
          setEntityUpd(res.form);
        }
      });
    }
  }, [entity]);

  useEffect(() => {
    if (consumer && consumer.defineRoute) {
      consumer.defineRoute({
        name: 'Editar Termo',
        uri: `/terms/${props.match.params.termUid}`,
      });
      getFormFields().then((entity) => {
        getConnector().then((connector) => {
          fetchData(connector).then((resp) => {
            let respCpy = { ...resp };
            let entityCopy = { ...entity };

            // @ts-ignore
            setData(respCpy);
            // @ts-ignore
            setEntity(entityCopy);
            return respCpy;
          });
        });
      });
    }
  }, []);

  const getConnector = async () => {
    const directory: IDirectoryModel = await SDK.dynamic.bridge(
      `auth/v1.0/directory/uid/${SDK.Api.authorization.activeProject.directory.uid}`,
      null,
      'GET'
    );

    if (directory) {
      const termsPolicies = directory.connectors.find(
        (connector: { type: string }) => connector.type === 'Policies_1'
      );
      if (termsPolicies) {
        setTermsConnector(termsPolicies);
        return termsPolicies;
      }
    }

    throw new Error(
      'Não foi possível encontrar o connector de políticas de privacidade'
    );
  };

  async function getFormFields() {
    const response = await fetch('./preferences.json');

    const { forms, general } = await response.json();

    setLanguage(general.language);

    return forms['edit-terms'];
  }

  const fetchData = async (
    termsConnector: IConnectorModel
  ): Promise<ITermsModel | null> => {
    const term: ITermsModel = await SDK.dynamic.bridge(
      `auth/v1.0/policies/connector/uid/${termsConnector?.uid}/term/uid/${props.match.params.termUid}?include_authorizations=true`,
      null,
      'GET'
    );

    if (term) {
      if (term) {
        if ('versions' in term && term.versions.length) {
          const currentVersion = term.versions.find(
            (version: ITermsVersionModel) => version.current_version
          );

          if (currentVersion) {
            const fileDetail: { content_uid: string } =
              await SDK.dynamic.bridge(
                `storage/v1.0/content/details?uid=${currentVersion.content_uid}`,
                null,
                'GET'
              );

            term.content_uid = fileDetail.content_uid;
          }
        }

        return term;
      }
    }

    return null;
  };

  const handleSave = async (data: ITermsModel & Record<string, any>) => {
    try {
      let uids = ids
        .map((item) => {
          if (data[item]) {
            const backup = { ...data[item] };
            delete data[item];
            return Object.keys(backup).filter((key) => backup[key]);
          }
        })
        .reduce((a, b) => [...(a || []), ...(b || [])], []);

      const entityReferences = (uids ?? []).map((uid) => ({
        reference_uid: uid,
        type: 'Entity',
      }));

      const references = [...entityReferences, ...(data.users || [])];

      if (data.users) delete data.users;

      if (references.length) {
        saveAuthorizationUpdateTermFlow(
          termsConnector?.uid ?? '',
          props.match.params.termUid,
          {
            ...authorization,
            references,
          }
        );
      }

      const term: ITermsModel = await SDK.dynamic.bridge(
        `auth/v1.0/policies/connector/uid/${termsConnector?.uid}/management/term/uid/${props.match.params.termUid}`,
        data,
        'PUT'
      );

      if (term) {
        const termWithVersions: ITermsModel = await SDK.dynamic.bridge(
          `auth/v1.0/policies/connector/uid/${termsConnector?.uid}/term/uid/${props.match.params.termUid}`,
          null,
          'GET'
        );

        if (termWithVersions) {
          if (termWithVersions) {
            const promises = termWithVersions.versions.map(
              async (version: ITermsVersionModel) => {
                if (version.current_version) {
                  return await SDK.dynamic.bridge(
                    `auth/v1.0/policies/connector/uid/${termsConnector?.uid}/term/version/uid/${version.uid}/management`,
                    {
                      ...version,
                      current_version: false,
                    },
                    'PUT'
                  );
                }
              }
            );

            await Promise.all(promises);
          }
        }
      }

      // TODO: Remover quando concertar o bug do endpoint auth/v1.0/policies/connector/uid/${termsPolicies?.uid}/term/uid/${resp[0].uid}/content
      const public_content: { share_uid: string } = await SDK.dynamic.bridge(
        `/storage/v1.0/content/share/scope/public?manual_project_uid=${SDK.Api.authorization.activeProject.uid}&uid=${data.content_uid}`,
        null,
        'POST'
      );

      await SDK.dynamic.bridge(
        `auth/v1.0/policies/connector/uid/${termsConnector?.uid}/term/uid/${term.uid}/management/version`,
        {
          current_version: true,
          // content_uid: data.content_uid,
          content_uid: public_content.share_uid,
        },
        'POST'
      );

      history.push('/terms');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <S.Container>
      <Header />

      <Sidebar
        currentRoute={consumer.currentRoute}
        defineGroup={consumer.defineGroup}
        defineRoute={consumer.defineRoute}
        groups={consumer.groups}
      />

      <Breadcrumb
        currentRoute={consumer.currentRoute}
        currentGroup={consumer.currentGroup}
        currentBreadcrumbTitle="Editar Termo"
        label="Editar Termo"
      />

      <S.Content ref={containerRef}>
        <Suspense fallback={<Loading />}>
          {entityUpd && data && (
            <DynamicForm
              parentRef={containerRef}
              handleSubmit={handleSave}
              form={entityUpd}
              submitText={entityUpd.submitText}
              handleSave={handleSave}
              defaultValues={data}
              language={language}
              defaultUserAuthorizations={defaultUsersSelecteds}
            />
          )}
        </Suspense>
      </S.Content>
    </S.Container>
  );
}

export default EditTerms;
