import React, { useEffect, useState } from 'react';
import './auth-with-token.css';
import { Redirect, useHistory } from 'react-router-dom';
import Sdk from 'api.digitalpages.module.sdk.api';
import Loading from '../loading';

export const ViewAuthWithToken = () => {
  const [logged, setLogged] = useState(false);

  const history = useHistory()

  const url = window.location.href;
  const regex = /\/auth-with-credentials\/(.*)/;
  const match = url.match(regex);
  const token = match ? match[1] : null;
  
  useEffect(() => {
    if(token){
      Sdk.Api.authorization.signinByCredential({
        "type": "Bearer",
        "access_token": token
      }).then(response => {
        setLogged(true)
        console.log(response)

        if (history) {
          history.push('/');
        }
      }).catch(error => {
        console.log(error);
      });
    }

  }, [token])

  return(
    <div>
        <Loading />
      </div>
  )
}

export default ViewAuthWithToken
