import React, { useEffect } from 'react';
import { Breadcrumb, Header, Sidebar } from '../../components';
import * as S from './reports.styled';
import { Link } from 'react-router-dom';

export default function ViewReports(props) {
  const {
    consumer: {
      currentRoute = null,
      currentGroup = null,
      defineRoute = null,
      defineGroup = null,
      groups = null,
    }, 
  } = props;
  
  useEffect(() => {
    if (defineRoute !== null && typeof defineRoute === "function") {
      defineRoute({ name: 'Relatórios', uri: '/reports' });
    }
  }, []);

  return (
    <>
      <Header />
      
      <Sidebar
        currentRoute={currentRoute}
        defineGroup={defineGroup}
        defineRoute={defineRoute}
        groups={groups}
      />

      <Breadcrumb
        currentRoute={currentRoute}
        currentGroup={currentGroup}
        label='Relatórios'
      />

      <S.MainList>
        <S.Item>
          <Link to='/report/scores'>
            Relatório de notas
          </Link>
        </S.Item>

        <S.Item>
          <Link to='/report/attendance'>
            Relatório de frequência
          </Link>
        </S.Item>
      </S.MainList>
    </>
  );
}