import React, { useState, useEffect, useRef } from 'react';
import { Header, Sidebar, Breadcrumb, Footer, ButtonIcon, Loading, Iframe } from '../../components';
import Switch from 'react-switch';
import "./personalize.scss";
import Components from 'rdp-builder-components';
import Chrome from 'react-color';
import { IconUpload } from '../../components/icon';
import Sdk from 'api.digitalpages.module.sdk.api';
import useAuthToken from '../../hooks/useAuthToken';
import Axios from 'axios';
import RDP_CONFIG from '../../config/config';
import { DAMService } from '../../services/dam';

export default function ViewPersonalize({ consumer, entity, history }) {

  const [preferences, setPreferences] = useState(null);
  const [formIndex, setFormIndex] = useState(0);

  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [msgSuccess, setMsgSuccess] = useState('')
  const [msgError, setMsgError] = useState('')

  // CONFIG CUSTOMIZATION

  const [configs, setConfigs] = useState()

  const [configJSON, setConfigJSON] = useState()

  const getProjectConfigs = () => {
    return new Promise((resolve, reject) => {
      Sdk.dynamic.bridge(
        `auth/v1.0/project/uid/${Sdk.authorization.activeProject.uid}`,
        null,
        'GET'
      )
        .then((project) => {
          resolve(project);
          function findMicrosoftIndex(data) {
            for (let i = 0; i < data?.length; i++) {
              if (data[i].type === "Customization_1") {
                return data[i];
              }
            }
            return -1;
          }

          const connector = findMicrosoftIndex(project?.connectors).configuration

          setConfigs(connector)
          const json = JSON.parse(configs)
          setConfigJSON(json.configs)

        })
        .catch((error) => {
          console.error('Erro ao buscar dados do projeto:', error);
          reject(error);
        });
    });
  };


  useEffect(() => {
    getProjectConfigs()
  }, [configs])

  function getValueByUid(uid) {
    const obj = configJSON?.find(item => item.uid === uid);
    return obj ? JSON.parse(obj.value) : null;
  }


  function getValueByUidChilds(uid, childUid, data) {
    const commonConfig = getValueByUid(uid, data);

    if (commonConfig && Array.isArray(commonConfig)) {
      const childConfig = commonConfig.find((item) => item.uid === childUid);
      if (childConfig) {
        return childConfig.value;
      }
    }

    return null;
  }

  // STATES COLOR PICKER

  const [colorBgButtonActive, setColorBgButtonActive] = useState(getValueByUidChilds('header', 'background', configJSON))
  const [showBgButtonActive, setShowBgButtonActive] = useState(false)

  const [colorBgButton, setColorBgButton] = useState(getValueByUidChilds('header', 'buttons_background', configJSON))
  const [showBgButton, setShowBgButton] = useState(false)

  const [bgIconButton, setBgIconButton] = useState(getValueByUidChilds('header', 'buttons_icon_background', configJSON))
  const [showBgIconButton, setShowBgIconButton] = useState(false)

  const [textButton, setTextButton] = useState(getValueByUidChilds('header', 'buttons_text_color', configJSON))
  const [showTextButton, setShowTextButton] = useState(false)

  const [menuDropdown, setMenuDropdown] = useState(getValueByUidChilds('header', 'dropdown_background', configJSON))
  const [showMenuDropdown, setShowMenuDropdown] = useState(false)

  const [buttonMenuDropdown, setButtonMenuDropdown] = useState(getValueByUidChilds('header', 'dropdown_button_background', configJSON))
  const [showButtonMenuDropdown, setShowButtonMenuDropdown] = useState(false)

  const [iconButtonMenuDropdown, setIconButtonMenuDropdown] = useState(getValueByUidChilds('header', 'dropdown_button_icon_background', configJSON))
  const [showIconButtonMenuDropdown, setShowIconButtonMenuDropdown] = useState(false)

  const [firstText, setFirstText] = useState(getValueByUidChilds('header', 'first_label_color', configJSON))
  const [showFirstText, setShowFirstText] = useState(false)

  const [headerNotifications, setHeaderNotifications] = useState(getValueByUidChilds('header', 'notifications_header_color', configJSON))
  const [showHeaderNotifications, setShowHeaderNotifications] = useState(false)

  const [secondText, setSecondText] = useState(getValueByUidChilds('header', 'second_label_color', configJSON))
  const [showSecondText, setShowSecond] = useState(false)

  const [bgButtonActiveSidebar, setBgButtonActiveSidebar] = useState(getValueByUidChilds('sidebar', 'active_button_background_color', configJSON))
  const [showBgButtonActiveSidebar, setShowBgButtonActiveSidebar] = useState(false)

  const [iconColorSidebar, setIconColorSidebar] = useState(getValueByUidChilds('sidebar', 'active_icon_color', configJSON))
  const [showIconColorSidebar, setShowIconColorSidebar] = useState(false)

  const [textActiveSidebar, setTextActiveSidebar] = useState(getValueByUidChilds('sidebar', 'active_label_color', configJSON))
  const [showTextActiveSidebar, setShowTextActiveSidebar] = useState(false)

  const [bgColorSidebar, setBgColorSidebar] = useState(getValueByUidChilds('sidebar', 'background_color', configJSON))
  const [showBgColorSidebar, setShowBgColorSidebar] = useState(false)

  const [bgSidebarButtonColor, setBgSidebarButtonColor] = useState(getValueByUidChilds('sidebar', 'button_background_color', configJSON))
  const [showBgColorSidebarButton, setShowBgColorSidebarButton] = useState(false)

  const [sidebarIconColor, setSidebarIconColor] = useState(getValueByUidChilds('sidebar', 'icon_color', configJSON))
  const [showSidebarIconColor, setShowSidebarIconColor] = useState(false)

  const [sidebarBorderIcon, setSidebarBorderIcon] = useState(getValueByUidChilds('sidebar', 'item_border_color', configJSON))
  const [showSidebarBorderIcon, setShowSidebarBorderIcon] = useState(false)

  const [sidebarColorText, setSidebarColorText] = useState(getValueByUidChilds('sidebar', 'label_color', configJSON))
  const [showSidebarColorText, setShowSidebarColorText] = useState(false)

  const [primaryColor, setPrimaryColor] = useState(getValueByUidChilds('common', 'primary_color', configJSON))
  const [showPrimaryColor, setShowPrimaryColor] = useState(false)

  const [secondaryColor, setSecondaryColor] = useState(getValueByUidChilds('common', 'secondary_color', configJSON))
  const [showSecondaryColor, setShowSecondaryColor] = useState(false)

  const [terciaryColor, setTerciaryColor] = useState(getValueByUidChilds('common', 'tertiary_color', configJSON))
  const [showTerciaryColor, setShowTerciaryColor] = useState(false)

  const [fourthColor, setFourthColor] = useState(getValueByUidChilds('common', 'fourthiary_color', configJSON))
  const [showFourthColor, setShowFourthColor] = useState(false)

  // BACKGROUND IMAGE 
  const [bgApresentation, setBgApresentation] = useState(getValueByUidChilds('common', 'apresentation_background', configJSON))

  const [isThumbModalOpen, setIsThumbModalOpen] = useState(false)

  const [content, setContent] = useState(null);

  const [language, setLanguage] = useState('pt');
  const [thumbIframe, setThumbIframe] = useState(null);
  const [newCourseThumb, setNewCourseThumb] = useState(null);
  // TAGS

  const [tags, setTags] = useState(getValueByUidChilds('login', 'login_type', configJSON));
  const [selectedTag, setSelectedTag] = useState('');

  // REGISTER TAB

  const [tagLogin, setTagLogin] = useState(getValueByUidChilds('register', 'auto_login', configJSON))
  const [selectedTagLogin, setSelectedTagLogin] = useState()

  const [userNameProperty, setUserNameProperty] = useState(getValueByUidChilds('register', 'username_property_to_register', configJSON))
  const [selectedUserNameProperty, setSelectedUserNameProperty] = useState()

  // RECOVER PASSWORD TAB

  const data = getValueByUid('dynamic_recovery_password')
  const enabledTypesData = data?.find((item) => item.uid === 'enabled_types');

  const [tagsRecover, setTagsRecover] = useState(enabledTypesData?.value)
  const [selectedTagRecover, setSelectedTagRecover] = useState('')

  const [menuLabelRecover, setMenuLabelRecover] = useState(getValueByUidChilds('dynamic_recovery_password', 'personal_info', configJSON))

  const [typeData, setTypeData] = useState(data?.find((item) => item.uid === 'types'))

  // OTHERS
  const [switchLoginTab, setSwitchLoginTab] = useState(getValueByUidChilds('login', 'show_register_button', configJSON))

  const [switchRoadCourses, setSwitchRoadCourses] = useState(getValueByUidChilds('course_tree', 'perform_activities_sequentially', configJSON))

  const [commingSoonLabel, setCommingSoonLabel] = useState(getValueByUidChilds('course_tree', 'coming_soon_label', configJSON))

  const [commingSoonDate, setCommingSoonDate] = useState(getValueByUidChilds('course_tree', 'coming_soon_date_label', configJSON))

  const [loginPlaceholder, setLoginPlaceholder] = useState(getValueByUidChilds('login', 'login_placeholder', configJSON))

  const [switchShowLogin, setSwitchShowLogin] = useState(getValueByUidChilds('register', 'show_login_button', configJSON))

  const [switchShowRegister, setSwitchShowRegister] = useState(getValueByUidChilds('register', 'show_login_button', configJSON))

  const [switchUsersGroup, setSwitchUsersGroup] = useState(getValueByUidChilds('users_group', 'enabled', configJSON))

  const [switchDownloadComposer, setSwitchDownloadComposer] = useState(getValueByUidChilds('composer', 'enable_download', configJSON))

  const [switchFirstAccess, setSwitchFirstAccess] = useState(getValueByUidChilds('first_access', 'show_first_access_button', configJSON))

  const [switchAutoLogin, setSwitchAutoLogin] = useState(getValueByUidChilds('register', 'auto_login', configJSON))

  useEffect(() => {
    // COMMON
    setPrimaryColor(getValueByUidChilds('common', 'primary_color', configJSON))
    setSecondaryColor(getValueByUidChilds('common', 'secondary_color', configJSON))
    setTerciaryColor(getValueByUidChilds('common', 'tertiary_color', configJSON))
    setFourthColor(getValueByUidChilds('common', 'fourthiary_color', configJSON))
    setBgApresentation(getValueByUidChilds('common', 'apresentation_background', configJSON))
    // SIDEBAR
    setBgButtonActiveSidebar(getValueByUidChilds('sidebar', 'active_button_background_color', configJSON));
    setIconColorSidebar(getValueByUidChilds('sidebar', 'active_icon_color', configJSON))
    setTextActiveSidebar(getValueByUidChilds('sidebar', 'active_label_color', configJSON))
    setBgColorSidebar(getValueByUidChilds('sidebar', 'background_color', configJSON))
    setBgSidebarButtonColor(getValueByUidChilds('sidebar', 'button_background_color', configJSON))
    setSidebarIconColor(getValueByUidChilds('sidebar', 'icon_color', configJSON))
    setSidebarBorderIcon(getValueByUidChilds('sidebar', 'item_border_color', configJSON))
    setSidebarColorText(getValueByUidChilds('sidebar', 'label_color', configJSON))
    // HEADER
    setColorBgButtonActive(getValueByUidChilds('header', 'background', configJSON))
    setColorBgButton(getValueByUidChilds('header', 'buttons_background', configJSON))
    setBgIconButton(getValueByUidChilds('header', 'buttons_icon_background', configJSON))
    setTextButton(getValueByUidChilds('header', 'buttons_text_color', configJSON))
    setMenuDropdown(getValueByUidChilds('header', 'dropdown_background', configJSON))
    setButtonMenuDropdown(getValueByUidChilds('header', 'dropdown_button_background', configJSON))
    setIconButtonMenuDropdown(getValueByUidChilds('header', 'dropdown_button_icon_background', configJSON))
    setFirstText(getValueByUidChilds('header', 'first_label_color', configJSON))
    setHeaderNotifications(getValueByUidChilds('header', 'notifications_header_color', configJSON))
    setSecondText(getValueByUidChilds('header', 'second_label_color', configJSON))
    // FIRST ACCESS
    setSwitchFirstAccess(getValueByUidChilds('first_access', 'show_first_access_button', configJSON))
    // COMPOSER
    setSwitchDownloadComposer(getValueByUidChilds('composer', 'enable_download', configJSON))
    // USER GROUP
    setSwitchUsersGroup(getValueByUidChilds('users_group', 'enabled', configJSON))
    // ROAD COURSES 
    setSwitchRoadCourses(getValueByUidChilds('course_tree', 'perform_activities_sequentially', configJSON))
    setCommingSoonDate(getValueByUidChilds('course_tree', 'coming_soon_date_label', configJSON))
    setCommingSoonLabel(getValueByUidChilds('course_tree', 'coming_soon_label', configJSON))
    // LOGIN
    setLoginPlaceholder(getValueByUidChilds('login', 'login_placeholder', configJSON))
    setTags(getValueByUidChilds('login', 'login_type', configJSON))
    setSwitchLoginTab(getValueByUidChilds('login', 'show_register_button', configJSON))
    // REGISTRO
    setSwitchShowRegister(getValueByUidChilds('register', 'show_login_button', configJSON))
    setSelectedTag(getValueByUidChilds('register', 'additional_fields', configJSON))
    setSwitchAutoLogin(getValueByUidChilds('register', 'auto_login', configJSON))
    setUserNameProperty(getValueByUidChilds('register', 'username_property_to_register', configJSON))
    // RECOVER PASSWORD
    setMenuLabelRecover(getValueByUidChilds('dynamic_recovery_password', 'personal_info', configJSON))
    setTagsRecover(getValueByUid('dynamic_recovery_password')?.find((item) => item.uid === 'enabled_types').value)
  }, [configJSON]);


  // HANDLECHANGE COLOR PICKER

  // BACKGROUND COLOR ACTIVE
  const handleChangeBgButtonActive = (color) => {
    setColorBgButtonActive(color.hex)
  };

  const handleChangeColorBgButtonActive = (e) => {
    e.preventDefault()
    setColorBgButtonActive(e.target.value)
  }

  // BACKGROUND COLOR BUTTONS
  const handleChangeBgButton = (color) => {
    setColorBgButton(color.hex)
  };

  const handleChangeColorBgButton = (e) => {
    e.preventDefault()
    setColorBgButton(e.target.value)
  }


  // BACKGROUND ICON BUTTONS
  const handleChangeBgIconButton = (color) => {
    setBgIconButton(color.hex)
  };

  const handleChangeColorBgIconButton = (e) => {
    e.preventDefault()
    setBgIconButton(e.target.value)
  }

  // BUTTON TEXT
  const handleChangeTextButton = (color) => {
    setTextButton(color.hex)
  };

  const handleChangeColorTextButton = (e) => {
    e.preventDefault()
    setTextButton(e.target.value)
  }

  // DROPDOWN MENU
  const handleChangeMenuDropdown = (color) => {
    setMenuDropdown(color.hex)
  };

  const handleChangeColorMenuDropdown = (e) => {
    e.preventDefault()
    setMenuDropdown(e.target.value)
  }

  // BUTTON DROPDOWN MENU
  const handleChangeButtonMenuDropdown = (color) => {
    setButtonMenuDropdown(color.hex)
  };

  const handleChangeColorButtonDropdown = (e) => {
    e.preventDefault()
    setButtonMenuDropdown(e.target.value)
  }


  // BACKGROUND DROPDOWN MENU
  const handleChangeIconButtonMenuDropdown = (color) => {
    setIconButtonMenuDropdown(color.hex)
  };

  const handleChangeColorIconButtonDropdown = (e) => {
    e.preventDefault()
    setIconButtonMenuDropdown(e.target.value)
  }


  // FIRST TEXT
  const handleChangeFirstText = (color) => {
    setFirstText(color.hex)
  };

  const handleChangeColorFirstText = (e) => {
    e.preventDefault()
    setFirstText(e.target.value)
  }


  // HEADER NOTIFICATIONS
  const handleChangeHeaderNotifications = (color) => {
    setHeaderNotifications(color.hex)
  };

  const handleChangeColorHeaderNotifications = (e) => {
    e.preventDefault()
    setHeaderNotifications(e.target.value)
  }

  // SECOND TEXT
  const handleChangeSecondText = (color) => {
    setSecondText(color.hex)
  };

  const handleChangeColorSecondText = (e) => {
    e.preventDefault()
    setSecondText(e.target.value)
  }

  // LOGIN PLACEHOLDER

  const handleChangeLoginPlaceholder = (e) => {
    e.preventDefault()
    setLoginPlaceholder(e.target.value)
  }

  // SIDEBAR BACKGROUND BUTTON ACTIVE 

  const handleChangeBgButtonActiveSidebar = (color) => {
    setBgButtonActiveSidebar(color.hex)
  }

  const handleChangeColorBgButtonActiveSidebar = (e) => {
    e.preventDefault()
    setBgButtonActiveSidebar(e.target.value)
  }

  // SIDEBAR COLOR ICON

  const handleChangeIconColorSidebar = (color) => {
    setIconColorSidebar(color.hex)
  }

  const handleChangeColorIconColorSidebar = (e) => {
    e.preventDefault()
    setIconColorSidebar(e.target.value)
  }

  // SIDEBAR TEXT ACTIVE COLOR 

  const handleChangeTextActiveColor = (color) => {
    setTextActiveSidebar(color.hex)
  }

  const handleChangeColorTextActiveColor = (e) => {
    e.preventDefault()
    setTextActiveSidebar(e.target.value)
  }

  // SIDEBAR BACKGROUND COLOR

  const handleChangeSidebarBgColor = (color) => {
    setBgColorSidebar(color.hex)
  }

  const handleChangeColorSidebarBgColor = (e) => {
    e.preventDefault()
    setBgColorSidebar(e.target.value)
  }

  // SIDEBAR BACKGROUND COLOR BUTTONS 

  const handleChangeSidebarBgButtonColor = (color) => {
    setBgSidebarButtonColor(color.hex)
  }

  const handleChangeColorSidebarBgButtonColor = (e) => {
    e.preventDefault()
    setBgSidebarButtonColor(e.target.value)
  }

  // SIDEBAR ICON COLOR

  const handleChangeIconColor = (color) => {
    setSidebarIconColor(color.hex)
  }

  const handleChangeColorSidebarIconColor = (e) => {
    e.preventDefault()
    setSidebarIconColor(e.target.value)
  }

  // SIDEBAR BORDER ICON 

  const handleChangeSidebarBorderIcon = (color) => {
    setSidebarBorderIcon(color.hex)
  }

  const handleChangeColorSidebarBorderIcon = (e) => {
    e.preventDefault()
    setSidebarBorderIcon(e.target.value)
  }

  // SIDEBAR COLOR TEXT

  const handleChangeSidebarColorText = (color) => {
    setSidebarColorText(color.hex)
  }

  const handleChangeColorSidebarColorText = (e) => {
    e.preventDefault()
    setSidebarColorText(e.target.value)
  }

  // BACKGROUND IMAGE 

  const handleOpenThumbIframe = () => {
    setThumbIframe(`${Sdk.Api.domain}/product/dam/current/${RDP_CONFIG.projectKey}/p/-/${Sdk.Api.enviroment === "production" ? "p" : "h"}/#/auth-with-token/${language}/images/${Sdk.authorization.activeProject.uid}/${token}`);
    setNewCourseThumb(null);
    const handler = async (event) => {
      console.log(event.data.content_type)
      if (event.data.content_type) {
        setContent(event.data);
        setThumbIframe(null);
        const url = await DAMService.getPublicUrl(event.data.uid);
        console.log(url)
        setBgApresentation(url)
        setNewCourseThumb(url);
      }
    };

    window.addEventListener('message', handler);

    // clean up
    return () => window.removeEventListener('message', handler);
  };

  // PRIMARY COLOR

  const handleChangePrimaryColor = (color) => {
    setPrimaryColor(color.hex)
  }

  const handleChangeColorPrimaryColor = (e) => {
    e.preventDefault()
    setPrimaryColor(e.target.value)
  }

  // SECONDARY COLOR

  const handleChangeSecondaryColor = (color) => {
    setSecondaryColor(color.hex)
  }

  const handleChangeColorSecondaryColor = (e) => {
    e.preventDefault()
    setSecondaryColor(e.target.value)
  }

  // TERCIARY COLOR

  const handleChangeTerciaryColor = (color) => {
    setTerciaryColor(color.hex)
  }

  const handleChangeColorTerciaryColor = (e) => {
    e.preventDefault()
    setTerciaryColor(e.target.value)
  }

  // FOURTH COLOR

  const handleChangeFourthColor = (color) => {
    setFourthColor(color.hex)
  }

  const handleChangeColorFourthColor = (e) => {
    e.preventDefault()
    setFourthColor(e.target.value)
  }

  // COMMING SOON DATE

  const handleChangeCommingSoonDate = (e) => {
    e.preventDefault()
    setCommingSoonDate(e.target.value)
  }

  // COMMING SOON LABEL

  const handleChangeCommingSoonLabel = (e) => {
    e.preventDefault()
    setCommingSoonLabel(e.target.value)
  }


  // TAG LOGIN PILLS INPUT

  const handleTagChange = (e) => {
    e.preventDefault()
    setSelectedTag(e.target.value);
    if (e.target.value !== '') {
      setTags([...tags, e.target.value]);
    }
  };

  const handleTagDelete = (tag) => {
    setTags(tags.filter((t) => t !== tag));
  };

  // TAG REGISTER PILLS INPUT

  const handleTagRegisterShowLoginChange = (e) => {
    e.preventDefault()
    setSelectedTagLogin(e.target.value);
    if (e.target.value !== '') {
      setTagLogin([...tagLogin, e.target.value]);
    }
  };

  const handleTagRegisterShowLoginDelete = (tag) => {
    setTagLogin(tagLogin.filter((t) => t !== tag));
  };

  // TAG AUTO_LOGIN PILLS INPUT

  const handleTagAutoLoginChange = (e) => {
    e.preventDefault()
    setUserNameProperty(e.target.value);
  };

  const handleTagAutoLoginDelete = (tag) => {
    setUserNameProperty(userNameProperty.filter((t) => t !== tag));
  };


  // TAG RECOVER PASSWORD 

  const handleTagChangeRecoverPassword = (e) => {
    setSelectedTagRecover(e.target.value);
    if (e.target.value !== '') {
      setTagsRecover([...tagsRecover, e.target.value]);
    }
  };

  const handleTagDeleteRecoverPassword = (tag) => {
    setTagsRecover(tagsRecover.filter((t) => t !== tag));
  };

  // SWITCHES

  const handleSwitchLoginTab = () => {
    setSwitchLoginTab(!switchLoginTab)
  }

  const handlSwitchRoadCousesTab = () => {
    setSwitchRoadCourses(!switchRoadCourses)
  }

  const handleSwitchShowRegister = () => {
    setSwitchShowRegister(!switchShowRegister)
  }

  const handleSwitchUsersGroup = () => {
    setSwitchUsersGroup(!switchUsersGroup)
  }

  const handleSwitchDownloadComposer = () => {
    setSwitchDownloadComposer(!switchDownloadComposer)
  }

  const handleSwitchFirstAccess = () => {
    setSwitchFirstAccess(!switchFirstAccess)
  }

  const handleSwitchAutoLogin = () => {
    setSwitchAutoLogin(!switchAutoLogin)
  }

  // RECOVER PASSWORD HANDLECHANGE

  const handleChangeMenuLabelRecover = (e) => {
    e.preventDefault()
    setMenuLabelRecover(e.target.value)
  }

  const previewColorRef = useRef(null);

  useEffect(() => {
    let hasInitialTrueState = false;

    const handleClickOutside = (event) => {
      if (previewColorRef.current && !previewColorRef.current.contains(event.target)) {
        if (hasInitialTrueState) {
          setShowBgButtonActive(false);
          setShowBgButton(false);
          setShowBgIconButton(false);
          setShowTextButton(false);
          setShowMenuDropdown(false);
          setShowButtonMenuDropdown(false);
          setShowIconButtonMenuDropdown(false);
          setShowFirstText(false);
          setShowHeaderNotifications(false);
          setShowSecond(false);
          setShowBgButtonActiveSidebar(false);
          setShowIconColorSidebar(false);
          setShowTextActiveSidebar(false);
          setShowBgColorSidebar(false);
          setShowBgColorSidebarButton(false);
          setShowSidebarIconColor(false);
          setShowSidebarBorderIcon(false);
          setShowSidebarColorText(false);
          setShowPrimaryColor(false);
          setShowSecondaryColor(false);
          setShowTerciaryColor(false);
          setShowFourthColor(false);
        } else {
          hasInitialTrueState = true;
        }
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {

    // handleMultiDelete();
    init()
    consumer.defineRoute({ name: 'Personalizar', uri: '/personalize' });
  }, []);

  async function init() {
    const res = await fetch('./preferences.json');
    const prefs = await res.json();
    setPreferences(prefs);
  }

  const renderCheckIcon = () => {
    return (
      <svg
        width="22"
        height="23"
        viewBox="0 0 22 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 0.5C4.925 0.5 0 5.425 0 11.5C0 17.575 4.925 22.5 11 22.5C17.075 22.5 22 17.575 22 11.5C22 5.425 17.075 0.5 11 0.5ZM15.768 9.64C15.8558 9.53964 15.9226 9.42274 15.9646 9.29617C16.0065 9.1696 16.0227 9.03591 16.0123 8.90298C16.0018 8.77005 15.9648 8.64056 15.9036 8.52213C15.8423 8.40369 15.758 8.29871 15.6555 8.21334C15.5531 8.12798 15.4346 8.06396 15.3071 8.02506C15.1796 7.98616 15.0455 7.97316 14.9129 7.98683C14.7802 8.00049 14.6517 8.04055 14.5347 8.10463C14.4178 8.16872 14.3149 8.25554 14.232 8.36L9.932 13.519L7.707 11.293C7.5184 11.1108 7.2658 11.01 7.0036 11.0123C6.7414 11.0146 6.49059 11.1198 6.30518 11.3052C6.11977 11.4906 6.0146 11.7414 6.01233 12.0036C6.01005 12.2658 6.11084 12.5184 6.293 12.707L9.293 15.707C9.39126 15.8052 9.50889 15.8818 9.63842 15.9321C9.76794 15.9823 9.9065 16.005 10.0453 15.9986C10.184 15.9923 10.32 15.9572 10.4444 15.8954C10.5688 15.8337 10.6791 15.7467 10.768 15.64L15.768 9.64Z"
          fill="white"
        />
      </svg>
    );
  };


  const renderPasswordDynamicRecover = () => {
    const data = getValueByUid('dynamic_recovery_password');
    const enabledTypesData = data.find((item) => item.uid === 'enabled_types');
    const typesData = data.find((item) => item.uid === 'types');

    const handleTagChangeRecoverPassword = (e) => {
      const selectedTag = e.target.value;
      // Verifica se a tag já existe no array
      if (!tagsRecover.includes(selectedTag)) {
        const newTags = [...tagsRecover, selectedTag];
        setTagsRecover(newTags);
      }
    };

    // Função para lidar com a exclusão de tags
    const handleTagDeleteRecoverPassword = (tag) => {
      const newTags = tagsRecover.filter((t) => t !== tag);
      setTagsRecover(newTags);
    };

    const dynamicInputs = [];

    if (enabledTypesData && enabledTypesData.value.includes('email')) {
      dynamicInputs.push(
        <div className="wrapper-select-tags">
          <select className="select-tag-pills" value="" onChange={handleTagChangeRecoverPassword} disabled={tagsRecover.length === 2}>
            <option value="">Selecione uma opção</option>
            <option value="email" disabled={tagsRecover.includes("email")}>Email</option>
            <option value="personal_info" disabled={tagsRecover.includes("personal_info")}>Informações Pessoais</option>
          </select>
          <div className="div-tags">
            {tagsRecover.map((tag, index) => (
              <div key={index} className="tag-pill">
                {tag === 'personal_info' ? 'informações pessoais' : tag}
                <button className="pill-close-button" onClick={() => handleTagDeleteRecoverPassword(tag)}>
                  X
                </button>
              </div>
            ))}
          </div>
        </div>
      );
    }


    tagsRecover.forEach((tag) => {
      const type = typesData.value.find((item) => item.type === tag);
      if (type) {
        dynamicInputs.push(
          <React.Fragment key={type.type}>
            <label className="label" htmlFor="title">
              {type.label}
            </label>
            <div className="input-wrapper">
              <Components.Forms.InputText
                name='title'
                type='text'
                placeholder=""
                border='1px solid #dbdfe6'
                borderRadius='4px'
                marginLeft='20px'
                marginRight='20px'
                marginBottom='20px'
                height='44px'
                fontColor='#575757'
                value={menuLabelRecover}
                onChange={(e) => setMenuLabelRecover(e.target.value)}
              />
            </div>
          </React.Fragment>
        );
      }
    });



    return (
      <div className="tab">
        {dynamicInputs}
      </div>
    );


  }

  const customizationObj = {
    "type": "Customization_1",
    "authorized_scopes": "Directory, Project",
    "authorized_services": "Authorization",
    "service": "Authorization",
    "content_references": [
      {
        "uid": "icon_url",
        "url": null,
        "description": "Imagem representando ^ícone do contexto.",
        "provider": "Internal"
      },
      {
        "uid": "wallpaper_url",
        "url": null,
        "description": "Imagem representando wallpaper do contexto.",
        "provider": "Internal"
      }
    ],
    "configs": [
      {
        "uid": "primary_color",
        "value": primaryColor
      },
      {
        "uid": "secondy_color",
        "value": secondaryColor
      },
      {
        "uid": "common",
        "value": JSON.stringify([
          {
            "uid": "apresentation_background",
            "value": bgApresentation
          },
          {
            "uid": "primary_color",
            "value": primaryColor
          },
          {
            "uid": "secondary_color",
            "value": secondaryColor
          },
          {
            "uid": "tertiary_color",
            "value": terciaryColor
          },
          {
            "uid": "fourthiary_color",
            "value": fourthColor
          }
        ])
      },
      {
        "uid": "header",
        "value": JSON.stringify([
          {
            "uid": "logotype",
            "value": null
          },
          {
            "uid": "background",
            "value": colorBgButtonActive
          },
          {
            "uid": "buttons_background",
            "value": colorBgButton
          },
          {
            "uid": "buttons_icon_background",
            "value": bgIconButton
          },
          {
            "uid": "buttons_text_color",
            "value": textButton
          },
          {
            "uid": "dropdown_background",
            "value": menuDropdown
          },
          {
            "uid": "dropdown_button_background",
            "value": buttonMenuDropdown
          },
          {
            "uid": "dropdown_button_icon_background",
            "value": iconButtonMenuDropdown
          },
          {
            "uid": "first_label_color",
            "value": firstText
          },
          {
            "uid": "notifications_header_color",
            "value": headerNotifications
          },
          {
            "uid": "second_label_color",
            "value": secondText
          }
        ])
      },
      {
        "uid": "sidebar",
        "value": JSON.stringify([
          {
            "uid": "active_button_background_color",
            "value": bgButtonActiveSidebar
          },
          {
            "uid": "active_icon_color",
            "value": iconColorSidebar
          },
          {
            "uid": "active_label_color",
            "value": textActiveSidebar
          },
          {
            "uid": "background_color",
            "value": bgColorSidebar
          },
          {
            "uid": "button_background_color",
            "value": bgSidebarButtonColor
          },
          {
            "uid": "icon_color",
            "value": sidebarIconColor
          },
          {
            "uid": "item_border_color",
            "value": sidebarBorderIcon
          },
          {
            "uid": "label_color",
            "value": sidebarColorText
          }
        ])
      },
      {
        "uid": "composer",
        "value": JSON.stringify([
          {
            "uid": "enable_download",
            "value": switchDownloadComposer
          }
        ])
      },
      {
        "uid": "login",
        "value": JSON.stringify([
          {
            "uid": "login_placeholder",
            "value": loginPlaceholder
          },
          {
            "uid": "login_type",
            "value": tags
          }
        ])
      },
      {
        "uid": "first_access",
        "value": JSON.stringify([
          {
            "uid": "show_first_access_button",
            "value": switchFirstAccess
          }
        ])
      },
      {
        "uid": "dynamic_recovery_password",
        "value": JSON.stringify([
          {
            "uid": "enabled_types",
            "value": [
              "email"
            ]
          },
          {
            "uid": "types",
            "value": [
              {
                "type": "personal_info",
                "label": "Utilizar informações pessoais",
                "formProps": {}
              },
              {
                "type": "email",
                "label": "Utilizar e-mail",
                "formProps": {}
              }
            ]
          }
        ])
      },
      {
        "uid": "course_tree",
        "value": JSON.stringify([
          {
            "uid": "perform_activities_sequentially",
            "value": switchRoadCourses
          },
          {
            "uid": "coming_soon_label",
            "value": commingSoonLabel
          },
          {
            "uid": "coming_soon_date_label",
            "value": commingSoonDate
          }
        ])
      },
      {
        "uid": "users_group",
        "value": JSON.stringify([
          {
            "uid": "enabled",
            "value": switchUsersGroup
          },
          {
            "uid": "title",
            "value": "Gestão de grupos de usuários"
          },
          {
            "uid": "subscription_button_label",
            "value": "Inscrever-se"
          },
        ])
      },
      {
        "uid": "register",
        "value": JSON.stringify([
          {
            "uid": "show_login_button",
            "value": switchShowRegister
          },
          {
            "uid": "additional_fields",
            "value": []
          },
          {
            "uid": "auto_login",
            "value": switchAutoLogin
          },
          {
            "uid": "username_property_to_register",
            "value": userNameProperty
          },
        ])
      },
    ],
    "reserved_content_uids": [
      "icon_url",
      "wallpaper_url"
    ],
    "reserved_config_uids": [
      "primary_color",
      "secondy_color"
    ],
    "class": "Api.DigitalPages.Connector.Customization.Connectors.ConnectorCustomization_1, Api.DigitalPages.Connector.Customization, Version= 1.0.3.0, Culture=neutral, PublicKeyToken=null",
    "label": "Customiza^ç^ão visual"
  }

  const token = useAuthToken()

  async function handleSubmit() {
    setLoadingSubmit(true);
    const connectorUid = await Sdk.Api.authorization.activeProject.connectors.filter(connector => connector.type === "Customization_1")[0].uid;
    if (connectorUid) {
      Axios.put(
        `https://api-euconquisto.azure-api.net/auth/v1.0/connector/uid/${connectorUid}/management`,
        {
          ...customizationObj,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "API-Instance-Uid": "cc75b460d9",
          },
        }
      )
        .then((response) => {
          setLoadingSubmit(false);
        })
        .catch((error) => {
          console.error(error);
          setLoadingSubmit(false);
        });
    }
  }



  if (preferences) {
    return <>
      <Header />
      <Sidebar
        currentRoute={consumer ? consumer.currentRoute : null}
        defineGroup={consumer ? consumer.defineGroup : null}
        defineRoute={consumer ? consumer.defineRoute : null}
        groups={consumer ? consumer.groups : null}
      />
      <Breadcrumb
        currentRoute={consumer ? consumer.currentRoute : null}
        defineRoute={consumer ? consumer.defineRoute : null}
      />
      <div class={thumbIframe ? "" : "rdp-admin-forum-container"}>
        {loadingSubmit && <Loading msg="Salvando configurações..." />}
        <div className='test-personalize'>
          <div className='edit-personalize-form'>
            <button
              className={`tab-button-personalize ${formIndex === 0 ? "active" : ""}`}
              onClick={() => setFormIndex(0)}>
              Trilha de Cursos
            </button>
            <button
              className={`tab-button-personalize ${formIndex === 1 ? "active" : ""}`}
              onClick={() => setFormIndex(1)}>
              Login
            </button>
            <button
              className={`tab-button-personalize ${formIndex === 2 ? "active" : ""}`}
              onClick={() => setFormIndex(2)}>
              Registro
            </button>
            <button
              className={`tab-button-personalize ${formIndex === 3 ? "active" : ""}`}
              onClick={() => setFormIndex(3)}>
              Grupo de Usuários
            </button>
            <button
              className={`tab-button-personalize ${formIndex === 4 ? "active" : ""}`}
              onClick={() => setFormIndex(4)}>
              Composer
            </button>
            <button
              className={`tab-button-personalize ${formIndex === 5 ? "active" : ""}`}
              onClick={() => setFormIndex(5)}>
              Primeiro Acesso
            </button>
            {/* <button
              className={`tab-button-personalize ${formIndex === 6 ? "active" : ""}`}
              onClick={() => setFormIndex(6)}>
              Recuperação de Senha Dinâmica
            </button> */}
            <button
              className={`tab-button-personalize ${formIndex === 7 ? "active" : ""}`}
              onClick={() => setFormIndex(7)}>
              Geral
            </button>
            <button
              className={`tab-button-personalize ${formIndex === 8 ? "active" : ""}`}
              onClick={() => setFormIndex(8)}>
              Cabeçalho
            </button>
            <button
              className={`tab-button-personalize ${formIndex === 9 ? "active" : ""}`}
              onClick={() => setFormIndex(9)}>
              Menu Lateral
            </button>
          </div>
          <div className='container'>
            <form>
              {formIndex === 0 &&
                <div className="tab">
                  <div className='wrapper-switch'>
                    <Switch
                      checked={switchRoadCourses}
                      className="edit-offer-permission-switch"
                      onColor="#c9b2e7"
                      onHandleColor="#764ab0"
                      handleDiameter={30}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={20}
                      width={48}
                      marginLeft="20px"
                      id="material-switch"
                      onChange={handlSwitchRoadCousesTab}
                    />
                    <span>Realizar Atividades Sequencialmente</span>

                  </div>
                  <label className='label' htmlFor={'title'}>
                    Label de em breve
                  </label>
                  <Components.Forms.InputText
                    name='title'
                    type='text'
                    placeholder='Realizar atividades sequencialmente'
                    border='1px solid #dbdfe6'
                    borderRadius='4px'
                    marginLeft='20px'
                    marginRight='20px'
                    marginBottom='20px'
                    height='44px'
                    fontColor='#575757'
                    defaultValue={commingSoonLabel}
                    value={commingSoonLabel}
                    onChange={handleChangeCommingSoonLabel}
                  />

                  <label className='label' htmlFor={'title'}>
                    Label de data de em breve
                  </label>
                  <Components.Forms.InputText
                    name='title'
                    type='text'
                    placeholder='Label de data de em breve'
                    border='1px solid #dbdfe6'
                    borderRadius='4px'
                    marginLeft='20px'
                    marginRight='20px'
                    marginBottom='20px'
                    height='44px'
                    fontColor='#575757'
                    defaultValue={commingSoonDate}
                    value={commingSoonDate}
                    onChange={handleChangeCommingSoonDate}
                  />
                </div>
              }

              {formIndex === 1 &&
                <>
                  <div className="tab">
                    <label className='label' htmlFor={'title'}>
                      Login Placeholder
                    </label>
                    <Components.Forms.InputText
                      name='title'
                      type='text'
                      placeholder='Label de data de em breve'
                      border='1px solid #dbdfe6'
                      borderRadius='4px'
                      marginLeft='20px'
                      marginRight='20px'
                      marginBottom='20px'
                      height='44px'
                      fontColor='#575757'
                      defaultValue={loginPlaceholder}
                      value={loginPlaceholder}
                      onChange={handleChangeLoginPlaceholder}
                    />
                    <label className='label' htmlFor={'title'}>
                      Tipo de Login
                    </label>
                    <div className='wrapper-select-tags'>
                      <select className='select-tag-pills' value="" onChange={handleTagChange}>
                        <option value="">Selecione uma opção</option>
                        <option value="email">Email</option>
                        <option value="cpf">CPF</option>
                        <option value="text">Texto</option>
                        <option value="cnpj">CNPJ</option>
                      </select>
                      <div className='div-tags'>
                        {tags.map((tag, index) => (
                          <div key={index} className="tag-pill">
                            {tag}
                            <button className='pill-close-button' onClick={() => handleTagDelete(tag)}>X</button>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className='wrapper-switch'>
                      <Switch
                        checked={switchLoginTab}
                        className="edit-offer-permission-switch"
                        onColor="#c9b2e7"
                        onHandleColor="#764ab0"
                        handleDiameter={30}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={20}
                        width={48}
                        marginLeft="20px"
                        id="material-switch"
                        onChange={handleSwitchLoginTab}
                      />
                      <span>Mostrar Botão de Registro</span>

                    </div>
                  </div>
                </>
              }

              {formIndex === 2 &&
                <div className="tab">
                  <div className='wrapper-switch'>
                    <Switch
                      checked={switchShowRegister}
                      className="edit-offer-permission-switch"
                      onColor="#c9b2e7"
                      onHandleColor="#764ab0"
                      handleDiameter={30}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={20}
                      width={48}
                      marginLeft="20px"
                      id="material-switch"
                      onChange={handleSwitchShowRegister}
                    />
                    <span>Mostrar Botão de Login</span>

                  </div>
                  <div className='wrapper-select-tags'>
                    <select className='select-tag-pills' value={tagLogin} onChange={handleTagRegisterShowLoginChange}>
                      <option value="">Campos Adicionais</option>
                      <option value="rg">RG</option>
                      <option value="cpf">CPF</option>
                      <option value="birthday">Data de Nascimento</option>
                    </select>
                  </div>
                  <div className='wrapper-switch'>
                    <Switch
                      checked={switchAutoLogin}
                      className="edit-offer-permission-switch"
                      onColor="#c9b2e7"
                      onHandleColor="#764ab0"
                      handleDiameter={30}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={20}
                      width={48}
                      marginLeft="20px"
                      id="material-switch"
                      onChange={handleSwitchAutoLogin}
                    />
                    <span>Auto Login</span>


                  </div>
                  <label className='label' htmlFor={'title'}>
                    Propriedade a Ser Considerada no Campo de Usuário ao se registrar
                  </label>
                  <div className='wrapper-select-tags'>
                    <select className='select-tag-pills' value={userNameProperty} onChange={handleTagAutoLoginChange}>
                      <option value="">Campos Adicionais</option>
                      <option value="Email">Data</option>
                      <option value="CPF">CPF</option>
                      <option value="Celular">Celular</option>
                    </select>
                  </div>
                </div>
              }

              {formIndex === 3 &&
                <div className="tab">

                  <div className='wrapper-switch'>
                    <Switch
                      checked={switchUsersGroup}
                      className="edit-offer-permission-switch"
                      onColor="#c9b2e7"
                      onHandleColor="#764ab0"
                      handleDiameter={30}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={20}
                      width={48}
                      marginLeft="20px"
                      id="material-switch"
                      onChange={handleSwitchUsersGroup}
                    />
                    <span>Habilitado</span>

                  </div>
                </div>
              }
              {formIndex === 4 &&
                <div className="tab">
                  <div className='wrapper-switch'>
                    <Switch
                      checked={switchDownloadComposer}
                      className="edit-offer-permission-switch"
                      onColor="#c9b2e7"
                      onHandleColor="#764ab0"
                      handleDiameter={30}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={20}
                      width={48}
                      marginLeft="20px"
                      id="material-switch"
                      onChange={handleSwitchDownloadComposer}
                    />
                    <span>Habilitar Download das Composições</span>
                  </div>
                </div>
              }
              {formIndex === 5 &&
                <div className="tab">

                  <div className='wrapper-switch'>
                    <Switch
                      checked={switchFirstAccess}
                      className="edit-offer-permission-switch"
                      onColor="#c9b2e7"
                      onHandleColor="#764ab0"
                      handleDiameter={30}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={20}
                      width={48}
                      marginLeft="20px"
                      id="material-switch"
                      onChange={handleSwitchFirstAccess}
                    />
                    <span>Mostrar Botões do Primeiro Acesso</span>
                  </div>
                </div>
              }
              {formIndex === 6 &&
                <>
                  {
                    renderPasswordDynamicRecover()
                  }
                </>
              }
              {formIndex === 7 &&
                
                <div className="tab">
                  <div className='image-dropzone-personalize'>
                    <label className='label' htmlFor={'title'}>
                      Imagem de Fundo da Tela de Login
                    </label>
                    <Components.Images.ImagePreview
                      src={bgApresentation}
                      alt='thumb image'
                      border='dashed'
                      lazy
                      onClick={() => setIsThumbModalOpen(true)}
                    />
                    <ButtonIcon
                      tooltip='dica'
                      actionClick={(event) => { event.preventDefault(); setIsThumbModalOpen(true); }}
                      color='primary'
                    >
                      <IconUpload />
                    </ButtonIcon>
                    {thumbIframe && (
                      <Iframe
                        onClickOutside={() => setThumbIframe(null)}
                        className='iframe-content-personalize'
                        src={thumbIframe}
                      />
                    )}
                    {
                      isThumbModalOpen &&
                      <Components.Modals.ModalContentSave
                        title='title'
                        saveLabel='save'
                        saveIcon={<Components.Icons.IconSave />}
                        // onSave={e => {
                        //   if (!loadingSubmit)
                        //     handleSaveThumb();
                        // }}
                        onClose={() => setIsThumbModalOpen(false)}
                      >
                        <Components.Buttons.CardButton
                          title='title'
                          subtitle='subtitle'
                          onClick={() => handleOpenThumbIframe()}
                        />
                        <label className='label'>
                          Label
                        </label>
                        <Components.Images.ImagePreview
                          src={bgApresentation}
                          alt='thumb image'
                          border='dashed'
                          lazy
                          onClick={() => handleOpenThumbIframe()}
                        />
                      </Components.Modals.ModalContentSave>

                    }

                  </div>

                  <label className='label' htmlFor={'title'}>
                    Cor Primária
                  </label>
                  <div className='input-wrapper'>
                    <Components.Forms.InputText
                      name='title'
                      type='text'
                      placeholder=''
                      border='1px solid #dbdfe6'
                      borderRadius='4px'
                      marginLeft='20px'
                      marginRight='20px'
                      marginBottom='20px'
                      height='44px'
                      fontColor='#575757'
                      defaultValue={primaryColor}
                      value={primaryColor}
                      onChange={handleChangeColorPrimaryColor}
                    />
                    <div className='preview-color' style={{ backgroundColor: primaryColor }} onClick={() => setShowPrimaryColor(!showPrimaryColor)} />
                    {showPrimaryColor ?
                      <div className='color-fieldbox' ref={previewColorRef}>
                        <Chrome color={primaryColor} onChange={handleChangePrimaryColor} />
                      </div>
                      : <></>}
                  </div>
                  <label className='label' htmlFor={'title'}>
                    Cor Secundária
                  </label>
                  <div className='input-wrapper'>
                    <Components.Forms.InputText
                      name='title'
                      type='text'
                      placeholder=''
                      border='1px solid #dbdfe6'
                      borderRadius='4px'
                      marginLeft='20px'
                      marginRight='20px'
                      marginBottom='20px'
                      height='44px'
                      fontColor='#575757'
                      defaultValue={secondaryColor}
                      value={secondaryColor}
                      onChange={handleChangeColorSecondaryColor}
                    />
                    <div className='preview-color' style={{ backgroundColor: secondaryColor }} onClick={() => setShowSecondaryColor(!showSecondaryColor)} />
                    {showSecondaryColor ?
                      <div className='color-fieldbox' ref={previewColorRef}>
                        <Chrome color={secondaryColor} onChange={handleChangeSecondaryColor} />
                      </div>
                      : <></>}
                  </div>
                  <label className='label' htmlFor={'title'}>
                    Cor Terciária
                  </label>
                  <div className='input-wrapper'>
                    <Components.Forms.InputText
                      name='title'
                      type='text'
                      placeholder=''
                      border='1px solid #dbdfe6'
                      borderRadius='4px'
                      marginLeft='20px'
                      marginRight='20px'
                      marginBottom='20px'
                      height='44px'
                      fontColor='#575757'
                      defaultValue={terciaryColor}
                      value={terciaryColor}
                      onChange={handleChangeColorTerciaryColor}
                    />
                    <div className='preview-color' style={{ backgroundColor: terciaryColor }} onClick={() => setShowTerciaryColor(!showTerciaryColor)} />
                    {showTerciaryColor ?
                      <div className='color-fieldbox' ref={previewColorRef}>
                        <Chrome color={terciaryColor} onChange={handleChangeTerciaryColor} />
                      </div>
                      : <></>}
                  </div>
                  <label className='label' htmlFor={'title'}>
                    Quarta Cor
                  </label>
                  <div className='input-wrapper'>
                    <Components.Forms.InputText
                      name='fourthColor'
                      type='text'
                      placeholder=''
                      border='1px solid #dbdfe6'
                      borderRadius='4px'
                      marginLeft='20px'
                      marginRight='20px'
                      marginBottom='20px'
                      height='44px'
                      fontColor='#575757'
                      defaultValue={fourthColor}
                      value={fourthColor}
                      onChange={handleChangeColorFourthColor}
                    />
                    <div className='preview-color' style={{ backgroundColor: fourthColor }} onClick={() => setShowFourthColor(!showFourthColor)} />
                    {showFourthColor ?
                      <div className='color-fieldbox' ref={previewColorRef}>
                        <Chrome color={fourthColor} onChange={handleChangeFourthColor} />
                      </div>
                      : <></>}
                  </div>
                </div>
              }
              {formIndex === 8 &&
                <div className="tab">
                  <label className='label' htmlFor={'title'}>
                    Cor de Fundo
                  </label>
                  <div className='input-wrapper'>
                    <Components.Forms.InputText
                      name='title'
                      type='text'
                      placeholder=''
                      border='1px solid #dbdfe6'
                      borderRadius='4px'
                      marginLeft='20px'
                      marginRight='20px'
                      marginBottom='20px'
                      height='44px'
                      fontColor='#575757'
                      defaultValue={colorBgButtonActive}
                      value={colorBgButtonActive}
                      onChange={handleChangeColorBgButtonActive}
                    />
                    <div className='preview-color' style={{ backgroundColor: colorBgButtonActive }} onClick={() => setShowBgButtonActive(!showBgButtonActive)} />
                    {showBgButtonActive ?
                      <div className='color-fieldbox' ref={previewColorRef}>
                        <Chrome color={colorBgButtonActive} onChange={handleChangeBgButtonActive} />
                      </div>
                      : <></>}
                  </div>
                  <div>

                    <label className='label' htmlFor={'title'}>
                      Cor de Fundo dos Botões
                    </label>
                    <div className='input-wrapper'>
                      <Components.Forms.InputText
                        name='title'
                        type='text'
                        placeholder=''
                        border='1px solid #dbdfe6'
                        borderRadius='4px'
                        marginLeft='20px'
                        marginRight='20px'
                        marginBottom='20px'
                        height='44px'
                        fontColor='#575757'
                        defaultValue={colorBgButton}
                        value={colorBgButton}
                        onChange={handleChangeColorBgButton}
                      />
                      <div className='preview-color' style={{ backgroundColor: colorBgButton }} onClick={() => setShowBgButton(!showBgButton)} />
                      {showBgButton ?
                        <div className='color-fieldbox' ref={previewColorRef}>
                          <Chrome color={colorBgButton} onChange={handleChangeBgButton} />
                        </div>
                        : <></>}
                    </div>
                  </div>
                  <div>

                    <label className='label' htmlFor={'title'}>
                      Cor de Fundo dos Ícones dos Botões
                    </label>
                    <div className='input-wrapper'>
                      <Components.Forms.InputText
                        name='title'
                        type='text'
                        placeholder=''
                        border='1px solid #dbdfe6'
                        borderRadius='4px'
                        marginLeft='20px'
                        marginRight='20px'
                        marginBottom='20px'
                        height='44px'
                        fontColor='#575757'
                        defaultValue={bgIconButton}
                        value={bgIconButton}
                        onChange={handleChangeColorBgIconButton}
                      />
                      <div className='preview-color' style={{ backgroundColor: bgIconButton }} onClick={() => setShowBgIconButton(!showBgIconButton)} />
                      {showBgIconButton ?
                        <div className='color-fieldbox' ref={previewColorRef}>
                          <Chrome color={bgIconButton} onChange={handleChangeBgIconButton} />
                        </div>
                        : <></>}
                    </div>
                  </div>
                  <div>
                    <label className='label' htmlFor={'title'}>
                      Cor do Texto dos Botões
                    </label>
                    <div className='input-wrapper'>
                      <Components.Forms.InputText
                        name='title'
                        type='text'
                        placeholder=''
                        border='1px solid #dbdfe6'
                        borderRadius='4px'
                        marginLeft='20px'
                        marginRight='20px'
                        marginBottom='20px'
                        height='44px'
                        fontColor='#575757'
                        defaultValue={textButton}
                        value={textButton}
                        onChange={handleChangeColorTextButton}
                      />
                      <div className='preview-color' style={{ backgroundColor: textButton }} onClick={() => setShowTextButton(!showTextButton)} />
                      {showTextButton ?
                        <div className='color-fieldbox' ref={previewColorRef}>
                          <Chrome color={textButton} onChange={handleChangeTextButton} />
                        </div>
                        : <></>}
                    </div>
                  </div>
                  <div>
                    <label className='label' htmlFor={'title'}>
                      Cor de Fundo do Menu Dropdown
                    </label>
                    <div className='input-wrapper'>
                      <Components.Forms.InputText
                        name='title'
                        type='text'
                        placeholder=''
                        border='1px solid #dbdfe6'
                        borderRadius='4px'
                        marginLeft='20px'
                        marginRight='20px'
                        marginBottom='20px'
                        height='44px'
                        fontColor='#575757'
                        defaultValue={menuDropdown}
                        value={menuDropdown}
                        onChange={handleChangeColorMenuDropdown}
                      />
                      <div className='preview-color' style={{ backgroundColor: menuDropdown }} onClick={() => setShowMenuDropdown(!showMenuDropdown)} />
                      {showMenuDropdown ?
                        <div className='color-fieldbox' ref={previewColorRef}>
                          <Chrome color={menuDropdown} onChange={handleChangeMenuDropdown} />
                        </div>
                        : <></>}
                    </div>
                  </div>
                  <div>
                    <label className='label' htmlFor={'title'}>
                      Cor de Fundo dos Botões do Menu Dropdown
                    </label>
                    <div className='input-wrapper'>
                      <Components.Forms.InputText
                        name='title'
                        type='text'
                        placeholder=''
                        border='1px solid #dbdfe6'
                        borderRadius='4px'
                        marginLeft='20px'
                        marginRight='20px'
                        marginBottom='20px'
                        height='44px'
                        fontColor='#575757'
                        defaultValue={buttonMenuDropdown}
                        value={buttonMenuDropdown}
                        onChange={handleChangeColorButtonDropdown}
                      />
                      <div className='preview-color' style={{ backgroundColor: buttonMenuDropdown }} onClick={() => setShowButtonMenuDropdown(!showButtonMenuDropdown)} />
                      {showButtonMenuDropdown ?
                        <div className='color-fieldbox' ref={previewColorRef}>
                          <Chrome color={buttonMenuDropdown} onChange={handleChangeButtonMenuDropdown} />
                        </div>
                        : <></>}
                    </div>
                  </div>
                  <div>
                    <label className='label' htmlFor={'title'}>
                      Cor de Fundo dos Ícones dos Botões do Menu Dropdown
                    </label>
                    <div className='input-wrapper'>
                      <Components.Forms.InputText
                        name='title'
                        type='text'
                        placeholder=''
                        border='1px solid #dbdfe6'
                        borderRadius='4px'
                        marginLeft='20px'
                        marginRight='20px'
                        marginBottom='20px'
                        height='44px'
                        fontColor='#575757'
                        defaultValue={iconButtonMenuDropdown}
                        value={iconButtonMenuDropdown}
                        onChange={handleChangeColorIconButtonDropdown}
                      />
                      <div className='preview-color' style={{ backgroundColor: iconButtonMenuDropdown }} onClick={() => setShowIconButtonMenuDropdown(!showIconButtonMenuDropdown)} />
                      {showIconButtonMenuDropdown ?
                        <div className='color-fieldbox' ref={previewColorRef}>
                          <Chrome color={iconButtonMenuDropdown} onChange={handleChangeIconButtonMenuDropdown} />
                        </div>
                        : <></>}
                    </div>
                  </div>
                  <div>
                    <label className='label' htmlFor={'title'}>
                      Cor do Primeiro Texto
                    </label>
                    <div className='input-wrapper'>
                      <Components.Forms.InputText
                        name='title'
                        type='text'
                        placeholder=''
                        border='1px solid #dbdfe6'
                        borderRadius='4px'
                        marginLeft='20px'
                        marginRight='20px'
                        marginBottom='20px'
                        height='44px'
                        fontColor='#575757'
                        defaultValue={firstText}
                        value={firstText}
                        onChange={handleChangeColorFirstText}
                      />
                      <div className='preview-color' style={{ backgroundColor: firstText }} onClick={() => setShowFirstText(!showFirstText)} />
                      {showFirstText ?
                        <div className='color-fieldbox' ref={previewColorRef}>
                          <Chrome color={firstText} onChange={handleChangeFirstText} />
                        </div>
                        : <></>}
                    </div>
                  </div>
                  <div>

                    <label className='label' htmlFor={'title'}>
                      Cor do Cabeçalho das Notificações
                    </label>
                    <div className='input-wrapper'>
                      <Components.Forms.InputText
                        name='title'
                        type='text'
                        placeholder=''
                        border='1px solid #dbdfe6'
                        borderRadius='4px'
                        marginLeft='20px'
                        marginRight='20px'
                        marginBottom='20px'
                        height='44px'
                        fontColor='#575757'
                        defaultValue={headerNotifications}
                        value={headerNotifications}
                        onChange={handleChangeColorHeaderNotifications}
                      />
                      <div className='preview-color' style={{ backgroundColor: headerNotifications }} onClick={() => setShowHeaderNotifications(!showHeaderNotifications)} />
                      {showHeaderNotifications ?
                        <div className='color-fieldbox' ref={previewColorRef}>
                          <Chrome color={headerNotifications} onChange={handleChangeHeaderNotifications} />
                        </div>
                        : <></>}
                    </div>
                    <label className='label' htmlFor={'title'}>
                      Cor do Segundo Texto
                    </label>
                    <div className='input-wrapper'>
                      <Components.Forms.InputText
                        name='title'
                        type='text'
                        placeholder=''
                        border='1px solid #dbdfe6'
                        borderRadius='4px'
                        marginLeft='20px'
                        marginRight='20px'
                        marginBottom='20px'
                        height='44px'
                        fontColor='#575757'
                        defaultValue={secondText}
                        value={secondText}
                        onChange={handleChangeColorSecondText}
                      />
                      <div className='preview-color' style={{ backgroundColor: secondText }} onClick={() => setShowSecond(!showSecondText)} />
                      {showSecondText ?
                        <div className='color-fieldbox' ref={previewColorRef}>
                          <Chrome color={secondText} onChange={handleChangeSecondText} />
                        </div>
                        : <></>}
                    </div>
                  </div>
                </div>
              }
              {formIndex === 9 &&
                <div className="tab">
                  <label className='label' htmlFor={'title'}>
                    Cor de Fundo do Botão Ativo
                  </label>
                  <div className='input-wrapper'>
                    <Components.Forms.InputText
                      name='title'
                      type='text'
                      placeholder=''
                      border='1px solid #dbdfe6'
                      borderRadius='4px'
                      marginLeft='20px'
                      marginRight='20px'
                      marginBottom='20px'
                      height='44px'
                      fontColor='#575757'
                      defaultValue={bgButtonActiveSidebar}
                      value={bgButtonActiveSidebar}
                      onChange={handleChangeColorBgButtonActiveSidebar}
                    />
                    <div className='preview-color' style={{ backgroundColor: bgButtonActiveSidebar }} onClick={() => setShowBgButtonActiveSidebar(!showBgButtonActiveSidebar)} />
                    {showBgButtonActiveSidebar ?
                      <div className='color-fieldbox' ref={previewColorRef}>
                        <Chrome color={bgButtonActiveSidebar} onChange={handleChangeBgButtonActiveSidebar} />
                      </div>
                      : <></>}
                  </div>
                  <div>

                    <label className='label' htmlFor={'title'}>
                      Cor do Ícone
                    </label>
                    <div className='input-wrapper'>
                      <Components.Forms.InputText
                        name='title'
                        type='text'
                        placeholder=''
                        border='1px solid #dbdfe6'
                        borderRadius='4px'
                        marginLeft='20px'
                        marginRight='20px'
                        marginBottom='20px'
                        height='44px'
                        fontColor='#575757'
                        defaultValue={iconColorSidebar}
                        value={iconColorSidebar}
                        onChange={handleChangeColorIconColorSidebar}
                      />
                      <div className='preview-color' style={{ backgroundColor: iconColorSidebar }} onClick={() => setShowIconColorSidebar(!showIconColorSidebar)} />
                      {showIconColorSidebar ?
                        <div className='color-fieldbox' ref={previewColorRef}>
                          <Chrome color={iconColorSidebar} onChange={handleChangeIconColorSidebar} />
                        </div>
                        : <></>}
                    </div>
                  </div>
                  <div>

                    <label className='label' htmlFor={'title'}>
                      Cor do Texto Ativo
                    </label>
                    <div className='input-wrapper'>
                      <Components.Forms.InputText
                        name='title'
                        type='text'
                        placeholder=''
                        border='1px solid #dbdfe6'
                        borderRadius='4px'
                        marginLeft='20px'
                        marginRight='20px'
                        marginBottom='20px'
                        height='44px'
                        fontColor='#575757'
                        defaultValue={textActiveSidebar}
                        value={textActiveSidebar}
                        onChange={handleChangeColorTextActiveColor}
                      />
                      <div className='preview-color' style={{ backgroundColor: textActiveSidebar }} onClick={() => setShowTextActiveSidebar(!showTextActiveSidebar)} />
                      {showTextActiveSidebar ?
                        <div className='color-fieldbox' ref={previewColorRef}>
                          <Chrome color={textActiveSidebar} onChange={handleChangeTextActiveColor} />
                        </div>
                        : <></>}
                    </div>
                  </div>
                  <div>
                    <label className='label' htmlFor={'title'}>
                      Cor de Fundo
                    </label>
                    <div className='input-wrapper'>
                      <Components.Forms.InputText
                        name='title'
                        type='text'
                        placeholder=''
                        border='1px solid #dbdfe6'
                        borderRadius='4px'
                        marginLeft='20px'
                        marginRight='20px'
                        marginBottom='20px'
                        height='44px'
                        fontColor='#575757'
                        defaultValue={bgColorSidebar}
                        value={bgColorSidebar}
                        onChange={handleChangeColorSidebarBgColor}
                      />
                      <div className='preview-color' style={{ backgroundColor: bgColorSidebar }} onClick={() => setShowBgColorSidebar(!showBgColorSidebar)} />
                      {showBgColorSidebar ?
                        <div className='color-fieldbox' ref={previewColorRef}>
                          <Chrome color={bgColorSidebar} onChange={handleChangeSidebarBgColor} />
                        </div>
                        : <></>}
                    </div>
                  </div>
                  <div>
                    <label className='label' htmlFor={'title'}>
                      Cor de Fundo dos Botões
                    </label>
                    <div className='input-wrapper'>
                      <Components.Forms.InputText
                        name='title'
                        type='text'
                        placeholder=''
                        border='1px solid #dbdfe6'
                        borderRadius='4px'
                        marginLeft='20px'
                        marginRight='20px'
                        marginBottom='20px'
                        height='44px'
                        fontColor='#575757'
                        defaultValue={bgSidebarButtonColor}
                        value={bgSidebarButtonColor}
                        onChange={handleChangeColorSidebarBgButtonColor}
                      />
                      <div className='preview-color' style={{ backgroundColor: bgSidebarButtonColor }} onClick={() => setShowBgColorSidebarButton(true)} />
                      {showBgColorSidebarButton ?
                        <div className='color-fieldbox' ref={previewColorRef}>
                          <Chrome color={bgSidebarButtonColor} onChange={handleChangeSidebarBgButtonColor} />
                        </div>
                        : null}
                    </div>
                  </div>
                  <div>
                    <label className='label' htmlFor={'title'}>
                      Cor dos Ícones
                    </label>
                    <div className='input-wrapper'>
                      <Components.Forms.InputText
                        name='title'
                        type='text'
                        placeholder=''
                        border='1px solid #dbdfe6'
                        borderRadius='4px'
                        marginLeft='20px'
                        marginRight='20px'
                        marginBottom='20px'
                        height='44px'
                        fontColor='#575757'
                        defaultValue={sidebarIconColor}
                        value={sidebarIconColor}
                        onChange={handleChangeColorSidebarIconColor}
                      />
                      <div className='preview-color' style={{ backgroundColor: sidebarIconColor }} onClick={() => setShowSidebarIconColor(!showSidebarIconColor)} />
                      {showSidebarIconColor ?
                        <div className='color-fieldbox' ref={previewColorRef}>
                          <Chrome color={sidebarIconColor} onChange={handleChangeIconColor} />
                        </div>
                        : <></>}
                    </div>
                  </div>
                  <div>
                    <label className='label' htmlFor={'title'}>
                      Cor da Borda dos Ítens
                    </label>
                    <div className='input-wrapper'>
                      <Components.Forms.InputText
                        name='title'
                        type='text'
                        placeholder=''
                        border='1px solid #dbdfe6'
                        borderRadius='4px'
                        marginLeft='20px'
                        marginRight='20px'
                        marginBottom='20px'
                        height='44px'
                        fontColor='#575757'
                        defaultValue={sidebarBorderIcon}
                        value={sidebarBorderIcon}
                        onChange={handleChangeColorSidebarBorderIcon}
                      />
                      <div className='preview-color' style={{ backgroundColor: sidebarBorderIcon }} onClick={() => setShowSidebarBorderIcon(!showSidebarBorderIcon)} />
                      {showSidebarBorderIcon ?
                        <div className='color-fieldbox' ref={previewColorRef}>
                          <Chrome color={sidebarBorderIcon} onChange={handleChangeSidebarBorderIcon} />
                        </div>
                        : <></>}
                    </div>
                  </div>
                  <div>
                    <label className='label' htmlFor={'title'}>
                      Cor do Texto
                    </label>
                    <div className='input-wrapper'>
                      <Components.Forms.InputText
                        name='title'
                        type='text'
                        placeholder=''
                        border='1px solid #dbdfe6'
                        borderRadius='4px'
                        marginLeft='20px'
                        marginRight='20px'
                        marginBottom='20px'
                        height='44px'
                        fontColor='#575757'
                        defaultValue={sidebarColorText}
                        value={sidebarColorText}
                        onChange={handleChangeColorSidebarColorText}
                      />
                      <div className='preview-color' style={{ backgroundColor: sidebarColorText }} onClick={() => setShowSidebarColorText(!showSidebarColorText)} />
                      {showSidebarColorText ?
                        <div className='color-fieldbox' ref={previewColorRef}>
                          <Chrome color={sidebarColorText} onChange={handleChangeSidebarColorText} />
                        </div>
                        : <></>}
                    </div>
                  </div>
                  <div>
                  </div>
                </div>
              }
              <div className='wrapper-submit'>
                <button onClick={handleSubmit} type='button' className="submit-button">
                  <span>{renderCheckIcon()}</span>
                  <div>Salvar</div>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  } else {
    return "";
  }
}

