import React from "react";
import * as S from "./entity-item-detail-modal.styled";
import Components from "rdp-builder-components";
import useLocalization from "../../hooks/useLocalization";
import IEntityItem from "../../@types/IEntityItem";
import EntityItemMembersTable from "../entity-item-members-table/entity-item-members-table";
import Label from "../label/label";
import TextField from "../inputs/text-field/text-field";

const tabs = {
  information: "Informações",
  members: "Membros"
};

type tabsKeys = keyof typeof tabs;

interface IEntityItemNewModalProps {
  item?: IEntityItem<IEntityItemData>;
  show: boolean;
  onClose: () => void;
  onSave: (item: IEntityItem<IEntityItemData>) => void;
}

function EntityItemDetailModal(props: IEntityItemNewModalProps) {
  const l = useLocalization();
  const [values, setValues] = React.useState<IEntityItem<IEntityItemData>>(props.item || {});
  const title = props.item && (props.item.data?.title || "").length ? String(props.item.data?.title) : l("new");
  const [tabsActive, setTabsActive] = React.useState<tabsKeys>("information");
  
  const handleSave = () => {
    if (props.onSave) {
      props.onSave(values);
      setValues(props.item || {})
    }
  }
  
  const handleCheck = (users: string[]) => {
    setValues({
      ...values,
      members: users.map((user) => ({
        user_uid: user,
      }))
    });
  }
  
  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
      setValues(props.item || {})
    }
  }

  if (!props.show) {
    return null;
  }
  
  return (
    <Components.Modals.ModalContentSave
      title={title.slice(0, 1).toUpperCase() + title.slice(1)}
      saveLabel={l("save")}
      saveIcon={<Components.Icons.IconSave />}
      onSave={handleSave}
      onClose={handleClose}
    >
      <S.ModalContent>
        <S.Column>
          <S.TabsContainer>
            <div className='edit-offer-form-buttons'>
              <button
                className={`tab-button ${tabsActive === "information" ? "active" : ""}`}
                onClick={() => setTabsActive("information")}>
                Informações
              </button>
              <button
                className={`tab-button ${tabsActive === "members" ? "active" : ""}`}
                onClick={() => setTabsActive("members")}>
                Membros
              </button>
            </div>
          </S.TabsContainer>

          {tabsActive === "information" && (
            <S.ItemDetailContainer>
              <S.Label>
                {l("informations")}
              </S.Label>

              <S.InputGroup>
                <Label>
                  {l("name")}<span style={{ color: "red" }}>*</span>
                </Label>

                <TextField
                  type="text"
                  placeholder={l("name")}
                  name="title"
                  required
                  value={values.data?.title}
                  onChange={(e) => setValues({
                    ...values,
                    data: {
                      ...values.data,
                      title: e.target.value
                    }
                  })}
                />
              </S.InputGroup>

              <S.InputGroup>
                <Label>
                  {l("initials")}
                </Label>

                <TextField
                  type="text"
                  placeholder={l("initials")}
                  name="initials"
                  required
                  value={values.data?.initials}
                  onChange={(e) => setValues({
                    ...values,
                    data: {
                      ...values.data,
                      initials: e.target.value
                    }
                  })}
                />
              </S.InputGroup>
            </S.ItemDetailContainer>
          )}

          {tabsActive === "members" && (
            <S.Row>
              <S.ItemMembersContainer>
                <S.Label>
                  {l("active members")}
                </S.Label>
                {props.item && (
                  <EntityItemMembersTable
                    entityItemUid={props.item.uid}
                    showOnlyActive={true}
                    onCheck={handleCheck}
                  />
                )}
              </S.ItemMembersContainer>

              <S.VerticalLine />

              <S.ItemMembersContainer>
                <S.Label>
                  {l("users")}
                </S.Label>
                {props.item && (
                  <EntityItemMembersTable
                    entityItemUid={props.item.uid}
                    onCheck={handleCheck}
                  />
                )}
              </S.ItemMembersContainer>
            </S.Row>
          )}
        </S.Column>
      </S.ModalContent>
    </Components.Modals.ModalContentSave>
  );
}

export default EntityItemDetailModal;