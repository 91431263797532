import IEntitySchema from "../@types/IEntitySchema";
import SDK from "api.digitalpages.module.sdk.api";
import IEntityItem from "../@types/IEntityItem";
import IEntityMember from "../@types/IEntityMember";
import IUserModel from "../@types/IUserModel";

const getBasicHeader = () => ({
  "Project-Key": SDK.Api.projectKey,
  "Api-Env": SDK.Api.enviroment,
  "Authorization": `Bearer ${SDK.authorization.credential.accessToken}`,
  "Content-Type": "application/json"
});

const entityEndpoint = {
  adminCreateEntitySchemaInProject: async (projectUid: Guid, data: IEntitySchema, schemas: IEntitySchema[], options?: {init?: RequestInit}) => {
    const init: RequestInit = {
      method: "POST",
      headers: getBasicHeader(),
      body: JSON.stringify(data)
    }

    if (schemas.find(s => s.name === data.name)) {
      throw new Error("Schema name already exists");
    }

    const resp = await fetch(`${SDK.Api.domain}/auth/v1.0/entity/schema/project/uid/${projectUid}`, options?.init || init);

    if (resp.status === 422) {
      throw new Error("Entity schema already exists");
    }

    if (resp.ok) {
      return await resp.json() as IEntitySchema;
    }

    return null;
  },
  listEntitySchemaInProject: (projectUid: Guid, options?: {init?: RequestInit}) => {
    const init: RequestInit = {
      method: "GET",
      headers: getBasicHeader()
    }

    return fetch(`${SDK.Api.domain}/auth/v1.0/entity/schema/available/project/uid/${projectUid}`, options?.init || init).then(res => res.json() as Promise<IEntitySchema[]>);
  },
  schemaItems: (schemaUid: Guid, options?: {init?: RequestInit}) => {
    const init: RequestInit = {
      method: "GET",
      headers: getBasicHeader()
    }

    return fetch(`${SDK.Api.domain}/auth/v1.0/entity/schema/uid/${schemaUid}/items`, options?.init || init).then(res => res.json() as Promise<IEntityItem<IEntityItemData>[]>);
  },
  createEntity: async <T = { [key: string]: number }>(schemaUid: Guid, data: IEntityItem<T>, datas: IEntityItem<T>[], options?: {init?: RequestInit}) => {
    const init: RequestInit = {
      method: "POST",
      headers: getBasicHeader(),
      body: JSON.stringify(data)
    }
    
    let datasCopy = [...datas];

    const resp = await fetch(`${SDK.Api.domain}/auth/v1.0/entity/schema/uid/${schemaUid}`, options?.init || init);

    if (resp.ok) {
      const entity = await resp.json() as IEntityItem<T>;
      
      const hasColision = datas.find(d => d?.row === entity?.row) !== undefined;
      
      if (hasColision) {
        // Necessita atualizar a lista, pois ocorreu colisão de itens
        // @ts-ignore
        const entityItensToMoveOneLineDown = datas.filter(d => "row" in d && "row" in entity && d?.row >= entity?.row);
        for (const d of entityItensToMoveOneLineDown) {
          await fetch(`${SDK.Api.domain}/auth/v1.0/entity/uid/${d.uid}/position`, {
            method: "PATCH",
            headers: getBasicHeader(),
            body: JSON.stringify({
              row: (d.row || 0) + 1,
              column: d.column
            })
          })
          datas.find(f => f.uid === d.uid)!.row = (entity.row || 0) + 1;
          
          datasCopy = datasCopy.map(m => {
            if (m.uid === d.uid) {
              return {
                ...m,
                row: (entity.row || 0) + 1
              }
            }
            return m;
          });
        }
      }

      let membersPromises = [];
      
      if (data.members) {
        membersPromises = data.members.map(async (member) => {
          return fetch(`${SDK.Api.domain}/auth/v1.0/entity/uid/${entity.uid}/member`, {
            method: "POST",
            headers: getBasicHeader(),
            body: JSON.stringify(member)
          }).then(res => res.json() as Promise<IEntityMember>);
        });
        
        await Promise.all(membersPromises);
        
        entity.members = data.members;
      }

      datasCopy.unshift(entity);
      
      return datasCopy;
    }
    
    return datas;
  },
  updateEntity: async <T = { [key: string]: number }>(entityUid: Guid, data: IEntityItem<T>, datas: IEntityItem<T>[],  options?: {init?: RequestInit}) => {
    const init: RequestInit = {
      method: "PUT",
      headers: getBasicHeader(),
      body: JSON.stringify(data)
    }

    const resp = await fetch(`${SDK.Api.domain}/auth/v1.0/entity/uid/${entityUid}`, options?.init || init);

    if (resp.ok) {
      const entity = await resp.json() as IEntityItem<T>;

      if ("members" in data) {
        const members = await fetch(`${SDK.Api.domain}/auth/v1.0/entity/uid/${entity.uid}/members`, {
          method: "GET",
          headers: getBasicHeader()
        }).then(res => res.json() as Promise<IEntityMember[]>);

        let membersPromises = [];

        if (data.members && data.members.length > 0) {
          // @ts-ignore
          const membersToRemove = members.filter(m => !data.members.find(d => d.user_uid === m.user_uid));
          
          if (membersToRemove.length > 0) {
            membersPromises = membersToRemove.map(async (member) => {
              return fetch(`${SDK.Api.domain}/auth/v1.0/entity/member/uid/${member.uid}`, {
                method: "DELETE",
                headers: getBasicHeader()
              });
            });
            
            await Promise.all(membersPromises);
          }
          
          membersPromises = data.members.filter(m => !members.find(m2 => m2.user_uid === m.user_uid)).map(async (member) => {
            return fetch(`${SDK.Api.domain}/auth/v1.0/entity/uid/${entity.uid}/member`, {
              method: "POST",
              headers: getBasicHeader(),
              body: JSON.stringify({
                ...member,
                // project_uid: SDK.authorization.activeProject.uid,
                // directory_uid: SDK.authorization.activeProject.directory.uid
              })
            }).then(res => res.json() as Promise<IEntityMember>);
          });

          await Promise.all(membersPromises);
          
          entity.members = data.members;
        } else if (members && members.length > 0 && data.members?.length === 0) { // delete all members
          membersPromises = members.map(async (member) => {
            return fetch(`${SDK.Api.domain}/auth/v1.0/entity/member/uid/${member.uid}`, {
              method: "DELETE",
              headers: getBasicHeader()
            });
          });

          await Promise.all(membersPromises);
          
          entity.members = [];
        }
      }
      
      return [entity, ...datas.filter(r => r.uid !== entityUid)];
    }

    return datas;
  },
  deleteEntity: async <T = { [key: string]: number }>(entityUid: Guid, datas: IEntityItem<T>[], options?: {init?: RequestInit}) => {
    const init: RequestInit = {
      method: "DELETE",
      headers: getBasicHeader(),
    }

    const resp = await fetch(`${SDK.Api.domain}/auth/v1.0/entity/uid/${entityUid}`, options?.init || init);
    
    if (resp.ok) {
      datas = datas.filter(d => d.uid !== entityUid);
      return datas;
    }
    
    return datas;
  },
  adminDeleteSchema: async <T = { [key: string]: number }>(schemaUid: Guid, options?: {init?: RequestInit}) => {
    const init: RequestInit = {
      method: "DELETE",
      headers: getBasicHeader(),
    }
    
    const resp = await fetch(`${SDK.Api.domain}/auth/v1.0/entity/schema/uid/${schemaUid}`, options?.init || init);
    
    if (resp.ok) {
      return schemaUid;
    }

    return null;
  },
  entityRegisterMember: async (entityUid: Guid, data: IEntityMember, datas: IEntityMember[], options?: {init?: RequestInit}) => {
    const init: RequestInit = {
      method: "POST",
      headers: getBasicHeader(),
      body: JSON.stringify(data)
    }

    const resp = await fetch(`${SDK.Api.domain}/auth/v1.0/entity/uid/${entityUid}/member`, options?.init || init);
    
    if (resp.ok) {
      const data = await resp.json() as IEntityMember;
      datas.unshift(data);
      return datas;
    }
    
    return datas;
  },
  entityMembers: (entityUid: Guid, options?: {init?: RequestInit}) => {
    const init: RequestInit = {
      method: "GET",
      headers: getBasicHeader()
    }

    return fetch(`${SDK.Api.domain}/auth/v1.0/entity/uid/${entityUid}/members`, options?.init || init).then(res => res.json() as Promise<IEntityMember[]>);;
  },
}

const userEndpoint = {
  userList: (filters: object, options?: {init?: RequestInit}) => {
    const init: RequestInit = {
      method: "GET",
      headers: getBasicHeader()
    }
    
    return fetch(`${SDK.Api.domain}/auth/v1.0/users`, options?.init || init).then(res => res.json() as Promise<IUserModel[]>);
  },
}

export default {
  entityEndpoint,
  userEndpoint
}