import React, { Component } from 'react';
import { format, addDays } from 'date-fns';
import './header-filter.scss';
import Select from 'react-select';
import { IconTrash } from '../icon';
import ModalDelete from '../modal-delete';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import RDP_UTILS from '../../config/util';
import { EntityService } from '../../services/entity-content';

const Locale = {
  sunday: 'DOM',
  monday: 'SEG',
  tuesday: 'TER',
  wednesday: 'QUA',
  thursday: 'QUI',
  friday: 'SEX',
  saturday: 'SAB',
  ok: 'OK',
  today: 'Hoje',
  yesterday: 'Ontem',
  hours: 'Horas',
  minutes: 'Minutos',
  seconds: 'Segundos',
};

const Ranges = [];

export default class HeaderFilter extends Component {
  constructor(props) {
    super(props);

    this.handleLocalSearchField = this.handleLocalSearchField.bind(this);
    this.handleSearch = this.handleSearch.bind(this);

    this.state = {
      openDeleteModal: false,
      datePickerValue: undefined,
      buscar: '',
      term: '',
      enabled_search: true
    };
  }

  onCloseModal() {
    this.setState({
      openDeleteModal: false,
    });
  }

  openModal() {
    const { selectedToDelete } = this.props;
    if (selectedToDelete.length > 0) {
      this.setState({
        openDeleteModal: true,
      });
    }
  }

  onChange = (date) => {
    const { onDateChange } = this.props;

    if (date.length > 1) {
      onDateChange(
        format(new Date(date[0]), 'dd/MM/yyyy'),
        format(new Date(date[0]), 'dd/MM/yyyy')
      );
    } else {
      onDateChange(null, null);
    }
  };

  handleSearchField(e) {
    const { onTextChange } = this.props;
    onTextChange(e.target.value);
  }

  handleLocalSearchField(value) {
    this.setState({
      "enabled_search": false
    });
    const { onLocalSearch } = this.props;
    if (onLocalSearch) onLocalSearch(value);
  }

  componentDidMount() {
    const { start_at, finish_at } = this.props;

    if (start_at && finish_at) {
      this.setState({
        datePickerValue: [new Date(start_at), new Date(finish_at)],
      });
    }
  }

  onRenderCalendar(value, formata) {
    return (
      <div className="date-range-dates-container">
        <span>{format(new Date(value[0]), 'dd/MM/yyyy') + ' - '}</span>
        <span>{format(new Date(value[1]), 'dd/MM/yyyy')}</span>
      </div>
    );
  }

  getEntityOptionValue = () => {
    const option = (this.state.entityOptions || []).find(
      (option) => option.uid == this.props.entityOptionFilter
    );
    if (!option) return null;

    return {
      value: option.uid,
      label: this.state.entityOptionsFields
        .filter((field) => !['input-color'].includes(field.type))
        .map((field) => option.data[field.identifier])
        .join(' - '),
    };
  };

  renderEntityOptionsValue = () => {
    if (!this.state.entityOptions || !this.state.entityOptions.length)
      return null;
    return (
      <Select
        placeholder={`Selecionar ${this.state.entityOption.field}`}
        options={(this.state.entityOptions || []).map((option) => ({
          color: 'red',
          value: option.uid,
          label: this.state.entityOptionsFields
            .filter((field) => !['input-color'].includes(field.type))
            .map((field) => option.data[field.identifier])
            .join(' - '),
          identifier: this.state.entityOption.identifier,
        }))}
        value={
          this.props.entityOptionFilter ? this.getEntityOptionValue() : null
        }
        onChange={this.props.onChangeEntityValue}
        isDisabled={
          !this.state.entityOptions || !this.state.entityOptions.length
        }
        isClearable={true}
        styles={RDP_UTILS.MultipleSelect.styleFilter}
        isSearchable={false}
        theme={(theme) => ({
          ...theme,
          borderRadius: 6,
          borderColor: '#fff',
          colors: {
            ...theme.colors,
            primary25: '#c7a5f5',
            primary: '#cccccc',
          },
        })}
      />
    );
  };

  onChangeEntityOption = (target) => {
    if (!target) {
      this.setState(
        {
          entityOptions: [],
        },
        () => {
          this.props.onChangeEntityValue();
        }
      );
      return;
    }

    EntityService.getOptionsReference(
      this.props.entityId,
      target.value.identifier
    ).then((entityOptions) => {
      this.setState({
        entityOptions,
        entityOptionsFields:
          target.value.reference_entity_properties.options_fields,
        entityOption: target.value,
      });
    });
  };

  renderEntityOptionsFilter = () => {
    if (!this.props.configuration) {
      return null;
    }

    const options = this.props.configuration
      .slice(0, 4)
      .filter((configuration) => configuration.type == 'reference-entity');
    if (!options.length) {
      return null;
    }

    return (
      <Select
        placeholder="Search by field"
        options={options.map((option) => ({
          label: option.field,
          value: option,
        }))}
        styles={RDP_UTILS.MultipleSelect.styleFilter}
        isSearchable={false}
        isClearable={true}
        onChange={this.onChangeEntityOption}
        theme={(theme) => ({
          ...theme,
          borderRadius: 6,
          borderColor: '#fff',
          colors: {
            ...theme.colors,
            primary25: '#c7a5f5',
            primary: '#cccccc',
          },
        })}
      />
    );
  };

  getSelectDefautValue(status) {
    if (status === 'Draft') {
      return { label: 'Rascunho', value: 'Draft' };
    } else if (status === 'Published') {
      return { label: 'Publicado', value: 'Published' };
    } else if (status === 'Scheduled') {
      return { label: 'Agendado', value: 'Scheduled' };
    } else if (status === 'Finished') {
      return { label: 'Concluído', value: 'Finished' };
    }

    return null;
  }

  handleResetSearch() {
    this.setState({
      "term": "",
      "enabled_search": true
    });
    this.props.onResetSearch()
  }

  onLocalSearchChange(e) {
    this.setState({
      "enabled_search": true,
      "term": e.target.value
    })
    if (e.target.value === "") {
      this.props.onResetSearch()
    }
  }
  
  handleSearch(e) {
    this.onLocalSearchChange(e);

    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = setTimeout(() => {
      this.handleLocalSearchField(this.state.term);
    }, 500);
  }

  renderLocalSearch() {
    if (this.props.enableLocalSearch) {
      return <div className="search-field-group">
        <input
          type="text"
          placeholder={this.props.localSearchPlaceholder}
          onChange={this.handleSearch}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              this.handleLocalSearchField(e.target.value)
            }
          }}
          value={this.state.term}
        />
        {(this.state.term === "") &&
          <span className="search-field-btn" onClick={() => this.handleLocalSearchField(this.state.term)}></span>
        }
        {(this.state.term.length > 0) &&
          <span className="clear-field-btn" onClick={() => this.handleResetSearch()}></span>
        }
      </div>
    }
  }

  render() {
    const {
      count,
      total,
      selectedToDelete,
      onStatusChange,
      text,
      status,
      entity,
      onDelete,
    } = this.props;
    const { openDeleteModal, datePickerValue } = this.state;

    return (
      <div className="rdp-admin-component-header-filter">
        <div className="info-counter">
          Mostrando <strong>{count}</strong> de <strong>{total}</strong>{' '}
          {this.state.entity}
        </div>
        <div className="wrapper-block-filters">
          <div className="block-filters">
            {(this.props.onStatusChange || this.props.onDateChange) && (
              <span className="filter">Filtrar por:</span>
            )}

            {this.props.onDateChange && (
              <div className="rdp-date-picker-range">
                <DateRangePicker
                  size="lg"
                  placeholder="Data de criação/modificação"
                  showOneCalendar
                  onChange={(date) => this.onChange(date)}
                  renderValue={(value, format) =>
                    this.onRenderCalendar(value, format)
                  }
                  value={datePickerValue}
                  locale={Locale}
                  ranges={Ranges}
                />
              </div>
            )}
            {this.props.onStatusChange && (
              <Select
                placeholder="Status"
                defaultValue={this.getSelectDefautValue(status)}
                options={[
                  { label: 'Nenhum', value: '' },
                  { label: 'Rascunho', value: 'Draft' },
                  { label: 'Publicado', value: 'Published' },
                  { label: 'Agendado', value: 'Scheduled' },
                  { label: 'Concluído', value: 'Finished' },
                ]}
                styles={RDP_UTILS.MultipleSelect.styleFilter}
                isSearchable={false}
                onChange={(value) => onStatusChange(value)}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 6,
                  borderColor: '#fff',
                  colors: {
                    ...theme.colors,
                    primary25: '#c7a5f5',
                    primary: '#cccccc',
                  },
                })}
              />
            )}

            {this.renderLocalSearch()}

            {(this.props.configuration || []).find((configuration) =>
              ['input-text'].includes(configuration.type)
            ) && (
              <>
                <div className="divider-field"></div>
                <input
                  className="search-field"
                  type="text"
                  placeholder="BUSCAR"
                  value={text}
                  onChange={(e) => this.handleSearchField(e)}
                />
              </>
            )}
            {!this.props.hideDelete &&
              <>
                <div className="divider-field"></div>
                <div className="delete-button">
                  {selectedToDelete.length > 0 && (
                    <span className="counter">{selectedToDelete.length}</span>
                  )}
                  <button
                    type="button"
                    className={
                      selectedToDelete.length > 0 ? 'has-selected-elements' : null
                    }
                    onClick={() => this.openModal()}
                  >
                    <IconTrash />
                  </button>
                  <ModalDelete
                    title={entity}
                    action={() => {
                      onDelete();
                      this.onCloseModal();
                    }}
                    open={openDeleteModal}
                    onClose={() => this.onCloseModal()}
                  />
                </div>
              </>
            }
          </div>
          <div className="block-filters">
            <span className="filter block-filter-hidden">Filtrar por:</span>
            {this.renderEntityOptionsFilter()}
            {this.renderEntityOptionsValue()}
          </div>
        </div>
      </div>
    );
  }
}
