function deepReplace(model, data, targetKey, replaceWith) {
  for (var key in model) {
    if (key === targetKey) model[key] = replaceWith;

    if (typeof model[key] === 'object') {
      deepReplace(model[key], data, targetKey, replaceWith);
    }
  }
}

export async function formatBody(data, model) {
  const entries = Object.entries(data);
  let newModel = model;

  entries.forEach((item) => {
    deepReplace(newModel, data, item[0], item[1]);
  });

  return newModel;
}
