import React, { useEffect } from "react";
import * as S from "./entity-item-tree.styled";
import IEntityItem from "../../@types/IEntityItem";
import EntityItemDetailModal from "../entity-item-detail-modal/entity-item-detail-modal";
import useOptimisticFetchEntityItems from "../../hooks/useOptimisticFetchEntityItems";
import EntityItemTreeList from "../entity-item-tree-list/entity-item-tree-list";
import EntityItemTreeItem from "../entity-item-tree-item/entity-item-tree-item";
import useOptimisticFetchSchemas from "../../hooks/useOptimisticFetchSchemas";
import Sdk from "api.digitalpages.module.sdk.api";
import IEntitySchema from "../../@types/IEntitySchema";
import Loading from "../loading";
import { l } from "../../hooks/useLocalization";
import ModalDelete from "../modal-delete";
import { useRecoilState, useSetRecoilState } from "recoil";
import { schemasState } from "../../recoil/atoms/schemasState";
import Checkbox from "../inputs/checkbox/checkbox";
import {selectedSchemasState} from "../../recoil/atoms/selectedSchemasState";

interface IEntityItemTreeProps {
  border?: boolean;
}

function EntityItemTree(props: IEntityItemTreeProps) {
  const {
    border = true,
  } = props;
  const { data, createSchema } = useOptimisticFetchSchemas(Sdk.authorization.activeProject.uid);
  const [schemas, setSchemas] = useRecoilState(schemasState);
  const [showAddItem, setShowAddItem] = React.useState<boolean>(false);
  const [titleValue, setTitleValue] = React.useState<string>("");
  
  useEffect(() => {
    if (data && schemas.length === 0) {
      setSchemas(data);
    }
  }, [data]);
  
  const handleAddSchema = (schema: IEntitySchema) => {
    try {
      createSchema({
        ...schema,
        limit_deph_in_hierarchy: 0,
        public: false,
        self_register: false,
        type: "security",
        properties: [
          {
            name: "title",
            type: "text",
            regex_validation: "",
          },
          {
            name: "initials",
            type: "text",
            regex_validation: "",
          },
        ]
      }).then((response) => {
        if (response) {
          setSchemas([...schemas, response]);
        }
      });
      setShowAddItem(false);
      setTitleValue("");
    } catch (e) {
      console.error(e);
    }
  }
  
  const hasSchemaWithName = (name: string) => {
    return schemas.find(s => s.name.toLowerCase() === name.toLowerCase());
  }
 
  if (!schemas) {
    return <Loading />;
  }
  
  return (
    <S.EntityItemsTree
      hasBorder={border}
    >
      {schemas.map(schema => (
        <SchemaList 
          key={schema.uid} 
          schema={schema} 
        />
      ))}

      {showAddItem && (
        <EntityItemTreeItem
          border={(titleValue === "" || hasSchemaWithName(titleValue)) ? "2px solid #ff0000" : "1px solid #764ab0"}
          title={
            <S.TitleInput
              type="text"
              autoFocus
              placeholder={l("name")}
              value={titleValue}
              onChange={e => setTitleValue(e.target.value)}
              onKeyDown={e => {
                if (e.key === "Enter" && !(titleValue === "" || hasSchemaWithName(titleValue))) {
                  handleAddSchema({
                    name: titleValue,
                  });
                } else if (e.key === "Escape") {
                  setShowAddItem(false);
                  setTitleValue("");
                }
              }}
            />
          }
          icon={null}
          column={0}
          onClickDelete={() => {
            setShowAddItem(false);
            setTitleValue("");
          }}
          deleteIcon={
            <svg width="24" height="24" viewBox="0 0 24 24">
              <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
            </svg>
          }
          deleteIconColor="red"
          onClickEdit={!(titleValue === "" || hasSchemaWithName(titleValue)) ? () => handleAddSchema({
            name: titleValue,
          }) : undefined}
          editIcon={
            <svg width="24" height="24" viewBox="0 0 24 24">
              <path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
            </svg>
          }
          editIconColor="green"
        />
      )}

      <S.EntityItemAddChildBtn
        type="button"
        tabIndex={0}
        onClick={() => setShowAddItem(true)}
      >
        <svg width="24" height="24" viewBox="0 0 24 24">
          <path fill="currentColor" d="M18,15H16V17H18M18,11H16V13H18M20,19H12V17H14V15H12V13H14V11H12V9H20M10,7H8V5H10M10,11H8V9H10M10,15H8V13H10M10,19H8V17H10M6,7H4V5H6M6,11H4V9H6M6,15H4V13H6M6,19H4V17H6M12,7V3H2V21H22V7H12Z" />
        </svg>
      </S.EntityItemAddChildBtn>
    </S.EntityItemsTree>
  );
}

function SchemaList(props: { schema: IEntitySchema }) {
  const {
    schema,
  } = props;
  const { deleteSchema } = useOptimisticFetchSchemas(Sdk.authorization.activeProject.uid);
  const { data: entityItems, createEntity } = useOptimisticFetchEntityItems(schema.uid);
  const [orderedData, setOrderedData] = React.useState<IEntityItem<IEntityItemData>[]>([]);
  const [showAddItem, setShowAddItem] = React.useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
  const [selectedSchemas, setSelectedSchemas] = useRecoilState<Guid[]>(selectedSchemasState);
  const setSchemas = useSetRecoilState(schemasState);
  const [selectAll, setSelectAll] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (entityItems) {
      const orderedData = entityItems.sort((a, b) => (a.row || 0) - (b.row || 0));
      setOrderedData(orderedData);
    }
  }, [entityItems]);

  const handleAddItem = (item: IEntityItem<IEntityItemData>) => {
    if (entityItems && schema.uid) {
      try {
        createEntity(schema.uid, item, entityItems).then(resp => {
          console.log("createEntity", resp);
        });
        setShowAddItem(false);
      } catch (e) {
        console.error(e);
      }
    }
  }

  const handleDeleteSchema = () => {
    if (schema.uid) {
      deleteSchema(schema.uid).then(resp => {
        setSchemas(schemas => schemas.filter(s => s.uid !== schema.uid));
      });
      setShowDeleteModal(false);
    }
  }
  
  return (
    <>
      <S.Relative key={schema.uid}>
        <S.CheckboxContainer className="entity-item-tree-checkbox-container">
          <Checkbox
            defaultChecked={selectedSchemas.includes(schema.uid || "")}
            onCheck={(value, label, checked) => {
              setSelectAll(checked);
              if (checked && schema.uid) {
                setSelectedSchemas([...selectedSchemas, schema.uid]);
              } else {
                setSelectedSchemas(selectedSchemas.filter(s => s !== schema.uid));
              }
            }}
          />
        </S.CheckboxContainer>
        
        <EntityItemTreeItem
          title={schema.name}
          icon={
            <svg width="24" height="24" viewBox="0 0 24 24">
              <path fill="currentColor" d="M18,15H16V17H18M18,11H16V13H18M20,19H12V17H14V15H12V13H14V11H12V9H20M10,7H8V5H10M10,11H8V9H10M10,15H8V13H10M10,19H8V17H10M6,7H4V5H6M6,11H4V9H6M6,15H4V13H6M6,19H4V17H6M12,7V3H2V21H22V7H12Z" />
            </svg>
          }
          column={0}
          hasChildren={(entityItems && entityItems.length > 0) || false}
          hasRightBrother={false}
          onClickDelete={(entityItems && entityItems.length > 0)  ? undefined : () => setShowDeleteModal(true)}
          onClickAdd={() => setShowAddItem(true)}
          selected={selectedSchemas.includes(schema.uid || "")}
        />
      </S.Relative>

      <EntityItemTreeList
        entitySchemaUid={schema.uid || ""}
        columnOffset={1}
        selectAll={selectAll}
      />

      {showAddItem && (
        <EntityItemDetailModal
          item={{
            data: {
              title: "",
              initials: "",
            },
            row: orderedData.length,
            column: 0,
          }}
          show
          onSave={handleAddItem}
          onClose={() => setShowAddItem(false)}
        />
      )}

      {showDeleteModal && (
        <ModalDelete
          open
          action={handleDeleteSchema}
          title={l("delete confirmation")}
          color=""
          onClose={() => setShowDeleteModal(false)}
        />
      )}
    </>
  )
}

export default EntityItemTree;