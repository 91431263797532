import styled from "styled-components";

export const ModalContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 20px;
`;

export const Label = styled.label`
  text-transform: capitalize;
`;

export const InputGroup = styled.div`
  padding: 20px;
  
  input {
    margin-bottom: 0;
  }
`;

export const ItemDetailContainer = styled.div`
  flex: 2;
`;

export const VerticalLine = styled.div`
  width: 1px;
  background: #e0e0e0;
  margin: 0 20px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
`;

export const TabsContainer = styled.div`
  margin-bottom: 20px;
`;

export const ItemMembersContainer = styled.div`
  flex: 4;
  max-width: 50%;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
`;