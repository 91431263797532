import React, { useState, useEffect, useRef } from 'react';
import {
  Header,
  Sidebar,
  BreadcrumbSimple
} from '../../components';
import Sdk from 'api.digitalpages.module.sdk.api';
import "./list-files.scss";
import PreloaderImage from './icon-preloader.gif';

export default function ViewFilesList({ consumer, entity, history }) {
  const [config, setConfig] = useState({});
  const [preferences, setPreferences] = useState(null);

  async function configTable() {
    const res = await fetch('./preferences.json');
    const prefs = await res.json();
    setPreferences(prefs);

    // const { tables } = await response.json();

    const Configuration = {
      options: {
        hasCheckbox: false,
        hasEdit: true,
        hasDelete: true,
      }
    };

    setConfig(Configuration);
  }

  function getDomain() {
    return Sdk.Api.enviroment === "production" ? "https://dam.euconquisto.com" : "https://dam-hmg.euconquisto.com"
  }

  useEffect(() => {
    configTable();

    consumer.defineRoute({ name: 'Arquivos', uri: '/files' });
  }, []);


  if (preferences) {
    var projectUid = Sdk.authorization.activeProject.uid;
    var accessToken = Sdk.authorization.credential.accessToken;

    return <>
      <Header />
      <Sidebar
        currentRoute={consumer ? consumer.currentRoute : null}
        defineGroup={consumer ? consumer.defineGroup : null}
        defineRoute={consumer ? consumer.defineRoute : null}
        groups={consumer ? consumer.groups : null}
      />
      <BreadcrumbSimple
        currentRoute={consumer ? consumer.currentRoute : null}
        currentGroup={consumer ? consumer.currentGroup : null}
        options={null}
        newOption={null}
        label="Arquivos"
      />
      <iframe className="rdp-manager-admin-list-compositions-iframe" src={`${getDomain()}/#/auth-with-token/pt_br/embedv2/${projectUid}/${accessToken}`}/>
    </>
  } else {
    return "";
  }
}