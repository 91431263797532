import React, {useEffect, useState} from "react";
import Switch from "react-switch";
import {Breadcrumb, Footer, Header, NotifyBox, Sidebar,} from "../../components";
import DynamicForm from "../../components/form-dynamic-v2";
import Loading from "../../components/loading";
import Sdk from "api.digitalpages.module.sdk.api";
import {formatBody} from "../../utils/formatBody";

import "./styles.scss";
import moment from "moment";
import Components from "rdp-builder-components";
import {ClassicSpinner} from "react-spinners-kit";
import SDK from "api.digitalpages.module.sdk.api";

export default function EditUser ({ consumer, match, history }) {
  const [entity, setEntity] = useState();
  const [userEntityList, setUserEntityList] = useState();
  const [ids, setIds] = useState();
  const [entityUpd, setEntityUpd] = useState();
  const [enrollmentFormDefaultValues, setEnrollmentFormDefaultValues] = useState(
    null);
  const [userRegistersTableRows, setUserRegistersTableRows] = useState(null);
  const [defaultValues, setDefaultValues] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState("");
  const [msgError, setMsgError] = useState("");
  const [formIndex, setFormIndex] = useState(0);
  const [submitNewUserStatus, setSubmitNewUserStatus] = useState("loaded");
  const [submitForgotPasswordStatus, setSubmitForgotPasswordStatus] = useState(
    "loaded");
  const [isLoadingRegisterTable, setIsLoadingRegisterTable] = useState(false);
  const [userRegistersTableActionLoading, setUserRegistersTableActionLoading] = useState(
    []);

  const [roles, setRoles] = useState(null);
  const [tempRoles, setTempRoles] = useState([]);
  const [user, setUser] = useState(null);

  async function getFormFields (user) {
    const response = await fetch("./preferences.json");

    const { forms } = await response.json();
    const form = forms["edit-user"];

    if (user) {
      const user_properties = user.user_properties;

      const directory = await SDK.dynamic.bridge(`auth/v1.0/directory/uid/${SDK.Api.authorization.activeProject.directory.uid}`, null, "GET")

      if (directory && "config" in directory) {
        const required_properties = directory.config.user_required_properties;

        required_properties.forEach(property => {
          if (user_properties.find(p => p.name === property.name)) return;
          user_properties.push({
            ...property,
            value: ""
          });
        });
      }


      form.fields = form.fields.map(field => {
        if (field.id === "properties") {
          field.options = user_properties;
        }

        return field;
      });
    }

    setEntity(form);
  }

  useEffect(() => {
    getRoles();
  }, []);

  const getRoles = async () => {
    var tempRoles = [
      {
        "type": "Viewer",
        "label": "Usuário comum",
      },
      {
        "type": "ReviewContributor",
        "label": "Revisor ",
      },
      {
        "type": "AdministratorGlobal",
        "label": "Administrador",
      },
      {
        "type": "TeamAdmin",
        "label": "Gerente de Área",
      },
    ];
    setRoles(tempRoles);
  };

  const loadEntities = async () => {
    if (!userEntityList) return;

    if (entity) {
      const entitiesCp = { ...entity };
      let idList = [];

      const res = await Sdk.dynamic.bridge(
        `auth/v1.0/entity/management/schema/available?type=security`,
        null,
        "GET",
      );

      for (const [idx, entity] of res.entries()) {
        const tree = await Sdk.dynamic.bridge(
          `auth/v1.0/entity/schema/uid/${entity.uid}/items`,
          null,
          "GET",
        );

        const schemaInfo = await Sdk.dynamic.bridge(
          `auth/v1.0/entity/schema/uid/${entity.uid}`,
          null,
          "GET",
        );

        function orderByRows() {
          const orderedObj = [...tree].sort((a, b) => a.row - b.row);
          return orderedObj;
        }

        const newTree = orderByRows()
        entitiesCp.fields.push({
          id: `container-category-${idx}`,
          group: entity.name,
          schema_properties: schemaInfo.properties,
          required: true,
          defaultValues: userEntityList,
          type: "input-tree-checkbox",
          config: {},
          field: `entities-${idx}`,
          options: newTree,
        });

        idList.push(`container-category-${idx}`);
      }

      setEntityUpd(entitiesCp);
      setIds(idList);
    }
  };

  useEffect(() => {
    getUserByUid(match.params.userUid).then(getFormFields);
  }, []);

  useEffect(() => {
    if (entity) {
      loadEntities();
    }
  }, [entity, userEntityList]);

  useEffect(() => {
    if (formIndex === 3 && isLoadingRegisterTable === false &&
      userRegistersTableRows === null) {
      setIsLoadingRegisterTable(true);
      loadRegistrations();
    }
    if (formIndex !== 3 && userRegistersTableRows !== null) {
      setUserRegistersTableRows(null);
    }
  }, [formIndex, isLoadingRegisterTable, userRegistersTableRows]);

  const loadRegistrations = async (index, register) => {
    const { result: userRegisters } = await Sdk.dynamic.bridge(
      `cms/v1.0/learning/user/uid/${match.params.userUid}/management/registers`,
    );

    if (index && register) {
      let rows = [...userRegistersTableRows];

      rows[index] = register;

      const userRegistersObj = {};
      userRegisters.forEach(register => {
        userRegistersObj[register.register.uid] = register.access_status;
      });
      setEnrollmentFormDefaultValues(userRegistersObj);
      setUserRegistersTableRows(rows);
      setIsLoadingRegisterTable(false);
    } else {
      const res = await Sdk.dynamic.bridge(
        `cms/v1.0/learning/user/uid/${match.params.userUid}/management/registers/available?page_size=10000`,
      );
      if (res) {
        const registers = res.result;

        const rows = [
          ...userRegisters,
          ...registers,
        ];

        const userRegistersObj = {};
        userRegisters.forEach(register => {
          userRegistersObj[register.register.uid] = register.access_status;
        });
        setEnrollmentFormDefaultValues(userRegistersObj);
        setUserRegistersTableRows(rows);
        setIsLoadingRegisterTable(false);
      }
    }
  };

  async function getUserByUid (uid) {
    const user = await Sdk.dynamic.bridge(
      `auth/v1.1/user/uid/${uid}/management?include_properties=true`,
      null,
      "GET",
    );

    setTempRoles(user.roles);

    await getUserEntities(uid);
    setDefaultValues(oldSt => ({ ...oldSt, ...handleDefaultValues(user) }));
    setUser(user);

    return user;
  }

  async function getUserEntities (uid) {
    const resp = await Sdk.dynamic.bridge(
      `auth/v1.0/entity/items/available/from/user/uid/${uid}`,
      null,
      "GET",
    );

    const userEntities = await resp.map((item) => item.uid);

    setUserEntityList(userEntities);
  }

  function handleDefaultValues (user) {
    const { name, email_principal, first_name, last_name, cpf, birthday = "" } = user.detail;
    const { login, blocked_access, user_properties = [] } = user;
    const properties = {};

    user_properties.forEach((property) => {
      properties[`property-${property.name}`] = property.value;
    });



    return {
      name,
      email_principal: email_principal,
      login,
      cpf,
      first_name,
      last_name,
      blocked_access,
      birthday: (birthday && birthday.length) ? birthday.split("T")[0] : "",
      ...properties
    };
  }

  function passwordsMatch (password, password_confirm) {
    return password === password_confirm;
  }

  async function handleSubmit (data) {
    setLoadingSubmit(true);

    const userRegisters = data["container-registers"];

    if (userRegisters) {
      const registerField = entityUpd.fields.find(
        (field) => field.id === "container-registers",
      );
      if (!registerField) {
        return;
      }

      for (const register in userRegisters) {
        if (userRegisters[register] !== "-" &&
          !defaultValues["container-registers"][register]) {
          await Sdk.dynamic.bridge(
            `cms/v1.0/learning/register/uid/${register}/user/uid/${match.params.userUid}/management/register`,
            { access_status: userRegisters[register] },
            "POST",
          );
        } else if (userRegisters[register] !== "-") {
          await Sdk.dynamic.bridge(
            `cms/v1.0/learning/register/uid/${register}/user/uid/${match.params.userUid}/management/register`,
            { access_status: userRegisters[register] },
            "PUT",
          );
        }
      }
    }

    const uids = await ids.map((item) => {
      if (data[item]) {
        return Object.keys(data[item]).filter((key) => data[item][key]);
      }
    });

    const filteredUids = await uids.filter(function (el) {
      return el != null;
    });

    const body = await formatBody(data, entity.submitModel);
    body.detail.name = `${data.first_name} ${data.last_name}`;

    if (!data.password && !data.password_confirm) {
      delete body.password;
    }

    if (body?.detail?.birthday === "birthday") {
      delete body.detail.birthday;
    }

    if (body?.detail?.cpf === "cpf") {
      delete body.detail.cpf;
    }

    if (body?.detail?.email_principal === "email_principal") {
      delete body.detail.email_principal;
    }

    if (data?.password?.length) {
      if (!passwordsMatch(data.password, data.password_confirm)) {
        setLoadingSubmit(false);
        setMsgError("As senhas são diferentes");
        return;
      }
    }

    if (user && "user_properties" in user) {
      // body.user_properties = [];
      for (const userProperty of user.user_properties) {
        if (userProperty.value) {
          const value = data[`property-${userProperty.name}`] || undefined;
          if (value !== undefined) {
            delete body[`property-${userProperty.name}`];
            userProperty.value = value;
            await Sdk.dynamic.bridge(
              `auth/v1.0/user/management/property/uid/${userProperty.uid}`,
              userProperty,
              "PUT",
            );
          }
        }
      }
    }

    const directory = await SDK.dynamic.bridge(`auth/v1.0/directory/uid/${SDK.Api.authorization.activeProject.directory.uid}`, null, "GET")

    if (directory && "config" in directory) {
      for (const property of directory.config.user_required_properties) {
        const hasProp = user.user_properties.find(p => p.name === property.name && "uid" in p)
        if (hasProp === undefined) {
          const value = data[`property-${property.name}`] || undefined;
          if (value !== undefined) {
            delete body[`property-${property.name}`];
            property.value = value;
            await Sdk.dynamic.bridge(
              `auth/v1.0/user/uid/${match.params.userUid}/management/property`,
              property,
              "POST",
            );
          }
        }
      }
    }

    try {
      updateUserEntities(filteredUids.flat());
      const user = await Sdk.dynamic.bridge(
        `auth/v1.0/user/uid/${match.params.userUid}/management`,
        body,
        "PUT",
      );

      setLoadingSubmit(false);
      setMsgSuccess("Usuário editado com sucesso!");
    } catch (error) {
      setLoadingSubmit(false);
      setMsgError("Erro ao editar usuário!");
    }
  }

  async function updateUserEntities (selectedEntities) {
    let toRegister = selectedEntities.filter(
      (x) => !userEntityList.includes(x),
    );
    let toDelete = userEntityList.filter((x) => !selectedEntities.includes(x));

    await toRegister.map(async (uid) => {
      await Sdk.dynamic.bridge(
        `auth/v1.0/entity/uid/${uid}/member`,
        { user_uid: match.params.userUid },
        "POST",
      );
    });

    await toDelete.map(async (uid) => {
      await Sdk.dynamic.bridge(
        `auth/v1.0/entity/uid/${uid}/member`,
        { user_uid: match.params.userUid },
        "DELETE",
      );
    });
  }

  const onRegisterSubmitNewUser = async () => {
    setSubmitNewUserStatus("loading");
    try {
      await Sdk.dynamic.bridge(`communication/v1.0/send/message/management`, {
        "action_type": "NewUserInProject",
        "user_uid": match.params.userUid,
        "type": "Email",
        "target_type": "Project",
        "target_uid": Sdk.Api.authorization.activeProject.uid,
        "context_type": "Directory",
        "context_uid": Sdk.Api.authorization.activeProject.directory.uid,
      }, "POST");
    } catch (error) {
      console.error(error);
    }
    setSubmitNewUserStatus("success");
    setTimeout(function () {
      setSubmitNewUserStatus("loaded");
    }, 3000);
  };

  const onRegisterSubmitForgotPassword = async () => {
    setSubmitForgotPasswordStatus("loading");
    try {
      await Sdk.dynamic.bridge(`communication/v1.0/send/message/management`, {
        "action_type": "UserResetPassword",
        "user_uid": match.params.userUid,
        "type": "Email",
        "target_type": "Project",
        "target_uid": Sdk.Api.authorization.activeProject.uid,
        "context_type": "Directory",
        "context_uid": Sdk.Api.authorization.activeProject.directory.uid,
      }, "POST");
    } catch (error) {
      console.error(error);
    }
    setSubmitForgotPasswordStatus("success");
    setTimeout(function () {
      setSubmitForgotPasswordStatus("loaded");
    }, 3000);
  };

  const checkIfHasRole = (role) => {
    var has = false;
    for (var i = 0; i < tempRoles.length; i++) {
      if (role.type === tempRoles[i].type) {
        has = true;
      }
    }

    return has;
  };

  const handleRole = async (checked, role) => {
    if (checked) {
      removeRole(role);
    } else {
      setRole(role);
    }
  };

  const setRole = async (role) => {
    var obj = {
      "type": role.type,
    };
    await Sdk.dynamic.bridge(`auth/v1.0/user/uid/${match.params.userUid}/role`,
      obj, "POST");
    getUserByUid(match.params.userUid);
  };

  const removeRole = async (role) => {
    var roleUid;
    for (var i = 0; i < tempRoles.length; i++) {
      if (tempRoles[i].type === role.type) {
        roleUid = tempRoles[i].uid;
      }
    }

    Sdk.dynamic.bridge(
      `auth/v1.0/user/uid/${match.params.userUid}/role/uid/${roleUid}`, null,
      "DELETE");

    setTimeout(function () {
      getUserByUid(match.params.userUid);
    }, 100);
  };

  const handleChangeRegisterSelect = (row, index) => async event => {
    if (userRegistersTableActionLoading.includes(row.uid)) {
      return;
    } else {
      setUserRegistersTableActionLoading(
        [...userRegistersTableActionLoading, row.uid]);
    }
    let register;

    try {
      const value = event.target.value;

      if (!row.register) {
        register = await Sdk.dynamic.bridge(
          `cms/v1.0/learning/register/uid/${row.uid}/user/uid/${match.params.userUid}/management/register`,
          {access_status: value},
          "POST",
        );
      } else {
        register = await Sdk.dynamic.bridge(
          `cms/v1.0/learning/register/uid/${row.register.uid}/user/uid/${match.params.userUid}/management/register`,
          { access_status: value },
          "PUT",
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      await loadRegistrations(index, register);
      setUserRegistersTableActionLoading(
        userRegistersTableActionLoading.filter(x => x !== row.uid));
    }
  };

  const getAccessStatusElement = (status) => {
    const statuses = {
      Undefined: (
        <Components.Tags.Tag
          color='grey'
          height='22px'
          fontWeight='bold'
          fontSize='8px'
        >
          Não definido
        </Components.Tags.Tag>
      ),
      Active: (
        <Components.Tags.Tag
          color='green'
          height='22px'
          fontWeight='bold'
          fontSize='8px'
        >
          Ativo
        </Components.Tags.Tag>
      ),
      Blocked: (
        <Components.Tags.Tag
          color='red'
          height='22px'
          fontWeight='bold'
          fontSize='8px'
        >
          Bloqueado
        </Components.Tags.Tag>
      ),
      Inactive: (
        <Components.Tags.Tag
          color='yellow'
          height='22px'
          fontWeight='bold'
          fontSize='8px'
        >
          Inativo
        </Components.Tags.Tag>
      ),
    };
    return statuses[status] || (
      <Components.Tags.Tag
        color='grey'
        height='22px'
        fontWeight='bold'
        fontSize='8px'
      >
        Sem matrícula
      </Components.Tags.Tag>
    );
  };

  return (
    <>
      {entity ? (
        <div className='rdp-admin-new-entity'>
          <Header />
          <Sidebar
            defineGroup={consumer ? consumer.defineGroup : null}
            defineRoute={consumer ? consumer.defineRoute : null}
            groups={consumer ? consumer.groups : null}
          />
          <Breadcrumb
            customNewOptionTitle='asds'
            currentRoute={consumer ? consumer.currentRoute : null}
          />
          <div id='rdp-admin-content-area' className='rdp-admin-content'>
            {/* <div className="mail-btn">
              <ButtonDynamic
                disabled={false}
                right
                color="blue"
                actionClick={() => alert('email')}
                iconDiv={true}
              >
                send first access email
              </ButtonDynamic>
            </div> */}
            {loadingSubmit && <Loading msg='Salvando...' />}
            {!loadingSubmit && msgSuccess && (
              <NotifyBox
                type='success'
                onClose={() => setMsgSuccess("")}
                message={msgSuccess}
              />
            )}
            {!loadingSubmit && msgError && (
              <NotifyBox
                type='error'
                onClose={() => setMsgError("")}
                message={msgError}
              />
            )}
            <div className='test'>
              <div className='edit-offer-form-buttons'>
                <button
                  className={`tab-button ${formIndex === 0 ? "active" : ""}`}
                  onClick={() => setFormIndex(0)}>
                  Informações
                </button>
                <button
                  className={`tab-button ${formIndex === 3 ? "active" : ""}`}
                  onClick={() => setFormIndex(3)}>
                  Matrículas
                </button>
                <button
                  className={`tab-button ${formIndex === 1 ? "active" : ""}`}
                  onClick={() => setFormIndex(1)}>
                  Permissões
                </button>
                <button
                  className={`tab-button ${formIndex === 2 ? "active" : ""}`}
                  onClick={() => setFormIndex(2)}>
                  Envio de e-mails
                </button>
              </div>

              {formIndex === 0 &&
                <>
                  {entityUpd && defaultValues && (
                    <DynamicForm
                      handleSubmit={handleSubmit}
                      form={entityUpd}
                      submitText={entity.submitText}
                      defaultValues={defaultValues}
                      autoComplete='off'
                    />
                  )}
                </>
              }

              {formIndex === 3 &&
                <>
                  {
                    (userRegistersTableRows !== null) && (
                      <div className='info-counter'>
                        Mostrando <strong>{userRegistersTableRows.length}</strong> resultados
                      </div>
                    )
                  }
                  <table className='rdp-admin-table' style={{ padding: "8px" }}>
                    <thead>
                    <tr>
                      <th>ID</th>
                      <th>Nome da Turma</th>
                      <th>Nome do Curso</th>
                      <th>Status Matrícula</th>
                      <th>Descrição</th>
                      <th>Criado em</th>
                      <th>Matrícula</th>
                    </tr>
                    </thead>

                    <tbody>
                    {
                      userRegistersTableRows !== null &&
                      userRegistersTableRows.map((row, index) => (
                        <tr key={row.uid}>
                          <td>{row.uid}</td>
                          <td>{row.name || row.register.name}</td>
                          <td>{row.course.title}</td>
                          <td>
                            {getAccessStatusElement(row.access_status)}
                          </td>
                          <td>{row.description || row.register.description}</td>
                          <td>
                            {moment().local().format("DD/MM/YYYY - HH:mm:ss")}
                          </td>
                          <td>
                            {
                              userRegistersTableActionLoading.includes(row.uid)
                                ? (
                                  <ClassicSpinner color='black' />
                                )
                                : (
                                  <select
                                    onChange={handleChangeRegisterSelect(row, index)}
                                    style={{ paddingRight: 12 }}
                                    value={row.register ? enrollmentFormDefaultValues[row.register.uid] || "" : ""}
                                  >
                                    <option value='' disabled>Sem Matrícula
                                    </option>
                                    {row.access_status &&
                                      <option value='Blocked'>Cancelado
                                        (admin)</option>}
                                    <option value='Active'>Inscrito</option>
                                  </select>
                                )
                            }
                          </td>
                        </tr>
                      ))
                    }
                    {isLoadingRegisterTable && (
                      <tr>
                        <td colSpan='7'>
                          <Loading msg='Carregando tabela...' />
                        </td>
                      </tr>
                    )}
                    </tbody>
                  </table>
                </>
              }

              {formIndex === 1 &&
                <ul className="edit-offer-permissions">
                  {roles && roles.length > 0 && roles.map(function(role, index) {
                    return <li key={index} className="edit-offer-permission">
                      <Switch
                        checked={checkIfHasRole(role)}
                        className="edit-offer-permission-switch"
                        onColor="#c9b2e7"
                        onHandleColor="#764ab0"
                        handleDiameter={30}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={20}
                        width={48}
                        id="material-switch"
                        onChange={(e) => handleRole(checkIfHasRole(role), role)}
                      />
                      <span>{role.label}</span>
                    </li>
                  })}
                </ul>
              }

              {formIndex === 2 &&
                <div className="paper">
                  <div className="edit-offer-form-email-btn" onClick={() => onRegisterSubmitNewUser()}>
                    {submitNewUserStatus === "loaded" &&
                      <span className="edit-offer-form-email-btn-label">Enviar e-mail de Primeiro Acesso</span>
                    }
                    {submitNewUserStatus === "loading" &&
                      <>
                        <span className="edit-offer-form-email-btn-label">Enviando e-mail de Primeiro Acesso...</span>
                        <span className="edit-offer-form-email-btn-icon-loading"></span>
                      </>
                    }
                    {submitNewUserStatus === "success" &&
                      <>
                        <span className="edit-offer-form-email-btn-label">E-mail de Primeiro Acesso enviado!</span>
                        <span className="edit-offer-form-email-btn-icon-success"></span>
                      </>
                    }
                  </div>
                  <div className="edit-offer-form-email-btn" onClick={() => onRegisterSubmitForgotPassword()}>
                    {submitForgotPasswordStatus === "loaded" &&
                      <span className="edit-offer-form-email-btn-label">Enviar e-mail de Recuperação de Senha</span>
                    }
                    {submitForgotPasswordStatus === "loading" &&
                      <>
                        <span className="edit-offer-form-email-btn-label">Enviando e-mail de Recuperação de Senha...</span>
                        <span className="edit-offer-form-email-btn-icon-loading"></span>
                      </>
                    }
                    {submitForgotPasswordStatus === "success" &&
                      <>
                        <span className="edit-offer-form-email-btn-label">E-mail de Recuperação de Senha enviado!</span>
                        <span className="edit-offer-form-email-btn-icon-success"></span>
                      </>
                    }
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      ) : (
        <Loading msg="Montando Formulário..." />
      )}
      <Footer />
    </>
  );
}
