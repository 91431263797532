import useSWR from "swr";
import cmsService from "../services/cms-service";

export default function useOptimisticFetchReportScoresManagement(registerUid?: string | null, searchQuery?: IPaginationQuery) {
  const resp = useSWR([registerUid, searchQuery], cmsService.learningManagementEndpoint.reportCourseActivityScoreListManagement);
  let data = resp.data;

  return {
    ...resp,
    data,
  };
}