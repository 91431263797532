import React from 'react';
import 'react-responsive-modal/styles.css';
import './dialog.scss';
import {IconCloseModal, IconPreview} from '../icon';

export default function Dialog({
  title = 'Caixa de diálogo',
  open,
  onClose,
  onCancel,
  cancelText = "Cancelar",
  onConfirm,
  icon = <IconPreview />,
  confirmText = 'Confirmar',
  confirmColor = "",
  children
}) {
  return (
    <div
      aria-hidden={open}
      role="dialog"
      className={`rdp-modal ${open ? 'open' : ''}`}
      onClose={onClose}
    >
      <div className="rdp-modal-overlay" onClick={onClose}></div>
      <div className="rdp-modal-container">
        <div className="rdp-modal-container-header">
          <div className="header-left">
            <div className="icon">{icon}</div>
            <span>{title}</span>
          </div>
          <button
            type="button"
            className="rdp-modal-close"
            onClick={onClose}
          >
            <IconCloseModal />
          </button>
        </div>
        <div className="rdp-modal-container-content">
          {children}
        </div>
        <div className="rdp-modal-container-footer">
          <button
            className="rdp-modal-button-cancel margin-right"
            onClick={onCancel}
          >
            {cancelText}
          </button>

          <button
            className={`rdp-modal-button-delete ${confirmColor}`}
            onClick={onConfirm}
          >
            {confirmText}
          </button>
        </div>
      </div>
    </div>
  );
}
