import useSWR from "swr";
import authService from "../services/auth-service";
import IEntitySchema from "../@types/IEntitySchema";
import toast from "react-hot-toast";
import useLocalization from "./useLocalization";

export default function useOptimisticFetchSchemas(projectUid: Guid) {
  const l = useLocalization();
  const resp = useSWR(projectUid, authService.entityEndpoint.listEntitySchemaInProject);
  let data: Array<IEntitySchema> | undefined = resp.data;

  return {
    ...resp,
    data,
    createSchema: async (data: IEntitySchema) => {
      if (resp.data) {
        let schema: IEntitySchema | null = null;
        let schemas = resp.data;

        const promise = new Promise<IEntitySchema[]>(async (resolve, reject) => {
          if (resp.data) {
            try {
              schema = await authService.entityEndpoint.adminCreateEntitySchemaInProject(projectUid, data, resp.data);
            } catch (e) {
              // @ts-ignore
              if (e.toString() === "Error: Schema name already exists") {
                toast.error(l("schema name already exists"));
              }
              reject(schemas);
            }
          }

          if (schema && schema.uid && resp.data && resp.data.length === 0) {
            await Promise.all([
              authService.entityEndpoint.createEntity<IEntityItemData>(schema.uid, {
                data: {
                  title: "Setor Administrativo",
                  initials: "ADM"
                },
                column: 0,
                row: 0,
                roles: "inherited"
              }, []),
              authService.entityEndpoint.createEntity<IEntityItemData>(schema.uid, {
                data: {
                  title: "Setor Financeiro",
                  initials: "FIN"
                },
                column: 0,
                row: 2,
                roles: "inherited"
              }, []),
              authService.entityEndpoint.createEntity<IEntityItemData>(schema.uid, {
                data: {
                  title: "Setor de Recursos Humanos",
                  initials: "RH"
                },
                column: 0,
                row: 3,
                roles: "inherited"
              }, []),
              authService.entityEndpoint.createEntity<IEntityItemData>(schema.uid, {
                data: {
                  title: "Setor Operacional",
                  initials: "OP"
                },
                column: 0,
                row: 4,
                roles: "inherited"
              }, []),
              authService.entityEndpoint.createEntity<IEntityItemData>(schema.uid, {
                data: {
                  title: "Operacional 1",
                  initials: "OP1"
                },
                column: 1,
                row: 5,
                roles: "inherited"
              }, [])
            ]);
          }
          if (schema) {
            resolve([...schemas, schema]);
          }
        });
        
        return toast.promise(
          resp.mutate(promise, {
            optimisticData: [...resp.data, data],
            rollbackOnError: true,
            populateCache: true,
            revalidate: false
          }).then(() => schema),
          {
            loading: l("Saving..."),
            success: l("Saved!"),
            error: l("Could not save.")
          }
        );
      }
    },
    deleteSchema: async (schemaUid: Guid) => {
      if (resp.data) {
        const deletion = await authService.entityEndpoint.adminDeleteSchema(schemaUid);
        const filtered = resp.data.filter(x => x.uid !== schemaUid);
        return toast.promise(
          resp.mutate(deletion ? filtered : resp.data, {
            optimisticData: filtered,
            rollbackOnError: true,
            populateCache: true,
            revalidate: false
          }).then(() => deletion),
          {
            loading: l("Deleting..."),
            success: l("Deleted!"),
            error: l("Could not delete.")
          }
        );
      }
    }
  };
}