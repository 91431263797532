const Configuration = {
  getEntity: () => ({
    layout_configuration : {
      hide_event_period : true,
      hide_products_restriction : true
    },
    configuration : [
      {
        field: "Login",
        identifier: "login",
        type: "input-text",
        group: "Acesso"
      },
      {
        field: "Nome",
        identifier: "detail.name",
        type: "input-text",
        group: "Detalhes"
      },
      {
        field: "Contato",
        identifier: "detail.name",
        type: "input-text",
        group: "Detalhes"
      },
      {
        field: "Imagem de perfil",
        identifier: "detail.name",
        type: "image",
        column: "right",
        group: "Imagem de perfil"
      },
      {
        field: "Grupos associados",
        identifier: "detail.name",
        type: "input-text",
        group: "Grupos"
      },
    ]
  })
};

export default Configuration;
