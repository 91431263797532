import React, { useState, useEffect } from 'react';
import { GenericList } from '../../builders/list';
import { Footer } from '../../components';
import Loading from '../../components/loading';
import Sdk from 'api.digitalpages.module.sdk.api';
import { textFromUid } from '../../utils/textUtilities';
import { toast } from 'react-toastify';

export default function ListProducts({ consumer, entity, history }) {
  const [elements, setElements] = useState([]);
  const [messages, setMessages] = useState({});
  const [config, setConfig] = useState({});
  const [preloader, setPreloader] = useState(true);

  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    configTable();
    getProducts();

    consumer.defineRoute({
      name: textFromUid('screen_products_title'),
      uri: '/store/products',
    });
  }, []);

  async function configTable() {
    const response = await fetch('./preferences.json');

    const { tables } = await response.json();

    setConfig(tables.products);
  }

  async function getProducts() {
    setPreloader(true);

    const tmpStructureCp = { ...elements };
    let schemaData = [];
    const result = await Sdk.dynamic.bridge(
      `store/v1.0/product/available`,
      null,
      'GET'
    );

    schemaData = result;

    schemaData.forEach((uidSchemas) => {
      tmpStructureCp.result = Array.isArray(tmpStructureCp.result)
        ? [...tmpStructureCp.result, uidSchemas]
        : [uidSchemas];

      tmpStructureCp.current_page = 1;
      tmpStructureCp.page_count = 1;
      tmpStructureCp.page_size = schemaData.length;
      tmpStructureCp.row_count = schemaData.length;

      if (schemaData.length == 0) {
        setMessages({ noContent: 'Nenhum produto registrado' });
      }
    });

    setElements(tmpStructureCp);

    setPreloader(false);
  }

  async function deleteProduct(productUid) {
    try {
      const result = await Sdk.dynamic.bridge(
        `store/v1.0/product/uid/${productUid}/management`,
        null,
        'DELETE'
      );

      const testttte = await getProducts();
    } catch (error) {}
  }

  const handleUpdate = (productUid) => {
    history.push(`/store/product/${productUid}`);
  };

  const handleDelete = async (productUid) => {
    setDeleting(true);
    setPreloader(true);

    try {
      await toast.promise(deleteProduct(productUid), {
        pending: textFromUid('pending_message_product_delete'),
        success: textFromUid('success_message_product_delete'),
        error: textFromUid('error_message_user_product_delete'),
      });

      await getProducts();
    } catch (error) {}
    setDeleting(false);
    setPreloader(false);
    window.location.reload();
  };

  return (
    <>
      <GenericList
        // enableLocalSearch={true}
        // localSearchPlaceholder="Buscar entidades"
        buttonDelete={false}
        consumer={consumer}
        elements={elements}
        messages={messages}
        fields={config.fields}
        options={config.options}
        hideDelete={true}
        breadcrumb={{
          selectOptions: null,
          routeNew: '/product/new',
          label: entity,
          customNewOptionTitle:textFromUid("button_new_product")
        }}
        loading={{
          preloader,
        }}
        actions={{
          onUpdate: handleUpdate,
          onDelete: handleDelete,
        }}
      />
      {deleting === true ? <Loading msg={'carregante'} /> : null}
      <Footer />
    </>
  );
}
