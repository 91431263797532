import React, { useState, useEffect } from 'react';
import {
  Header,
  Sidebar,
  Breadcrumb,
  NotifyBox,
  Footer,
} from '../../components';
import Loading from '../../components/loading';
import DynamicForm from '../../components/form-dynamic-v2';
import './new-user.scss';
import { formatBody } from '../../utils/formatBody';
import { formatColumnToJson } from '../../utils/formatColumnToJSON';

import Sdk from 'api.digitalpages.module.sdk.api';
import SDK from "api.digitalpages.module.sdk.api";

export default function NewUser({ consumer, history }) {
  const [entity, setEntity] = useState(null);
  const [entityUpd, setEntityUpd] = useState(null);
  const [canAddEntity, setCanAddEntity] = useState(null);
  const [ids, setIds] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState('');
  const [msgError, setMsgError] = useState('');

  async function getFormFields() {
    const response = await fetch('./preferences.json');

    const { forms } = await response.json();

    const form = forms['new-user'];
    const props = [];

    const directory = await SDK.dynamic.bridge(`auth/v1.0/directory/uid/${SDK.Api.authorization.activeProject.directory.uid}`, null, "GET")

    if (directory && "user_required_properties" in directory) {
      const required_properties = directory.user_required_properties;

      required_properties.forEach(property => {
        props.push({
          ...property,
          value: ""
        });
      });
    }

    form.fields = form.fields.map(field => {
      if (field.id === "properties") {
        field.options = props;
      }

      return field;
    });


    setEntity(forms['new-user']);

    consumer.defineRoute({
      name: forms['new-user'].pageTitle,
      path: 'users/new',
    });
  }

  const getOptions = async (list) => {
    const options = await list.map((item) => {
      return { uid: item.uid, label: item.data[Object.keys(item.data)[0]] };
    });

    return options;
  };

  const loadEntities = async () => {
    if (!canAddEntity) return;

    if (entity) {
      const entitiesCp = { ...entity };
      let idList = [];

      const res = await Sdk.dynamic.bridge(
        `auth/v1.0/entity/management/schema/available?type=security`,
        null,
        'GET'
      );

      for (const [idx, entity] of res.entries()) {
        const tree = await Sdk.dynamic.bridge(
          `auth/v1.0/entity/management/schema/uid/${entity.uid}/items`,
          null,
          'GET'
        );

        const schemaInfo = await Sdk.dynamic.bridge(
          `auth/v1.0/entity/management/schema/uid/${entity.uid}`,
          null,
          'GET'
        );

        function orderByRows(obj) {
          const orderedObj = [...obj].sort((a, b) => a.row - b.row);
          return orderedObj;
        }

        const newTree = orderByRows(tree)

        // let [checkboxes, test] = formatColumnToJson(tree, tree[0], tree[0]);

        entitiesCp.fields.push({
          id: `container-category-${idx}`,
          group: entity.name,
          schema_properties: schemaInfo.properties,
          required: true,
          type: 'input-tree-checkbox',
          config: {},
          field: `entities-${idx}`,
          array: newTree,
        });

        idList.push(`container-category-${idx}`);
      }

      setEntityUpd(entitiesCp);
      setIds(idList);
    }
  };

  useEffect(() => {
    getFormFields();
    checkRoles(Sdk.authorization.user.roles);
  }, []);

  useEffect(() => {
    if (canAddEntity) {
      loadEntities();
    }
  }, [entity, canAddEntity]);

  function passwordsMatch(password, password_confirm) {
    if (password === password_confirm) {
      return true;
    } else {
      return false;
    }
  }

  // async function registerUserOnProject(userUid) {
  //   Sdk.dynamic.bridge(
  //     `auth/v1.0/project/uid/${Sdk.authorization.activeProject.uid}/register/user/uid/${userUid}`,
  //     null,
  //     'POST'
  //   );
  // }

  async function registerUserOnEntity(uids, userUid) {
    if (Array.isArray(uids))
      uids.map(
        async (uid) =>
          await Sdk.dynamic.bridge(
            `auth/v1.0/entity/uid/${uid}/member`,
            { user_uid: userUid },
            'POST'
          )
      );
  }

  async function handleSubmit(dataForm) {
    setLoadingSubmit(true);

    var uids = [];
    var filteredUids = [];

    if (ids) {
      uids = await ids.map((item) => {
        if (dataForm[item]) {
          return Object.keys(dataForm[item]).filter(
            (key) => dataForm[item][key]
          );
        }
      });

      filteredUids = await uids.filter(function (el) {
        return el != null;
      });
    }

    const { password, password_confirm } = dataForm;

    let body = await formatBody(dataForm, entity.submitModel);
    body.detail.name = `${body?.detail?.first_name} ${body?.detail?.last_name}`;

    if (body?.detail?.birthday === "birthday" || body?.detail?.birthday === "") {
      delete body.detail.birthday;
    }

    if (body?.detail?.cpf === "cpf") {
      delete body.detail.cpf;
    }

    if (body?.detail?.email_principal === "email_principal") {
      delete body.detail.email_principal;
    }

    const directory = await SDK.dynamic.bridge(`auth/v1.0/directory/uid/${SDK.Api.authorization.activeProject.directory.uid}`, null, "GET")

    if (directory && "user_required_properties" in directory) {
      body.user_properties = [];
      for (const property of directory.user_required_properties) {
        const value = dataForm[`property-${property.name}`] || undefined;

        if (value !== undefined) {
          delete body[`property-${property.name}`];
          property.value = value;

          body.user_properties.push(property);
        }
      }
    }

    if ((password && passwordsMatch(password, password_confirm)) || !password) {
      try {
        const user = await Sdk.dynamic.bridge(
          `auth/v1.0/user/project/uid/${Sdk.authorization.activeProject.uid}`,
          body,
          'POST'
        );

        await registerUserOnEntity(filteredUids.flat(), user.uid);
        // await registerUserOnProject(user.uid);

        // if (dataForm.firstMail) {
        //   alert('enviar email ');
        // }

        setLoadingSubmit(false);
        setMsgSuccess('Usuário registrado com sucesso!');
        history.push(`/users/${user.uid}`);
      } catch (error) {
        console.log(error);
        setLoadingSubmit(false);
        setMsgError('Erro ao registrar o usuário');
      }
    } else {
      setLoadingSubmit(false);
      setMsgError('As senhas são diferentes');
    }
  }

  async function checkRoles(items) {
    if (!items) return false;

    const roles = [
      'AdministratorGlobal',
      'SchemaAdmin',
      'SchemaContributor',
      'EntityAdmin',
      'EntityContributor',
    ];
    const restrictedRole = 'TeamAdmin';

    const userRoles = items.map((item) => item.type);

    const hasRestrictedRole = userRoles.includes(restrictedRole);
    const canEdit = !hasRestrictedRole && userRoles.some((r) => roles.includes(r));

    setCanAddEntity(canEdit);
  }

  if (canAddEntity === false) {
    return null;
  }

  return (
    <>
      {entity ? (
        <div className="rdp-admin-new-entity">
          <Header />
          <Sidebar
            currentRoute="/users/new"
            defineGroup={consumer ? consumer.defineGroup : null}
            defineRoute={consumer ? consumer.defineRoute : null}
            groups={consumer ? consumer.groups : null}
          />
          <Breadcrumb currentRoute={consumer ? consumer.currentRoute : null} />
          <div id="rdp-admin-content-area" className="rdp-admin-content">
            {loadingSubmit && <Loading msg="Salvando..." />}
            {!loadingSubmit && msgSuccess && (
              <NotifyBox
                type="success"
                onClose={() => setMsgSuccess('')}
                message={msgSuccess}
              />
            )}
            {!loadingSubmit && msgError && (
              <NotifyBox
                type="error"
                onClose={() => setMsgError('')}
                message={msgError}
              />
            )}
            <div className="test">
              {entity && (
                <DynamicForm
                  handleSubmit={handleSubmit}
                  form={entityUpd ? entityUpd : entity}
                  submitText={entity.submitText}
                  // defaultValues={defaultValues}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <Loading msg="Montando Formulário..." />
      )}
      <Footer />
    </>
  );
}
