import React, { Component } from "react";
import SDK from "api.digitalpages.module.sdk.api";
import { LoginService } from "../../services/login";
import "./header.scss";

import Loading from "../loading";
import RDP_UTILS from "../../config/util";
import { Toaster } from "react-hot-toast";

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: null,
      projects: [],
      active_project: null,
      active_directory: null,
      active_user_dropdown: false,
      active_projects_dropdown: false,
      active_directies_dropdown: false,
      displayed_preloader: false,
    };

    this.updateUserAccess = this.updateUserAccess.bind(this);
  }

  async componentDidMount() {
    await this.updateUserAccess();

    SDK.authorization.addUserInfoListen(this.updateUserAccess);
    SDK.authorization.addActiveProjectListen(this.updateUserAccess);
  }

  async updateUserAccess() {
    const actProject = SDK.authorization.activeProject;
    const { projects } = SDK.authorization;

    let userName = null;

    if (SDK.authorization.user) {
      if (SDK.authorization.user.detail) {
        userName = SDK.authorization.user.detail.name;
      } else {
        // versão antiga da API
        userName = SDK.authorization.user.name;
      }
    }

    this.setState({
      username: userName !== null ? userName : null,
      projects: projects || [],
      active_project: actProject || null,
      active_directory: null,
    });
  }

  logout() {
    this.toggleUserDropdown();
    LoginService.logout().then((data) => {
      localStorage.clear();
      RDP_UTILS.redirectTo('login');
    });
  }

  async changeProject(project) {
    this.setState({
      displayed_preloader: true,
    });
    
    window.localStorage.setItem("rdp-manager-active-project", project.uid);

    await SDK.authorization.setActiveProject(project);
    // window.location.reload();
  }

  async changeDirectory(directory) {
    this.setState({
      displayed_preloader: true,
    });

    await SDK.authorization.signinVirtualInDirectory(directory.uid);

    this.setState({ displayed_preloader: false });

    await this.updateUserAccess();
  }

  renderProjectsDropdown() {
    const _this = this;
    const activeProject = this.state.active_project;
    const { projects } = this.state;

    // todo:ajustar posição do popup
    if (this.state.active_projects_dropdown === true) {
      return (
        <div className="rdp-analytics-header-items-dropdown-container">
          <div
            className="rdp-analytics-header-items-dropdown-overlay"
            onClick={() => this.toggleProjectsDropdown()}
          />
          <ul className="rdp-analytics-header-items-dropdown-popup">
            {projects.map((project, index) => (
              <li
                key={`${index}_pro`}
                className={`rdp-analytics-header-items-dropdown-popup-name ${
                  activeProject && activeProject.uid === project.uid
                    ? 'active'
                    : ''
                }`}
                onClick={() => _this.changeProject(project)}
              >
                {project.name}
              </li>
            ))}
          </ul>
        </div>
      );
    }
  }

  toggleProjectsDropdown() {
    this.setState({
      active_projects_dropdown: !this.state.active_projects_dropdown,
      active_directies_dropdown: false,
    });
  }

  toggleDirectoriesDropdown() {
    this.setState({
      active_directies_dropdown: !this.state.active_directies_dropdown,
      active_projects_dropdown: false,
    });
  }

  renderUserDropdown() {
    if (this.state.active_user_dropdown === true) {
      return (
        <div className="rdp-analytics-header-user-dropdown-container">
          <div
            className="rdp-analytics-header-user-dropdown-overlay"
            onClick={() => this.toggleUserDropdown()}
          />
          <div className="rdp-analytics-header-user-dropdown-popup">
            <span
              className="rdp-analytics-header-user-dropdown-signout"
              onClick={() => this.logout()}
            >
              Logout
            </span>
          </div>
        </div>
      );
    }
  }

  toggleUserDropdown() {
    this.setState({
      active_user_dropdown: !this.state.active_user_dropdown,
    });
  }

  renderPreloader() {
    if (this.state.displayed_preloader === true) {
      return <Loading globalLoading msg="RDP Manager - Loading Project ..." />;
    }
  }

  render() {
    const activeProjectName =
      this.state.active_project !== null ? this.state.active_project.name : '';
    this.renderPreloader();

    if (window.self !== window.top) {
      return (
        <></>
      )
    } else {
      return (
        <div className="rdp-admin-component-header">
          <Toaster
            position="top-right"
            reverseOrder={false}
          />
          
          <div
            role="button"
            className="rdp-admin-component-header-logo"
            onClick={() => {
              window.location.href = '#';
            }} 
          />
          {/* <span className="rdp-analytics-header-active-item" onClick={() => this.toggleProjectsDropdown()}>{activeProjectName}</span> */}
  
          <div className="rdp-analytics-header-options">
            <div
              className="rdp-analytics-header-active-item"
              onClick={() => this.toggleProjectsDropdown()}
            >
              <span className="rdp-analytics-header-active-item-label">
                Projeto
              </span>
              <span className="rdp-analytics-header-active-item-name">
                {activeProjectName}
              </span>
              {this.renderProjectsDropdown()}
            </div>
          </div>
  
          <div
            className="rdp-analytics-header-active-user"
            onClick={() => this.toggleUserDropdown()}
          >
            <span className="rdp-analytics-header-active-user-avatar" />
            <span className="rdp-analytics-header-active-user-hello">Olá,</span>
            <span className="rdp-analytics-header-active-user-name">
              {this.state.username}
            </span>
          </div>
  
          {this.renderUserDropdown()}
        </div>
      );
    }
  }
}
