import React, { useState, useEffect, useRef } from 'react';
import { GenericList } from '../../builders/list';
import {
  Header,
  Sidebar,
  Breadcrumb,
  Registrations
} from '../../components';
import Loading from '../../components/loading';
import Sdk from 'api.digitalpages.module.sdk.api';
import $ from 'jquery';
import './emails.scss';
import DynamicForm from '../../components/form-dynamic-v2';
import { DAMService } from '../../services/dam';
import { getConnectorFromArray } from '../../utils/getConnectorFromArray';
import PreloaderImage from './icon-preloader.gif';
import TemplateCsv from './template-csv.csv';

export default function ViewEmails({ consumer, entity, history }) {
  const [config, setConfig] = useState({});
  const [preferences, setPreferences] = useState(null);
  const [registrationsUsers, setRegistrationsUsers] = useState(null);
  const [registrationsRenderNewUser, setRegistrationsRenderNewUser] = useState(false);
  const [registrationsLoadingNewUser, setRegistrationsLoadingNewUser] = useState(true);
  const [registrationsRegisteringNewUser, setRegistrationsRegisteringNewUser] = useState(false);
  const [registrationsFeedbackNewUser, setRegistrationsFeedbackNewUser] = useState(null);

  const [registrationsRenderForgotPassword, setRegistrationsRenderForgotPassword] = useState(false);
  const [registrationsLoadingForgotPassword, setRegistrationsLoadingForgotPassword] = useState(null);
  const [registrationsRegisteringForgotPassword, setRegistrationsRegisteringForgotPassword] = useState(false);
  const [registrationsFeedbackForgotPassword, setRegistrationsFeedbackForgotPassword] = useState(null);

  const csvInputRefEmailNewUser = useRef();
  const csvInputRefEmailForgotPassword = useRef();

  async function configTable() {
    const res = await fetch('./preferences.json');
    const prefs = await res.json();
    setPreferences(prefs);

    // const { tables } = await response.json();

    const Configuration = {
      options: {
        hasCheckbox: false,
        hasEdit: true,
        hasDelete: true,
      }
    };

    setConfig(Configuration);
  }

  useEffect(() => {
    configTable();

    consumer.defineRoute({ name: 'Envio de e-mails', uri: '/emails' });
  }, []);

  async function onCsvFileChangeNewUser(evt) {
    var f = evt.currentTarget.files[0];

    setRegistrationsLoadingNewUser(true);
    setRegistrationsRenderNewUser(true);

    const apiUsers = await Sdk.Api.authorization.usersByProject();
    console.log(apiUsers);

    if (f) {
      var r = new FileReader();
      r.onload = function(e) { 
        var contents = e.target.result;

        const csvJSON = csv => {
          const lineBreak = /\r\n/.exec(csv) ? '\r\n' : '\n';
          var lines=csv.split(lineBreak);
          var result = [];
          var headers=lines[0].split(",");
        
          for(var i=1;i<lines.length;i++){
            var obj = {};
            var currentline=lines[i].split(",");
        
            for(var j=0;j<headers.length;j++){
              obj[headers[j]] = currentline[j];
            }
            result.push(obj);
          }

          return result;
        }

        var csvUsers = csvJSON(contents);
        var tempUsers = [];
    
        for (var i = 0; i < apiUsers.length; i++) {
          for (var j = 0; j < csvUsers.length; j++) {
            if (apiUsers[i].detail.email_principal === csvUsers[j].email) {
              tempUsers.push({
                "uid": apiUsers[i].uid,
                "name": apiUsers[i].detail.name,
                "email": csvUsers[j].email
              })
            }
          } 
        }

        setRegistrationsUsers(tempUsers);

        setRegistrationsLoadingNewUser(false);
      }
      r.readAsText(f);
    }
  }

  async function onCsvFileChangeForgotPassword(evt) {
    var f = evt.currentTarget.files[0];

    setRegistrationsLoadingForgotPassword(true);
    setRegistrationsRenderForgotPassword(true);

    const apiUsers = await Sdk.Api.authorization.usersByProject();
    console.log(apiUsers);

    if (f) {
      var r = new FileReader();
      r.onload = function(e) { 
        var contents = e.target.result;

        const csvJSON = csv => {
          const lineBreak = /\r\n/.exec(csv) ? '\r\n' : '\n';
          var lines=csv.split(lineBreak);
          var result = [];
          var headers=lines[0].split(",");
        
          for(var i=1;i<lines.length;i++){
            var obj = {};
            var currentline=lines[i].split(",");
        
            for(var j=0;j<headers.length;j++){
              obj[headers[j]] = currentline[j];
            }
            result.push(obj);
          }

          return result;
        }

        var csvUsers = csvJSON(contents);
        var tempUsers = [];
    
        for (var i = 0; i < apiUsers.length; i++) {
          for (var j = 0; j < csvUsers.length; j++) {
            if (apiUsers[i].detail.email_principal === csvUsers[j].email) {
              tempUsers.push({
                "uid": apiUsers[i].uid,
                "name": apiUsers[i].detail.name,
                "email": csvUsers[j].email
              })
            }
          } 
        }

        setRegistrationsUsers(tempUsers);

        setRegistrationsLoadingForgotPassword(false);
      }
      r.readAsText(f);
    }
  }

  function onImportCsvNewUser() {
    csvInputRefEmailNewUser.current.click();
  }

  function onImportCsvForgotPassword() {
    csvInputRefEmailForgotPassword.current.click();
  }

  const registrationsOnCloseNewUser = () => {
    csvInputRefEmailNewUser.current.value = "";
    setRegistrationsLoadingNewUser(true);
    setRegistrationsUsers(null);
    setRegistrationsFeedbackNewUser(null);
    setRegistrationsRenderNewUser(false);
  }

  const registrationsOnCloseForgotPassword = () => {
    csvInputRefEmailForgotPassword.current.value = "";
    setRegistrationsLoadingForgotPassword(true);
    setRegistrationsUsers(null);
    setRegistrationsFeedbackForgotPassword(null);
    setRegistrationsRenderForgotPassword(false);
  }

  const onRegisterSubmitNewUser = async () => {
    setRegistrationsRegisteringNewUser(true);

    var feedback = "success";
    for (var i = 0; i < registrationsUsers.length; i++) {
      try {
        await Sdk.dynamic.bridge(`communication/v1.0/send/message/management`, {
          "action_type" : "NewUserInProject",
          "user_uid" : registrationsUsers[i].uid,
          "type" :"Email",
          "target_type" : "Project",
          "target_uid" : Sdk.Api.authorization.activeProject.uid,
          "context_type" : "Directory",
          "context_uid" : Sdk.Api.authorization.activeProject.directory.uid
        }, "POST")
      } catch (error) {
        // var feedback = "error";
        console.log(error);
      }
    }

    setRegistrationsFeedbackNewUser(feedback);
    setRegistrationsRegisteringNewUser(false);
  }

  const onRegisterSubmitForgotPassword = async () => {
    setRegistrationsRegisteringForgotPassword(true);

    var feedback = "success";
    for (var i = 0; i < registrationsUsers.length; i++) {
      try {
        await Sdk.dynamic.bridge(`communication/v1.0/send/message/management`, {
          "action_type" : "UserResetPassword",
          "user_uid" : registrationsUsers[i].uid,
          "type" :"Email",
          "target_type" : "Project",
          "target_uid" : Sdk.Api.authorization.activeProject.uid,
          "context_type" : "Directory",
          "context_uid" : Sdk.Api.authorization.activeProject.directory.uid
        }, "POST")
      } catch (error) {
        // var feedback = "error";
        console.log(error);
      }
    }

    setRegistrationsFeedbackForgotPassword(feedback);
    setRegistrationsRegisteringForgotPassword(false);
  }

  const importDownloadCsvModelOnClick = () => {
    window.open(TemplateCsv, "_blank");
  }

  if (preferences) {
    return <>
      <Header />
      <Sidebar
        currentRoute={consumer ? consumer.currentRoute : null}
        defineGroup={consumer ? consumer.defineGroup : null}
        defineRoute={consumer ? consumer.defineRoute : null}
        groups={consumer ? consumer.groups : null}
      />
      <Breadcrumb
        currentRoute={consumer ? consumer.currentRoute : null}
        currentGroup={consumer ? consumer.currentGroup : null}
        options={null}
        newOption={null}
        label="Envio de e-mail"
        importDownloadCsvModelEnabled={true}
        importDownloadCsvModelOnClick={() => importDownloadCsvModelOnClick()}
      />

      {registrationsRenderNewUser && registrationsLoadingNewUser &&
        <Registrations
          headerTitle="Usuários que receberão o e-mail de Boas Vindas"
          loading={true}
          onClose={() => registrationsOnCloseNewUser()}
        />
      }

      {registrationsRenderNewUser && !registrationsLoadingNewUser &&
        <Registrations
          headerTitle="Usuários que receberão os e-mails de Boas Vindas"
          processBtnLabel="Enviar e-mails de Boas Vindas"
          processingBtnLabel="Enviando e-mails de Boas Vindas..."
          successMessage="E-mails enviados com sucesso!"
          errorMessage = "Erro ao enviar e-mails. Verifique se o arquivo CSV está correto."
          users={registrationsUsers}
          registering={registrationsRegisteringNewUser}
          onClose={() => registrationsOnCloseNewUser()}
          onSuccessBtnClick={() => registrationsOnCloseNewUser()}
          onErrorBtnClick={() => registrationsOnCloseNewUser()}
          onRegister={() => onRegisterSubmitNewUser()}
          feedback={registrationsFeedbackNewUser}
        />
      }

      {registrationsRenderForgotPassword && registrationsLoadingForgotPassword &&
        <Registrations
          headerTitle="Usuários que receberão os e-mails de Recuperação de Senha"
          loading={true}
          onClose={() => registrationsOnCloseForgotPassword()}
        />
      }

      {registrationsRenderForgotPassword && !registrationsLoadingForgotPassword &&
        <Registrations
          headerTitle="Usuários que receberão os e-mails de Recuperação de Senha"
          processBtnLabel="Enviar e-mails de Recuperação de Senha"
          processingBtnLabel="Enviando e-mails de Recuperação de Senha..."
          successMessage="E-mails enviados com sucesso!"
          errorMessage = "Erro ao enviar e-mails. Verifique se o arquivo CSV está correto."
          users={registrationsUsers}
          registering={registrationsRegisteringForgotPassword}
          onClose={() => registrationsOnCloseForgotPassword()}
          onSuccessBtnClick={() => registrationsOnCloseForgotPassword()}
          onErrorBtnClick={() => registrationsOnCloseForgotPassword()}
          onRegister={() => onRegisterSubmitForgotPassword()}
          feedback={registrationsFeedbackForgotPassword}
        />
      }
      
      <input ref={csvInputRefEmailNewUser} type="file" className="rdp-admin-view-emails-input-file" onChange={(e) => onCsvFileChangeNewUser(e)}/>
      <input ref={csvInputRefEmailForgotPassword} type="file" className="rdp-admin-view-emails-input-file" onChange={(e) => onCsvFileChangeForgotPassword(e)}/>

      <ul className="rdp-admin-view-emails">
        <li className="rdp-admin-view-emails-item" onClick={() => onImportCsvNewUser()}>
          Importar CSV para enviar e-mail de Primeiro Acesso
        </li>
        <li className="rdp-admin-view-emails-item" onClick={() => onImportCsvForgotPassword()}>
          Importar CSV para enviar e-mail de Recuperação de Senha
        </li>
      </ul>
    </>
  } else {
    return "";
  }
}